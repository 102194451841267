import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import Panel from 'components/panel'
import _ from 'lodash'

import styles from './styles.less'

class SearchPanel extends Component {
  constructor (props) {
    super(props)

    this.state = { value: this.props.value || '' }
    this.dispatchChangeCallback = _.debounce((value) => this.props.onChange(value), 300)
  }

  onChange (value) {
    this.setState({
      value: value
    })

    this.dispatchChangeCallback(value)
  }

  render () {
    return (
      <div className={styles.wrapper}>
        <Panel>
          <FormGroup>
            <InputGroup>
              <div className={styles.input}>
                <i className='fa fa-search' />
                <FormControl
                  type='text'
                  placeholder='Comece a digitar para pesquisar...'
                  value={this.state.value}
                  readOnly={this.props.disabled}
                  onChange={event => this.onChange(event.target.value)}
                />
                <div className='clearfix' />
              </div>
            </InputGroup>
          </FormGroup>
        </Panel>
      </div>
    )
  }
}

SearchPanel.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

SearchPanel.defaultProps = {
  disabled: false
}

export default SearchPanel
