class Cache {
  static save (key, value, ttl = 0) {
    Cache.remove(key)
    window.localStorage.setItem(key, JSON.stringify(value))

    if (ttl > 0) {
      setTimeout(() => {
        Cache.remove(key)
      }, ttl)
    }
  }

  static remove (key) {
    window.localStorage.removeItem(key)
  }

  static load (key) {
    return JSON.parse(window.localStorage.getItem(key))
  }

  static clear () {
    window.localStorage.clear()
  }
}

export default Cache
