// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components-forms-file__file--1HN0h .form-control {\n  overflow: hidden;\n}\n.components-forms-file__file--1HN0h .form-control.placeholder {\n  color: #a9a9ae;\n}\n.components-forms-file__file--1HN0h .components-forms-file__imagePreview--1sLa8 {\n  position: relative;\n  max-width: 200px;\n  height: auto;\n  padding: 5px;\n}\n.components-forms-file__file--1HN0h .components-forms-file__imagePreview--1sLa8 img {\n  max-width: 100%;\n  border-radius: 4px;\n}\n.components-forms-file__file--1HN0h .components-forms-file__actions--NgE2W {\n  position: relative;\n  margin-top: 10px;\n}\n.components-forms-file__file--1HN0h .components-forms-file__actions--NgE2W .btn-default {\n  border-color: #ccc;\n}\n.components-forms-file__file--1HN0h .components-forms-file__actions--NgE2W [type=\"file\"] {\n  display: none;\n}\n.components-forms-file__file--1HN0h .components-forms-file__maxSize--3mAiY {\n  display: inline-block;\n  font-style: italic;\n  padding-left: 5px;\n}\n", "",{"version":3,"sources":["webpack://components/forms/file.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;AAAJ;AAFA;EAKI,cAAA;AAAJ;AALA;EASI,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;AADJ;AAXA;EAeM,eAAA;EACA,kBAAA;AADN;AAfA;EAqBI,kBAAA;EACA,gBAAA;AAHJ;AAnBA;EAyBM,kBAAA;AAHN;AAtBA;EA6BM,aAAA;AAJN;AAzBA;EAkCI,qBAAA;EACA,kBAAA;EACA,iBAAA;AANJ","sourcesContent":[".file {\n  :global .form-control {\n    overflow: hidden;\n  }\n  :global .form-control.placeholder {\n    color: #a9a9ae;\n  }\n\n  .imagePreview {\n    position: relative;\n    max-width: 200px;\n    height: auto;\n    padding: 5px;\n\n    img {\n      max-width: 100%;\n      border-radius: 4px;\n    }\n  }\n\n  .actions {\n    position: relative;\n    margin-top: 10px;\n\n    :global .btn-default {\n      border-color: #ccc;\n    }\n\n    [type=\"file\"] {\n      display: none;\n    }\n  }\n\n  .maxSize {\n    display: inline-block;\n    font-style: italic;\n    padding-left: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file": "components-forms-file__file--1HN0h",
	"imagePreview": "components-forms-file__imagePreview--1sLa8",
	"actions": "components-forms-file__actions--NgE2W",
	"maxSize": "components-forms-file__maxSize--3mAiY"
};
export default ___CSS_LOADER_EXPORT___;
