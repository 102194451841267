import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import ReactSelect from 'react-select'

import 'react-select/less/default.less'
import './select.less'

import Group from './group'

const Select = (props) => {
  const sanitize = (key) => {
    if (!props.options[key]) {
      return null
    }

    return {
      value: key,
      label: props.options[key]
    }
  }

  const onChange = (value) => {
    let callbackValue = value ? value.value : null

    if (props.onChange) {
      props.onChange(callbackValue)
    }

    props.input.onChange(callbackValue)
  }

  const generateOptions = () => {
    const options = []

    for (let key in props.options) {
      options.push(sanitize(key))
    }

    return options
  }

  return (
    <Group {...props}>
      <ReactSelect
        multi={props.multi}
        clearable={props.clearable}
        searchable={props.searchable}
        disabled={props.disabled}
        placeholder={props.placeholder}
        name={props.input.name}
        value={sanitize(props.input.value)}
        onChange={(value) => onChange(value)}
        onBlur={() => props.input.onBlur(props.input.value.value)}
        options={generateOptions()}
        allowCreate={false}
        clearAllText='Limpar tudo'
        clearValueText='Limpar'
        noResultsText='Nenhum resultado encontrado'
        searchPromptText='Digite para pesquisar'
      />
    </Group>
  )
}

const SelectField = (props) => (
  <Field {...props} component={Select} />
)

SelectField.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.object,
  multi: PropTypes.bool,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
}

SelectField.defaultProps = {
  options: {},
  multi: false,
  clearable: true,
  searchable: true,
  disabled: false,
  placeholder: 'Selecione'
}

export default SelectField
