import React from 'react'

import Helper from 'components/templates/dashboard/helper'

const CompaniesHelper = () => (
  <Helper>
    <h3>Instruções</h3>
    <div>
      Aqui você pode configurar as infomações das <code>Lojas</code>.
      <br />
      <br />
      <h4>Dicas:</h4>
      <ul>
        <li>Caso você tenha encartes, promoções e banners diferentes para cada loja, após inserir a loja selecione <b>Sim</b> na coluna <b>Menu</b>. Assim seu site terá a opção para os usuários escolham a loja que gostariam de visualizar o conteúdo.</li>
      </ul>
      <br />

      <h4>Dicas para a images dde cada loja:</h4>
      <ul>
        <li><b>Tamanho</b>: Não se preocupe, quando você adiciona uma imagem o sistema já otimiza para que o site fique rápido de carregar.</li>
        <li><b>Formato</b>: É recomendado o uso de imagens do tipo <b>.jpg</b>, pois são bem mais leves. Mas se quiser um pouco mais de qualidade utilize o tipo de imagem .png, o sistema otimiza ambos os tipos mas o <b>JPG</b> chega a ficar 5x menor que o PNG.</li>
        <li><b>Proporção</b>: <b>660px</b> de largura por <b>366px</b> de altura.</li>
      </ul>
    </div>
  </Helper>
)

export default CompaniesHelper
