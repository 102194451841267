import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import { Action, Actions, Ordenator, Table } from 'components/datalist'
import Panel from 'components/panel'
import ConfirmDialog from 'components/app/confirm-dialog'
import { routeTo } from 'router'
import { fetchPermissions } from 'security/authorization'
import createForm, { Buttons, Form, Input, Select, toForm, toMutation, Wysiwyg } from 'components/forms'
import Spinner from 'components/spinner'
import Validation from 'utils/validation'
import FidelityCardEditMutation from 'relay/mutations/FidelityCardEditMutation'
import FidelityCardRemoveGroupMutation from 'relay/mutations/FidelityCardRemoveGroupMutation'
import FidelityCardEditGroupOrderMutation from 'relay/mutations/FidelityCardEditGroupOrderMutation'
import { affirmativeOptions } from 'utils/constants'

class FidelityCardForm extends Component {
  _removeGroup (group) {
    const { id } = group

    FidelityCardRemoveGroupMutation.commit(id, {
      onCompleted: ({ FidelityCardRemoveGroup }) => {},
      onError: (errors) => {}
    })
  }

  _submitFormAndAdd () {
    const { history, handleSubmit, pristine } = this.props
    if (!pristine) {
      handleSubmit()
    }

    history.push(routeTo('dashboard.fidelity-card.group.add'))
  }

  _onOrderChange (direction, group, refreshCallback) {
    FidelityCardEditGroupOrderMutation.commit(
      { id: group.id, direction },
      {
        onCompleted: ({ FidelityCardEditGroupOrder }) => { refreshCallback() },
        onError: (transaction) => {}
      }
    )
  }

  _showUp (group, groupList) {
    if (groupList.length === 1) {
      return false
    }

    if (groupList[0].id === group.id) {
      return false
    }

    return true
  }

  _showDown (group, groupList) {
    if (groupList.length === 1) {
      return false
    }

    if (groupList[groupList.length - 1].id === group.id) {
      return false
    }

    return true
  }

  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading, viewer } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={loading} />

        <Panel>
          <Input name='title' horizontal type='text' label='Título' />

          <Input name='buttonText' horizontal type='text' label='Texto botão' />

          <Input name='bannerText' horizontal type='text' label='Texto banner' />

          <Wysiwyg name='text' horizontal label='Texto' />

          <Select
            name='active'
            horizontal
            label='Exibir página?'
            clearable={false}
            options={affirmativeOptions}
          />

          <Select
            name='activeForm'
            horizontal
            label='Exibir formulário?'
            clearable={false}
            options={affirmativeOptions}
          />

          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
          />
        </Panel>

        <Table
          title='Grupos'
          actions={(<a onClick={() => this._submitFormAndAdd()} className='btn btn-primary'> <i className='fa fa-plus' /> Adicionar Grupo </a>)}
          columns={[
            { name: 'Nome', property: 'name' },
            {
              name: 'Ordem',
              callback: (group, { refreshCallback, data }) => (
                <Ordenator
                  showUp={this._showUp(group, data)}
                  showDown={this._showDown(group, data)}
                  onUp={() => this._onOrderChange('UP', group, refreshCallback)}
                  onDown={() => this._onOrderChange('DOWN', group, refreshCallback)} />
              )
            },
            {
              name: 'Ações',
              callback: (group) => (
                <Actions>
                  <Action title='Alterar'>
                    <Link to={routeTo('dashboard.fidelity-card.group.edit', { id: group.id })}>
                      <i className='fa fa-edit' />
                    </Link>
                  </Action>

                  <Action title='Excluir'>
                    <ConfirmDialog
                      icon='fa fa-trash-o'
                      title={group.name}
                      subtitle='Deseja remover este Grupo? Esta ação não poderá ser desfeita.'
                      onOk={() => this._removeGroup(group)}>
                      <i className='fa fa-trash-o' />
                    </ConfirmDialog>
                  </Action>
                </Actions>
              )
            }
          ]}
          data={viewer ? viewer.fidelityCardForm.fieldGroups : []}
        />
      </Form>
    )
  }
}

const validation = new Validation({
  title: [Validation.required],
  active: [Validation.required],
  activeForm: [Validation.required]
})

const FidelityCardEditForm = createForm(FidelityCardForm, validation)

class FidelityCardEdit extends Component {
  constructor (props) {
    super(props)

    this.state = { permissions: {} }
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['fidelity-card:edit'])
    this.setState({ permissions })
  }

  _onSubmit (values) {
    const { title, text, buttonText, bannerText, active, activeForm } = values
    FidelityCardEditMutation.commit(
      toMutation({ title, text, buttonText, bannerText, active, activeForm }),
      {
        onCompleted: ({ FidelityCardEdit }) => {},
        onError: (errors) => {}
      }
    )
  }

  render () {
    const { loading, viewer } = this.props
    return (
      <Content
        title='Cartão Fidelidade'
        permission='fidelity-card:edit'
        menu={{ link: 'Cartão Fidelidade' }}
        breadcrumb={{
          title: 'Cartão Fidelidade',
          items: [
            { url: '/dashboard', title: 'Dashboard' },
            { url: '/dashboard/fidelity-card', title: 'Cartão Fidelidade' },
            { title: 'Alterar' }
          ]
        }}>

        {!this.props.viewer ? (
          <FidelityCardEditForm
            loading={loading}
          />
        ) : (
          <FidelityCardEditForm
            {...this.props}
            loading={loading}
            initialValues={toForm(viewer.fidelityCardForm)}
            onSubmit={values => this._onSubmit(values)}
          />
        )}

      </Content>
    )
  }
}

const FidelityCardEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query fidelityCardEdit_viewer_Query {
        viewer {
          id
          fidelityCardForm {
            id
            title
            text
            bannerText
            buttonText
            active
            activeForm
            fieldGroups {
              id
              name
              fields {
                id
                name
                description
                type
                required
                options
              }
            }
          }
        }
      }
    `}
    render={({ props: rendererProps }) => (
      <FidelityCardEdit
        {...rendererProps}
        {...props}
        loading={!rendererProps}
      />
    )}
  />
)

export default connect(null, { ...appActionCreators })(FidelityCardEditRenderer)
