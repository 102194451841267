import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-bootstrap'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actionCreators from '../actions'

import MenuLink from './menu-link'

class MenuGroup extends Component {
  constructor (props) {
    super(props)

    this.state = {
      previous: false
    }
  }

  isActive () {
    return this.props.menu.group === this.props.title
  }

  toggleMenuGroup () {
    let activeGroup = this.state.previous
    if (!this.isActive()) {
      activeGroup = this.props.title

      this.setState({
        previous: this.props.menu.group
      })
    }

    this.props.actions.changeActiveMenu(activeGroup)
  }

  render () {
    return (
      <li className={this.isActive() ? 'active' : ''}>
        <a onClick={() => this.toggleMenuGroup()}>
          <i className={this.props.icon} />
          <span className='nav-label'>
            {this.props.title}
          </span>
          <span className='fa arrow' />
        </a>
        <Collapse in={this.isActive()} >
          <ul className='nav nav-second-level'>
            {this.props.submenu.map((item, index) => (
              <MenuLink key={index} title={item.title} url={item.url} group={this.props.title} />
            ))}
          </ul>
        </Collapse>
      </li>
    )
  }
}

MenuGroup.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  submenu: PropTypes.array
}

const mapStateToProps = (state) => ({
  menu: state.dashboard.menu
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuGroup)
