/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ArticleEditInput = {|
  id: string,
  title: string,
  image: FileInput,
  summary?: ?string,
  text?: ?string,
  font?: ?string,
  fontLink?: ?string,
  date?: ?string,
  time?: ?string,
  active?: ?boolean,
  clientMutationId?: ?string,
|};
export type FileInput = {|
  key?: ?string,
  url?: ?string,
  name?: ?string,
  size?: ?number,
  type?: ?string,
|};
export type ArticleEditMutationVariables = {|
  input: ArticleEditInput
|};
export type ArticleEditMutationResponse = {|
  +ArticleEdit: ?{|
    +article: ?{|
      +id: string,
      +title: ?string,
    |},
    +error: ?string,
  |}
|};
export type ArticleEditMutation = {|
  variables: ArticleEditMutationVariables,
  response: ArticleEditMutationResponse,
|};
*/


/*
mutation ArticleEditMutation(
  $input: ArticleEditInput!
) {
  ArticleEdit(input: $input) {
    article {
      id
      title
    }
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ArticleEditPayload",
    "kind": "LinkedField",
    "name": "ArticleEdit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "article",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArticleEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArticleEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "89b4320b25cb059f13887ac69de73168",
    "id": null,
    "metadata": {},
    "name": "ArticleEditMutation",
    "operationKind": "mutation",
    "text": "mutation ArticleEditMutation(\n  $input: ArticleEditInput!\n) {\n  ArticleEdit(input: $input) {\n    article {\n      id\n      title\n    }\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6ae513ee104071113271a4562bb7a427';

module.exports = node;
