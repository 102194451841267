import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'react-relay'
import Environment from 'relay'

import Panel from 'components/panel'
import Spinner from 'components/spinner'
import createForm, { Buttons, DynamicSelect, File, Form, Input, NumericInput, Select } from 'components/forms'
import Validation from 'utils/validation'
import { activeOptions } from 'utils/constants'

class CompaniesForm extends Component {
  state = {
    firstMount: true,
    state: null
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.state.firstMount && nextProps.initialValues) {
      this.setState({
        firstMount: false
      })

      this._changeState(nextProps.initialValues.state)
    }
  }

  _changeState (state) {
    const stateChanged = this.state.state && this.state.state !== state.id

    this.setState({
      state: state ? state.id : null
    })

    if (stateChanged) {
      this.props.change('city', '')
    }
  }

  render () {
    const { handleSubmit, pristine, submitting, valid, reset } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={this.props.loading}/>

        <Panel>
          <File name='image' horizontal label='Imagem'/>

          <hr className='hr-line-dashed'/>
          <Input name='name' horizontal type='text' label='Nome'/>

          <hr className='hr-line-dashed'/>
          <Input name='email' horizontal type='email' label='E-mail'/>

          <hr className='hr-line-dashed'/>
          <Input name='phone' horizontal type='text' label='Telefone'/>

          <hr className='hr-line-dashed'/>
          <Input name='parkingLots' horizontal type='text' label='Número de vagas de estacionamento'/>

          <hr className='hr-line-dashed'/>
          <Input name='openingHours' horizontal type='text' label='Horário de atendimento'/>

          <hr className='hr-line-dashed'/>
          <Select
            name='active'
            horizontal
            label='Status'
            options={activeOptions}
            clearable={false}
          />
        </Panel>

        <Panel>
          <DynamicSelect
            name='state'
            horizontal
            label='Estado'
            clearable={false}
            environment={Environment}
            query={graphql`
              query companiesForm_states_Query($first: Int!, $search: String) {
                viewer {
                  ...companiesForm_states_viewer
                }
              }
            `}
            fragments={{
              viewer: graphql`
                fragment companiesForm_states_viewer on Viewer {
                  id
                  states(first: $first, search: $search) @connection(key: "list_states") {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              `
            }}
            onChange={(value) => this._changeState(value)}
          />

          <hr className='hr-line-dashed'/>
          <DynamicSelect
            name='city'
            horizontal
            label='Cidade'
            clearable={false}
            placeholder={!this.state.state ? 'Selecione um estado' : 'Selecione'}
            disabled={!this.state.state}
            environment={Environment}
            query={graphql`
              query companiesForm_cities_Query($first: Int!, $state: ID!, $search: String) {
                viewer {
                  ...companiesForm_cities_viewer
                }
              }
            `}
            fragments={{
              viewer: graphql`
                fragment companiesForm_cities_viewer on Viewer {
                  id
                  cities(first: $first, state: $state, search: $search) @connection(key: "list_cities") {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              `
            }}
            variables={{
              state: this.state.state
            }}
          />

          <hr className='hr-line-dashed'/>
          <Input name='address' horizontal type='text' label='Endereço'/>

          <hr className='hr-line-dashed'/>
          <Input name='number' horizontal type='text' label='Número'/>

          <hr className='hr-line-dashed'/>
          <Input name='neighborhood' horizontal type='text' label='Bairro'/>

          <hr className='hr-line-dashed'/>
          <Input name='zipCode' horizontal type='text' label='CEP'/>

          <hr className='hr-line-dashed'/>
          <NumericInput name='latitude' horizontal label='Latitude'/>
          <NumericInput name='longitude' horizontal label='Longitude'/>
        </Panel>

        <Panel>
          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
          />
        </Panel>
      </Form>
    )
  }
}

CompaniesForm.defaultProps = {
  loading: false
}

CompaniesForm.propTypes = {
  loading: PropTypes.bool
}

const CompaniesValidation = new Validation({
  image: [Validation.required],
  name: [Validation.required],
  active: [Validation.required],
  email: [Validation.email],
  state: [Validation.required],
  city: [Validation.required]
})

export default createForm(CompaniesForm, CompaniesValidation)
