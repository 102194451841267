import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import { routeTo } from 'router'
import SearchPanel from 'components/search-panel'
import ProductGroupsProductList from './product-groups-product-list'
import Panel from 'components/panel'
import { Link } from 'react-router-dom'

class ProductGroupsAddProducts extends Component {
  constructor (props) {
    super(props)

    this.state = {search: ''}
  }
  render () {
    const { viewer } = this.props
    return (
      <Content
        title='Adicionar Produtos'
        permission='product-groups:edit'
        menu={{link: 'Grupos de Produtos'}}
        breadcrumb={{
          title: 'Grupos de Produtos',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/product-groups', title: 'Grupos de Produtos'},
            {url: `/dashboard/product-groups/edit/${viewer.productGroup.id}`, title: `${viewer.productGroup.title}`},
            {title: 'Adicionar Produtos'}
          ],
          actions: (
            <Link to={routeTo('dashboard.product-groups.edit', {id: viewer.productGroup.id})} className='btn btn-primary'>
              <i className='fa fa-check' /> Produtos adicionados! Voltar a edição
            </Link>
          )
        }}>
        <SearchPanel onChange={(search) => this.setState({ search })} />
        {this.state.search && (
          <ProductGroupsProductList search={this.state.search} productGroupId={viewer.productGroup.id} />
        )}
        {!this.state.search && (
          <Panel>
            <p style={{textAlign: 'center'}}><i className='fa fa-search' /> Use o campo de busca acima para localizar os produtos</p>
          </Panel>
        )}
      </Content>
    )
  }
}

const ProductGroupsAddProductsRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query productGroupsAddProducts_Query($id: ID!) {
        viewer {
          productGroup(id: $id) {
            id
            title
          }
        }
      }
    `}
    variables={{id: props.match.params.id}}
    render={({error, props: rendererProps}) => {
      if (error) {
        console.error(error)
      }

      if (rendererProps) {
        return (<ProductGroupsAddProducts {...rendererProps} {...props} />)
      }

      return null
    }}
  />
)

export default connect(null, appActionCreators)(ProductGroupsAddProductsRenderer)
