const list = {
  'SERVER_OFFLINE': 'Não foi possível conectar-se ao servidor, tente novamente mais tarde',
  'INVALID_EMAIL_PASSWORD': 'Usuário ou senha inválidos',
  'INVALID_PASSWORD': 'Senha inválida',
  'FK_ROLE_USER': 'Um ou mas usuários estão vinculados a este grupo',
  'NOT_FOUND': 'Registro não encontrado'
}

export function message (code) {
  if (list[code]) {
    return list[code]
  }

  return 'Um erro ocorreu, tente novamente mais tarde'
}

export function getMessagesFromErrors (errors) {
  const errorMessages = []
  errors.forEach(err => {
    Object.keys(err.state).forEach(key => {
      err.state[key].forEach(message => errorMessages.push(message))
    })
  })

  return errorMessages
}
