import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'

import Content from 'components/templates/dashboard/content'
import { toMutation } from 'components/forms'
import { getMessagesFromErrors } from 'utils/messages'
import { routeTo } from 'router'
import ProductGroupsForm from './product-groups-form'
import ProductGroupsHelper from './product-groups-helper'
import ProductGroupAddMutation from 'relay/mutations/ProductGroupAddMutation'
import { daysOfTheWeekOptions } from 'utils/constants'

import _ from 'lodash'

class ProductGroupsAdd extends Component {
  constructor (props) {
    super(props)

    this.state = {loading: false}
  }

  _loading (loading) {
    this.setState({...this.state, loading})
  }

  _success (newGroupId) {
    this.props.notify('success', 'Sucesso', 'Grupo de Produtos adicionado com sucesso')
    this.props.history.push(routeTo('dashboard.product-groups.edit', {id: newGroupId}))
  }

  _error (message) {
    this.props.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    const mutationValues = _.omit(values, ['temporary'])
    ProductGroupAddMutation.commit(
      toMutation(mutationValues), {
        onCompleted: ({ProductGroupAdd}) => {
          this._loading(false)
          this._success(ProductGroupAdd.productGroupEdge.node.id)
        },
        onError: (errors) => {
          this._loading(false)

          getMessagesFromErrors(errors).forEach(m => this._error(m))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title='Adicionar Grupo de Produtos'
        permission='product-groups:add'
        menu={{link: 'Grupos de Produtos'}}
        breadcrumb={{
          title: 'Grupos de Produtos',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/product-groups', title: 'Grupos de Produtos'},
            {title: 'Adicionar'}
          ]
        }} >
        <Row>
          <Col sm={7}>
            <ProductGroupsForm
              loading={this.state.loading}
              initialValues={{
                sortBy: 'MANUAL',
                active: true,
                description: '<p></p>',
                temporary: false,
                weekDays: Object.keys(daysOfTheWeekOptions)
              }}
              onSubmit={(values) => this._onSubmit(values)}
            />
          </Col>
          <Col sm={5}>
            <ProductGroupsHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const ProductGroupsAddRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query productGroupsAdd_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (<ProductGroupsAdd {...rendererProps} {...props} />)}
  />
)

export default connect(null, appActionCreators)(ProductGroupsAddRenderer)
