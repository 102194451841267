import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import ChangePasswordByRecoveryTokenMutation from '../../../relay/mutations/ChangePasswordByRecoveryTokenMutation'
import Content from 'components/templates/login/content'
import { routeTo } from 'router'
import PasswordRecoveryResponseForm from './password-recovery-response-form'

class PasswordRecoveryResponseView extends Component {
  constructor (props) {
    super(props)

    this.state = { loading: false }
  }

  _loading (loading) {
    this.setState({ loading })
  }

  _success () {
    this.props.actions.notify('success', 'Sucesso', 'Sua senha foi alterada.')
    this.props.history.push(routeTo('login'))
  }

  _error () {
    this.props.actions.notify('error', 'Erro', 'Não foi possível definir a nova senha.')
  }

  _onSubmit ({ password }) {
    const { params: { token } } = this.props.match
    this._loading(true)

    ChangePasswordByRecoveryTokenMutation.commit(
      { password, token },
      {
        onCompleted: ({ ChangePasswordByRecoveryToken }) => {
          this._loading(false)

          if (ChangePasswordByRecoveryToken.error) {
            this._error()
          } else {
            this._success()
          }
        },
        onError: (transaction) => {
          this._loading(false)

          this._error()
        }
      }
    )
  }

  render () {
    const loading = this.props.loading || this.state.loading

    return (
      <Content title='Recuperar senha'>
        <PasswordRecoveryResponseForm loading={loading} onSubmit={(values) => this._onSubmit(values)} />

        <hr />
        <a className='link' href={routeTo('login')}>
          Voltar para o login
        </a>
      </Content>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(PasswordRecoveryResponseView)
