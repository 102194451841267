/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FidelityCardInputType = "CHOICE" | "DATE" | "LONG_TEXT" | "MULTIPLE_SELECTION" | "NUMERIC" | "PHONE" | "SELECTION" | "SHORT_TEXT" | "%future added value";
export type FidelityCardAddGroupInput = {|
  name: string,
  clientMutationId?: ?string,
|};
export type FidelityCardAddGroupMutationVariables = {|
  input: FidelityCardAddGroupInput
|};
export type FidelityCardAddGroupMutationResponse = {|
  +FidelityCardAddGroup: ?{|
    +fidelityCardForm: ?{|
      +id: string,
      +title: ?string,
      +text: ?string,
      +fieldGroups: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +fields: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
          +description: ?string,
          +type: ?FidelityCardInputType,
          +required: ?boolean,
        |}>,
      |}>,
    |},
    +fidelityCardInputGroup: ?{|
      +id: string,
      +name: ?string,
    |},
  |}
|};
export type FidelityCardAddGroupMutation = {|
  variables: FidelityCardAddGroupMutationVariables,
  response: FidelityCardAddGroupMutationResponse,
|};
*/


/*
mutation FidelityCardAddGroupMutation(
  $input: FidelityCardAddGroupInput!
) {
  FidelityCardAddGroup(input: $input) {
    fidelityCardForm {
      id
      title
      text
      fieldGroups {
        id
        name
        fields {
          id
          name
          description
          type
          required
        }
      }
    }
    fidelityCardInputGroup {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FidelityCardAddGroupPayload",
    "kind": "LinkedField",
    "name": "FidelityCardAddGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FidelityCardForm",
        "kind": "LinkedField",
        "name": "fidelityCardForm",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FidelityCardInputGroup",
            "kind": "LinkedField",
            "name": "fieldGroups",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FidelityCardInput",
                "kind": "LinkedField",
                "name": "fields",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "required",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FidelityCardInputGroup",
        "kind": "LinkedField",
        "name": "fidelityCardInputGroup",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FidelityCardAddGroupMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FidelityCardAddGroupMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "463851370280c1959350c08d49967c7c",
    "id": null,
    "metadata": {},
    "name": "FidelityCardAddGroupMutation",
    "operationKind": "mutation",
    "text": "mutation FidelityCardAddGroupMutation(\n  $input: FidelityCardAddGroupInput!\n) {\n  FidelityCardAddGroup(input: $input) {\n    fidelityCardForm {\n      id\n      title\n      text\n      fieldGroups {\n        id\n        name\n        fields {\n          id\n          name\n          description\n          type\n          required\n        }\n      }\n    }\n    fidelityCardInputGroup {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e4a26fa4eb1386e78c32cefa4175ecbe';

module.exports = node;
