import React from 'react'

import Helper from 'components/templates/dashboard/helper'

const UsersHelper = () => (
  <Helper>
    <h3>Instruções</h3>
    <p>
    Aqui você pode configurar os <code>Usuários</code> e os <code>Grupos</code> de permisões, de acordo com a opção seleciona do menu lateral a esquerda.
    <br />
      <br />
      <br />
      <h4>Dicas:</h4>
      <ul>
        <li>Crie usuários apenas para quem realmente irá utilizar ou monitorar o site.</li>
        <li>Lembre-se sempre de remover o usuário de pessoas que não fazem mais parte da equipe.</li>
        <li>Nunca de mais permição que uma pessoa necessita para desempenhar sua função. Isso proteje a empresa e a pessoa.</li>
      </ul>
    </p>
  </Helper>
)

export default UsersHelper
