/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserAddInput = {|
  role: string,
  name: string,
  email: string,
  password?: ?string,
  clientMutationId?: ?string,
|};
export type UserAddMutationVariables = {|
  input: UserAddInput
|};
export type UserAddMutationResponse = {|
  +UserAdd: ?{|
    +userEdge: ?{|
      +node: ?{|
        +id: string,
        +role: ?{|
          +id: string,
          +name: ?string,
        |},
        +name: ?string,
        +email: ?string,
      |}
    |},
    +viewer: ?{|
      +users: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
            +email: ?string,
          |}
        |}>
      |}
    |},
    +error: ?string,
  |}
|};
export type UserAddMutation = {|
  variables: UserAddMutationVariables,
  response: UserAddMutationResponse,
|};
*/


/*
mutation UserAddMutation(
  $input: UserAddInput!
) {
  UserAdd(input: $input) {
    userEdge {
      node {
        id
        role {
          id
          name
        }
        name
        email
      }
    }
    viewer {
      users {
        edges {
          node {
            id
            name
            email
          }
        }
      }
      id
    }
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UserEdge",
  "kind": "LinkedField",
  "name": "userEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "role",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "UserConnection",
  "kind": "LinkedField",
  "name": "users",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserAddPayload",
        "kind": "LinkedField",
        "name": "UserAdd",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserAddPayload",
        "kind": "LinkedField",
        "name": "UserAdd",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "901692d57e15fa925c3ce2e5eef88e94",
    "id": null,
    "metadata": {},
    "name": "UserAddMutation",
    "operationKind": "mutation",
    "text": "mutation UserAddMutation(\n  $input: UserAddInput!\n) {\n  UserAdd(input: $input) {\n    userEdge {\n      node {\n        id\n        role {\n          id\n          name\n        }\n        name\n        email\n      }\n    }\n    viewer {\n      users {\n        edges {\n          node {\n            id\n            name\n            email\n          }\n        }\n      }\n      id\n    }\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '72ded0f0aad2556d3728f9e8ff47ea13';

module.exports = node;
