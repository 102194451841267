/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FidelityCardEditInput = {|
  title: string,
  text?: ?string,
  bannerText?: ?string,
  buttonText?: ?string,
  active?: ?boolean,
  activeForm?: ?boolean,
  clientMutationId?: ?string,
|};
export type FidelityCardEditMutationVariables = {|
  input: FidelityCardEditInput
|};
export type FidelityCardEditMutationResponse = {|
  +FidelityCardEdit: ?{|
    +fidelityCardForm: ?{|
      +id: string,
      +title: ?string,
      +text: ?string,
      +bannerText: ?string,
      +buttonText: ?string,
      +active: ?boolean,
      +activeForm: ?boolean,
    |}
  |}
|};
export type FidelityCardEditMutation = {|
  variables: FidelityCardEditMutationVariables,
  response: FidelityCardEditMutationResponse,
|};
*/


/*
mutation FidelityCardEditMutation(
  $input: FidelityCardEditInput!
) {
  FidelityCardEdit(input: $input) {
    fidelityCardForm {
      id
      title
      text
      bannerText
      buttonText
      active
      activeForm
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FidelityCardEditPayload",
    "kind": "LinkedField",
    "name": "FidelityCardEdit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FidelityCardForm",
        "kind": "LinkedField",
        "name": "fidelityCardForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bannerText",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "buttonText",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activeForm",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FidelityCardEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FidelityCardEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc2ced1822596a8a14cf85af593923d8",
    "id": null,
    "metadata": {},
    "name": "FidelityCardEditMutation",
    "operationKind": "mutation",
    "text": "mutation FidelityCardEditMutation(\n  $input: FidelityCardEditInput!\n) {\n  FidelityCardEdit(input: $input) {\n    fidelityCardForm {\n      id\n      title\n      text\n      bannerText\n      buttonText\n      active\n      activeForm\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f3c05b4e6a81791cfa1d1e3226037eb';

module.exports = node;
