import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import { toForm, toMutation } from 'components/forms'
import { getMessagesFromErrors } from 'utils/messages'
import { routeTo } from 'router'
import OfferCatalogsForm from './offer-catalogs-form'
import OfferCatalogsHelper from './offer-catalogs-helper'
import OfferCatalogEditMutation from 'relay/mutations/OfferCatalogEditMutation'

const EditForm = OfferCatalogsForm(true)

class OfferCatalogsEdit extends Component {
  constructor (props) {
    super(props)

    this.state = {loading: false}
  }

  _loading (loading) {
    this.setState({loading})
  }

  _success () {
    this._loading(false)

    this.props.notify('success', 'Sucesso', 'Encarte alterado com sucesso')
    this.props.history.push(routeTo('dashboard.offer-catalogs'))
  }

  _error (errors) {
    this._loading(false)

    getMessagesFromErrors(errors)
      .forEach(m => this.props.notify('error', 'Erro', m))
  }

  _onSubmit (values) {
    this._loading(true)

    OfferCatalogEditMutation.commit(Environment, toMutation(values), this.props.viewer, {
      onCompleted: ({OfferCatalogEdit}) => this._success(),
      onError: (errors) => this._error(errors)
    })
  }

  render () {
    const { viewer, config } = this.props
    const companiesCount = viewer.companies.edges.length
    let companies = companiesCount === 1 ? (
      [viewer.companies.edges[0].node.id]
    ) : (
      viewer.offerCatalog.companies.edges.map(e => e.node.id)
    )
    return (
      <Content
        title='Alterar Encarte'
        permission='offer-catalogs:edit'
        menu={{link: 'Encartes'}}
        breadcrumb={{
          title: 'Encartes',
          items: [
          {url: '/dashboard', title: 'Dashboard'},
          {url: '/dashboard/offer-catalogs', title: 'Encartes'},
          {title: 'Alterar'}
          ]
        }}>
        <Row>
          <Col sm={7}>
            {!viewer ? (
              <EditForm loading />
            ) : (
              <EditForm
                loading={this.state.loading}
                viewer={viewer}
                maxUploadSize={config.maxUploadSize}
                initialValues={toForm({
                  ...viewer.offerCatalog,
                  temporary: !!viewer.offerCatalog.startDate,
                  companies
                })}
                onSubmit={values => this._onSubmit(values)}
              />
            )}
          </Col>
          <Col sm={5}>
            <OfferCatalogsHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const OfferCatalogsEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query offerCatalogsEdit_Query($id: ID!) {
        config {
          maxUploadSize
        }
        viewer {
          companies(selectableInMenu: true) {
            edges {
              node {
                id
                name
              }
            }
          }
          offerCatalog(id: $id) {
            id
            title
            category {
              id
              name
            }
            isuuDocumentId
            weekDays
            startDate
            endDate
            active
            companies {
              edges {
                node {
                  id
                  name
                }
              }
            }
            image {
              key
              url
            }
          }
        }
      }
    `}
    variables={{id: props.match.params.id}}
    render={({props: rendererProps}) => {
      if (!rendererProps) {
        return null
      }

      return (
        <OfferCatalogsEdit {...rendererProps} {...props} />
      )
    }}
  />
)

export default connect(null, appActionCreators)(OfferCatalogsEditRenderer)
