/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BannerRemoveInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type BannerRemoveMutationVariables = {|
  input: BannerRemoveInput
|};
export type BannerRemoveMutationResponse = {|
  +BannerRemove: ?{|
    +deletedID: ?string,
    +error: ?string,
  |}
|};
export type BannerRemoveMutation = {|
  variables: BannerRemoveMutationVariables,
  response: BannerRemoveMutationResponse,
|};
*/


/*
mutation BannerRemoveMutation(
  $input: BannerRemoveInput!
) {
  BannerRemove(input: $input) {
    deletedID
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BannerRemovePayload",
    "kind": "LinkedField",
    "name": "BannerRemove",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BannerRemoveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BannerRemoveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "caa77caf59e63d0ad005eace1ccacd08",
    "id": null,
    "metadata": {},
    "name": "BannerRemoveMutation",
    "operationKind": "mutation",
    "text": "mutation BannerRemoveMutation(\n  $input: BannerRemoveInput!\n) {\n  BannerRemove(input: $input) {\n    deletedID\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '350e2800c0f362e491a3471a0b82522a';

module.exports = node;
