import React from 'react'
import PropTypes from 'prop-types'
import * as Bootstrap from 'react-bootstrap'
import _ from 'lodash'

import Panel from 'components/panel'

import styles from './datalist.less'

const Table = (props) => (
  <div className={styles.tableWrapper}>
    <Panel title={props.title} actions={props.actions}>
      <Bootstrap.Table hover responsive striped>
        <thead>
        <tr>
          {
            props.columns.map((column, index) => (
              <th key={index}>{column.name}</th>
            ))
          }
        </tr>
        </thead>
        <tbody>
        {
          props.data && props.data.map((data, dataIndex) => (
            <tr key={dataIndex}>
              {
                props.columns.map((header, headerIndex) => (
                  <td key={headerIndex}>
                    {header.callback ? header.callback(data, {
                      info: props.info,
                      data: props.data,
                      refreshCallback: props.refreshCallback
                    }) : _.get(data, header.property)}
                  </td>
                ))
              }
            </tr>
          ))
        }
        {props.data.length < 1 && (
          <tr>
            <td className="text-center" colSpan={props.columns.length}>
              Nenhum registro encontrado
            </td>
          </tr>
        )}
        </tbody>
      </Bootstrap.Table>
    </Panel>
  </div>
)

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array
}

Table.defaultProps = {
  data: []
}

export default Table
