import { FETCH_HOST, NOTIFICATION_ADD, NOTIFICATION_REMOVE } from './constants'

export function fetchHost (host) {
  return function (dispatch) {
    dispatch(
      {
        type: FETCH_HOST,
        payload: {
          host: host
        }
      }
    )
  }
}

export function notify (level, title, message) {
  return function (dispatch) {
    dispatch(
      {
        type: NOTIFICATION_ADD,
        payload: {
          level: level,
          title: title,
          message: message,
          uid: new Date().getTime() + Math.floor(Math.random() * 1000)
        }
      }
    )
  }
}

export function markNotificationAsShown (uid) {
  return function (dispatch) {
    dispatch(
      {
        type: NOTIFICATION_REMOVE,
        payload: {
          uid: uid
        }
      }
    )
  }
}
