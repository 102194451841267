import 'react-dates/initialize';
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, touch } from 'redux-form'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'

import Group from './group'

import 'react-dates/lib/css/_datepicker.css'
import './datepicker.less'

moment.locale('pt-BR')

class Datepicker extends Component {
  state = {
    focused: false
  }

  sanitize (value) {
    if (!value) {
      return null
    }

    const sanitizedValue = moment(value)
    return sanitizedValue
  }

  initialVisibleMonth (value) {
    if (!value) {
      return moment()
    }

    const sanitizedValue = moment(value)
    return sanitizedValue
  }

  onChange (date) {
    const callbackValue = date ? moment(date).format('YYYY-MM-DD') : null
    this.props.input.onChange(callbackValue)

    this.props.meta.dispatch(
      touch(this.props.meta.form, this.props.input.name)
    )
  }

  render () {
    return (
      <Group {...this.props}>
        <SingleDatePicker
          date={this.sanitize(this.props.input.value)}
          initialVisibleMonth={() => this.initialVisibleMonth(this.props.input.value)}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          showClearDate={this.props.allowClear}
          isOutsideRange={this.props.excluding}
          numberOfMonths={1}
          focused={this.state.focused}
          onDateChange={date => this.onChange(date)}
          onFocusChange={({ focused }) => this.setState({ focused })}
          hideKeyboardShortcutsPanel
        />
      </Group>
    )
  }
}

const DatepickerField = (props) => (
  <Field {...props} component={Datepicker} />
)

DatepickerField.propTypes = {
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  placeholder: PropTypes.string,
  excluding: PropTypes.func
}

DatepickerField.defaultProps = {
  disabled: false,
  allowClear: true,
  placeholder: 'Selecione',
  excluding: day => false
}

export default DatepickerField
