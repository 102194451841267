import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import LeadEditMutation from 'relay/mutations/LeadEditMutation'

import Content from 'components/templates/dashboard/content'
import { toForm, toMutation } from 'components/forms'
import { getMessagesFromErrors } from 'utils/messages'
import { routeTo } from 'router'
import LeadsForm from './leads-form'
import LeadsHelper from './leads-helper'

class LeadsEdit extends Component {
  constructor (props) {
    super(props)

    this.state = {loading: false}
  }

  _loading (loading) {
    this.setState({loading})
  }

  _success () {
    this._loading(false)

    this.props.notify('success', 'Sucesso', 'Lead alterado com sucesso')
    this.props.history.push(routeTo('dashboard.leads'))
  }

  _error (errors) {
    this._loading(false)

    getMessagesFromErrors(errors)
      .forEach(m => this.props.notify('error', 'Erro', m))
  }

  _onSubmit (values) {
    this._loading(true)

    LeadEditMutation.commit(Environment, toMutation(values), this.props.viewer, {
      onCompleted: ({LeadEdit}) => this._success(),
      onError: (errors) => this._error(errors)
    })
  }

  render () {
    return (
      <Content
        title='Alterar lead'
        permission='leads:edit'
        menu={{link: 'Leads'}}
        breadcrumb={{
          title: 'Leads',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/leads', title: 'Leads'},
            {title: 'Alterar'}
          ]
        }}>
        <Row>
          <Col sm={7}>
            {!this.props.viewer ? (
              <LeadsForm loading />
            ) : (
              <LeadsForm
                loading={this.state.loading}
                initialValues={toForm(this.props.viewer.lead)}
                onSubmit={values => this._onSubmit(values)}
              />
            )}
          </Col>
          <Col sm={5}>
            <LeadsHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const LeadsEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query leadsEdit_Query($id: ID!) {
        viewer {
          lead(id: $id) {
            id
            name
            email
            phone
            whatsapp
            company {
              id
              name
            }
          }
        }
      }
    `}
    variables={{id: props.match.params.id}}
    render={({props: rendererProps}) => (<LeadsEdit {...rendererProps} {...props} />)}
  />
)

export default connect(null, appActionCreators)(LeadsEditRenderer)
