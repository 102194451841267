import { CHANGE_ACTIVE_MENU, TOGGLE_MINI_MENU } from './constants'
import Cookie from 'utils/cookie'

const initialState = {
  menu: {
    group: '',
    link: '',
    mini: Cookie.load('miniMenu') === 'true' || false
  }
}

function dashboard (state = initialState, action) {
  switch (action.type) {
    case CHANGE_ACTIVE_MENU:
      return Object.assign({}, state, {
        menu: {
          group: action.payload.group,
          link: action.payload.link,
          mini: state.menu.mini
        }
      })

    case TOGGLE_MINI_MENU: {
      Cookie.save('miniMenu', action.payload.mini, true)

      return Object.assign({}, state, {
        menu: {
          group: state.menu.group,
          link: state.menu.link,
          mini: action.payload.mini
        }
      })
    }

    default:
      return state
  }
}

export default dashboard
