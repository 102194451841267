import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation FidelityCardRemoveGroupMutation($input: FidelityCardRemoveGroupInput!) {
    FidelityCardRemoveGroup(input: $input) {
      fidelityCardForm {
        id
        title
        text
        fieldGroups {
          id
          name
          fields {
            id
            name
            description
            type
            required
          }
        }
      }
      deletedId
    }
  }
`

function commit (id, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: {id}
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
