import React, { Component } from 'react'
import Table from 'components/datalist/table'
import { Actions, Action } from 'components/datalist'
import SetPriceModalCreator from './products-form-set-price-modal'
import BooleanLabel from 'components/boolean-label'
import { toMoney } from 'utils/numbers'
import _ from 'lodash'

export default class extends Component {
  pricesList () {
    const { product, companies } = this.props

    const getPriceForCompany = (prices, company) => _.find(prices, price => price.company.id === company.id)

    return companies.edges.map(company => {
      const price = getPriceForCompany(product.prices, company.node) || {}
      return {
        company: company.node,
        price: price.price,
        promotionalPrice: price.promotionalPrice,
        promotion: price.promotion
      }
    })
  }

  getEditPriceModal (price, product) {
    const { company } = price
    const SetPriceModal = SetPriceModalCreator(`SetPriceModalForm-${company.id}`)

    return (
      <SetPriceModal
        product={product}
        initialValues={price}
      />
    )
  }

  render () {
    const { product } = this.props
    return (
      <Table
        columns={[
          { name: 'Empresa', property: 'company.name' },
          { name: 'Preço', callback: price => toMoney(price.price) },
          { name: 'Preço promocional', callback: price => toMoney(price.promotionalPrice) },
          { name: 'Promoção', callback: price => (<BooleanLabel value={price.promotion} trueLabel='Sim' falseLabel='Não' />) },
          {
            name: '',
            callback: price => (
              <Actions>
                <Action title='Alterar'>
                  {this.getEditPriceModal(price, product)}
                </Action>
              </Actions>
            )
          }
        ]}
        data={this.pricesList()}
      />
    )
  }
}
