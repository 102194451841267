import React from 'react'
import * as Bootstrap from 'react-bootstrap'

const Form = (props) => (
  <Bootstrap.Form {...props}>
    {props.children}
  </Bootstrap.Form>
)

export default Form
