import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import DataList, { Actions, Action } from 'components/datalist'
import ConfirmDialog from 'components/app/confirm-dialog'
import { routeTo } from 'router'
import { fetchPermissions } from 'security/authorization'
import { message } from 'utils/messages'
import ContactRemoveMutation from 'relay/mutations/ContactRemoveMutation'

class ContactsList extends Component {
  constructor (props) {
    super(props)

    this.state = {permissions: {}}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['contacts:add', 'contacts:edit', 'contacts:remove'])
    this.setState({ permissions })
  }

  _remove (contact) {
    ContactRemoveMutation.commit(
      {id: contact.id},
      this.props.viewer,
      {
        onCompleted: ({ ContactRemove }) => {
          this.props.notify('success', 'Sucesso', 'Contato removido com sucesso')
        },
        onError: (transaction) => {
          this.props.notify('error', 'Erro', message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title='Contatos'
        permission='contacts:list'
        menu={{link: 'Contatos'}}
        breadcrumb={{
          title: 'Contatos',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/contacts', title: 'Contatos'},
            {title: 'Listar'}
          ],
          actions: (
            <div>
              {this.state.permissions['contacts:add'] && (
                <Link to={routeTo('dashboard.contacts.add')} className='btn btn-primary'>
                  <i className='fa fa-plus' /> Adicionar
                </Link>
              )}
            </div>
          )
        }}>
        <DataList
          name='ContactsList'
          environment={Environment}
          query={graphql`
            query contactsList_datalist_Query($first: Int!, $search: String) {
              viewer {
                ...contactsList_datalist_viewer
              }
            }
          `}
          fragments={{
            viewer: graphql`
              fragment contactsList_datalist_viewer on Viewer {
                id
                contacts(first: $first, search: $search) @connection(key: "list_contacts", filters: []) {
                  edges {
                    node {
                      id
                      title
                      type
                      value
                      companies {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                  pageInfo {
                    hasNextPage
                  }
                }
              }
            `
          }}
          columns={[
            {name: 'Título de identificação', property: 'title'},
            {
              name: '',
              callback: (contact) => (
                <div><i className={`fa fa-${contact.type === 'PHONE' ? 'phone' : 'envelope'}`} /> {contact.value}</div>
              )
            },
            {
              name: 'Ação',
              callback: (contact) => (
                <Actions>
                  {this.state.permissions['contacts:edit'] && (
                    <Action title='Alterar'>
                      <Link to={routeTo('dashboard.contacts.edit', {id: contact.id})}>
                        <i className='fa fa-edit' />
                      </Link>
                    </Action>
                  )}
                  {this.state.permissions['contacts:remove'] && (
                    <Action title='Excluir'>
                      <ConfirmDialog
                        icon='fa fa-trash-o'
                        title={contact.name}
                        subtitle='Deseja remover este Contato? Esta ação não poderá ser desfeita.'
                        onOk={() => this._remove(contact)}>
                        <i className='fa fa-trash-o' />
                      </ConfirmDialog>
                    </Action>
                  )}
                </Actions>
              )
            }
          ]}
        />
      </Content>
    )
  }
}

const ContactsListRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query contactsList_viewer_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (
      <ContactsList
        {...rendererProps}
        {...props} />
    )}
  />
)

export default connect(null, {...appActionCreators})(ContactsListRenderer)
