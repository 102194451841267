/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserEditInput = {|
  id: string,
  role: string,
  name: string,
  email: string,
  password?: ?string,
  clientMutationId?: ?string,
|};
export type UserEditMutationVariables = {|
  input: UserEditInput
|};
export type UserEditMutationResponse = {|
  +UserEdit: ?{|
    +user: ?{|
      +id: string,
      +name: ?string,
    |},
    +error: ?string,
  |}
|};
export type UserEditMutation = {|
  variables: UserEditMutationVariables,
  response: UserEditMutationResponse,
|};
*/


/*
mutation UserEditMutation(
  $input: UserEditInput!
) {
  UserEdit(input: $input) {
    user {
      id
      name
    }
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserEditPayload",
    "kind": "LinkedField",
    "name": "UserEdit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "54db4e7ef17f14f239eb867fd086f110",
    "id": null,
    "metadata": {},
    "name": "UserEditMutation",
    "operationKind": "mutation",
    "text": "mutation UserEditMutation(\n  $input: UserEditInput!\n) {\n  UserEdit(input: $input) {\n    user {\n      id\n      name\n    }\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c2d1ba98992a41a763d7dab224dc5103';

module.exports = node;
