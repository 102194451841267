import { reduxForm } from 'redux-form'

import Booleans from 'utils/booleans'

export Buttons from './buttons'
export Checkboxes from './checkboxes'
export Datepicker from './datepicker'
export Datetimepicker from './datetimepicker'
export DynamicCheckboxes from './dynamic-checkboxes'
export DynamicSelect from './dynamic-select'
export DynamicSelectCreatable from './dynamic-select-creatable'
export File from './file'
export Form from './form'
export Group from './group'
export Input from './input'
export Select from './select'
export Wysiwyg from './wysiwyg'
export Textarea from './textarea'
export Tags from './tags'
export CheckboxGroup from './checkbox-group'
export PhoneInput from './phone-input'
export MoneyInput from './money-input'
export NumericInput from './numeric-input'
export ColorPickerGroup from './color-picker-group'

export function toForm (values) {
  return Object.keys(values).reduce((prev, curr) => {
    const value = values[curr]
    const sanitized = (typeof value === 'boolean') ? value.toString() : value

    return { ...prev, [curr]: sanitized }
  }, {})
}

export function toMutation (values) {
  const sanitize = (value) => {
    if (value === 'true' || value === 'false') {
      return Booleans.valueOf(value)
    }
    if (typeof value === 'string' && value.length === 0) {
      return null
    }

    return value
  }

  return Object.keys(values).reduce((prev, curr) => {
    const value = values[curr]
    const sanitized = sanitize(value)

    return { ...prev, [curr]: sanitized }
  }, {})
}

export default function createForm (component, validate) {
  return reduxForm({
    enableReinitialize: true,
    form: component.name,
    validate
  })(component)
}
