import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation ContactMessageViewMutation($input: ContactMessageViewInput!) {
    ContactMessageView(input: $input) {
      contactMessage {
        id
        viewedAt
      }
      error
    }
  }
`
function getConfigs (id) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      contactMessage: id
    }
  }]
}

function commit (environment, values, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: values.id
        }
      },
      configs: getConfigs(values.id),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
