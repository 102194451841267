import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation UserAddMutation($input: UserAddInput!) {
    UserAdd(input: $input) {
      userEdge {
        node {
          id
          role {
            id
            name
          }
          name
          email
        }
      }
      viewer {
        users {
          edges {
            node {
              id
              name
              email
            }
          }
        }
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'RANGE_ADD',
    parentName: 'viewer',
    parentID: viewer.id,
    connectionName: 'users',
    edgeName: 'userEdge',
    rangeBehaviors: {
      '': 'prepend'
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    UserAdd: {
      user: {
        role: values.role,
        name: values.name,
        firstName: values.name.split(' ')[0],
        email: values.email
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          role: values.role.id,
          name: values.name,
          email: values.email,
          password: values.password
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
