// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components-templates-dashboard-helper-helper__wrapper--3uwuc {\n  padding: 10px 25px;\n  text-align: center;\n  color: #5d5e6e;\n}\n.components-templates-dashboard-helper-helper__wrapper--3uwuc .components-templates-dashboard-helper-helper__icon--35U8K {\n  font-size: 84px;\n  color: #8998a5;\n  margin-bottom: 10px;\n}\n.components-templates-dashboard-helper-helper__wrapper--3uwuc p {\n  line-height: 24px;\n}\n.components-templates-dashboard-helper-helper__wrapper--3uwuc ul {\n  text-align: left;\n}\n.components-templates-dashboard-helper-helper__wrapper--3uwuc h4 {\n  text-align: left;\n}\n.components-templates-dashboard-helper-helper__wrapper--3uwuc code {\n  color: #FFF;\n  background: #8998a5;\n}\n", "",{"version":3,"sources":["webpack://components/templates/dashboard/helper/helper.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,kBAAA;EACA,cAAA;AACF;AAJA;EAMI,eAAA;EACA,cAAA;EACA,mBAAA;AACJ;AATA;EAYI,iBAAA;AAAJ;AAZA;EAgBI,gBAAA;AADJ;AAfA;EAoBI,gBAAA;AAFJ;AAlBA;EAwBI,WAAA;EACA,mBAAA;AAHJ","sourcesContent":[".wrapper {\n  padding: 10px 25px;\n  text-align: center;\n  color: #5d5e6e;\n\n  .icon {\n    font-size: 84px;\n    color: #8998a5;\n    margin-bottom: 10px;\n  }\n\n  p {\n    line-height: 24px;\n  }\n\n  ul {\n    text-align: left;\n  }\n\n  h4 {\n    text-align: left;\n  }\n\n  code {\n    color: #FFF;\n    background: #8998a5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "components-templates-dashboard-helper-helper__wrapper--3uwuc",
	"icon": "components-templates-dashboard-helper-helper__icon--35U8K"
};
export default ___CSS_LOADER_EXPORT___;
