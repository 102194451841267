import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'components/spinner'
import createForm, { Form, Input, Buttons } from 'components/forms'
import Validation from 'utils/validation'

const PasswordRecoveryRequestForm = (props) => {
  const { handleSubmit, pristine, submitting, valid } = props

  return (
    <div>
      <Spinner visible={props.loading} />

      <Form onSubmit={handleSubmit}>
        <Input name='email' type='email' placeholder='Digite seu e-mail' />

        <Buttons pristine={pristine} submitting={submitting} valid={valid} submitText='Solicitar nova senha' />
      </Form>
    </div>
  )
}

PasswordRecoveryRequestForm.defaultProps = {
  loading: false
}

PasswordRecoveryRequestForm.propTypes = {
  loading: PropTypes.bool
}

const validation = new Validation({
  email: [Validation.required, Validation.email]
})

export default createForm(PasswordRecoveryRequestForm, validation)
