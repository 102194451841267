import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation CompanyAddMutation($input: CompanyAddInput!) {
    CompanyAdd(input: $input) {
      companyEdge {
        node {
          id
          state {
            id
            name
          }
          city {
            id
            name
          }
          name
          email
          phone
          image {
            key
            url
          }
          address
          number
          neighborhood
          zipCode
          parkingLots
          openingHours
          active
          location {
            latitude
            longitude
          }
        }
      }
      viewer {
        companies {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'RANGE_ADD',
    parentName: 'viewer',
    parentID: viewer.id,
    connectionName: 'companies',
    edgeName: 'companyEdge',
    rangeBehaviors: {
      '': 'prepend'
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    CompanyAdd: {
      company: {
        state: values.state,
        city: values.city,
        name: values.name,
        email: values.email,
        phone: values.phone,
        image: values.image,
        address: values.address,
        number: values.number,
        neighborhood: values.neighborhood,
        zipCode: values.zipCode,
        parkingLots: values.parkingLots,
        openingHours: values.openingHours,
        active: values.active,
        location: {
          latitude: values.latitude,
          longitude: values.longitude
        }
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          state: values.state.id,
          city: values.city.id,
          name: values.name,
          email: values.email,
          phone: values.phone,
          image: values.image,
          address: values.address,
          number: values.number,
          neighborhood: values.neighborhood,
          zipCode: values.zipCode,
          parkingLots: values.parkingLots,
          openingHours: values.openingHours,
          active: values.active,
          latitude: values.latitude,
          longitude: values.longitude
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
