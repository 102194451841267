/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductGroupChangeOrderInput = {|
  id: string,
  productId: string,
  order: number,
  clientMutationId?: ?string,
|};
export type ProductGroupChangeOrderMutationVariables = {|
  input: ProductGroupChangeOrderInput
|};
export type ProductGroupChangeOrderMutationResponse = {|
  +ProductGroupChangeOrder: ?{|
    +productGroup: ?{|
      +id: string,
      +products: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +image: ?{|
          +url: ?string
        |},
      |}>,
    |}
  |}
|};
export type ProductGroupChangeOrderMutation = {|
  variables: ProductGroupChangeOrderMutationVariables,
  response: ProductGroupChangeOrderMutationResponse,
|};
*/


/*
mutation ProductGroupChangeOrderMutation(
  $input: ProductGroupChangeOrderInput!
) {
  ProductGroupChangeOrder(input: $input) {
    productGroup {
      id
      products {
        id
        name
        image {
          url
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductGroupChangeOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductGroupChangeOrderPayload",
        "kind": "LinkedField",
        "name": "ProductGroupChangeOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductGroup",
            "kind": "LinkedField",
            "name": "productGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "products",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductGroupChangeOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductGroupChangeOrderPayload",
        "kind": "LinkedField",
        "name": "ProductGroupChangeOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductGroup",
            "kind": "LinkedField",
            "name": "productGroup",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "products",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c872f98e39b0ea12a180b4c71aeeede",
    "id": null,
    "metadata": {},
    "name": "ProductGroupChangeOrderMutation",
    "operationKind": "mutation",
    "text": "mutation ProductGroupChangeOrderMutation(\n  $input: ProductGroupChangeOrderInput!\n) {\n  ProductGroupChangeOrder(input: $input) {\n    productGroup {\n      id\n      products {\n        id\n        name\n        image {\n          url\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd45dff10c1c27b607f388d8032461a76';

module.exports = node;
