import React from 'react'

export default ({ value, trueLabel, falseLabel }) => {
  if (value !== true && value !== false) {
    return null
  }

  const colorClass = value ? 'primary' : 'default'

  return (<span className={`label label-${colorClass}`}>{value ? trueLabel : falseLabel}</span>)
}
