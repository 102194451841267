import React from 'react'

import Helper from 'components/templates/dashboard/helper'

const OfferCatalogsHelper = () => (
  <Helper>
    <h3>Instruções</h3>
    <p>
      Aqui você pode configurar os <code>Encartes</code> as potenciais para se tornarem clientes do supermercado.
      <br />
      <br />
      <h4>Dicas:</h4>
      <ul>
        <li>Você pode configurar os encartes como temporário, para que eles sejam inseridos e removidos no site na data e horário que você definir previamente.</li>
        <li>Caso tenha encartes diferentes para situações diferentes, você pode criar categorias.</li>
      </ul>
      <br />
      <h4>Dicas para o arquivo PDF do encarte:</h4>
      <ul>
        <li><b>Tamanho</b>: é recomendado que o arquivo PDF do encarte <b>não ultrapasse 10MB</b>, para que fique rápido para ser carregado no site.</li>
        <li><b>Proporção</b>: <b>1495px</b> de largura por <b>680px</b> de altura.</li>

      </ul>
    </p>
  </Helper>
)

export default OfferCatalogsHelper
