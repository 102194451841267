/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RecipeEditInput = {|
  id: string,
  state?: ?string,
  city?: ?string,
  title: string,
  image: FileInput,
  advertisingImage?: ?FileInput,
  preparationTime?: ?string,
  yield?: ?string,
  ingredients?: ?string,
  instructions?: ?string,
  author?: ?string,
  date?: ?string,
  active?: ?boolean,
  clientMutationId?: ?string,
|};
export type FileInput = {|
  key?: ?string,
  url?: ?string,
  name?: ?string,
  size?: ?number,
  type?: ?string,
|};
export type RecipeEditMutationVariables = {|
  input: RecipeEditInput
|};
export type RecipeEditMutationResponse = {|
  +RecipeEdit: ?{|
    +recipe: ?{|
      +id: string,
      +title: ?string,
    |},
    +error: ?string,
  |}
|};
export type RecipeEditMutation = {|
  variables: RecipeEditMutationVariables,
  response: RecipeEditMutationResponse,
|};
*/


/*
mutation RecipeEditMutation(
  $input: RecipeEditInput!
) {
  RecipeEdit(input: $input) {
    recipe {
      id
      title
    }
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RecipeEditPayload",
    "kind": "LinkedField",
    "name": "RecipeEdit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Recipe",
        "kind": "LinkedField",
        "name": "recipe",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecipeEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecipeEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fd8c4d88bfcc1f0e010c5d2d833945a2",
    "id": null,
    "metadata": {},
    "name": "RecipeEditMutation",
    "operationKind": "mutation",
    "text": "mutation RecipeEditMutation(\n  $input: RecipeEditInput!\n) {\n  RecipeEdit(input: $input) {\n    recipe {\n      id\n      title\n    }\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fc322986c934bfa32a17a6a386a8bfc3';

module.exports = node;
