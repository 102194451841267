import { Component } from 'react'

import { connect } from 'react-redux'

class MiniMenuListener extends Component {
  UNSAFE_componentWillReceiveProps (nextProps) {
    document.body.classList.toggle('mini-navbar', nextProps.menu.mini)
  }

  render () {
    return null
  }
}

const mapStateToProps = (state) => ({
  menu: state.dashboard.menu
})

export default connect(mapStateToProps)(MiniMenuListener)
