import React, { Component } from 'react'
import NotificationSystem from 'react-notification-system'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

class Notifications extends Component {
  UNSAFE_componentWillReceiveProps (nextProps) {
    nextProps.notifications.map((notification) => {
      this.refs.notificationSystem.addNotification({
        message: notification.message,
        title: notification.title,
        level: notification.level,
        uid: notification.uid
      })

      this.props.actions.markNotificationAsShown(notification.uid)
    })
  }

  render () {
    return (
      <NotificationSystem ref='notificationSystem' style={false} />
    )
  }
}

const mapStateToProps = (state) => ({
  notifications: state.app.notifications
})

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
