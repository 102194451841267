/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferCatalogEditInput = {|
  id: string,
  title: string,
  companies?: ?$ReadOnlyArray<?string>,
  pdfUrl?: ?string,
  startDate?: ?any,
  endDate?: ?any,
  weekDays?: ?$ReadOnlyArray<?string>,
  category: string,
  active: boolean,
  image?: ?FileInput,
  clientMutationId?: ?string,
|};
export type FileInput = {|
  key?: ?string,
  url?: ?string,
  name?: ?string,
  size?: ?number,
  type?: ?string,
|};
export type OfferCatalogEditMutationVariables = {|
  input: OfferCatalogEditInput
|};
export type OfferCatalogEditMutationResponse = {|
  +OfferCatalogEdit: ?{|
    +offerCatalog: ?{|
      +id: string,
      +title: ?string,
      +order: ?number,
      +isuuDocumentId: ?string,
      +category: ?{|
        +id: string,
        +name: ?string,
      |},
      +active: ?boolean,
      +startDate: ?any,
      +endDate: ?any,
      +weekDays: ?$ReadOnlyArray<?string>,
      +image: ?{|
        +key: ?string,
        +url: ?string,
      |},
    |}
  |}
|};
export type OfferCatalogEditMutation = {|
  variables: OfferCatalogEditMutationVariables,
  response: OfferCatalogEditMutationResponse,
|};
*/


/*
mutation OfferCatalogEditMutation(
  $input: OfferCatalogEditInput!
) {
  OfferCatalogEdit(input: $input) {
    offerCatalog {
      id
      title
      order
      isuuDocumentId
      category {
        id
        name
      }
      active
      startDate
      endDate
      weekDays
      image {
        key
        url
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isuuDocumentId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "OfferCatalogCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weekDays",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OfferCatalogEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OfferCatalogEditPayload",
        "kind": "LinkedField",
        "name": "OfferCatalogEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OfferCatalog",
            "kind": "LinkedField",
            "name": "offerCatalog",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OfferCatalogEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OfferCatalogEditPayload",
        "kind": "LinkedField",
        "name": "OfferCatalogEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OfferCatalog",
            "kind": "LinkedField",
            "name": "offerCatalog",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38f48ecd69f060a2a7a8c506480d4983",
    "id": null,
    "metadata": {},
    "name": "OfferCatalogEditMutation",
    "operationKind": "mutation",
    "text": "mutation OfferCatalogEditMutation(\n  $input: OfferCatalogEditInput!\n) {\n  OfferCatalogEdit(input: $input) {\n    offerCatalog {\n      id\n      title\n      order\n      isuuDocumentId\n      category {\n        id\n        name\n      }\n      active\n      startDate\n      endDate\n      weekDays\n      image {\n        key\n        url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e28329088809e136138906850dc75e9c';

module.exports = node;
