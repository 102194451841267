import { commitMutation, graphql } from 'react-relay'
import Environment, { removeUpdater } from 'relay'

const mutation = graphql`
  mutation ProductRemoveMutation($input: ProductRemoveInput!) {
    ProductRemove(input: $input) {
      deletedId
    }
  }
`

function commit (productId, viewer, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: {
          id: productId
        }
      },
      updater: (store) => {
        const payload = store.getRootField('ProductRemove')
        const deletedId = payload.getValue('deletedId')

        removeUpdater(store, viewer, 'list_products', deletedId)
      },
      optimisticUpdater: store => {
        removeUpdater(store, viewer, 'list_products', productId)
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
