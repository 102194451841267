import { AWS_KEY, AWS_SECRET } from './index'
import { uploadImage } from '../files'

async function getClient (region) {
  return new Promise((resolve, reject) => {
    import('aws-sdk').then(AWS => {
      AWS.config.update({
        accessKeyId: AWS_KEY,
        secretAccessKey: AWS_SECRET,
        region: region
      })

      resolve(new AWS.S3())
    })
  })
}

export async function upload (region, bucket, file) {
  return uploadImage(file)
}
