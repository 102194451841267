/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BannerEditInput = {|
  id: string,
  companies?: ?$ReadOnlyArray<?string>,
  title: string,
  buttonCaption?: ?string,
  image?: ?FileInput,
  position?: ?string,
  order?: ?number,
  link?: ?string,
  linkTarget?: ?string,
  temporary?: ?boolean,
  start?: ?string,
  end?: ?string,
  days?: ?$ReadOnlyArray<?string>,
  active?: ?boolean,
  clientMutationId?: ?string,
|};
export type FileInput = {|
  key?: ?string,
  url?: ?string,
  name?: ?string,
  size?: ?number,
  type?: ?string,
|};
export type BannerEditMutationVariables = {|
  input: BannerEditInput
|};
export type BannerEditMutationResponse = {|
  +BannerEdit: ?{|
    +banner: ?{|
      +id: string,
      +title: ?string,
      +order: ?number,
      +active: ?boolean,
    |},
    +error: ?string,
  |}
|};
export type BannerEditMutation = {|
  variables: BannerEditMutationVariables,
  response: BannerEditMutationResponse,
|};
*/


/*
mutation BannerEditMutation(
  $input: BannerEditInput!
) {
  BannerEdit(input: $input) {
    banner {
      id
      title
      order
      active
    }
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BannerEditPayload",
    "kind": "LinkedField",
    "name": "BannerEdit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Banner",
        "kind": "LinkedField",
        "name": "banner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "order",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BannerEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BannerEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b7fbcb8a87dd9db8d56706d902bc5248",
    "id": null,
    "metadata": {},
    "name": "BannerEditMutation",
    "operationKind": "mutation",
    "text": "mutation BannerEditMutation(\n  $input: BannerEditInput!\n) {\n  BannerEdit(input: $input) {\n    banner {\n      id\n      title\n      order\n      active\n    }\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0818421a0261d558eb53cba1d9dc69ae';

module.exports = node;
