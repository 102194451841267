import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'
import { contactIdentificationOptions } from 'utils/constants'

const mutation = graphql`
  mutation ContactAddMutation($input: ContactAddInput!) {
    ContactAdd(input: $input) {
      contactEdge {
        node {
          id
          title
          type
          value
          companies {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'RANGE_ADD',
    parentName: 'viewer',
    parentID: viewer.id,
    connectionName: 'contacts',
    edgeName: 'contactEdge',
    rangeBehaviors: {
      '': 'prepend'
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    ContactAdd: {
      contact: {
        title: values.title,
        type: values.type,
        value: values.value,
        companies: values.companies
      }
    }
  }
}

function commit (values, viewer, config) {
  const title = contactIdentificationOptions[values.identification]
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: {
          ...values,
          title,
          companies: values.companies
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
