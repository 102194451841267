import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import RoleEditMutation from 'relay/mutations/RoleEditMutation'

import Content from 'components/templates/dashboard/content'
import { toForm, toMutation } from 'components/forms'
import { message } from 'utils/messages'
import { routeTo } from 'router'
import RolesForm from './roles-form'
import RolesHelper from './roles-helper'

class RolesEdit extends Component {
  state = {
    loading: false
  }

  _loading (loading) {
    this.setState({
      loading: loading
    })
  }

  _success () {
    this.props.actions.notify('success', 'Sucesso', 'Grupo alterado com sucesso')
    this.props.history.push(routeTo('dashboard.roles'))
  }

  _error (message) {
    this.props.actions.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    RoleEditMutation.commit(
      Environment,
      toMutation(values),
      this.props.viewer,
      {
        onCompleted: ({ RoleEdit }) => {
          this._loading(false)

          if (RoleEdit.error) {
            this._error(message(RoleEdit.error))
          } else {
            this._success()
          }
        },
        onError: (transaction) => {
          this._loading(false)

          this._error(message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title='Alterar grupo'
        permission='roles:edit'
        menu={{
          group: 'Acesso',
          link: 'Grupos'
        }}
        breadcrumb={{
          title: 'Grupos',
          items: [
            { 'url': '/dashboard', 'title': 'Dashboard' },
            { 'url': '/dashboard/roles', 'title': 'Grupos' },
            { 'title': 'Alterar' }
          ]
        }}
      >
        <Row>
          <Col sm={7}>
            {!this.props.viewer ? (
              <RolesForm loading />
            ) : (
              <RolesForm
                loading={this.state.loading}
                initialValues={toForm(this.props.viewer.role)}
                onSubmit={(values) => this._onSubmit(values)}
              />
            )}
          </Col>

          <Col sm={5}>
            <RolesHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const RolesEditQueryRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query rolesEdit_Query($id: ID!) {
        viewer {
          role(id: $id) {
            id
            name
            permissions
          }
        }
      }
    `}
    variables={{ id: props.match.params.id }}
    render={({props: rendererProps}) => (
      <RolesEdit
        {...rendererProps}
        {...props}
      />
    )}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(RolesEditQueryRenderer)
