import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import { ProfileEditMutation } from 'relay/mutations'

import Content from 'components/templates/dashboard/content'
import Helper from 'components/templates/dashboard/helper'
import { toForm, toMutation } from 'components/forms'
import { message } from 'utils/messages'
import ProfileForm from './profile-form'

class Profile extends Component {
  state = {
    loading: false
  }

  _loading (loading) {
    this.setState({
      loading: loading
    })
  }

  _success () {
    this.props.actions.notify('success', 'Sucesso', 'Seu perfil foi atualizado')
  }

  _error (message) {
    this.props.actions.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    ProfileEditMutation.commit(
      Environment,
      toMutation(values),
      this.props.viewer,
      {
        onCompleted: ({ ProfileEdit }) => {
          this._loading(false)

          if (ProfileEdit.error) {
            this._error(message(ProfileEdit.error))
          } else {
            this._success()
          }
        },
        onError: (transaction) => {
          this._loading(false)

          this._error(message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title={'Meu perfil'}
        breadcrumb={{
          title: 'Meu perfil',
          items: [
            { 'url': '/dashboard', 'title': 'Dashboard' },
            { 'title': 'Meu Perfil' }
          ]
        }}
      >
        <Row>
          <Col sm={7}>
            {!this.props.viewer ? (
              <ProfileForm loading />
            ) : (
              <ProfileForm
                loading={this.state.loading}
                initialValues={toForm(this.props.viewer.me)}
                onSubmit={(values) => this._onSubmit(values)}
              />
            )}
          </Col>

          <Col sm={5}>
            <Helper>
              <h3>Instruções</h3>
              <p>
                Aqui você pode alterar o <b>Nome</b>, o <b>E-mail</b> e a <b>Senha</b> da sua conta.
                <br />
                <br />
                <h4>Dicas:</h4>
                <ul>
                  <li>Insira o seu nome completo.</li>
                  <li>Insira um e-mail que você utilize com frequência.</li>
                  <li>Elabore boas senhas, misture caracteres como: números, sinais de pontuação e letras maiúsculas e minúsculas.</li>
                  <li>Altere sua senha periodicamente ou sempre que julgar necessário.</li>
                  <li>Certifique-se de não estar sendo observado ao digitar sua senha.</li>
                  <li>Nunca forneça sua senha para outras pessoas.</li>
                  <li>Certifique-se de clicar no botão sair no canto superior direito quando desejar sair do sistema.</li>
                </ul>
              </p>
            </Helper>
          </Col>
        </Row>
      </Content>
    )
  }
}

const ProfileQueryRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query profile_Query {
        viewer {
          me {
            id
            name
            email
          }
        }
      }
    `}
    render={({props: rendererProps}) => (
      <Profile
        {...rendererProps}
        {...props}
      />
    )}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(ProfileQueryRenderer)
