import React from 'react'
import PropTypes from 'prop-types'

import styles from './actions.less'

const Actions = (props) => (
  <div className={styles.actions}>
    {props.children}
  </div>
)

Actions.propTypes = {
  children: PropTypes.node.isRequired
}

export default Actions
