/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferCatalogAddInput = {|
  companies?: ?$ReadOnlyArray<?string>,
  title: string,
  pdfUrl: string,
  startDate?: ?any,
  endDate?: ?any,
  weekDays?: ?$ReadOnlyArray<?string>,
  category: string,
  active: boolean,
  image?: ?FileInput,
  clientMutationId?: ?string,
|};
export type FileInput = {|
  key?: ?string,
  url?: ?string,
  name?: ?string,
  size?: ?number,
  type?: ?string,
|};
export type OfferCatalogAddMutationVariables = {|
  input: OfferCatalogAddInput
|};
export type OfferCatalogAddMutationResponse = {|
  +OfferCatalogAdd: ?{|
    +offerCatalogEdge: ?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +order: ?number,
        +isuuDocumentId: ?string,
        +category: ?{|
          +id: string,
          +name: ?string,
        |},
        +active: ?boolean,
        +startDate: ?any,
        +endDate: ?any,
        +weekDays: ?$ReadOnlyArray<?string>,
        +image: ?{|
          +key: ?string,
          +url: ?string,
        |},
      |}
    |}
  |}
|};
export type OfferCatalogAddMutation = {|
  variables: OfferCatalogAddMutationVariables,
  response: OfferCatalogAddMutationResponse,
|};
*/


/*
mutation OfferCatalogAddMutation(
  $input: OfferCatalogAddInput!
) {
  OfferCatalogAdd(input: $input) {
    offerCatalogEdge {
      node {
        id
        title
        order
        isuuDocumentId
        category {
          id
          name
        }
        active
        startDate
        endDate
        weekDays
        image {
          key
          url
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isuuDocumentId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "OfferCatalogCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weekDays",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OfferCatalogAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OfferCatalogAddPayload",
        "kind": "LinkedField",
        "name": "OfferCatalogAdd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OfferCatalogEdge",
            "kind": "LinkedField",
            "name": "offerCatalogEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OfferCatalog",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OfferCatalogAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OfferCatalogAddPayload",
        "kind": "LinkedField",
        "name": "OfferCatalogAdd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OfferCatalogEdge",
            "kind": "LinkedField",
            "name": "offerCatalogEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OfferCatalog",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14e04e15e59d6f2fdc8cbc0272e08262",
    "id": null,
    "metadata": {},
    "name": "OfferCatalogAddMutation",
    "operationKind": "mutation",
    "text": "mutation OfferCatalogAddMutation(\n  $input: OfferCatalogAddInput!\n) {\n  OfferCatalogAdd(input: $input) {\n    offerCatalogEdge {\n      node {\n        id\n        title\n        order\n        isuuDocumentId\n        category {\n          id\n          name\n        }\n        active\n        startDate\n        endDate\n        weekDays\n        image {\n          key\n          url\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e8355280a8f4cd50991d14fc38945ec4';

module.exports = node;
