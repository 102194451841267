import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'

import Content from 'components/templates/dashboard/content'
import { toMutation } from 'components/forms'
import { getMessagesFromErrors } from 'utils/messages'
import { routeTo } from 'router'
import LeadsForm from './leads-form'
import LeadsHelper from './leads-helper'
import LeadAddMutation from 'relay/mutations/LeadAddMutation'

class LeadsAdd extends Component {
  constructor (props) {
    super(props)

    this.state = {loading: false}
  }

  _loading (loading) {
    this.setState({...this.state, loading})
  }

  _success () {
    this.props.notify('success', 'Sucesso', 'Lead adicionado com sucesso')
    this.props.history.push(routeTo('dashboard.leads'))
  }

  _error (message) {
    this.props.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    LeadAddMutation.commit(
      Environment,
      toMutation(values),
      this.props.viewer,
      {
        onCompleted: ({LeadAdd}) => {
          this._loading(false)

          this._success()
        },
        onError: (errors) => {
          this._loading(false)

          getMessagesFromErrors(errors).forEach(m => this._error(m))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title='Adicionar Lead'
        permission='leads:add'
        menu={{link: 'Leads'}}
        breadcrumb={{
          title: 'Leads',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/leads', title: 'Leads'},
            {title: 'Adicionar'}
          ]
        }} >
        <Row>
          <Col sm={7}>
            <LeadsForm loading={this.state.loading} onSubmit={(values) => this._onSubmit(values)} />
          </Col>
          <Col sm={5}>
            <LeadsHelper />
          </Col>
        </Row>
      </Content>

    )
  }
}

const LeadsAddRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query leadsAdd_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (<LeadsAdd {...rendererProps} {...props} />)}
  />
)

export default connect(null, appActionCreators)(LeadsAddRenderer)
