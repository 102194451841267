import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import ProductGroupRemoveMutation from 'relay/mutations/ProductGroupRemoveMutation'
import Content from 'components/templates/dashboard/content'
import DataList, { Actions, Action } from 'components/datalist'
import ConfirmDialog from 'components/app/confirm-dialog'
import { routeTo } from 'router'
import { fetchPermissions } from 'security/authorization'
import BooleanLabel from 'components/boolean-label'

class ProductGroupsList extends Component {
  constructor (props) {
    super(props)

    this.state = {permissions: {}}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['product-groups:add', 'product-groups:edit', 'product-groups:remove'])
    this.setState({ permissions })
  }

  _remove (group) {
    ProductGroupRemoveMutation.commit(group.id, this.props.viewer, {
      onCompleted: ({ProductGroupRemove}) => {
        this.props.notify('success', 'Sucesso', 'Groupo de produtos removido com sucesso')
      },
      onError: (errors) => {
        this.props.notify('error', 'Erro', 'Erro ao remover grupo de produtos')
      }
    })
  }

  render () {
    return (
      <Content
        title='Grupos de Produtos'
        permission='product-groups:list'
        menu={{link: 'Grupos de Produtos'}}
        breadcrumb={{
          title: 'Grupos de Produtos',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/product-groups', title: 'Grupos de Produtos'},
            {title: 'Listar'}
          ],
          actions: this.state.permissions['product-groups:add'] && (
            <Link to={routeTo('dashboard.product-groups.add')} className='btn btn-primary'>
              <i className='fa fa-plus' /> Adicionar
            </Link>
          )
        }}>
        <DataList
          name='ProductGroupsList'
          environment={Environment}
          query={graphql`
            query productGroupsList_datalist_Query($first: Int!, $search: String) {
              viewer {
                ...productGroupsList_datalist_viewer
              }
            }
          `}
          fragments={{
            viewer: graphql`
              fragment productGroupsList_datalist_viewer on Viewer {
                id
                productGroups(first: $first, search: $search) @connection(key: "list_productGroups", filters: []) {
                  edges {
                    node {
                      id
                      title
                      active
                    }
                  }
                  pageInfo {
                    hasNextPage
                  }
                }
              }
            `
          }}
          columns={[
            {name: 'Título', property: 'title'},
            {name: 'Situação', callback: group => (<BooleanLabel value={group.active} trueLabel='Publicado' falseLabel='Inativo' />)},
            {
              name: 'Ação',
              callback: group => (
                <Actions>
                  {this.state.permissions['product-groups:edit'] && (
                    <Action title='Alterar'>
                      <Link to={routeTo('dashboard.product-groups.edit', {id: group.id})}>
                        <i className='fa fa-edit' />
                      </Link>
                    </Action>
                  )}
                  {this.state.permissions['product-groups:remove'] && (
                    <Action title='Excluir'>
                      <ConfirmDialog
                        icon='fa fa-trash-o'
                        title={group.title}
                        subtitle='Deseja remover este Grupo de produtos? Esta ação não poderá ser desfeita.'
                        onOk={() => this._remove(group)}>
                        <i className='fa fa-trash-o' />
                      </ConfirmDialog>
                    </Action>
                  )}
                </Actions>
              )
            }
          ]}
        />
      </Content>
    )
  }
}

const ProductGroupsListRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query productGroupsList_viewer_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (
      <ProductGroupsList
        {...rendererProps}
        {...props} />
    )}
  />
)

export default connect(null, {...appActionCreators})(ProductGroupsListRenderer)
