import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation LeadAddMutation($input: LeadAddInput!) {
    LeadAdd(input: $input) {
      leadEdge {
        node {
          id
          name
          email
        }
      }
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'RANGE_ADD',
    parentName: 'viewer',
    parentID: viewer.id,
    connectionName: 'leads',
    edgeName: 'leadEdge',
    rangeBehaviors: {
      '': 'prepend'
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    LeadAdd: {
      lead: {
        name: values.name,
        email: values.email,
        phone: values.phone,
        whatsapp: values.whatsapp
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          name: values.name,
          email: values.email,
          phone: values.phone,
          whatsapp: values.whatsapp,
          company: values.company.id
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
