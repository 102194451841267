/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type productGroupsAddProducts_QueryVariables = {|
  id: string
|};
export type productGroupsAddProducts_QueryResponse = {|
  +viewer: ?{|
    +productGroup: ?{|
      +id: string,
      +title: ?string,
    |}
  |}
|};
export type productGroupsAddProducts_Query = {|
  variables: productGroupsAddProducts_QueryVariables,
  response: productGroupsAddProducts_QueryResponse,
|};
*/


/*
query productGroupsAddProducts_Query(
  $id: ID!
) {
  viewer {
    productGroup(id: $id) {
      id
      title
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "id"
    }
  ],
  "concreteType": "ProductGroup",
  "kind": "LinkedField",
  "name": "productGroup",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "productGroupsAddProducts_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "productGroupsAddProducts_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9976b36da24f4c93c1cb55ab3b4d5a89",
    "id": null,
    "metadata": {},
    "name": "productGroupsAddProducts_Query",
    "operationKind": "query",
    "text": "query productGroupsAddProducts_Query(\n  $id: ID!\n) {\n  viewer {\n    productGroup(id: $id) {\n      id\n      title\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d62215694caefcf583c93282acb3fc9';

module.exports = node;
