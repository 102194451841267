import React from 'react'

import Helper from 'components/templates/dashboard/helper'

export default () => (
  <Helper>
    <h3>Instruções</h3>
    <p>Aqui você pode configurar os produtos.</p>
  </Helper>
)
