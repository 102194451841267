import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import UserRemoveMutation from 'relay/mutations/UserRemoveMutation'

import Content from 'components/templates/dashboard/content'
import DataList, { Actions, Action } from 'components/datalist'
import ConfirmDialog from 'components/app/confirm-dialog'
import { routeTo } from 'router'
import { fetchPermissions } from 'security/authorization'
import { message } from 'utils/messages'

class UsersList extends Component {
  state = {
    permissions: {}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions([
      'users:add', 'users:edit', 'users:remove'
    ])

    this.setState({ permissions })
  }

  _remove (user) {
    UserRemoveMutation.commit(
      Environment,
      user,
      this.props.viewer,
      {
        onCompleted: ({ UserRemove }) => {
          if (UserRemove.error) {
            this.props.actions.notify('error', 'Erro', message(UserRemove.error))
          } else {
            this.props.actions.notify('success', 'Sucesso', 'Usuário removido com sucesso')
          }
        },
        onError: (transaction) => {
          this.props.actions.notify('error', 'Erro', message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title='Usuários'
        permission='users:list'
        menu={{
          group: 'Acesso',
          link: 'Usuários'
        }}
        breadcrumb={{
          title: 'Usuários',
          items: [
            { 'url': '/dashboard', 'title': 'Dashboard' },
            { 'url': '/dashboard/users', 'title': 'Usuários' },
            { 'title': 'Listar' }
          ],
          actions: this.state.permissions['users:add'] && (
            <Link to={routeTo('dashboard.users.add')} className='btn btn-primary'>
              <i className='fa fa-plus' /> Adicionar
            </Link>
          )
        }}
      >
        <DataList
          name='UsersList'
          environment={Environment}
          query={graphql`
            query usersList_datatalist_Query($first: Int!, $search: String) {
              viewer {
                ...usersList_datatalist_viewer
              }
            }
          `}
          fragments={{
            viewer: graphql`
              fragment usersList_datatalist_viewer on Viewer {
                id
                users(first: $first, search: $search) @connection(key: "list_users", filters: []) {
                  edges {
                    node {
                      id
                      name
                      email
                    }
                  }
                  pageInfo {
                    hasNextPage
                  }
                }
              }
            `
          }}
          columns={[
            {
              name: 'Nome',
              property: 'name'
            },
            {
              name: 'E-mail',
              property: 'email'
            },
            {
              name: 'Ações',
              callback: (user) => {
                if (this.props.viewer.me.id === user.id) {
                  return null
                }

                return (
                  <Actions>
                    {this.state.permissions['users:edit'] && (
                      <Action title='Alterar'>
                        <Link to={routeTo('dashboard.users.edit', { id: user.id })}>
                          <i className='fa fa-edit' />
                        </Link>
                      </Action>
                    )}
                    {this.state.permissions['users:remove'] && (
                      <Action title='Excluir'>
                        <ConfirmDialog
                          icon='fa fa-trash-o'
                          title={user.name}
                          subtitle='Deseja remover este usuário? Isso não poderá ser desfeito.'
                          onOk={() => this._remove(user)}>
                          <i className='fa fa-trash-o' />
                        </ConfirmDialog>
                      </Action>
                    )}
                  </Actions>
                )
              }
            }
          ]}
        />
      </Content>
    )
  }
}

const UsersListRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query usersList_viewer_Query {
        viewer {
          id
          me {
            id
          }
        }
      }
    `}
    render={({props: rendererProps}) => (
      <UsersList
        {...rendererProps}
        {...props}
      />
    )}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(UsersListRenderer)
