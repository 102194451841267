import React from 'react'

import Helper from 'components/templates/dashboard/helper'

const generalHelper = (
  <Helper>
    <h3>Instruções</h3>
    <p>Esta seção é destinada as configurações <code>Gerais</code> do site.</p>
  </Helper>
)

const socialHelper = (
  <Helper>
    <h3>Instruções</h3>
    <p>Esta seção é destinada as configurações de <code>Mídias sociais</code>.</p>
  </Helper>
)

const searchHelper = (
  <Helper>
    <h3>Instruções</h3>
    <p>Esta seção é destinada as configurações de <code>Buscadores</code>.</p>
  </Helper>
)

const emailHelper = (
  <Helper>
    <h3>Instruções</h3>
    <p>Esta seção é destinada as configurações de <code>E-mail</code>.</p>
  </Helper>
)

const themeHelper = (
  <Helper>
    <h3>Instruções</h3>
    <p>Esta seção é destinada as configurações de <code>Tema</code>.</p>
  </Helper>
)

const maintenanceHelper = (
  <Helper>
    <h3>Instruções</h3>
    <p>Esta seção é destinada as configurações de <code>Disponibilidade</code>.</p>
  </Helper>
)

const helperTypeMap = new Map()
helperTypeMap.set('general', generalHelper)
helperTypeMap.set('email', emailHelper)
helperTypeMap.set('search', searchHelper)
helperTypeMap.set('social', socialHelper)
helperTypeMap.set('theme', themeHelper)
helperTypeMap.set('maintenance', maintenanceHelper)

export default ({type}) => helperTypeMap.get(type)
