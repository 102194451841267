import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation OfferCatalogEditMutation($input: OfferCatalogEditInput!) {
    OfferCatalogEdit(input: $input) {
      offerCatalog {
        id
        title
        order
        isuuDocumentId
        category {
          id
          name
        }
        active
        startDate
        endDate
        weekDays
        image {
          key
          url
        }
      }
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      offerCatalog: viewer.offerCatalog.id
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    OfferCatalogEdit: {
      offerCatalog: {
        title: values.title,
        category: values.category
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: values.id,
          companies: values.companies,
          title: values.title,
          pdfUrl: values.file ? values.file.url : null,
          startDate: values.temporary ? values.startDate : null,
          endDate: values.temporary ? values.endDate : null,
          weekDays: values.weekDays,
          category: values.category.name,
          active: values.active,
          image: values.image
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
