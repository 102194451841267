import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'components/spinner'
import createForm, { Form, Input, Buttons } from 'components/forms'
import Validation from '../../../utils/validation'

const PasswordRecoveryResponseForm = (props) => {
  const { handleSubmit, pristine, submitting, valid } = props

  return (
    <div>
      <Spinner visible={props.loading} />

      <Form onSubmit={handleSubmit}>
        <Input name='password' type='password' placeholder='Digite a nova senha' />
        <Input name='passwordConfirm' type='password' placeholder='Confirme a senha' />

        <Buttons pristine={pristine} submitting={submitting} valid={valid} submitText='Salvar' />
      </Form>
    </div>
  )
}

PasswordRecoveryResponseForm.defaultProps = {
  loading: false
}

PasswordRecoveryResponseForm.propTypes = {
  loading: PropTypes.bool
}

const generalValidation = new Validation({
  password: [Validation.required, Validation.minLength(8)],
  passwordConfirm: [Validation.required, Validation.minLength(8)]
})

const validation = (values) => {
  let errors = generalValidation(values)
  const { password, passwordConfirm } = values
  if (Object.keys(errors).length === 0 && password !== passwordConfirm) {
    errors.passwordConfirm = 'As senhas não conferem'
  }
  return errors
}

export default createForm(PasswordRecoveryResponseForm, validation)
