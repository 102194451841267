import React from 'react'
import PropTypes from 'prop-types'

const Icon = (props) => {
  const { size } = props

  return (
    <svg width={size} height={size} viewBox='0 0 100 100'>
      <g id='XMLID_3_'>
        <path id='XMLID_12_' style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#A9B2BB'}} d='M63.834,25.137l11.137,11.138
          c7.371,7.375,7.371,19.439,0,26.818L63.834,74.232c-7.379,7.371-19.444,7.371-26.823,0L25.874,63.094
          c-7.372-7.379-7.372-19.443,0-26.818l11.137-11.138C44.39,17.762,56.455,17.762,63.834,25.137' />
        <path id='XMLID_6_' style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF'}} d='M58.572,51.011h0.08h1.066
          c2.38,0,4.204,0.774,4.204,2.77c0,2.106-1.788,2.945-3.89,2.945c-0.577,0-1.022,0-1.394-0.025c-0.029,0-0.052-0.004-0.066-0.004
          c-0.292,0-0.365-0.303-0.365-0.387v-0.084v-4.781v-0.072C58.206,51.241,58.367,51.011,58.572,51.011 M58.564,41.917
          c0.015-0.004,0.037-0.004,0.058-0.007c0.387-0.048,0.905-0.081,1.657-0.081c2.138,0,3.19,0.876,3.19,2.241
          c0,1.511-1.3,2.456-4,2.456h-0.868h-0.058c-0.19,0-0.336-0.229-0.336-0.357v-0.087v-3.744V42.26
          C58.206,42.132,58.338,41.968,58.564,41.917z M35.435,42.191c0.022,0,0.043-0.004,0.072-0.007c0.351-0.044,0.862-0.077,1.519-0.077
          c3.788,0,6.554,2.175,6.554,6.803c0,5.328-3.051,7.605-7.145,7.536c-0.285,0-0.643,0-0.949-0.022c-0.022,0-0.043,0-0.066-0.004
          c-0.153-0.011-0.321-0.186-0.321-0.372v-0.07V42.614v-0.066C35.092,42.282,35.348,42.202,35.435,42.191z M29.07,60.985
          c0.029,0,0.058,0.007,0.087,0.015c1.248,0.182,3.168,0.357,5.905,0.357c4.627,0,8.517-0.88,11.116-2.912
          c2.277-1.788,4.102-4.909,4.102-9.747c0-4.347-1.686-7.291-4.277-9.08c-2.313-1.613-5.189-2.346-9.714-2.346
          c-2.59,0-5.087,0.423-7.057,0.722c-0.037,0.007-0.066,0.011-0.102,0.019c-0.277,0.043-0.372,0.215-0.372,0.38v0.08v22.06v0.062
          C28.756,60.692,28.771,60.941,29.07,60.985z M52.229,60.985c0.03,0,0.059,0.015,0.081,0.015c1.291,0.212,3.262,0.357,5.868,0.357
          c5.043,0,7.992-0.774,9.744-2.105c1.576-1.19,2.664-2.945,2.664-5.19c0-2.803-1.752-4.941-4.627-5.751v-0.07
          c2.766-1.051,3.853-2.978,3.853-4.941c0-2.314-1.329-3.996-3.19-4.872c-1.817-0.912-3.853-1.156-7.218-1.156
          c-2.657,0-5.489,0.536-7.057,0.784c-0.029,0.004-0.058,0.011-0.087,0.015c-0.241,0.018-0.321,0.274-0.321,0.324v0.099v22.072v0.069
          C51.937,60.751,52.046,60.985,52.229,60.985z' />
        <path id='XMLID_5_' style={{fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF'}} d='M71.994,43.508l-0.007-5.229
          c-0.007-0.31-0.328-0.566-0.664-0.566h-2.16c1.117,1.544,1.97,3.219,2.569,4.971C71.833,42.957,71.92,43.231,71.994,43.508' />
      </g>
      <path id='XMLID_4_' style={{fill: '#A9B2BB'}} d='M50.42,10.02c-21.87,0-39.66,17.79-39.66,39.66c0,10.91,4.43,20.8,11.58,27.98
        l1.41-1.41c-6.79-6.82-10.99-16.21-10.99-26.57c0-20.76,16.9-37.66,37.66-37.66c10.36,0,19.75,4.2,26.57,10.99l1.41-1.41
        C71.22,14.45,61.33,10.02,50.42,10.02z'>
        <animateTransform
          attributeType='xml'
          attributeName='transform'
          type='rotate'
          from='0 50 50'
          to='-360 50 50'
          dur='1.2s'
          repeatCount='indefinite'
        />
      </path>
      <path id='XMLID_13_' style={{fill: '#CED4D8'}} d='M50.42,0.77c-26.97,0-48.91,21.94-48.91,48.91c0,13.45,5.47,25.66,14.29,34.52
        l1.42-1.42C8.75,74.29,3.51,62.59,3.51,49.68c0-25.86,21.05-46.91,46.91-46.91c12.91,0,24.61,5.24,33.1,13.71l1.42-1.42
        C76.08,6.24,63.87,0.77,50.42,0.77z'>
        <animateTransform
          attributeType='xml'
          attributeName='transform'
          type='rotate'
          from='0 50 50'
          to='360 50 50'
          dur='0.5s'
          repeatCount='indefinite'
        />
      </path>
    </svg>
  )
}

Icon.propTypes = {
  size: PropTypes.number.isRequired
}

export default Icon
