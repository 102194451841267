import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import LoginEmailMutation from 'relay/mutations/LoginEmailMutation'

import Content from 'components/templates/login/content'
import Cookie from 'utils/cookie'
import { routeTo } from 'router'
import { message } from 'utils/messages'
import LoginForm from './login-form'

class LoginView extends Component {
  state = {
    loading: false
  }

  _loading (loading) {
    this.setState({
      loading: loading
    })
  }

  _success (payload) {
    Cookie.save('Authorization', payload.token)

    this.props.actions.notify('success', 'Login efetuado com sucesso', `Seja bem vindo, ${payload.me.firstName}.`)
    this.props.history.push(routeTo('dashboard'))
  }

  _error (message) {
    this.props.actions.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    LoginEmailMutation.commit(
      Environment,
      values,
      this.props.viewer,
      {
        onCompleted: ({ LoginEmail }) => {
          this._loading(false)

          if (LoginEmail.error) {
            this._error(message(LoginEmail.error))
          } else {
            this._success(LoginEmail)
          }
        },
        onError: (transaction) => {
          this._loading(false)

          this._error(message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    const loading = this.props.loading || this.state.loading

    return (
      <Content title='Login'>
        <LoginForm loading={loading} onSubmit={(values) => this._onSubmit(values)} />

        <hr />
        <a className='link' href={routeTo('password-recovery-request')}>
          Esqueceu sua senha?
        </a>
      </Content>
    )
  }
}

const LoginViewQueryRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query login_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (
      <LoginView
        loading={rendererProps === null}
        {...rendererProps}
        {...props}
      />
    )}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(LoginViewQueryRenderer)
