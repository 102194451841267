import React from 'react'
import PropTypes from 'prop-types'

import styles from './helper.less'

const Helper = (props) => {
  let icon = props.icon
  if (icon) {
    icon += ' ' + styles.icon
  }

  return (
    <div className={styles.wrapper}>
      {(icon) && (
        <i className={icon} />
      )}

      {props.children}
    </div>
  )
}

Helper.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node.isRequired
}

Helper.defaultProps = {
  icon: 'fa fa-comments-o'
}

export default Helper
