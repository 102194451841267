/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductStamp = "DIET" | "ECONOMIC" | "FROZEN" | "GLUTEN_FREE" | "IMPORTED" | "LOW_FAT" | "ORGANIC" | "OWN_BRAND" | "%future added value";
export type ProductEditInput = {|
  id?: ?string,
  name: string,
  image?: ?FileInput,
  gtin: any,
  size?: ?number,
  unitOfMeasure?: ?string,
  saleUnit?: ?string,
  hasVariableWeight?: ?boolean,
  additionalInfo?: ?string,
  stamps?: ?$ReadOnlyArray<?ProductStamp>,
  active: boolean,
  clientMutationId?: ?string,
|};
export type FileInput = {|
  key?: ?string,
  url?: ?string,
  name?: ?string,
  size?: ?number,
  type?: ?string,
|};
export type ProductEditMutationVariables = {|
  input: ProductEditInput
|};
export type ProductEditMutationResponse = {|
  +ProductEdit: ?{|
    +product: ?{|
      +name: ?string,
      +image: ?{|
        +url: ?string
      |},
      +gtin: ?any,
      +size: ?number,
      +unitOfMeasure: ?string,
      +saleUnit: ?string,
      +hasVariableWeight: ?boolean,
      +additionalInfo: ?string,
      +stamps: ?$ReadOnlyArray<?ProductStamp>,
      +active: ?boolean,
    |}
  |}
|};
export type ProductEditMutation = {|
  variables: ProductEditMutationVariables,
  response: ProductEditMutationResponse,
|};
*/


/*
mutation ProductEditMutation(
  $input: ProductEditInput!
) {
  ProductEdit(input: $input) {
    product {
      name
      image {
        url
        id
      }
      gtin
      size
      unitOfMeasure
      saleUnit
      hasVariableWeight
      additionalInfo
      stamps
      active
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gtin",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitOfMeasure",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saleUnit",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasVariableWeight",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "additionalInfo",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stamps",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductEditPayload",
        "kind": "LinkedField",
        "name": "ProductEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductEditPayload",
        "kind": "LinkedField",
        "name": "ProductEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c709632ec5e42718984a275a972edc9a",
    "id": null,
    "metadata": {},
    "name": "ProductEditMutation",
    "operationKind": "mutation",
    "text": "mutation ProductEditMutation(\n  $input: ProductEditInput!\n) {\n  ProductEdit(input: $input) {\n    product {\n      name\n      image {\n        url\n        id\n      }\n      gtin\n      size\n      unitOfMeasure\n      saleUnit\n      hasVariableWeight\n      additionalInfo\n      stamps\n      active\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '088709caf5e6a646ff95fe68b83c8749';

module.exports = node;
