import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Col, Button } from 'react-bootstrap'

import styles from './buttons.less'

const Buttons = (props) => {
  const { reset, pristine, submitting, valid, resetText, submitText, horizontal, labelSize, fieldSize } = props

  const buttons = (
    <div className={styles.buttons}>
      {reset && (
        <Button type='button' className='margin-right-5' disabled={pristine || submitting} onClick={reset}>
          {resetText}
        </Button>
      )}

      <Button type='submit' bsStyle='primary' disabled={pristine || submitting || !valid}>
        {submitText}
      </Button>
    </div>
  )

  if (horizontal) {
    return (
      <FormGroup>
        <Col smOffset={labelSize} sm={fieldSize}>
          {buttons}
        </Col>
      </FormGroup>
    )
  }

  return (
    <FormGroup>
      {buttons}
    </FormGroup>
  )
}

Buttons.propTypes = {
  horizontal: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  valid: PropTypes.bool,
  reset: PropTypes.func,
  submitText: PropTypes.node,
  resetText: PropTypes.node,
  labelSize: PropTypes.number,
  fieldSize: PropTypes.number
}

Buttons.defaultProps = {
  horizontal: false,
  pristine: true,
  submitting: false,
  valid: true,
  submitText: (
    <div>
      <i className='fa fa-check' />
      &nbsp;Salvar alterações
    </div>
  ),
  resetText: (
    <div>
      <i className='fa fa-trash-o' />
      &nbsp;Limpar
    </div>
  ),
  labelSize: 3,
  fieldSize: 9
}

export default Buttons
