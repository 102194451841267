import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation BannerEditMutation($input: BannerEditInput!) {
    BannerEdit(input: $input) {
      banner {
        id
        title
        order
        active
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      banner: viewer.banner.id
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    BannerEdit: {
      banner: values
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: values.id,
          companies: values.companies,
          title: values.title,
          buttonCaption: values.buttonCaption,
          image: values.image,
          position: values.position,
          order: values.order,
          link: values.link,
          linkTarget: values.linkTarget,
          temporary: values.temporary,
          start: values.start,
          end: values.end,
          days: values.days,
          active: values.active
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
