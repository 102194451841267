import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import ProductGroupEditMutation from 'relay/mutations/ProductGroupEditMutation'
import Content from 'components/templates/dashboard/content'
import { toForm, toMutation } from 'components/forms'
import { getMessagesFromErrors } from 'utils/messages'
import { routeTo } from 'router'
import ProductGroupsForm from './product-groups-form'
import ProductGroupsHelper from './product-groups-helper'

class ProductGroupsEdit extends Component {
  constructor (props) {
    super(props)

    this.state = {loading: false}
  }

  _loading (loading) {
    this.setState({loading})
  }

  _success () {
    this._loading(false)

    this.props.notify('success', 'Sucesso', 'Grupo de Produtos alterado com sucesso')
    this.props.history.push(routeTo('dashboard.product-groups'))
  }

  _error (errors) {
    this._loading(false)

    getMessagesFromErrors(errors)
      .forEach(m => this.props.notify('error', 'Erro', m))
  }

  _onSubmit (values) {
    this._loading(true)

    ProductGroupEditMutation.commit(toMutation(values), {
      onCompleted: ({ProductGroupEdit}) => this._success(),
      onError: (errors) => this._error(errors)
    })
  }

  render () {
    const { viewer } = this.props
    return (
      <Content
        title='Alterar Grupo de Produtos'
        permission='product-groups:edit'
        menu={{link: 'Grupos de Produtos'}}
        breadcrumb={{
          title: 'Grupos de Produtos',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/product-groups', title: 'Grupos de Produtos'},
            {title: 'Alterar'}
          ]
        }}>
        <Row>
          <Col sm={7}>
            {!viewer ? (
              <ProductGroupsForm loading />
            ) : (
              <ProductGroupsForm
                loading={this.state.loading}
                initialValues={toForm({
                  ...viewer.productGroup,
                  temporary: !!viewer.productGroup.start
                })}
                productGroup={viewer.productGroup}
                editing
                onSubmit={values => this._onSubmit(values)}
              />
            )}
          </Col>
          <Col sm={5}>
            <ProductGroupsHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const ProductGroupsEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query productGroupsEdit_Query($id: ID!) {
        viewer {
          productGroup(id: $id) {
            id
            title
            description
            sortBy
            products {
              id
              image {
                url
              }
              name
            }
            active
            start
            end
            weekDays
          }
        }
      }
    `}
    variables={{id: props.match.params.id}}
    render={({error, props: rendererProps}) => {
      if (error) {
        console.error(error)
      }

      if (rendererProps) {
        return (<ProductGroupsEdit {...rendererProps} {...props} />)
      }

      return null
    }}
  />
)

export default connect(null, appActionCreators)(ProductGroupsEditRenderer)
