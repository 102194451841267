/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupermarketSocialEditInput = {|
  image?: ?FileInput,
  facebook?: ?string,
  twitter?: ?string,
  youtube?: ?string,
  googleplus?: ?string,
  instagram?: ?string,
  clientMutationId?: ?string,
|};
export type FileInput = {|
  key?: ?string,
  url?: ?string,
  name?: ?string,
  size?: ?number,
  type?: ?string,
|};
export type SupermarketSocialEditMutationVariables = {|
  input: SupermarketSocialEditInput
|};
export type SupermarketSocialEditMutationResponse = {|
  +SupermarketSocialEdit: ?{|
    +supermarket: ?{|
      +id: string,
      +social: ?{|
        +image: ?{|
          +url: ?string
        |},
        +facebook: ?string,
        +twitter: ?string,
        +googleplus: ?string,
        +youtube: ?string,
        +instagram: ?string,
      |},
    |}
  |}
|};
export type SupermarketSocialEditMutation = {|
  variables: SupermarketSocialEditMutationVariables,
  response: SupermarketSocialEditMutationResponse,
|};
*/


/*
mutation SupermarketSocialEditMutation(
  $input: SupermarketSocialEditInput!
) {
  SupermarketSocialEdit(input: $input) {
    supermarket {
      id
      social {
        image {
          url
          id
        }
        facebook
        twitter
        googleplus
        youtube
        instagram
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "facebook",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "twitter",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "googleplus",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "youtube",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instagram",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SupermarketSocialEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SupermarketSocialEditPayload",
        "kind": "LinkedField",
        "name": "SupermarketSocialEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupermarketSocial",
                "kind": "LinkedField",
                "name": "social",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SupermarketSocialEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SupermarketSocialEditPayload",
        "kind": "LinkedField",
        "name": "SupermarketSocialEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupermarketSocial",
                "kind": "LinkedField",
                "name": "social",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56524483079d5653c6108684e615eedf",
    "id": null,
    "metadata": {},
    "name": "SupermarketSocialEditMutation",
    "operationKind": "mutation",
    "text": "mutation SupermarketSocialEditMutation(\n  $input: SupermarketSocialEditInput!\n) {\n  SupermarketSocialEdit(input: $input) {\n    supermarket {\n      id\n      social {\n        image {\n          url\n          id\n        }\n        facebook\n        twitter\n        googleplus\n        youtube\n        instagram\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc0bf1eb0c3a5ce35d8176fd1a0e7744';

module.exports = node;
