/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BannerAddInput = {|
  companies?: ?$ReadOnlyArray<?string>,
  title: string,
  buttonCaption?: ?string,
  image?: ?FileInput,
  position?: ?string,
  order?: ?number,
  link?: ?string,
  linkTarget?: ?string,
  temporary?: ?boolean,
  start?: ?string,
  end?: ?string,
  days?: ?$ReadOnlyArray<?string>,
  active?: ?boolean,
  clientMutationId?: ?string,
|};
export type FileInput = {|
  key?: ?string,
  url?: ?string,
  name?: ?string,
  size?: ?number,
  type?: ?string,
|};
export type BannerAddMutationVariables = {|
  input: BannerAddInput
|};
export type BannerAddMutationResponse = {|
  +BannerAdd: ?{|
    +bannerEdge: ?{|
      +node: ?{|
        +id: string,
        +companies: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string
            |}
          |}>
        |},
        +title: ?string,
        +image: ?{|
          +key: ?string,
          +url: ?string,
        |},
        +position: ?string,
        +order: ?number,
        +link: ?string,
        +linkTarget: ?string,
        +temporary: ?boolean,
        +start: ?any,
        +end: ?any,
        +days: ?$ReadOnlyArray<?string>,
        +active: ?boolean,
      |}
    |},
    +viewer: ?{|
      +banners: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +title: ?string,
            +order: ?number,
            +active: ?boolean,
          |}
        |}>
      |}
    |},
    +error: ?string,
  |}
|};
export type BannerAddMutation = {|
  variables: BannerAddMutationVariables,
  response: BannerAddMutationResponse,
|};
*/


/*
mutation BannerAddMutation(
  $input: BannerAddInput!
) {
  BannerAdd(input: $input) {
    bannerEdge {
      node {
        id
        companies {
          edges {
            node {
              id
            }
          }
        }
        title
        image {
          key
          url
          id
        }
        position
        order
        link
        linkTarget
        temporary
        start
        end
        days
        active
      }
    }
    viewer {
      banners {
        edges {
          node {
            id
            title
            order
            active
          }
        }
      }
      id
    }
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CompanyConnection",
  "kind": "LinkedField",
  "name": "companies",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CompanyEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Company",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "link",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkTarget",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "temporary",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "days",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "BannerConnection",
  "kind": "LinkedField",
  "name": "banners",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BannerEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Banner",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            (v8/*: any*/),
            (v15/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BannerAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BannerAddPayload",
        "kind": "LinkedField",
        "name": "BannerAdd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BannerEdge",
            "kind": "LinkedField",
            "name": "bannerEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Banner",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BannerAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BannerAddPayload",
        "kind": "LinkedField",
        "name": "BannerAdd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BannerEdge",
            "kind": "LinkedField",
            "name": "bannerEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Banner",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f84ce83189e639bfd1edb78bbeb0108",
    "id": null,
    "metadata": {},
    "name": "BannerAddMutation",
    "operationKind": "mutation",
    "text": "mutation BannerAddMutation(\n  $input: BannerAddInput!\n) {\n  BannerAdd(input: $input) {\n    bannerEdge {\n      node {\n        id\n        companies {\n          edges {\n            node {\n              id\n            }\n          }\n        }\n        title\n        image {\n          key\n          url\n          id\n        }\n        position\n        order\n        link\n        linkTarget\n        temporary\n        start\n        end\n        days\n        active\n      }\n    }\n    viewer {\n      banners {\n        edges {\n          node {\n            id\n            title\n            order\n            active\n          }\n        }\n      }\n      id\n    }\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2c46cc7d74355ec6c77e2b92b208a031';

module.exports = node;
