/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type usersList_viewer_QueryVariables = {||};
export type usersList_viewer_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +me: ?{|
      +id: string
    |},
  |}
|};
export type usersList_viewer_Query = {|
  variables: usersList_viewer_QueryVariables,
  response: usersList_viewer_QueryResponse,
|};
*/


/*
query usersList_viewer_Query {
  viewer {
    id
    me {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "usersList_viewer_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "usersList_viewer_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b536acd41a8ea1aed3ceba72411ac5f5",
    "id": null,
    "metadata": {},
    "name": "usersList_viewer_Query",
    "operationKind": "query",
    "text": "query usersList_viewer_Query {\n  viewer {\n    id\n    me {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '70db9235ac758f94e17b4cf5a54685ac';

module.exports = node;
