import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InputGroup, FormControl, Button } from 'react-bootstrap'

import styles from './incrementer.less'

let changeTimeout

class Incrementer extends Component {
  state = {
    value: this.props.value
  }

  _handleDecrement () {
    const newValue = this.state.value - 1

    this.setState({
      value: newValue
    })

    clearTimeout(changeTimeout)
    changeTimeout = setTimeout(() => {
      this.props.onChange(newValue)
    }, 500)
  }

  _handleIncrement () {
    const newValue = this.state.value + 1

    this.setState({
      value: newValue
    })

    clearTimeout(changeTimeout)
    changeTimeout = setTimeout(() => {
      this.props.onChange(newValue)
    }, 500)
  }

  render () {
    const isUndefined = typeof this.props.value === 'undefined'
    const isNull = this.props.value === null
    if (isUndefined || isNull) {
      return null
    }

    return (
      <div className={styles.incrementer}>
        <InputGroup>
          <InputGroup.Button>
            <Button onClick={() => this._handleDecrement()}>
              {this.props.decrementIcon}
            </Button>
          </InputGroup.Button>

          <FormControl
            value={this.state.value}
            className={styles.input}
            readOnly
          />

          <InputGroup.Button>
            <Button onClick={() => this._handleIncrement()}>
              {this.props.incrementIcon}
            </Button>
          </InputGroup.Button>
        </InputGroup>
      </div>
    )
  }
}

Incrementer.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  decrementIcon: PropTypes.node,
  incrementIcon: PropTypes.node
}

Incrementer.defaultProps = {
  decrementIcon: (<i className='fa fa-minus' />),
  incrementIcon: (<i className='fa fa-plus' />)
}

export default Incrementer
