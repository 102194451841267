import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import ContactEditMutation from 'relay/mutations/ContactEditMutation'

import Content from 'components/templates/dashboard/content'
import { toForm, toMutation } from 'components/forms'
import { getMessagesFromErrors } from 'utils/messages'
import { routeTo } from 'router'
import ContactsForm from './contacts-form'
import ContactsHelper from './contacts-helper'

class ContactsEdit extends Component {
  constructor (props) {
    super(props)

    this.state = {loading: false}
  }

  _loading (loading) {
    this.setState({loading})
  }

  _success () {
    this._loading(false)

    this.props.notify('success', 'Sucesso', 'Contato alterado com sucesso')
    this.props.history.push(routeTo('dashboard.contacts'))
  }

  _error (errors) {
    this._loading(false)

    getMessagesFromErrors(errors)
      .forEach(m => this.props.notify('error', 'Erro', m))
  }

  _onSubmit (values) {
    this._loading(true)

    ContactEditMutation.commit(toMutation(values), this.props.viewer, {
      onCompleted: ({LeadEdit}) => this._success(),
      onError: (errors) => this._error(errors)
    })
  }

  render () {
    const { viewer } = this.props
    return (
      <Content
        title='Alterar Contato'
        permission='contacts:edit'
        menu={{link: 'Contatos'}}
        breadcrumb={{
          title: 'Contatos',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/contacts', title: 'Contatos'},
            {title: 'Alterar'}
          ]
        }}>
        <Row>
          <Col sm={7}>
            {!viewer ? (
              <ContactsForm loading />
            ) : (
              <ContactsForm
                loading={this.state.loading}
                viewer={viewer}
                initialValues={toForm({
                  ...viewer.contact,
                  companies: viewer.contact.companies.edges.map(e => e.node.id)
                })}
                onSubmit={values => this._onSubmit(values)}
              />
            )}
          </Col>
          <Col sm={5}>
            <ContactsHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const ContactsEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query contactsEdit_Query($id: ID!) {
        viewer {
          companies {
            edges {
              node {
                id
                name
              }
            }
          }
          contact(id: $id) {
            id
            title
            identification
            type
            value
            companies {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    `}
    variables={{id: props.match.params.id}}
    render={({props: rendererProps}) => (
      rendererProps ? (
        <ContactsEdit {...rendererProps} {...props} />
      ) : null
    )}
  />
)

export default connect(null, appActionCreators)(ContactsEditRenderer)
