import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import ProductRemoveMutation from 'relay/mutations/ProductRemoveMutation'
import Content from 'components/templates/dashboard/content'
import DataList, { Actions, Action } from 'components/datalist'
import ConfirmDialog from 'components/app/confirm-dialog'
import { routeTo } from 'router'
import { fetchPermissions } from 'security/authorization'

class ProductsList extends Component {
  constructor (props) {
    super(props)

    this.state = {permissions: {}}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['products:add', 'products:edit', 'products:remove'])
    this.setState({ permissions })
  }

  _remove (product) {
    ProductRemoveMutation.commit(product.id, this.props.viewer, {
      onCompleted: ({ProductRemove}) => {
        this.props.notify('success', 'Sucesso', 'Produto removido com sucesso')
      },
      onError: (errors) => {
        this.props.notify('error', 'Erro', 'Erro ao remover produto')
      }
    })
  }

  render () {
    return (
      <Content
        title='Produtos'
        permission='products:list'
        menu={{link: 'Produtos'}}
        breadcrumb={{
          title: 'Produtos',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/products', title: 'Produtos'},
            {title: 'Listar'}
          ],
          actions: this.state.permissions['products:add'] && (
            <Link to={routeTo('dashboard.products.add')} className='btn btn-primary'>
              <i className='fa fa-plus' /> Adicionar
            </Link>
          )
        }}>
        <DataList
          name='ProductsList'
          environment={Environment}
          query={graphql`
            query productsList_datalist_Query($first: Int!, $search: String) {
              viewer {
                ...productsList_datalist_viewer
              }
            }
          `}
          fragments={{
            viewer: graphql`
              fragment productsList_datalist_viewer on Viewer {
                id
                products(first: $first, search: $search) @connection(key: "list_products", filters: []) {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                  pageInfo {
                    hasNextPage
                  }
                }
              }
            `
          }}
          columns={[
            {name: 'Nome', property: 'name'},
            {
              name: 'Ação',
              callback: product => (
                <Actions>
                  {this.state.permissions['products:edit'] && (
                    <Action title='Alterar'>
                      <Link to={routeTo('dashboard.products.edit', {id: product.id})}>
                        <i className='fa fa-edit' />
                      </Link>
                    </Action>
                  )}
                  {this.state.permissions['products:remove'] && (
                    <Action title='Excluir'>
                      <ConfirmDialog
                        icon='fa fa-trash-o'
                        title={product.name}
                        subtitle='Deseja remover este Produto? Esta ação não poderá ser desfeita.'
                        onOk={() => this._remove(product)}>
                        <i className='fa fa-trash-o' />
                      </ConfirmDialog>
                    </Action>
                  )}
                </Actions>
              )
            }
          ]}
        />
      </Content>
    )
  }
}

const ProductsListRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query productsList_viewer_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (
      <ProductsList
        {...rendererProps}
        {...props} />
    )}
  />
)

export default connect(null, {...appActionCreators})(ProductsListRenderer)
