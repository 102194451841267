import { commitMutation, graphql } from 'react-relay'

import { removeUpdater } from 'relay'

const mutation = graphql`
  mutation CompanyRemoveMutation($input: CompanyRemoveInput!) {
    CompanyRemove(input: $input) {
      deletedID
      error
    }
  }
`

function commit (environment, company, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: company.id
        }
      },
      updater: (store) => {
        const payload = store.getRootField('CompanyRemove')
        const deletedID = payload.getValue('deletedID')

        removeUpdater(store, viewer, 'list_companies', deletedID)
      },
      optimisticUpdater: (store) => {
        removeUpdater(store, viewer, 'list_companies', company.id)
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
