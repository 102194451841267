/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductGroupSort = "HIGHER_PRICE" | "LOWER_PRICE" | "MANUAL" | "RANDOM" | "%future added value";
export type WeekDay = "FRIDAY" | "MONDAY" | "SATURDAY" | "SUNDAY" | "THURSDAY" | "TUESDAY" | "WEDNESDAY" | "%future added value";
export type ProductGroupEditInput = {|
  id?: ?string,
  title: string,
  description?: ?string,
  sortBy: ProductGroupSort,
  active: boolean,
  start?: ?any,
  end?: ?any,
  weekDays?: ?$ReadOnlyArray<?WeekDay>,
  clientMutationId?: ?string,
|};
export type ProductGroupEditMutationVariables = {|
  input: ProductGroupEditInput
|};
export type ProductGroupEditMutationResponse = {|
  +ProductGroupEdit: ?{|
    +productGroup: ?{|
      +id: string,
      +title: ?string,
      +description: ?string,
      +sortBy: ?ProductGroupSort,
      +active: ?boolean,
      +start: ?any,
      +end: ?any,
      +weekDays: ?$ReadOnlyArray<?WeekDay>,
    |}
  |}
|};
export type ProductGroupEditMutation = {|
  variables: ProductGroupEditMutationVariables,
  response: ProductGroupEditMutationResponse,
|};
*/


/*
mutation ProductGroupEditMutation(
  $input: ProductGroupEditInput!
) {
  ProductGroupEdit(input: $input) {
    productGroup {
      id
      title
      description
      sortBy
      active
      start
      end
      weekDays
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProductGroupEditPayload",
    "kind": "LinkedField",
    "name": "ProductGroupEdit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductGroup",
        "kind": "LinkedField",
        "name": "productGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sortBy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "start",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "end",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weekDays",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductGroupEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductGroupEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6525ae226542eecf45de59e870e9e07e",
    "id": null,
    "metadata": {},
    "name": "ProductGroupEditMutation",
    "operationKind": "mutation",
    "text": "mutation ProductGroupEditMutation(\n  $input: ProductGroupEditInput!\n) {\n  ProductGroupEdit(input: $input) {\n    productGroup {\n      id\n      title\n      description\n      sortBy\n      active\n      start\n      end\n      weekDays\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4f55f2043563d04b676db3b47a42e87a';

module.exports = node;
