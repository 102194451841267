import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import Panel from 'components/panel'
import { fetchPermissions } from 'security/authorization'
import createForm, { Form, Input, Wysiwyg, Buttons, toForm } from 'components/forms'
import Spinner from 'components/spinner'
import Validation from 'utils/validation'
import SupermarketInstitutionalEditMutation from 'relay/mutations/SupermarketInstitutionalEditMutation'

class SupermarketInstitutionalForm extends Component {
  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={loading} />

        <Panel>
          <Input name='title' horizontal type='text' label='Título' />

          <Wysiwyg name='text' horizontal label='Texto' />

          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
           />
        </Panel>
      </Form>
    )
  }
}

const validation = new Validation({
  title: [Validation.required]
})

const SupermarketInstitutionalEditForm = createForm(SupermarketInstitutionalForm, validation)

class SupermarketInstitutionalEdit extends Component {
  constructor (props) {
    super(props)

    this.state = {permissions: {}}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['institutional:edit'])
    this.setState({ permissions })
  }

  _onSubmit (values) {
    const {title, text} = values
    SupermarketInstitutionalEditMutation.commit(
      {title, text},
      this.props.viewer,
      {
        onCompleted: ({SupermarketInstitutionalEdit}) => {},
        onError: (errors) => {}
      }
    )
  }

  render () {
    const { loading, viewer } = this.props
    return (
      <Content
        title='Institucional'
        permission='institutional:edit'
        menu={{link: 'Institucional'}}
        breadcrumb={{
          title: 'Institucional',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/institutional', title: 'Institucional'},
            {title: 'Alterar'}
          ]
        }}>

        {!this.props.viewer ? (
          <SupermarketInstitutionalEditForm
            loading={loading}
           />
        ) : (
          <SupermarketInstitutionalEditForm
            {...this.props}
            loading={loading}
            initialValues={toForm(viewer.supermarket)}
            onSubmit={values => this._onSubmit(values)}
           />
        )}

      </Content>
    )
  }
}

const InstitutionalEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query institutionalEdit_viewer_Query {
        viewer {
          id
          supermarket {
            id
            title
            text
          }
        }
      }
    `}
    render={({props: rendererProps}) => (
      <SupermarketInstitutionalEdit
        {...rendererProps}
        {...props}
        loading={!rendererProps}
      />
    )}
  />
)

export default connect(null, {...appActionCreators})(InstitutionalEditRenderer)
