import { graphql } from 'react-relay'
import { fetchQuery } from 'relay-runtime'

import Environment from 'relay'
import Cache from 'utils/cache'

function createPermissions (role, permissions) {
  const toReturn = {}

  permissions.map((permission) => {
    toReturn[permission] = role.permissions.indexOf(permission) > -1
  })

  return toReturn
}

async function getRoleFromServer () {
  try {
    const props = await fetchQuery(
      Environment,
      graphql`
        query authorization_Query {
          viewer {
            id
            me {
              id
              role {
                permissions
              }
            }
          }
        }
      `
    )

    return props.viewer.me.role
  } catch (e) {
    return { permissions: [] }
  }
}

export async function fetchPermissions (requestedPermissions) {
  const cacheID = 'authorizationRole'
  const roleFromCache = Cache.load(cacheID)
  if (roleFromCache) {
    return createPermissions(roleFromCache, requestedPermissions)
  }

  const roleFromServer = await getRoleFromServer()
  const fiveSeconds = 5000
  Cache.save(cacheID, roleFromServer, fiveSeconds)
  return createPermissions(roleFromServer, requestedPermissions)
}
