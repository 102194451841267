import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation ProductGroupChangeOrderMutation($input: ProductGroupChangeOrderInput!) {
    ProductGroupChangeOrder(input: $input) {
      productGroup {
        id
        products {
          id
          name
          image {
            url
          }
        }
      }
    }
  }
`

function commit ({ id, productId, order }, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: { id, productId, order }
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
