/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BannerEditActiveInput = {|
  id: string,
  active: boolean,
  clientMutationId?: ?string,
|};
export type BannerEditActiveMutationVariables = {|
  input: BannerEditActiveInput
|};
export type BannerEditActiveMutationResponse = {|
  +BannerEditActive: ?{|
    +banner: ?{|
      +id: string,
      +active: ?boolean,
    |},
    +error: ?string,
  |}
|};
export type BannerEditActiveMutation = {|
  variables: BannerEditActiveMutationVariables,
  response: BannerEditActiveMutationResponse,
|};
*/


/*
mutation BannerEditActiveMutation(
  $input: BannerEditActiveInput!
) {
  BannerEditActive(input: $input) {
    banner {
      id
      active
    }
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "BannerEditActivePayload",
    "kind": "LinkedField",
    "name": "BannerEditActive",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Banner",
        "kind": "LinkedField",
        "name": "banner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BannerEditActiveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BannerEditActiveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "701a9d719e3002b20f0cd2af3392a377",
    "id": null,
    "metadata": {},
    "name": "BannerEditActiveMutation",
    "operationKind": "mutation",
    "text": "mutation BannerEditActiveMutation(\n  $input: BannerEditActiveInput!\n) {\n  BannerEditActive(input: $input) {\n    banner {\n      id\n      active\n    }\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7decd5a2b5835f30e178505bc45216f2';

module.exports = node;
