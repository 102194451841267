import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation ProductGroupRemoveProductMutation($input: ProductGroupRemoveProductInput!) {
    ProductGroupRemoveProduct(input: $input) {
      productGroup {
        id
        products {
          id
        }
      }
    }
  }
`
function commit ({id, productId}, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: {productId, id}
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
