import React from 'react'
import PropTypes from 'prop-types'

import DocumentTitle from 'components/app/document-title'

const Content = (props) => (
  <div>
    <DocumentTitle>{props.title}</DocumentTitle>

    {props.children}
  </div>
)

Content.propTypes = {
  title: PropTypes.string
}

export default Content
