import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import CompanyEditMutation from 'relay/mutations/CompanyEditMutation'

import Content from 'components/templates/dashboard/content'
import { toForm, toMutation } from 'components/forms'
import { message } from 'utils/messages'
import { routeTo } from 'router'
import CompaniesForm from './companies-form'
import CompaniesHelper from './companies-helper'

class CompaniesEdit extends Component {
  state = {
    loading: false
  }

  _loading (loading) {
    this.setState({
      loading: loading
    })
  }

  _success () {
    this.props.actions.notify('success', 'Sucesso', 'Loja alterada com sucesso')
    this.props.history.push(routeTo('dashboard.companies'))
  }

  _error (message) {
    this.props.actions.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    CompanyEditMutation.commit(
      Environment,
      toMutation(values),
      this.props.viewer,
      {
        onCompleted: ({ CompanyEdit }) => {
          this._loading(false)

          if (CompanyEdit.error) {
            this._error(message(CompanyEdit.error))
          } else {
            this._success()
          }
        },
        onError: (transaction) => {
          this._loading(false)

          this._error(message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    const { viewer } = this.props
    return (
      <Content
        title='Alterar loja'
        permission='companies:edit'
        menu={{ link: 'Lojas' }}
        breadcrumb={{
          title: 'Lojas',
          items: [
            { 'url': '/dashboard', 'title': 'Dashboard' },
            { 'url': '/dashboard/companies', 'title': 'Lojas' },
            { 'title': 'Alterar' }
          ]
        }}
      >
        <Row>
          <Col sm={7}>
            {!viewer ? (
              <CompaniesForm loading />
            ) : (
              <CompaniesForm
                loading={this.state.loading}
                initialValues={toForm({
                  ...viewer.company,
                  ...viewer.company.location
                })}
                onSubmit={(values) => this._onSubmit(values)}
              />
            )}
          </Col>

          <Col sm={5}>
            <CompaniesHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const CompaniesEditQueryRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query companiesEdit_Query($id: ID!) {
        viewer {
          company(id: $id) {
            id
            state {
              id
              name
            }
            city {
              id
              name
            }
            name
            email
            phone
            image {
              key
              url
            }
            address
            number
            neighborhood
            zipCode
            parkingLots
            openingHours
            active
            location {
              latitude
              longitude
            }
          }
        }
      }
    `}
    variables={{ id: props.match.params.id }}
    render={({props: rendererProps}) => (
      <CompaniesEdit
        {...rendererProps}
        {...props}
      />
    )}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(CompaniesEditQueryRenderer)
