import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import routes from './routes'
import { drawRoutes, routeTo } from './index'
import Cookie from 'utils/cookie'

import NotFoundView from 'components/views/not-found'

const Routes = (props) => (
  <BrowserRouter>
    <Switch>
      <Route
        exact
        path='/'
        render={() => {
          const redirectUrl = Cookie.load('Authorization') ? routeTo('dashboard') : routeTo('login')
          return <Redirect to={redirectUrl} />
        }}
      />

      {drawRoutes(routes)}

      <Route component={NotFoundView} />
    </Switch>
  </BrowserRouter>
)

export default Routes
