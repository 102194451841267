import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation ProductGroupEditMutation($input: ProductGroupEditInput!) {
    ProductGroupEdit(input: $input) {
      productGroup {
        id
        title
        description
        sortBy
        active
        start
        end
        weekDays
      }
    }
  }
`

function commit (values, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: {
          id: values.id,
          title: values.title,
          description: values.description,
          sortBy: values.sortBy,
          active: values.active,
          start: values.start,
          end: values.end,
          weekDays: values.weekDays
        }
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
