import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import styles from './actions.less'

const Action = (props) => {
  let children = props.children
  if (props.title) {
    const tooltip = <Tooltip id='tooltip' bsClass={`${styles.tooltip} tooltip`}>{props.title}</Tooltip>

    children = (
      <OverlayTrigger placement='top' delayShow={500} overlay={tooltip}>
        {props.children}
      </OverlayTrigger>
    )
  }

  return (
    <div className={styles.action}>
      {children}
    </div>
  )
}

Action.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node
}

export default Action
