import React, { useRef } from 'react'
import { Field } from 'redux-form'
import './wysiwyg.less'
import JoditEditor from 'jodit-react'
import { getImageUploadUrl, imageResizer } from 'utils/urls'
import Cookie from '../../utils/cookie'

const Wysiwyg = (props) => {
  const editor = useRef(null)

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    uploader: {
      url: getImageUploadUrl(),
      headers: {
        'Authorization': Cookie.load('Authorization')
      },
      prepareData: (formData) => {
        formData.append('temporary', false)
      },
      error: (e) => console.log(e),
      contentType: () => false,
      isSuccess: (e) => e.url !== null,
      process: (e) => e,
      filesVariableName: (e) => 'image',
      getMessage: (e) => '',
      defaultHandlerSuccess: function (data, resp) {
        this.s.insertImage(imageResizer(data.url))
      }
    }
  }

  function onChange (newContent) {
    props.input.onChange(newContent)
  }

  return (
    <JoditEditor
      ref={editor}
      value={props.input.value}
      config={config}
      tabIndex={1} // tabIndex of textarea
      onBlur={newContent => onChange(newContent)} // preferred to use only this option to update the content for performance reasons
    />
  )
}

const WysiwygField = (props) => (
  <Field {...props} component={Wysiwyg} />
)

export default WysiwygField
