import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation SupermarketInstitutionalEditMutation($input: SupermarketInstitutionalEditInput!) {
    SupermarketInstitutionalEdit(input: $input) {
      supermarket {
        id
        title
        text
      }
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      supermarket: viewer.supermarket.id
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    SupermarketInstitutionalEdit: {
      supermarket: {
        title: values.title,
        text: values.text
      }
    }
  }
}

function commit (values, viewer, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: {
          title: values.title,
          text: values.text
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
// function commit (values, config) {
//   return commitMutation(
//     Environment,
//     {
//       mutation,
//       variables: {
//         input: values
//       },
//       onCompleted: config.onCompleted,
//       onError: config.onError
//     }
//   )
// }
//
// export default {commit}
