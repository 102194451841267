import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import ArticleRemoveMutation from 'relay/mutations/ArticleRemoveMutation'

import Content from 'components/templates/dashboard/content'
import DataList, { Action, Actions } from 'components/datalist'
import ConfirmDialog from 'components/app/confirm-dialog'
import { routeTo } from 'router'
import { fetchPermissions } from 'security/authorization'
import { message } from 'utils/messages'

class ArticlesList extends Component {
  state = {
    permissions: {}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions([
      'articles:add', 'articles:edit', 'articles:remove'
    ])

    this.setState({ permissions })
  }

  _remove (article) {
    ArticleRemoveMutation.commit(
      Environment,
      article,
      this.props.viewer,
      {
        onCompleted: ({ ArticleRemove }) => {
          if (ArticleRemove.error) {
            this.props.actions.notify('error', 'Erro', message(ArticleRemove.error))
          } else {
            this.props.actions.notify('success', 'Sucesso', 'Notícia removida com sucesso')
          }
        },
        onError: (transaction) => {
          this.props.actions.notify('error', 'Erro', message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title='Notícias'
        permission='articles:list'
        menu={{
          link: 'Notícias'
        }}
        breadcrumb={{
          title: 'Notícias',
          items: [
            { 'url': '/dashboard', 'title': 'Dashboard' },
            { 'url': '/dashboard/articles', 'title': 'Notícias' },
            { 'title': 'Listar' }
          ],
          actions: this.state.permissions['articles:add'] && (
            <Link to={routeTo('dashboard.articles.add')} className='btn btn-primary'>
              <i className='fa fa-plus'/> Adicionar
            </Link>
          )
        }}
      >
        <DataList
          name='ArticlesList'
          environment={Environment}
          query={graphql`
            query articlesList_datalist_Query($first: Int!, $search: String) {
              viewer {
                ...articlesList_datalist_viewer
              }
            }
          `}
          fragments={{
            viewer: graphql`
              fragment articlesList_datalist_viewer on Viewer {
                id
                articles(first: $first, search: $search) @connection(key: "list_articles", filters: []) {
                  edges {
                    node {
                      id
                      title
                    }
                  }
                  pageInfo {
                    hasNextPage
                  }
                }
              }
            `
          }}
          columns={[
            {
              name: 'Título',
              property: 'title'
            },
            {
              name: 'Ação',
              callback: (article) => (
                <Actions>
                  {this.state.permissions['articles:edit'] && (
                    <Action title='Alterar'>
                      <Link to={routeTo('dashboard.articles.edit', { id: article.id })}>
                        <i className='fa fa-edit'/>
                      </Link>
                    </Action>
                  )}
                  {this.state.permissions['articles:remove'] && (
                    <Action title='Excluir'>
                      <ConfirmDialog
                        icon='fa fa-trash-o'
                        title={article.name}
                        subtitle='Deseja remover esta notícia? Isso não poderá ser desfeito.'
                        onOk={() => this._remove(article)}>
                        <i className='fa fa-trash-o'/>
                      </ConfirmDialog>
                    </Action>
                  )}
                </Actions>
              )
            }
          ]}
        />
      </Content>
    )
  }
}

const ArticlesListRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query articlesList_viewer_Query {
        viewer {
          id
        }
      }
    `}
    render={({ props: rendererProps }) => (
      <ArticlesList
        {...rendererProps}
        {...props}
      />
    )}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(ArticlesListRenderer)
