import { commitMutation, graphql } from 'react-relay'
import Environment, { removeUpdater } from 'relay'

const mutation = graphql`
  mutation ProductGroupRemoveMutation($input: ProductGroupRemoveInput!) {
    ProductGroupRemove(input: $input) {
      deletedId
    }
  }
`

function commit (id, viewer, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: {
          id: id
        }
      },
      updater: (store) => {
        const payload = store.getRootField('ProductGroupRemove')
        const deletedId = payload.getValue('deletedId')

        removeUpdater(store, viewer, 'list_productGroups', deletedId)
      },
      optimisticUpdater: store => {
        removeUpdater(store, viewer, 'list_productGroups', id)
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
