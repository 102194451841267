import React, { Component } from 'react'
import Content from 'components/templates/dashboard/content'
import Environment from 'relay'
import { graphql, QueryRenderer } from 'react-relay'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import ContactMessageViewMutation from 'relay/mutations/ContactMessageViewMutation'
import ContactMessage from './contact-message'
import WorkWithUsMessage from './work-with-us-message'
import FidelityCardMessage from './fidelity-card-message'

class ContactMessagesShow extends Component {
  componentDidMount () {
    const { id } = this.props.match.params

    ContactMessageViewMutation.commit(Environment, {id}, {
      onCompleted: () => {},
      onError: () => {}
    })
  }

  _renderForm (viewer) {
    if (!viewer) {
      return (<span />)
    }

    const {values, type, viewedAt} = viewer.contactMessage
    if (type === 'CONTACT') {
      return (<ContactMessage values={values} viewedAt={viewedAt} />)
    }

    if (type === 'WORK_WITH_US') {
      return (<WorkWithUsMessage values={values} viewedAt={viewedAt} />)
    }

    if (type === 'FIDELITY_CARD') {
      return (<FidelityCardMessage values={values} viewedAt={viewedAt} />)
    }
  }

  render () {
    return (
      <Content
        title='Visualizar Mensagem'
        permission='contact-messages:view'
        menu={{link: 'Mensagens'}}
        breadcrumb={{
          title: 'Mensagens',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/contact-messages', title: 'Mensagens'},
            {title: 'Visualizar'}
          ]
        }}>
        {this._renderForm(this.props.viewer)}
      </Content>
    )
  }
}

const ContactMessagesShowRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query contactMessagesShow_Query($id: ID!) {
        viewer {
          contactMessage(id: $id) {
            id
            type
            subject
            values
            viewedAt
          }
        }
      }
    `}
    variables={{id: props.match.params.id}}
    render={({props: rendererProps}) => (
      <ContactMessagesShow
        {...rendererProps}
        {...props}
      />
    )}
  />
)

export default connect(null, appActionCreators)(ContactMessagesShowRenderer)
