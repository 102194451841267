import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation RecipeEditMutation($input: RecipeEditInput!) {
    RecipeEdit(input: $input) {
      recipe {
        id
        title
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      recipe: viewer.recipe.id
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    RecipeEdit: {
      recipe: {
        state: values.state,
        city: values.city,
        title: values.title,
        image: values.image,
        advertisingImage: values.advertisingImage,
        preparationTime: values.preparationTime,
        yield: values.yield,
        ingredients: values.ingredients,
        instructions: values.instructions,
        author: values.author,
        date: values.date,
        active: values.active
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: values.id,
          state: values.state ? values.state.id : null,
          city: values.city ? values.city.id : null,
          title: values.title,
          image: values.image,
          advertisingImage: values.advertisingImage,
          preparationTime: values.preparationTime,
          yield: values.yield,
          ingredients: values.ingredients,
          instructions: values.instructions,
          author: values.author,
          date: values.date,
          active: values.active
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
