import React from 'react'
import PropTypes from 'prop-types'

import Spinner from 'components/spinner'
import createForm, { Form, Input, Buttons } from 'components/forms'
import Validation from 'utils/validation'

const LoginForm = (props) => {
  const { handleSubmit, pristine, submitting, valid } = props

  return (
    <div>
      <Spinner visible={props.loading} />

      <Form onSubmit={handleSubmit}>
        <Input name='email' type='email' placeholder='Digite seu e-mail' />
        <Input name='password' type='password' placeholder='Digite sua senha' />
        <Buttons pristine={pristine} submitting={submitting} valid={valid} submitText='Entrar' />
      </Form>
    </div>
  )
}

LoginForm.defaultProps = {
  loading: false
}

LoginForm.propTypes = {
  loading: PropTypes.bool
}

const LoginValidation = new Validation({
  email: [Validation.required, Validation.email],
  password: [Validation.required]
})

export default createForm(LoginForm, LoginValidation)
