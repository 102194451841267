class Booleans {
  static valueOf (value) {
    const isDefined = value !== undefined
    const isNottNull = value !== null

    return isDefined && isNottNull && value.toString() === 'true'
  }
}

export default Booleans
