import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { Field } from 'redux-form'

import { upload } from 'utils/aws/s3'
import { isImage, toBytes } from 'utils/files'
import Spinner from 'components/spinner'
import Group from './group'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import styles from './file.less'
import { imageResizer } from '../../utils/urls'

class File extends Component {
  state = {
    loading: false
  }

  loading (loading) {
    this.setState({
      loading: loading
    })
  }

  async _onChange (event) {
    this.loading(true)

    try {
      if (!!this.props.maxSize && event.target.files[0].size > toBytes(this.props.maxSize)) {
        throw new Error(`O arquivo selecionado excede o tamanho máximo permitido de ${this.props.maxSize}`)
      }

      const { region, bucket } = this.props.host.aws
      const data = await upload(region, bucket, event.target.files[0])

      this.refs.input.value = null

      this.props.input.onChange(data)
    } catch (error) {
      this.props.actions.notify('error', 'Erro', error.message)
    }

    this.loading(false)
  }

  renderPreview () {
    const value = this.props.input.value
    if (!value || !value.url) {
      return (
        <div className='form-control placeholder'>
          { this.props.placeholder }
        </div>
      )
    }

    if (isImage(value.url)) {
      return (
        <div className={`form-control ${styles.imagePreview}`}>
          <Spinner visible={this.state.loading} />
          <img src={`${imageResizer(value.url)}`}
            alt={value.name}
            title={value.name} />
        </div>
      )
    }

    if (!isImage(value.url) && value.name) {
      return (
        <div className={`form-control ${styles.imagePreview}`}>
          <Spinner visible={this.state.loading} />
          {value.name}
        </div>
      )
    }

    const returnText = this.state.loading ? 'Carregando...' : value.name
    return (
      <div className='form-control'>
        { returnText }
      </div>
    )
  }

  renderActions () {
    const { value } = this.props.input
    return (
      <div className={styles.actions}>
        <input
          type='file'
          className='form-control'
          ref='input'
          accept={this.props.accept}
          onChange={(event) => this._onChange(event)}
        />

        <Button
          disabled={this.state.loading}
          onClick={() => this.refs.input.click()}>
          Selecionar
        </Button>

        {value && (
          <Button
            bsStyle='danger'
            className='margin-left-5'
            disabled={this.state.loading}
            onClick={() => this.props.input.onChange('')}>
            Remover
          </Button>
        )}

        {this.props.maxSize && (
          <p className={styles.maxSize}>Tamanho máximo: {this.props.maxSize}</p>
        )}
      </div>
    )
  }

  render () {
    return (
      <Group {...this.props}>
        <div className={styles.file}>
          {this.renderPreview()}
          {this.renderActions()}
        </div>
      </Group>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

const mapStateToProps = (state) => ({
  host: state.app.host
})

const connectedField = connect(mapStateToProps, mapDispatchToProps)(File)

const FileField = (props) => (
  <Field {...props} component={connectedField} />
)

FileField.propTypes = {
  placeholder: PropTypes.string,
  accept: PropTypes.string
}

FileField.defaultProps = {
  placeholder: 'Nenhum arquivo selecionado',
  accept: 'image/*'
}

export default FileField
