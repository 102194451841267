import React, { Component } from 'react'
import createAbsoluteGrid from 'react-absolute-grid'
import _ from 'lodash'

const Grid = createAbsoluteGrid(({ item }) => (
  <item.component item={item.data}/>
))

class SortableGrid extends Component {
  constructor (props) {
    super(props)

    this.onMoveDebounced = _.debounce(this.onMove, 40)

    const { renderItem, items: itemsData, keyProperty } = props
    const items = itemsData.map((item, index) => ({
      key: _.get(item, keyProperty),
      sort: index,
      data: item,
      component: renderItem
    }))

    this.state = { items, lastReorderedItem: null }
  }

  onMove (sourceKey, targetKey) {
    const { items } = this.state

    const source = _.find(items, { key: sourceKey })
    const target = _.find(items, { key: targetKey })

    const targetSort = target.sort

    const itemsReordered = items.map(item => {
      // Decrement sorts between positions when target is greater
      if (item.key === source.key) {
        return {
          ...item,
          sort: targetSort
        }
      } else if (target.sort > source.sort && (item.sort <= target.sort && item.sort > source.sort)) {
        return {
          ...item,
          sort: item.sort - 1
        }
        // Increment sorts between positions when source is greater
      } else if (item.sort >= target.sort && item.sort < source.sort) {
        return {
          ...item,
          sort: item.sort + 1
        }
      }

      return item
    })

    const sourceResorted = _.find(itemsReordered, { key: sourceKey })

    this.setState({ items: itemsReordered, lastReorderedItem: sourceResorted })
  }

  onDragEnd (e) {
    const { lastReorderedItem } = this.state
    if (lastReorderedItem) {
      this.props.onOrderChanged({
        item: lastReorderedItem.data,
        order: lastReorderedItem.sort
      })
    }
  }

  render () {
    const { width, height } = this.props

    return (
      <Grid
        items={this.state.items}
        itemWidth={width}
        itemHeight={height}
        responsive
        dragEnabled
        onMove={this.onMoveDebounced.bind(this)}
        onDragEnd={this.onDragEnd.bind(this)}
      />
    )
  }
}

export default SortableGrid
