import { Component } from 'react'
import PropTypes from 'prop-types'

let resizeTimeout

class ResizeListener extends Component {
  handleResize () {
    clearTimeout(resizeTimeout)

    resizeTimeout = setTimeout(() => {
      this.props.onResize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }, 100)
  }

  componentDidMount () {
    this.handleResize()
    window.addEventListener('resize', () => this.handleResize())
  }

  componentWillUnmount () {
    window.removeEventListener('resize', () => this.handleResize())
  }

  render () {
    return this.props.children
  }
}

ResizeListener.propTypes = {
  onResize: PropTypes.func.isRequired,
  children: PropTypes.node
}

ResizeListener.defaultProps = {
  children: null
}

export default ResizeListener
