import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import DataList, { Actions, Action, Toggler, Ordenator} from 'components/datalist'
import ConfirmDialog from 'components/app/confirm-dialog'
import { routeTo } from 'router'
import { fetchPermissions } from 'security/authorization'
import { message } from 'utils/messages'
import OfferCatalogRemoveMutation from 'relay/mutations/OfferCatalogRemoveMutation'
import OfferCatalogEditOrderMutation from 'relay/mutations/OfferCatalogEditOrderMutation'
import OfferCatalogEditActiveMutation from 'relay/mutations/OfferCatalogEditActiveMutation'

class OfferCatalogsList extends Component {
  constructor (props) {
    super(props)

    this.state = {permissions: {}}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['offer-catalogs:add', 'offer-catalogs:edit', 'offer-catalogs:remove'])
    this.setState({ permissions })
  }

  _remove (offerCatalog) {
    OfferCatalogRemoveMutation.commit(
      Environment,
      offerCatalog,
      this.props.viewer,
      {
        onCompleted: ({ OfferCatalogRemove }) => {
          if (OfferCatalogRemove.error) {
            this.props.notify('error', 'Erro', message(OfferCatalogRemove.error))
          } else {
            this.props.notify('success', 'Sucesso', 'Encarte removido com sucesso')
          }
        },
        onError: (transaction) => {
          this.props.notify('error', 'Erro', message('SERVER_OFFLINE'))
        }
      }
    )
  }

  _editOrderMutation (offerCatalog, direction, refreshCallback) {
    OfferCatalogEditOrderMutation.commit(
      Environment,
      {id: offerCatalog.id, direction},
      this.props.viewer,
      {
        onCompleted: ({ OfferCatalogEditOrder }) => { refreshCallback() },
        onError: (transaction) => {}
      }
    )
  }
  _onOrderDown (offerCatalog, refreshCallback) {
    this._editOrderMutation(offerCatalog, 'DOWN', refreshCallback)
  }

  _onOrderUp (offerCatalog, refreshCallback) {
    this._editOrderMutation(offerCatalog, 'UP', refreshCallback)
  }

  _editActive (offerCatalog, active) {
    OfferCatalogEditActiveMutation.commit(
      Environment,
      {id: offerCatalog.id, active: active},
      this.props.viewer,
      {
        onCompleted: ({ OfferCatalogEditActive }) => {},
        onError: (transaction) => {}
      }
    )
  }

  render () {
    return (
      <Content
        title='Encartes'
        permission='offer-catalogs:list'
        menu={{link: 'Encartes'}}
        breadcrumb={{
          title: 'Encartes',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/offer-catalogs', title: 'Encartes'},
            {title: 'Listar'}
          ],
          actions: this.state.permissions['offer-catalogs:add'] && (
            <Link to={routeTo('dashboard.offer-catalogs.add')} className='btn btn-primary'>
              <i className='fa fa-plus' /> Adicionar
            </Link>
          )
        }}>
        <DataList
          name='OfferCatalogsList'
          environment={Environment}
          query={graphql`
            query offerCatalogsList_datalist_Query($first: Int!, $search: String) {
              viewer {
                ...offerCatalogsList_datalist_viewer
              }
            }
          `}
          fragments={{
            viewer: graphql`
              fragment offerCatalogsList_datalist_viewer on Viewer {
                id
                offerCatalogs(first: $first, search: $search) @connection(key: "list_offerCatalogs", filters: []) {
                  edges {
                    node {
                      id
                      title
                      category {
                        id
                        name
                      }
                      order
                      active
                      isFirst
                      isLast
                    }
                  }
                  pageInfo {
                    hasNextPage
                  }
                }
              }
            `
          }}
          columns={[
            {name: 'Título de identificação', property: 'title'},
            {name: 'Categoria', property: 'category.name'},
            {
              name: 'Ordem',
              callback: (offerCatalog, {refreshCallback}) => (
                <Ordenator
                  showUp={!(offerCatalog.isFirst && offerCatalog.isLast) && !offerCatalog.isFirst}
                  showDown={!(offerCatalog.isFirst && offerCatalog.isLast) && !offerCatalog.isLast}
                  onUp={() => this._onOrderUp(offerCatalog, refreshCallback)}
                  onDown={() => this._onOrderDown(offerCatalog, refreshCallback)}
                />
              )
            },
            {
              name: 'Situação',
              callback: (offerCatalog) => (
                <Toggler
                  value={offerCatalog.active}
                  onChange={(value) => this._editActive(offerCatalog, value)}
                />
              )
            },
            {
              name: 'Ação',
              callback: (offerCatalog) => (
                <Actions>
                  {this.state.permissions['offer-catalogs:edit'] && (
                    <Action title='Alterar'>
                      <Link to={routeTo('dashboard.offer-catalogs.edit', {id: offerCatalog.id})}>
                        <i className='fa fa-edit' />
                      </Link>
                    </Action>
                  )}
                  {this.state.permissions['offer-catalogs:remove'] && (
                    <Action title='Excluir'>
                      <ConfirmDialog
                        icon='fa fa-trash-o'
                        title={offerCatalog.name}
                        subtitle='Deseja remover este Encarte? Esta ação não poderá ser desfeita.'
                        onOk={() => this._remove(offerCatalog)}>
                        <i className='fa fa-trash-o' />
                      </ConfirmDialog>
                    </Action>
                  )}
                </Actions>
              )
            }
          ]}
        />
      </Content>
    )
  }
}

const OfferCatalogsListRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query offerCatalogsList_viewer_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (
      <OfferCatalogsList
        {...rendererProps}
        {...props} />
    )}
  />
)

export default connect(null, {...appActionCreators})(OfferCatalogsListRenderer)
