import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation ArticleEditMutation($input: ArticleEditInput!) {
    ArticleEdit(input: $input) {
      article {
        id
        title
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      article: viewer.article.id
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    ArticleEdit: {
      article: {
        title: values.title,
        image: values.image,
        summary: values.summary,
        text: values.text,
        font: values.font,
        fontLink: values.fontLink,
        date: values.date,
        time: values.time,
        active: values.active
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: values.id,
          title: values.title,
          image: values.image,
          summary: values.summary,
          text: values.text,
          font: values.font,
          fontLink: values.fontLink,
          date: values.date,
          time: values.time,
          active: values.active
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
