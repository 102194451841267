/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductGroupAddProductsInput = {|
  id?: ?string,
  productIds?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type ProductGroupAddProductsMutationVariables = {|
  input: ProductGroupAddProductsInput
|};
export type ProductGroupAddProductsMutationResponse = {|
  +ProductGroupAddProducts: ?{|
    +productGroup: ?{|
      +id: string,
      +products: ?$ReadOnlyArray<?{|
        +id: string
      |}>,
    |}
  |}
|};
export type ProductGroupAddProductsMutation = {|
  variables: ProductGroupAddProductsMutationVariables,
  response: ProductGroupAddProductsMutationResponse,
|};
*/


/*
mutation ProductGroupAddProductsMutation(
  $input: ProductGroupAddProductsInput!
) {
  ProductGroupAddProducts(input: $input) {
    productGroup {
      id
      products {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProductGroupAddProductsPayload",
    "kind": "LinkedField",
    "name": "ProductGroupAddProducts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductGroup",
        "kind": "LinkedField",
        "name": "productGroup",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "products",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductGroupAddProductsMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductGroupAddProductsMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "21a79f6157d03b105cacb2f3f323ff57",
    "id": null,
    "metadata": {},
    "name": "ProductGroupAddProductsMutation",
    "operationKind": "mutation",
    "text": "mutation ProductGroupAddProductsMutation(\n  $input: ProductGroupAddProductsInput!\n) {\n  ProductGroupAddProducts(input: $input) {\n    productGroup {\n      id\n      products {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd6b4af93c81de928e3959526d1b03988';

module.exports = node;
