import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, InputGroup, DropdownButton, MenuItem, FormControl } from 'react-bootstrap'

import Panel from 'components/panel'

import styles from './search.less'

let searchTimeout

class Search extends Component {
  state = {
    value: this.props.value || '',
    filter: this.props.filters ? Object.keys(this.props.filters).slice(0, 1) : null
  }

  _handleSearch () {
    clearTimeout(searchTimeout)
    searchTimeout = setTimeout(() => {
      this.props.callback(this.state.value, this.state.filter)
    }, 350)
  }

  _changeFilter (filter) {
    this.setState({
      filter: filter
    })

    if (this.state.value.length > 0) {
      this._handleSearch()
    }
  }

  _changeValue (value) {
    this.setState({
      value: value
    })

    this._handleSearch()
  }

  render () {
    let dropdownTitle = 'Filtrar por'
    if (this.state.filter) {
      dropdownTitle += ' ' + this.props.filters[this.state.filter]
    }

    return (
      <div className={styles.wrapper}>
        <Panel>
          <FormGroup>
            <InputGroup>
              {this.props.filters && (
                <DropdownButton
                  componentClass={InputGroup.Button}
                  id='input-dropdown-addon'
                  title={dropdownTitle}
                  disabled={this.props.disabled}
                  onSelect={(filter) => this._changeFilter(filter)}
                >
                  {
                    Object.keys(this.props.filters).map((filterKey, filterIndex) => (
                      <MenuItem
                        eventKey={filterKey}
                        key={filterIndex}
                      >
                        {this.props.filters[filterKey]}
                      </MenuItem>
                    ))
                  }
                </DropdownButton>
              )}

              <div className={styles.input}>
                <i className='fa fa-search' />
                <FormControl
                  type='text'
                  placeholder='Comece a digitar para pesquisar...'
                  value={this.state.value}
                  readOnly={this.props.disabled}
                  onChange={(event) => this._changeValue(event.target.value)}
                />
                <div className='clearfix' />
              </div>
            </InputGroup>
          </FormGroup>
        </Panel>
      </div>
    )
  }
}

Search.propTypes = {
  callback: PropTypes.func.isRequired,
  filters: PropTypes.object,
  disabled: PropTypes.bool
}

Search.defaultProps = {
  disabled: false
}

export default Search
