import { Component } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actionCreators from '../actions'

class ActiveMenuHandler extends Component {
  UNSAFE_componentWillMount () {
    this.props.actions.changeActiveMenu(this.props.group, this.props.link)
  }

  render () {
    return null
  }
}

ActiveMenuHandler.propTypes = {
  link: PropTypes.string.isRequired,
  group: PropTypes.string
}

ActiveMenuHandler.defaultProps = {
  group: ''
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(ActiveMenuHandler)
