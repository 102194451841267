import { commitMutation, graphql } from 'react-relay'

import { removeUpdater } from 'relay'

const mutation = graphql`
  mutation RecipeRemoveMutation($input: RecipeRemoveInput!) {
    RecipeRemove(input: $input) {
      deletedID
      error
    }
  }
`

function commit (environment, recipe, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: recipe.id
        }
      },
      updater: (store) => {
        const payload = store.getRootField('RecipeRemove')
        const deletedID = payload.getValue('deletedID')

        removeUpdater(store, viewer, 'list_recipes', deletedID)
      },
      optimisticUpdater: (store) => {
        removeUpdater(store, viewer, 'list_recipes', recipe.id)
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
