/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductGroupSort = "HIGHER_PRICE" | "LOWER_PRICE" | "MANUAL" | "RANDOM" | "%future added value";
export type WeekDay = "FRIDAY" | "MONDAY" | "SATURDAY" | "SUNDAY" | "THURSDAY" | "TUESDAY" | "WEDNESDAY" | "%future added value";
export type ProductGroupAddInput = {|
  title: string,
  description?: ?string,
  sortBy: ProductGroupSort,
  active: boolean,
  start?: ?any,
  end?: ?any,
  weekDays?: ?$ReadOnlyArray<?WeekDay>,
  clientMutationId?: ?string,
|};
export type ProductGroupAddMutationVariables = {|
  input: ProductGroupAddInput
|};
export type ProductGroupAddMutationResponse = {|
  +ProductGroupAdd: ?{|
    +productGroupEdge: ?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +description: ?string,
        +sortBy: ?ProductGroupSort,
        +active: ?boolean,
        +start: ?any,
        +end: ?any,
        +weekDays: ?$ReadOnlyArray<?WeekDay>,
      |}
    |}
  |}
|};
export type ProductGroupAddMutation = {|
  variables: ProductGroupAddMutationVariables,
  response: ProductGroupAddMutationResponse,
|};
*/


/*
mutation ProductGroupAddMutation(
  $input: ProductGroupAddInput!
) {
  ProductGroupAdd(input: $input) {
    productGroupEdge {
      node {
        id
        title
        description
        sortBy
        active
        start
        end
        weekDays
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProductGroupAddPayload",
    "kind": "LinkedField",
    "name": "ProductGroupAdd",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductGroupEdge",
        "kind": "LinkedField",
        "name": "productGroupEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductGroup",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sortBy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "active",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "start",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "end",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "weekDays",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductGroupAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductGroupAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8af6c39649b06bac8371643e6a00d577",
    "id": null,
    "metadata": {},
    "name": "ProductGroupAddMutation",
    "operationKind": "mutation",
    "text": "mutation ProductGroupAddMutation(\n  $input: ProductGroupAddInput!\n) {\n  ProductGroupAdd(input: $input) {\n    productGroupEdge {\n      node {\n        id\n        title\n        description\n        sortBy\n        active\n        start\n        end\n        weekDays\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '85c946a3812742304b38b65b73074353';

module.exports = node;
