import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import LeadRemoveMutation from 'relay/mutations/LeadRemoveMutation'
import LeadExportMutation from 'relay/mutations/LeadExportMutation'
import Content from 'components/templates/dashboard/content'
import DataList, { Actions, Action } from 'components/datalist'
import ConfirmDialog from 'components/app/confirm-dialog'
import { routeTo } from 'router'
import { fetchPermissions } from 'security/authorization'
import { message } from 'utils/messages'
import LoadingIcon from 'components/icons/loading'

class LeadsList extends Component {
  constructor (props) {
    super(props)

    this.state = {permissions: {}, exporting: false, exportFileUrl: null}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['leads:add', 'leads:edit', 'leads:remove', 'leads:export'])
    this.setState({ permissions })
  }

  _remove (lead) {
    LeadRemoveMutation.commit(Environment, lead, this.props.viewer, {
      onCompleted: ({LeadRemove}) => {
        if (LeadRemove.error) {
          this.props.notify('error', 'Erro', message(LeadRemove.error))
        } else {
          this.props.notify('success', 'Sucesso', 'Lead removido com sucesso')
        }
      },
      onError: (transaction) => {
        this.props.notify('error', 'Erro', message('SERVER_OFFLINE'))
      }
    })
  }

  _export () {
    this.setState({exporting: true})
    LeadExportMutation.commit(Environment, {
      onCompleted: ({LeadExport}) => {
        this.setState({exporting: false, exportFileUrl: LeadExport.file.url})
      },
      onError: (errors) => {
        console.log(errors)
        this.setState({exporting: false})
      }
    })
  }

  render () {
    return (
      <Content
        title='Leads'
        permission='leads:list'
        menu={{link: 'Leads'}}
        breadcrumb={{
          title: 'Leads',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/leads', title: 'Leads'},
            {title: 'Listar'}
          ],
          actions: (
            <div>
              {this.state.permissions['leads:add'] && (
                <Link to={routeTo('dashboard.leads.add')} className='btn btn-primary'>
                  <i className='fa fa-plus' /> Adicionar
                </Link>
              )} {this.state.permissions['leads:export'] && (
                <button onClick={() => this._export()} className='btn btn-info'>
                  {this.state.exporting ? (
                    <LoadingIcon />
                  ) : (
                    <i className='fa fa-cloud-download' />
                  )} {this.state.exporting ? 'Exportando arquivo...' : 'Exportar CSV'}
                </button>
              )}
            </div>
          )
        }}>
        {this.state.exportFileUrl && (
          <div className='alert alert-info'>
            Arquivo exportado, clique para baixar: <a href={this.state.exportFileUrl}><i className='fa fa-cloud-download' /> Baixar</a>
          </div>
        )}
        <DataList
          name='LeadsList'
          environment={Environment}
          query={graphql`
            query leadsList_datalist_Query($first: Int!, $search: String) {
              viewer {
                ...leadsList_datalist_viewer
              }
            }
          `}
          fragments={{
            viewer: graphql`
              fragment leadsList_datalist_viewer on Viewer {
                id
                leads(first: $first, search: $search) @connection(key: "list_leads", filters: []) {
                  edges {
                    node {
                      id
                      name
                      email
                    }
                  }
                  pageInfo {
                    hasNextPage
                  }
                }
              }
            `
          }}
          columns={[
            {name: 'Nome', property: 'name'},
            {name: 'E-mail', property: 'email'},
            {
              name: 'Ação',
              callback: (lead) => (
                <Actions>
                  {this.state.permissions['leads:edit'] && (
                    <Action title='Alterar'>
                      <Link to={routeTo('dashboard.leads.edit', {id: lead.id})}>
                        <i className='fa fa-edit' />
                      </Link>
                    </Action>
                  )}
                  {this.state.permissions['leads:remove'] && (
                    <Action title='Excluir'>
                      <ConfirmDialog
                        icon='fa fa-trash-o'
                        title={lead.name}
                        subtitle='Deseja remover este Lead? Esta ação não poderá ser desfeita.'
                        onOk={() => this._remove(lead)}>
                        <i className='fa fa-trash-o' />
                      </ConfirmDialog>
                    </Action>
                  )}
                </Actions>
              )
            }
          ]}
        />
      </Content>
    )
  }
}

const LeadsListRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query leadsList_viewer_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (
      <LeadsList
        {...rendererProps}
        {...props} />
    )}
  />
)

export default connect(null, {...appActionCreators})(LeadsListRenderer)
