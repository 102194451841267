import { Component } from 'react'
import PropTypes from 'prop-types'

let scrollTimeout

class InfiniteScroll extends Component {
  handleScroll () {
    clearTimeout(scrollTimeout)

    scrollTimeout = setTimeout(() => {
      const visibleArea = window.scrollY + window.innerHeight
      const toReach = window.outerHeight - this.props.offset

      if (visibleArea >= toReach) {
        this.props.onScroll()
      }
    }, 100)
  }

  componentDidMount () {
    window.addEventListener('scroll', () => this.handleScroll())
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', () => this.handleScroll())
  }

  render () {
    return this.props.children
  }
}

InfiniteScroll.propTypes = {
  onScroll: PropTypes.func.isRequired,
  offset: PropTypes.number,
  children: PropTypes.node
}

InfiniteScroll.defaultProps = {
  offset: 25,
  children: null
}

export default InfiniteScroll
