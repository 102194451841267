import { Environment, Network, RecordSource, Store } from 'relay-runtime'

import handlerProvider from './handler-provider'
import Cookie from 'utils/cookie'
import { getGraphQLUrl } from 'utils/urls'

// Define a function that fetches the results of an operation (query/mutation/etc)
// and returns its results as a Promise:
function fetchQuery (
  operation,
  variables
) {
  return fetch(getGraphQLUrl(), {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': Cookie.load('Authorization')
    }, // Add authentication and other headers here
    body: JSON.stringify({
      query: operation.text, // GraphQL text from input
      variables
    })
  }).then(response => {
    return response.json()
  })
}

// Create a network layer from the fetch function
const network = Network.create(fetchQuery)

const source = new RecordSource()
const store = new Store(source)

export default new Environment({
  handlerProvider,
  network,
  store
})
