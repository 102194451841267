import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'react-bootstrap'

class ConfirmDialog extends Component {
  constructor (props) {
    super(props)

    this.state = {
      visible: false
    }
  }

  show () {
    this.setState({
      visible: true
    })
  }

  close () {
    this.setState({
      visible: false
    })
  }

  onOk () {
    this.props.onOk()
    this.close()
  }

  onCancel () {
    if (this.props.onCancel) {
      this.props.onCancel(this.close)
    }
    this.close()
  }

  render () {
    let icon = this.props.icon
    if (icon) {
      icon += ' modal-icon'
    }

    return (
      <div>
        <a onClick={() => { this.show() }}>
          {this.props.children}
        </a>

        <Modal
          show={this.state.visible}
          onHide={() => { this.close() }}
          dialogClassName='inmodal'>
          {(icon || this.props.title || this.props.subtitle) && (
            <Modal.Header
              closeButton
              className={!this.props.body ? 'no-border' : ''}
            >
              {icon && (
                <i className={icon} />
              )}
              {this.props.title && (
                <Modal.Title>{this.props.title}</Modal.Title>
              )}
              {this.props.subtitle && (
                <small className='font-bold'>{this.props.subtitle}</small>
              )}
            </Modal.Header>
          )}

          {this.props.body && (
            <Modal.Body>{this.props.body}</Modal.Body>
          )}

          <Modal.Footer>
            <Button onClick={() => { this.onCancel() }}>
              <i className='fa fa-times' />
              &nbsp;Não
            </Button>
            <Button bsStyle='primary' onClick={() => { this.onOk() }}>
              Sim&nbsp;
              <i className='fa fa-check' />
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

ConfirmDialog.propTypes = {
  children: PropTypes.node.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  body: PropTypes.string
}

export default ConfirmDialog
