import React, { Component } from 'react'
import Panel from 'components/panel'
import ViewedLabel from './viewed-label'
import { formatExtended } from 'utils/date'

export default class FidelityCardMessage extends Component {
  render () {
    if (!this.props.values) {
      return (<div />)
    }

    return (
      <div>
        {this.props.values.map(group => (
          <Panel title={group.name} key={group.name}>
            {group.fields.map((field) => (
              <div key={field.value}>
                <label>{field.name}: </label> {field.value} <br />
              </div>
            ))}
          </Panel>
        ))}
        <Panel>
          <label>Situação: </label> <ViewedLabel viewed={this.props.viewedAt} /> <br />
          <hr className="hr-line-dashed" />
          <label>Data da Visualização: </label> {formatExtended(this.props.viewedAt)}
        </Panel>
      </div>
    )
  }
}
