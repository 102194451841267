/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupermarketMaintenanceEditInput = {|
  enabled: boolean,
  message: string,
  allowedIps?: ?string,
  clientMutationId?: ?string,
|};
export type SupermarketMaintenanceEditMutationVariables = {|
  input: SupermarketMaintenanceEditInput
|};
export type SupermarketMaintenanceEditMutationResponse = {|
  +SupermarketMaintenanceEdit: ?{|
    +supermarket: ?{|
      +id: string,
      +maintenance: ?{|
        +enabled: ?boolean,
        +message: ?string,
        +allowedIps: ?string,
      |},
    |}
  |}
|};
export type SupermarketMaintenanceEditMutation = {|
  variables: SupermarketMaintenanceEditMutationVariables,
  response: SupermarketMaintenanceEditMutationResponse,
|};
*/


/*
mutation SupermarketMaintenanceEditMutation(
  $input: SupermarketMaintenanceEditInput!
) {
  SupermarketMaintenanceEdit(input: $input) {
    supermarket {
      id
      maintenance {
        enabled
        message
        allowedIps
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowedIps",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SupermarketMaintenanceEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SupermarketMaintenanceEditPayload",
        "kind": "LinkedField",
        "name": "SupermarketMaintenanceEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupermarketMaintenance",
                "kind": "LinkedField",
                "name": "maintenance",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SupermarketMaintenanceEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SupermarketMaintenanceEditPayload",
        "kind": "LinkedField",
        "name": "SupermarketMaintenanceEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupermarketMaintenance",
                "kind": "LinkedField",
                "name": "maintenance",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88aedf54f44b4e242260c4c4c803631b",
    "id": null,
    "metadata": {},
    "name": "SupermarketMaintenanceEditMutation",
    "operationKind": "mutation",
    "text": "mutation SupermarketMaintenanceEditMutation(\n  $input: SupermarketMaintenanceEditInput!\n) {\n  SupermarketMaintenanceEdit(input: $input) {\n    supermarket {\n      id\n      maintenance {\n        enabled\n        message\n        allowedIps\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1f4148a504ffd8d8ca8438c147d40c1b';

module.exports = node;
