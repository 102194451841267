import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation LeadExportMutation($input: LeadExportInput!) {
    LeadExport(input: $input) {
      file {
        id
        key
        url
      }
    }
  }
`

function commit (environment, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {}
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
