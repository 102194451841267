import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'redux-form'
import * as Bootstrap from 'react-bootstrap'

import Group from './group'

class Checkbox extends Component {
  _onChange (event) {
    if (event.target.checked) {
      this._add()
    } else {
      this._remove()
    }
  }

  _add () {
    this.props.fields.push(this.props.checkbox.value)
  }

  _remove () {
    this.props.fields.getAll().map((check, checkIndex) => {
      if (check === this.props.checkbox.value) {
        this.props.fields.remove(checkIndex)
      }
    })
  }

  render () {
    const values = this.props.fields.getAll()
    const checked = values && values.indexOf(this.props.checkbox.value) !== -1

    return (
      <Bootstrap.Checkbox
        inline={this.props.inline}
        defaultChecked={checked}
        onChange={(event) => this._onChange(event)}
      >
        {this.props.checkbox.name}
      </Bootstrap.Checkbox>
    )
  }
}

const Checkboxes = (props) => {
  return (
    <Group {...props}>
      {
        props.list.map((check, checkIndex) => {
          return (
            <FieldArray
              key={checkIndex}
              checkbox={check}
              {...props}
              component={Checkbox}
            />
          )
        })
      }
    </Group>
  )
}

Checkboxes.defaultProps = {
  inline: true
}

Checkboxes.propTypes = {
  inline: PropTypes.bool,
  list: PropTypes.array.isRequired
}

export default Checkboxes
