/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupermarketPrivacyPolicyEditInput = {|
  privacyPolicy?: ?string,
  clientMutationId?: ?string,
|};
export type SupermarketPrivacyPolicyEditMutationVariables = {|
  input: SupermarketPrivacyPolicyEditInput
|};
export type SupermarketPrivacyPolicyEditMutationResponse = {|
  +SupermarketPrivacyPolicyEdit: ?{|
    +supermarket: ?{|
      +id: string,
      +privacyPolicy: ?string,
    |}
  |}
|};
export type SupermarketPrivacyPolicyEditMutation = {|
  variables: SupermarketPrivacyPolicyEditMutationVariables,
  response: SupermarketPrivacyPolicyEditMutationResponse,
|};
*/


/*
mutation SupermarketPrivacyPolicyEditMutation(
  $input: SupermarketPrivacyPolicyEditInput!
) {
  SupermarketPrivacyPolicyEdit(input: $input) {
    supermarket {
      id
      privacyPolicy
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SupermarketPrivacyPolicyEditPayload",
    "kind": "LinkedField",
    "name": "SupermarketPrivacyPolicyEdit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Supermarket",
        "kind": "LinkedField",
        "name": "supermarket",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "privacyPolicy",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SupermarketPrivacyPolicyEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SupermarketPrivacyPolicyEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "feb473414e52e7d089cc6a3ffbb17ef7",
    "id": null,
    "metadata": {},
    "name": "SupermarketPrivacyPolicyEditMutation",
    "operationKind": "mutation",
    "text": "mutation SupermarketPrivacyPolicyEditMutation(\n  $input: SupermarketPrivacyPolicyEditInput!\n) {\n  SupermarketPrivacyPolicyEdit(input: $input) {\n    supermarket {\n      id\n      privacyPolicy\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ee21ade9ba6981a2f6440842b8c8ad86';

module.exports = node;
