/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactRemoveInput = {|
  id?: ?string,
  clientMutationId?: ?string,
|};
export type ContactRemoveMutationVariables = {|
  input: ContactRemoveInput
|};
export type ContactRemoveMutationResponse = {|
  +ContactRemove: ?{|
    +deletedId: ?string
  |}
|};
export type ContactRemoveMutation = {|
  variables: ContactRemoveMutationVariables,
  response: ContactRemoveMutationResponse,
|};
*/


/*
mutation ContactRemoveMutation(
  $input: ContactRemoveInput!
) {
  ContactRemove(input: $input) {
    deletedId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ContactRemovePayload",
    "kind": "LinkedField",
    "name": "ContactRemove",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactRemoveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactRemoveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "207b97bb7fa47d0dc03a4855a7bc5431",
    "id": null,
    "metadata": {},
    "name": "ContactRemoveMutation",
    "operationKind": "mutation",
    "text": "mutation ContactRemoveMutation(\n  $input: ContactRemoveInput!\n) {\n  ContactRemove(input: $input) {\n    deletedId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd920b92ad64ac89b2907ec7c72e04748';

module.exports = node;
