import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import RequestPasswordRecoveryMutation from '../../../relay/mutations/RequestPasswordRecoveryMutation'
import Content from 'components/templates/login/content'
import { routeTo } from 'router'
import PasswordRecoveryRequestForm from './password-recovery-request-form'

class PasswordRecoveryRequestView extends Component {
  constructor (props) {
    super(props)

    this.state = { loading: false }
  }

  _loading (loading) {
    this.setState({ loading })
  }

  _success () {
    this.props.actions.notify('success', 'Sucesso', 'Em breve você receberá um link para recuperar sua senha.')
    this.props.history.push(routeTo('login'))
  }

  _error () {
    this.props.actions.notify('error', 'Erro', 'Não foi possível solicitar a recuperação de senha')
  }

  _onSubmit (values) {
    this._loading(true)

    RequestPasswordRecoveryMutation.commit(
      values,
      {
        onCompleted: ({ RequestPasswordRecovery }) => {
          this._loading(false)

          if (RequestPasswordRecovery.error) {
            this._error()
          } else {
            this._success()
          }
        },
        onError: (transaction) => {
          this._loading(false)

          this._error()
        }
      }
    )
  }

  render () {
    const loading = this.props.loading || this.state.loading

    return (
      <Content title='Recuperar senha'>
        <PasswordRecoveryRequestForm loading={loading} onSubmit={(values) => this._onSubmit(values)} />

        <hr />
        <a className='link' href={routeTo('login')}>
          Voltar para o login
        </a>
      </Content>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(PasswordRecoveryRequestView)
