import { commitMutation, graphql } from 'react-relay'

import { removeUpdater } from 'relay'

const mutation = graphql`
  mutation BannerRemoveMutation($input: BannerRemoveInput!) {
    BannerRemove(input: $input) {
      deletedID
      error
    }
  }
`

function commit (environment, banner, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: banner.id
        }
      },
      updater: (store) => {
        const payload = store.getRootField('BannerRemove')
        const deletedID = payload.getValue('deletedID')

        removeUpdater(store, viewer, 'list_banners', deletedID)
      },
      optimisticUpdater: (store) => {
        removeUpdater(store, viewer, 'list_banners', banner.id)
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
