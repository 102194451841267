import { bannerPositionsTitles, bannerPositionsRoutes, bannerPositionsPermissions } from './constants'

export function title (position, action = 'LIST') {
  return bannerPositionsTitles[position][action]
}

export function route (position, action = 'LIST') {
  return bannerPositionsRoutes[position][action]
}

export function permission (position, action = 'LIST') {
  return bannerPositionsPermissions[position][action]
}
