import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Panel from 'components/panel'
import Spinner from 'components/spinner'
import createForm, { Form, File, Input, Select, Wysiwyg, CheckboxGroup, Buttons } from 'components/forms'
import Validation from 'utils/validation'
import { activeOptions, affirmativeOptions, productStampsOptions } from 'utils/constants'
import SectionCollapse from 'components/section-collapse'
import ProductFormPrices from './products-form-prices'

class ProductsForm extends Component {
  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading, editing, companies, product } = this.props

    return (
      <div style={{backgroundColor: '#FFFFFF', marginBottom: '25px'}}>
        <SectionCollapse title='Dados gerais' visible>
          <Panel>
            <Form horizontal onSubmit={handleSubmit}>
              <Spinner visible={loading} />

              <File name='image' horizontal label='Imagem' />
              <hr className='hr-line-dashed' />

              <Input name='name' horizontal type='text' label='Nome' />

              <Input name='gtin' horizontal type='number' label='GTIN' />

              <Select
                name='active'
                horizontal
                label='Situação'
                options={activeOptions}
                clearable={false}
                searchable={false}
              />
              <hr className='hr-line-dashed' />

              <Input name='size' horizontal type='numeric' label='Conteúdo' />

              <Input name='unitOfMeasure' horizontal type='text' label='Unidade do Conteúdo' />

              <Input name='saleUnit' horizontal type='text' label='Unidade de Venda' />

              <Select
                name='hasVariableWeight'
                horizontal
                label='Pesável'
                options={affirmativeOptions}
                clearable={false}
                searchable={false}
              />

              <CheckboxGroup
                horizontal
                name='stamps'
                label='Selos'
                options={productStampsOptions}
              />

              <hr className='hr-line-dashed' />

              <Wysiwyg name='additionalInfo' horizontal label='Informação adicional' />

              <Buttons
                horizontal
                pristine={pristine}
                submitting={submitting}
                valid={valid}
                reset={reset}
              />
            </Form>
          </Panel>
        </SectionCollapse>

        <SectionCollapse title='Preços' visible>
          {editing && (
            <ProductFormPrices companies={companies} product={product} />
          )}
          {!editing && (
            <Panel>
              <p>Salve o produto antes de inserir os preços.</p>
            </Panel>
          )}
        </SectionCollapse>
      </div>
    )
  }
}

ProductsForm.defaultProps = {
  loading: false
}

ProductsForm.propTypes = {
  loading: PropTypes.bool
}

const validation = new Validation({
  name: [Validation.required],
  gtin: [Validation.required]
})

export default createForm(ProductsForm, validation)
