import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import RoleAddMutation from 'relay/mutations/RoleAddMutation'

import Content from 'components/templates/dashboard/content'
import { toMutation } from 'components/forms'
import { message } from 'utils/messages'
import { routeTo } from 'router'
import RolesForm from './roles-form'
import RolesHelper from './roles-helper'

class RolesAdd extends Component {
  state = {
    loading: false
  }

  _loading (loading) {
    this.setState({
      loading: loading
    })
  }

  _success () {
    this.props.actions.notify('success', 'Sucesso', 'Grupo adicionado com sucesso')
    this.props.history.push(routeTo('dashboard.roles'))
  }

  _error (message) {
    this.props.actions.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    RoleAddMutation.commit(
      Environment,
      toMutation(values),
      this.props.viewer,
      {
        onCompleted: ({ RoleAdd }) => {
          this._loading(false)

          if (RoleAdd.error) {
            this._error(message(RoleAdd.error))
          } else {
            this._success()
          }
        },
        onError: (transaction) => {
          this._loading(false)

          this._error(message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title='Adicionar grupo'
        permission='roles:add'
        menu={{
          group: 'Acesso',
          link: 'Grupos'
        }}
        breadcrumb={{
          title: 'Grupos',
          items: [
            { 'url': '/dashboard', 'title': 'Dashboard' },
            { 'url': '/dashboard/roles', 'title': 'Grupos' },
            { 'title': 'Adicionar' }
          ]
        }}
      >
        <Row>
          <Col sm={7}>
            <RolesForm loading={this.state.loading} onSubmit={(values) => this._onSubmit(values)} />
          </Col>

          <Col sm={5}>
            <RolesHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const RolesAddQueryRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query rolesAdd_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (
      <RolesAdd
        {...rendererProps}
        {...props}
      />
    )}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(RolesAddQueryRenderer)
