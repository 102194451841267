/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type configurationMaintenanceEdit_QueryVariables = {||};
export type configurationMaintenanceEdit_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +supermarket: ?{|
      +id: string,
      +maintenance: ?{|
        +enabled: ?boolean,
        +message: ?string,
        +allowedIps: ?string,
      |},
    |},
  |}
|};
export type configurationMaintenanceEdit_Query = {|
  variables: configurationMaintenanceEdit_QueryVariables,
  response: configurationMaintenanceEdit_QueryResponse,
|};
*/


/*
query configurationMaintenanceEdit_Query {
  viewer {
    id
    supermarket {
      id
      maintenance {
        enabled
        message
        allowedIps
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowedIps",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "configurationMaintenanceEdit_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupermarketMaintenance",
                "kind": "LinkedField",
                "name": "maintenance",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "configurationMaintenanceEdit_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupermarketMaintenance",
                "kind": "LinkedField",
                "name": "maintenance",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d571d42bca5f603ed0dd0705c9729f3e",
    "id": null,
    "metadata": {},
    "name": "configurationMaintenanceEdit_Query",
    "operationKind": "query",
    "text": "query configurationMaintenanceEdit_Query {\n  viewer {\n    id\n    supermarket {\n      id\n      maintenance {\n        enabled\n        message\n        allowedIps\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5507f8f2567f142b8784530bf76081d1';

module.exports = node;
