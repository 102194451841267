/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactIdentification = "FIDELITY_CARD" | "GENERAL" | "RH" | "SAC" | "%future added value";
export type ContactType = "EMAIL" | "PHONE" | "%future added value";
export type ContactAddInput = {|
  title: string,
  identification: ContactIdentification,
  type: ContactType,
  value?: ?string,
  companies?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type ContactAddMutationVariables = {|
  input: ContactAddInput
|};
export type ContactAddMutationResponse = {|
  +ContactAdd: ?{|
    +contactEdge: ?{|
      +node: ?{|
        +id: string,
        +title: ?string,
        +type: ?ContactType,
        +value: ?string,
        +companies: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
            |}
          |}>
        |},
      |}
    |}
  |}
|};
export type ContactAddMutation = {|
  variables: ContactAddMutationVariables,
  response: ContactAddMutationResponse,
|};
*/


/*
mutation ContactAddMutation(
  $input: ContactAddInput!
) {
  ContactAdd(input: $input) {
    contactEdge {
      node {
        id
        title
        type
        value
        companies {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ContactAddPayload",
    "kind": "LinkedField",
    "name": "ContactAdd",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactEdge",
        "kind": "LinkedField",
        "name": "contactEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyConnection",
                "kind": "LinkedField",
                "name": "companies",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompanyEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Company",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactAddMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactAddMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c7a4c0f3477aa61834f4f54ed9ad688e",
    "id": null,
    "metadata": {},
    "name": "ContactAddMutation",
    "operationKind": "mutation",
    "text": "mutation ContactAddMutation(\n  $input: ContactAddInput!\n) {\n  ContactAdd(input: $input) {\n    contactEdge {\n      node {\n        id\n        title\n        type\n        value\n        companies {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '794bbdedbb75a1268d15cdd1f5327123';

module.exports = node;
