export const activeOptions = {
  'false': 'Inativo',
  'true': 'Publicado'
}

export const affirmativeOptions = {
  'false': 'Não',
  'true': 'Sim'
}

export const linkTargetOptions = {
  SELF: 'Mesma aba',
  BLANK: 'Nova aba'
}

export const daysOfTheWeekOptions = {
  SUNDAY: 'Domingo',
  MONDAY: 'Segunda-feira',
  TUESDAY: 'Terça-feira',
  WEDNESDAY: 'Quarta-feira',
  THURSDAY: 'Quinta-feira',
  FRIDAY: 'Sexta-feira',
  SATURDAY: 'Sábado'
}

export const fieldTypes = {
  'SHORT_TEXT': 'Texto curto',
  'LONG_TEXT': 'Texto longo',
  'NUMERIC': 'Numérico',
  'SELECTION': 'Seleção',
  'MULTIPLE_SELECTION': 'Seleção multipla',
  'CHOICE': 'Escolha',
  'DATE': 'Data',
  'PHONE': 'Telefone'
}

export const contactIdentificationOptions = {
  'GENERAL': 'Contato geral',
  'RH': 'Trabalhe conosco',
  'SAC': 'SAC - Serviço de atendimento ao consumidor',
  'FIDELITY_CARD': 'Solicite cartão fidelidade'
}

export const contactMessageTypes = {
  'CONTACT': 'Fale conosco',
  'WORK_WITH_US': 'Trabalhe conosco',
  'FIDELITY_CARD': 'Cartão fidelidade'
}

export const productStampsOptions = [
  { id: 'LOW_FAT', name: 'Baixo teor de gordura' },
  { id: 'FROZEN', name: 'Congelado' },
  { id: 'DIET', name: 'Diet' },
  { id: 'ECONOMIC', name: 'Econômico' },
  { id: 'GLUTEN_FREE', name: 'Não contém Glúten' },
  { id: 'IMPORTED', name: 'Importado' },
  { id: 'OWN_BRAND', name: 'Marca própria' },
  { id: 'ORGANIC', name: 'Orgânico' }
]

export const productGroupSortOptions = {
  'LOWER_PRICE': 'Menor Preço',
  'HIGHER_PRICE': 'Maior Preço',
  'RANDOM': 'Randômico',
  'MANUAL': 'Manual'
}

export const themes = {
  'dynamic': 'Dinâmico',
  'start': 'Base'
}

export const themeColors = {
  dynamic: [
    { label: 'Cor do tema', name: 'primary', default: '#eeeeee' }
  ],
  start: [
    { label: 'Primária', name: 'primary', default: '#eeeeee' }
  ]
}
