import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'
import { contactIdentificationOptions } from 'utils/constants'

const mutation = graphql`
  mutation ContactEditMutation($input: ContactEditInput!) {
    ContactEdit(input: $input) {
      contact {
        id
        title
        type
        value
        companies {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      contact: viewer.contact.id
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    ContactEdit: {
      contact: {
        title: values.title,
        type: values.type,
        value: values.value,
        companies: values.companies
      }
    }
  }
}

function commit (values, viewer, config) {
  const title = contactIdentificationOptions[values.identification]

  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: {
          ...values,
          title,
          companies: values.companies
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
