/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type offerCatalogsEdit_QueryVariables = {|
  id: string
|};
export type offerCatalogsEdit_QueryResponse = {|
  +config: ?{|
    +maxUploadSize: ?string
  |},
  +viewer: ?{|
    +companies: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +offerCatalog: ?{|
      +id: string,
      +title: ?string,
      +category: ?{|
        +id: string,
        +name: ?string,
      |},
      +isuuDocumentId: ?string,
      +weekDays: ?$ReadOnlyArray<?string>,
      +startDate: ?any,
      +endDate: ?any,
      +active: ?boolean,
      +companies: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
          |}
        |}>
      |},
      +image: ?{|
        +key: ?string,
        +url: ?string,
      |},
    |},
  |},
|};
export type offerCatalogsEdit_Query = {|
  variables: offerCatalogsEdit_QueryVariables,
  response: offerCatalogsEdit_QueryResponse,
|};
*/


/*
query offerCatalogsEdit_Query(
  $id: ID!
) {
  config {
    maxUploadSize
  }
  viewer {
    companies(selectableInMenu: true) {
      edges {
        node {
          id
          name
        }
      }
    }
    offerCatalog(id: $id) {
      id
      title
      category {
        id
        name
      }
      isuuDocumentId
      weekDays
      startDate
      endDate
      active
      companies {
        edges {
          node {
            id
            name
          }
        }
      }
      image {
        key
        url
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Config",
  "kind": "LinkedField",
  "name": "config",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxUploadSize",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CompanyEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "selectableInMenu",
      "value": true
    }
  ],
  "concreteType": "CompanyConnection",
  "kind": "LinkedField",
  "name": "companies",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": "companies(selectableInMenu:true)"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "OfferCatalogCategory",
  "kind": "LinkedField",
  "name": "category",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isuuDocumentId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weekDays",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "CompanyConnection",
  "kind": "LinkedField",
  "name": "companies",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "offerCatalogsEdit_Query",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "OfferCatalog",
            "kind": "LinkedField",
            "name": "offerCatalog",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "offerCatalogsEdit_Query",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "OfferCatalog",
            "kind": "LinkedField",
            "name": "offerCatalog",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0cf86cd594709d68ab8cd93f11e23bcc",
    "id": null,
    "metadata": {},
    "name": "offerCatalogsEdit_Query",
    "operationKind": "query",
    "text": "query offerCatalogsEdit_Query(\n  $id: ID!\n) {\n  config {\n    maxUploadSize\n  }\n  viewer {\n    companies(selectableInMenu: true) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n    offerCatalog(id: $id) {\n      id\n      title\n      category {\n        id\n        name\n      }\n      isuuDocumentId\n      weekDays\n      startDate\n      endDate\n      active\n      companies {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n      image {\n        key\n        url\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f459c953ff664a542782f5f89b94c82';

module.exports = node;
