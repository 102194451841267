/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupermarketThemeEditInput = {|
  themeId: string,
  overrideColors: boolean,
  colors?: ?$ReadOnlyArray<?NameValueInput>,
  clientMutationId?: ?string,
|};
export type NameValueInput = {|
  name?: ?string,
  value?: ?string,
|};
export type SupermarketThemeEditMutationVariables = {|
  input: SupermarketThemeEditInput
|};
export type SupermarketThemeEditMutationResponse = {|
  +SupermarketThemeEdit: ?{|
    +supermarket: ?{|
      +id: string,
      +theme: ?{|
        +themeId: ?string,
        +overrideColors: ?boolean,
        +colors: ?$ReadOnlyArray<?{|
          +name: ?string,
          +value: ?string,
        |}>,
      |},
    |}
  |}
|};
export type SupermarketThemeEditMutation = {|
  variables: SupermarketThemeEditMutationVariables,
  response: SupermarketThemeEditMutationResponse,
|};
*/


/*
mutation SupermarketThemeEditMutation(
  $input: SupermarketThemeEditInput!
) {
  SupermarketThemeEdit(input: $input) {
    supermarket {
      id
      theme {
        themeId
        overrideColors
        colors {
          name
          value
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "themeId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overrideColors",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "NameValue",
  "kind": "LinkedField",
  "name": "colors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SupermarketThemeEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SupermarketThemeEditPayload",
        "kind": "LinkedField",
        "name": "SupermarketThemeEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupermarketTheme",
                "kind": "LinkedField",
                "name": "theme",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SupermarketThemeEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SupermarketThemeEditPayload",
        "kind": "LinkedField",
        "name": "SupermarketThemeEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupermarketTheme",
                "kind": "LinkedField",
                "name": "theme",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38a00e098cf46a18ab41f2eb8578aa5f",
    "id": null,
    "metadata": {},
    "name": "SupermarketThemeEditMutation",
    "operationKind": "mutation",
    "text": "mutation SupermarketThemeEditMutation(\n  $input: SupermarketThemeEditInput!\n) {\n  SupermarketThemeEdit(input: $input) {\n    supermarket {\n      id\n      theme {\n        themeId\n        overrideColors\n        colors {\n          name\n          value\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd800e504bd5984815b3b3dd45f02c57';

module.exports = node;
