import React, { Component } from 'react'
import Panel from 'components/panel'
import { Grid, Row, Col, Thumbnail, Button } from 'react-bootstrap'
import Environment from 'relay'
import { graphql, QueryRenderer } from 'react-relay'
import _ from 'lodash'
import ProductGroupAddProductsMutation from 'relay/mutations/ProductGroupAddProductsMutation'
import ProductGroupRemoveProductMutation from 'relay/mutations/ProductGroupRemoveProductMutation'

class ProductGroupsProductList extends Component {
  isProductAdded (productGroup, productId) {
    return !!_.find(productGroup.products, { id: productId })
  }

  addProduct (productGroupId, productId) {
    ProductGroupAddProductsMutation.commit({
      productIds: [productId],
      id: productGroupId
    }, {
      onCompleted: ({ProductGroupAddProducts}) => console.log('success'),
      onError: errors => console.error(errors)
    })
  }

  removeProduct (productGroupId, productId) {
    ProductGroupRemoveProductMutation.commit({
      productId,
      id: productGroupId
    }, {
      onCompleted: ({ProductGroupRemoveProduct}) => console.log('success'),
      onError: errors => console.error(errors)
    })
  }

  render () {
    const { products, productGroup } = this.props.viewer
    return (
      <Panel>
        <Grid>
          <Row>
            {products.edges.map(e => e.node).map(p => (
              <Col xs={12} md={4} lg={3} key={p.id}>
                <Thumbnail src={p.image.url} alt={p.name}>
                  <p style={{textAlign: 'center'}}>{p.name}</p>
                  <p style={{textAlign: 'center'}}>
                    {!this.isProductAdded(productGroup, p.id) && (
                      <Button bsStyle='primary' onClick={() => this.addProduct(productGroup.id, p.id)}>
                        <i className='fa fa-check' />
                      </Button>
                    )}
                    {this.isProductAdded(productGroup, p.id) && (
                      <Button bsStyle='danger' onClick={() => this.removeProduct(productGroup.id, p.id)}>
                        <i className='fa fa-times' />
                      </Button>
                    )}
                  </p>
                </Thumbnail>
              </Col>
            ))}
          </Row>
        </Grid>
      </Panel>
    )
  }
}

const ProductGroupsProductListRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query productGroupsProductList_Query($search: String!, $productGroupId: ID!) {
        viewer {
          products(search: $search) {
            edges {
              node {
                id
                image {
                  url
                }
                name
              }
            }
          }
          productGroup(id: $productGroupId) {
            id
            products {
              id
            }
          }
        }
      }
    `}
    variables={{
      search: props.search,
      productGroupId: props.productGroupId
    }}
    render={({error, props: rendererProps}) => {
      if (error) {
        console.error(error)
      }

      if (rendererProps) {
        return (<ProductGroupsProductList {...rendererProps} {...props} />)
      }

      return null
    }}
  />
)

export default ProductGroupsProductListRenderer
