import { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

class DocumentTitle extends Component {
  update (text) {
    let title = []

    if (typeof text !== typeof undefined) {
      if (Array.isArray(text)) {
        title = title.concat(text)
      } else {
        title.push(text)
      }
    }

    title.push(this.props.host.name)

    document.title = title.join(' - ')
  }

  componentDidMount () {
    this.update(this.props.children)
  }

  UNSAFE_componentWillReceiveProps ({ children }) {
    this.update(children)
  }

  shouldComponentUpdate () {
    return false
  }

  render () {
    return null
  }
}

DocumentTitle.propTypes = {
  children: PropTypes.node
}

const mapStateToProps = (state) => ({
  host: state.app.host
})

export default connect(mapStateToProps)(DocumentTitle)
