import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import Panel from 'components/panel'
import { fetchPermissions } from 'security/authorization'
import createForm, { Form, Input, Buttons, toForm } from 'components/forms'
import { Row, Col } from 'react-bootstrap'
import Spinner from 'components/spinner'
import ConfigurationHelper from './configuration-helper'
import SupermarketSearchEditMutation from 'relay/mutations/SupermarketSearchEditMutation'

class SearchConfigurationFormComponent extends Component {
  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={!!loading} />

        <Panel>
          <Input name='googleTagManagerId' horizontal type='text' label='Google Tag Manager ID' />
          <hr className='hr-line-dashed' />
          <Input name='googleWebmastersId' horizontal type='text' label='Google Webmasters ID' />
          <hr className='hr-line-dashed' />
          <Input name='googleAnalyticsId' horizontal type='text' label='Google Analytics ID' />
          <hr className='hr-line-dashed' />
          <Input name='bingVerificationToken' horizontal type='text' label='Código de verificação do Bing' />

          <hr className='hr-line-dashed' />

          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
           />
        </Panel>
      </Form>
    )
  }
}

const SearchConfigurationForm = createForm(SearchConfigurationFormComponent)

class SearchConfigurationEdit extends Component {
  constructor (props) {
    super(props)

    this.state = {permissions: {}}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['configuration:search:edit'])
    this.setState({ permissions })
  }

  _onSubmit ({googleWebmastersId, googleAnalyticsId, bingVerificationToken, googleTagManagerId}) {
    SupermarketSearchEditMutation.commit({googleWebmastersId, googleAnalyticsId, bingVerificationToken, googleTagManagerId}, {
      onCompleted: ({SupermarketSearchEdit}) => this.props.notify('success', 'Sucesso', 'Configurações de Buscadores foram salvas'),
      onError: (errors) => this.props.notify('error', 'Erro', 'Não foi possível salvar suas alterações')
    })
  }

  render () {
    const { loading, viewer } = this.props
    return (
      <Content
        title='Buscadores'
        permission='configuration:search:edit'
        menu={{ group: 'Configurações', link: 'Buscadores' }}
        breadcrumb={{
          title: 'Buscadores',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/configuration/search', title: 'Buscadores'},
            {title: 'Alterar'}
          ]
        }}>
        <Row>
          <Col sm={7}>
            {!viewer ? (
              <SearchConfigurationForm loading={loading} />
            ) : (
              <SearchConfigurationForm
                {...this.props}
                loading={loading}
                initialValues={toForm(viewer.supermarket.search || {})}
                onSubmit={values => this._onSubmit(values)}
               />
            )}
          </Col>
          <Col sm={5}>
            <ConfigurationHelper type='search' />
          </Col>
        </Row>
      </Content>
    )
  }
}

const SearchConfigurationEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query configurationSearchEdit_Query {
        viewer {
          id
          supermarket {
            id
            search {
              googleWebmastersId
              googleAnalyticsId
              bingVerificationToken
              googleTagManagerId
            }
          }
        }
      }
    `}
    render={({error, props: rendererProps}) => {
      if (error) {
        console.error(error)
      }

      if (rendererProps) {
        return (
          <SearchConfigurationEdit
            {...rendererProps}
            {...props}
          />
        )
      }

      return null
    }}
  />
)

export default connect(null, {...appActionCreators})(SearchConfigurationEditRenderer)
