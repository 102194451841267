/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type configurationThemeEdit_QueryVariables = {||};
export type configurationThemeEdit_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +supermarket: ?{|
      +id: string,
      +theme: ?{|
        +themeId: ?string,
        +overrideColors: ?boolean,
        +colors: ?$ReadOnlyArray<?{|
          +name: ?string,
          +value: ?string,
        |}>,
      |},
    |},
  |}
|};
export type configurationThemeEdit_Query = {|
  variables: configurationThemeEdit_QueryVariables,
  response: configurationThemeEdit_QueryResponse,
|};
*/


/*
query configurationThemeEdit_Query {
  viewer {
    id
    supermarket {
      id
      theme {
        themeId
        overrideColors
        colors {
          name
          value
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "themeId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "overrideColors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "NameValue",
  "kind": "LinkedField",
  "name": "colors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "configurationThemeEdit_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupermarketTheme",
                "kind": "LinkedField",
                "name": "theme",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "configurationThemeEdit_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupermarketTheme",
                "kind": "LinkedField",
                "name": "theme",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b442f2cf7d573ff0afc806cceea1573b",
    "id": null,
    "metadata": {},
    "name": "configurationThemeEdit_Query",
    "operationKind": "query",
    "text": "query configurationThemeEdit_Query {\n  viewer {\n    id\n    supermarket {\n      id\n      theme {\n        themeId\n        overrideColors\n        colors {\n          name\n          value\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9d68c9ecd8a321fb413bf7ee0c686931';

module.exports = node;
