import React from 'react'

import DBlinksLogo from 'components/dblinks/logo'
import DBlinksInfo from 'components/dblinks/info'

import loginStyles from 'assets/less/login.less'

const Header = () => (
  <a className={loginStyles.header}
    alt={DBlinksInfo.nome}
    href={DBlinksInfo.site} target='_blank'>
    <DBlinksLogo />
  </a>
)

export default Header
