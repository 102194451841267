import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import BannerEditOrderMutation from 'relay/mutations/BannerEditOrderMutation'
import BannerEditActiveMutation from 'relay/mutations/BannerEditActiveMutation'
import BannerRemoveMutation from 'relay/mutations/BannerRemoveMutation'

import Content from 'components/templates/dashboard/content'
import DataList, { Actions, Action, Incrementer, Toggler } from 'components/datalist'
import ConfirmDialog from 'components/app/confirm-dialog'
import { routeTo } from 'router'
import { fetchPermissions } from 'security/authorization'
import { message } from 'utils/messages'
import { permission, title, route } from './utils'

class BannersList extends Component {
  state = {
    permissions: {}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions([
      permission(this.props.position, 'ADD'),
      permission(this.props.position, 'EDIT'),
      permission(this.props.position, 'REMOVE')
    ])

    this.setState({ permissions })
  }

  _editOrder (banner, order) {
    BannerEditOrderMutation.commit(
      Environment,
      {
        id: banner.id,
        order: order
      },
      this.props.viewer,
      {
        onCompleted: ({ BannerEditOrder }) => {
          if (BannerEditOrder.error) {
            this.props.actions.notify('error', 'Erro', message(BannerEditOrder.error))
          } else {
            this.props.actions.notify('success', 'Sucesso', 'Banner alterado com sucesso')
          }
        },
        onError: (transaction) => {
          this.props.actions.notify('error', 'Erro', message('SERVER_OFFLINE'))
        }
      }
    )
  }

  _editActive (banner, active) {
    BannerEditActiveMutation.commit(
      Environment,
      {
        id: banner.id,
        active: active
      },
      this.props.viewer,
      {
        onCompleted: ({ BannerEditActive }) => {
          if (BannerEditActive.error) {
            this.props.actions.notify('error', 'Erro', message(BannerEditActive.error))
          } else {
            this.props.actions.notify('success', 'Sucesso', 'Banner alterado com sucesso')
          }
        },
        onError: (transaction) => {
          this.props.actions.notify('error', 'Erro', message('SERVER_OFFLINE'))
        }
      }
    )
  }

  _remove (banner) {
    BannerRemoveMutation.commit(
      Environment,
      banner,
      this.props.viewer,
      {
        onCompleted: ({ BannerRemove }) => {
          if (BannerRemove.error) {
            this.props.actions.notify('error', 'Erro', message(BannerRemove.error))
          } else {
            this.props.actions.notify('success', 'Sucesso', 'Banner removido com sucesso')
          }
        },
        onError: (transaction) => {
          this.props.actions.notify('error', 'Erro', message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title={title(this.props.position)}
        permission={permission(this.props.position)}
        menu={{
          group: 'Banners',
          link: title(this.props.position)
        }}
        breadcrumb={{
          title: title(this.props.position),
          items: [
            { 'url': routeTo('dashboard'), 'title': 'Dashboard' },
            { 'url': routeTo(route(this.props.position)), 'title': title(this.props.position) },
            { 'title': 'Listar' }
          ],
          actions: this.state.permissions[permission(this.props.position, 'ADD')] && (
            <Link to={routeTo(route(this.props.position, 'ADD'))} className='btn btn-primary'>
              <i className='fa fa-plus' /> Adicionar
            </Link>
          )
        }}
      >
        <DataList
          name={`${this.props.position}BannersList`}
          environment={Environment}
          query={graphql`
            query bannersList_datalist_Query($first: Int!, $position: String, $search: String) {
              viewer {
                ...bannersList_datalist_viewer
              }
            }
          `}
          fragments={{
            viewer: graphql`
              fragment bannersList_datalist_viewer on Viewer {
                id
                banners(first: $first, position: $position, search: $search) @connection(key: "list_banners", filters: []) {
                  edges {
                    node {
                      id
                      title
                      order
                      active
                    }
                  }
                  pageInfo {
                    hasNextPage
                  }
                }
              }
            `
          }}
          variables={{
            position: this.props.position
          }}
          columns={[
            {
              name: 'Título',
              property: 'title'
            },
            {
              name: 'Ordem',
              callback: (banner) => (
                <Incrementer
                  value={banner.order}
                  onChange={(value) => this._editOrder(banner, value)}
                />
              )
            },
            {
              name: 'Status',
              callback: (banner) => (
                <Toggler
                  value={banner.active}
                  onChange={(value) => this._editActive(banner, value)}
                />
              )
            },
            {
              name: 'Ação',
              callback: (banner) => (
                <Actions>
                  {this.state.permissions[permission(this.props.position, 'EDIT')] && (
                    <Action title='Alterar'>
                      <Link to={routeTo(route(this.props.position, 'EDIT'), { id: banner.id })}>
                        <i className='fa fa-edit' />
                      </Link>
                    </Action>
                  )}
                  {this.state.permissions[permission(this.props.position, 'REMOVE')] && (
                    <Action title='Excluir'>
                      <ConfirmDialog
                        icon='fa fa-trash-o'
                        title={banner.name}
                        subtitle='Deseja remover este banner? Isso não poderá ser desfeito.'
                        onOk={() => this._remove(banner)}>
                        <i className='fa fa-trash-o' />
                      </ConfirmDialog>
                    </Action>
                  )}
                </Actions>
              )
            }
          ]}
        />
      </Content>
    )
  }
}

const BannersListRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query bannersList_viewer_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (
      <BannersList
        {...rendererProps}
        {...props}
      />
    )}
  />
)

export default function BannersListByPosition (position) {
  const BannersListByPosition = (props) => (
    <BannersListRenderer
      {...props}
      position={position}
    />
  )

  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(appActionCreators, dispatch)
  })

  return connect(null, mapDispatchToProps)(BannersListByPosition)
}
