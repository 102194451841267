import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import * as Bootstrap from 'react-bootstrap'
import _ from 'lodash'

import Group from './group'

const hrStyle = { marginBottom: '1px', marginTop: '6px' }

class CheckboxGroup extends Component {
  getValues (array) {
    return array.map(o => o[this.props.valueKey])
  }

  setSelectedOptions (selected) {
    this.props.input.onChange(this.getValues(selected))
  }

  getSelectedOptions () {
    const { input, options, valueKey } = this.props
    if (!input.value) {
      return []
    }

    return options.filter(o => _.includes(input.value, o[valueKey]))
  }

  selectAll (isSelect) {
    const { options } = this.props

    if (isSelect) {
      this.setSelectedOptions(options)
    } else {
      this.setSelectedOptions([])
    }
  }

  allOptionsSelected () {
    return this.getSelectedOptions().length === this.props.options.length
  }

  onChange (option, checked) {
    const selection = this.getSelectedOptions()

    let newSelection
    if (checked) {
      newSelection = [...selection, option]
    } else {
      newSelection = selection
        .filter(o => o[this.props.valueKey] !== option[this.props.valueKey])
    }

    this.setSelectedOptions(newSelection)
  }

  isSelected (option) {
    return this.getSelectedOptions().filter(o => o[this.props.valueKey] === option[this.props.valueKey]).length > 0
  }

  render () {
    const { options } = this.props

    if (options.length === 0) {
      return (
        <Group {...this.props}>
          <Bootstrap.FormControl.Static>
            Nenhum registro encontrado
          </Bootstrap.FormControl.Static>
        </Group>
      )
    }

    return (
      <Group {...this.props}>
        {this.props.enableSelectAll && (
          <Bootstrap.Checkbox
            inline={this.props.inline}
            checked={this.allOptionsSelected()}
            onChange={(event) => this.selectAll(event.target.checked)}>
            {this.allOptionsSelected() ? 'Desmarcar' : 'Marcar'} todas
          </Bootstrap.Checkbox>
        )}
        {this.props.enableSelectAll && (
          <hr style={hrStyle} />
        )}

        {options.map((option, index) => (
          <Bootstrap.Checkbox
            key={index}
            inline={this.props.inline}
            checked={this.isSelected(option)}
            onChange={(event) => this.onChange(option, event.target.checked)}>
            {option[this.props.labelKey]}
          </Bootstrap.Checkbox>
        ))}
      </Group>
    )
  }
}

const CheckboxGroupField = (props) => {
  return (
    <Field
      {...props}
      component={CheckboxGroup}
    />
  )
}

CheckboxGroupField.propTypes = {
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  inline: PropTypes.bool,
  options: PropTypes.array
}

CheckboxGroupField.defaultProps = {
  valueKey: 'id',
  labelKey: 'name',
  inline: false
}

export default CheckboxGroupField
