import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'react-relay'
import Environment from 'relay'

import Panel from 'components/panel'
import Spinner from 'components/spinner'
import createForm, { Form, File, Input, Select, DynamicSelect, Wysiwyg, Buttons, Datepicker } from 'components/forms'
import Validation from 'utils/validation'
import { activeOptions } from 'utils/constants'

class RecipesForm extends Component {
  state = {
    firstMount: true,
    state: null
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.state.firstMount && nextProps.initialValues) {
      this.setState({
        firstMount: false
      })

      this._changeState(nextProps.initialValues.state)
    }
  }

  _changeState (state) {
    const stateChanged = this.state.state && this.state.state !== state.id

    this.setState({
      state: state ? state.id : null
    })

    if (stateChanged) {
      this.props.change('city', '')
    }
  }

  render () {
    const { handleSubmit, pristine, submitting, valid, reset } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={this.props.loading} />

        <Panel>
          <File name='image' horizontal label='Imagem' />

          <hr className='hr-line-dashed' />
          <File name='advertisingImage' horizontal label='Banner publicitário' />

          <hr className='hr-line-dashed' />
          <Input name='title' horizontal type='text' label='Título' />

          <hr className='hr-line-dashed' />
          <Input name='preparationTime' horizontal type='text' label='Tempo de preparo (em minutos)' />

          <hr className='hr-line-dashed' />
          <Input name='yield' horizontal type='text' label='Rendimento (em porções)' />

          <hr className='hr-line-dashed' />
          <Wysiwyg name='ingredients' horizontal label='Lista de ingredientes' />

          <hr className='hr-line-dashed' />
          <Wysiwyg name='instructions' horizontal label='Instruções de preparo' />

          <hr className='hr-line-dashed' />
          <Datepicker name='date' horizontal type='date' label='Data' />

          <hr className='hr-line-dashed' />
          <Select
            name='active'
            horizontal
            label='Status'
            options={activeOptions}
          />
        </Panel>

        <Panel>
          <Input name='author' horizontal type='text' label='Enviada por' />

          <hr className='hr-line-dashed' />
          <DynamicSelect
            name='state'
            horizontal
            label='Estado'
            environment={Environment}
            query={graphql`
              query recipesForm_states_Query($first: Int!, $search: String) {
                viewer {
                  ...recipesForm_states_viewer
                }
              }
            `}
            fragments={{
              viewer: graphql`
                fragment recipesForm_states_viewer on Viewer {
                  id
                  states(first: $first, search: $search) @connection(key: "list_states") {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              `
            }}
            onChange={(value) => this._changeState(value)}
          />

          <hr className='hr-line-dashed' />
          <DynamicSelect
            name='city'
            horizontal
            label={'Cidade'}
            placeholder={!this.state.state ? 'Selecione um estado' : 'Selecione'}
            disabled={!this.state.state}
            environment={Environment}
            query={graphql`
              query recipesForm_cities_Query($first: Int!, $state: ID!, $search: String) {
                viewer {
                  ...recipesForm_cities_viewer
                }
              }
            `}
            fragments={{
              viewer: graphql`
                fragment recipesForm_cities_viewer on Viewer {
                  id
                  cities(first: $first, state: $state, search: $search) @connection(key: "list_cities") {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              `
            }}
            variables={{
              state: this.state.state
            }}
          />
        </Panel>

        <Panel>
          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
          />
        </Panel>
      </Form>
    )
  }
}

RecipesForm.defaultProps = {
  loading: false
}

RecipesForm.propTypes = {
  loading: PropTypes.bool
}

const RecipesValidation = new Validation({
  image: [Validation.required],
  title: [Validation.required],
  status: [Validation.required]
})

export default createForm(RecipesForm, RecipesValidation)
