import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation OfferCatalogEditOrderMutation($input: OfferCatalogEditOrderInput!) {
    OfferCatalogEditOrder(input: $input) {
      offerCatalog {
        id
        order
        isFirst
        isLast
      }
    }
  }
`

function getConfigs (values) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      offerCatalog: values.id
    }
  }]
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: values
      },
      configs: getConfigs(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
