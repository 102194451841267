import React, { Component } from 'react'
import { reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { Form, MoneyInput, Buttons, Select, toMutation } from 'components/forms'
import Validation from 'utils/validation'
import { Modal } from 'react-bootstrap'
import { affirmativeOptions } from 'utils/constants'
import ProductSetPriceMutation from 'relay/mutations/ProductSetPriceMutation'

class SetProductPriceModal extends Component {
  constructor (props) {
    super(props)
    this.state = {visible: false}
  }

  _close () {
    this.setState({visible: false})
  }

  _onTriggerClick () {
    const {reset} = this.props
    reset()
    this.setState({visible: true})
  }

  _onSubmit (values) {
    const { product: { id: productId } } = this.props
    const { company: { id: companyId } } = values

    const mutationVariables = {
      ...toMutation(values),
      product: productId,
      company: companyId
    }

    ProductSetPriceMutation.commit(mutationVariables, {
      onCompleted: ({ProductSetPrice}) => { this._close() },
      onError: (errors) => {}
    })
  }

  render () {
    const { handleSubmit, pristine, submitting, valid, reset } = this.props

    return (
      <div>
        <a onClick={() => this._onTriggerClick()}>
          <i className='fa fa-edit' />
        </a>

        <Modal
          show={this.state.visible}
          onHide={() => { this._close() }}
          dialogClassName='inmodal'>
          <Modal.Header closeButton>
            <Modal.Title>Definir Preços</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form horizontal onSubmit={handleSubmit(this._onSubmit.bind(this))}>
              <MoneyInput name='price' horizontal type='number' label='Preço' />

              <Select
                name='promotion'
                horizontal
                label='Promoção'
                options={affirmativeOptions}
                clearable={false}
                searchable={false}
              />

              <MoneyInput name='promotionalPrice' horizontal type='number' label='Preço promocional' />

              <Buttons
                horizontal
                pristine={pristine}
                submitting={submitting}
                valid={valid}
                reset={reset}
               />
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const validate = new Validation({
  price: [Validation.required],
  promotion: [Validation.required]
})

export default function (formName) {
  const selector = formValueSelector(formName)

  return connect(
    (state, props) => ({showPromotionalPrice: !!selector(state, 'promotion')})
  )(
    reduxForm({
      enableReinitialize: true,
      form: formName,
      validate
    })(SetProductPriceModal)
  )
}
