import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import CompanyRemoveMutation from 'relay/mutations/CompanyRemoveMutation'
import CompanyToggleSelectableInMenuMutation from 'relay/mutations/CompanyToggleSelectableInMenuMutation'

import Content from 'components/templates/dashboard/content'
import DataList, { Action, Actions, Toggler } from 'components/datalist'
import ConfirmDialog from 'components/app/confirm-dialog'
import { routeTo } from 'router'
import { fetchPermissions } from 'security/authorization'
import { getMessagesFromErrors, message } from 'utils/messages'

class CompaniesList extends Component {
  state = {
    permissions: {}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions([
      'companies:add', 'companies:edit', 'companies:remove'
    ])

    this.setState({ permissions })
  }

  _remove (company) {
    CompanyRemoveMutation.commit(
      Environment,
      company,
      this.props.viewer,
      {
        onCompleted: ({ CompanyRemove }) => {
          if (CompanyRemove.error) {
            this.props.actions.notify('error', 'Erro', message(CompanyRemove.error))
          } else {
            this.props.actions.notify('success', 'Sucesso', 'Loja removida com sucesso')
          }
        },
        onError: (errors) => {
          getMessagesFromErrors(errors).forEach(m => (
            this.props.actions.notify('error', 'Erro', m)
          ))
        }
      }
    )
  }

  _toggleSelectableInMenu (company) {
    CompanyToggleSelectableInMenuMutation.commit(company, {
      onCompleted: ({ CompanyToggleSelectableInMenu }) => { },
      onError: (errors) => {
        getMessagesFromErrors(errors).forEach(m => (
          this.props.actions.notify('error', 'Erro', m)
        ))
      }
    })
  }

  render () {
    return (
      <Content
        title='Lojas'
        permission='companies:list'
        menu={{ link: 'Lojas' }}
        breadcrumb={{
          title: 'Lojas',
          items: [
            { 'url': '/dashboard', 'title': 'Dashboard' },
            { 'url': '/dashboard/companies', 'title': 'Lojas' },
            { 'title': 'Listar' }
          ],
          actions: this.state.permissions['companies:add'] && (
            <Link to={routeTo('dashboard.companies.add')} className='btn btn-primary'>
              <i className='fa fa-plus'/> Adicionar
            </Link>
          )
        }}
      >
        <DataList
          name='CompaniesList'
          environment={Environment}
          query={graphql`
            query companiesList_datalist_Query($first: Int!, $search: String) {
              viewer {
                ...companiesList_datalist_viewer
              }
            }
          `}
          fragments={{
            viewer: graphql`
              fragment companiesList_datalist_viewer on Viewer {
                id
                companies(first: $first, search: $search) @connection(key: "list_companies", filters: []) {
                  edges {
                    node {
                      id
                      name
                      selectableInMenu
                    }
                  }
                  pageInfo {
                    hasNextPage
                  }
                }
              }
            `
          }}
          columns={[
            {
              name: 'Nome',
              property: 'name'
            },
            {
              name: 'Menu',
              callback: (company) => (
                this.state.permissions['companies:edit'] && (
                  <Toggler
                    value={company.selectableInMenu}
                    labels={{ 'false': 'Não', 'true': 'Sim' }}
                    onChange={(value) => this._toggleSelectableInMenu(company)}
                  />
                )
              )
            },
            {
              name: 'Ação',
              callback: (company) => (
                <Actions>
                  {this.state.permissions['companies:edit'] && (
                    <Action title='Alterar'>
                      <Link to={routeTo('dashboard.companies.edit', { id: company.id })}>
                        <i className='fa fa-edit'/>
                      </Link>
                    </Action>
                  )}
                  {this.state.permissions['companies:remove'] && (
                    <Action title='Excluir'>
                      <ConfirmDialog
                        icon='fa fa-trash-o'
                        title={company.name}
                        subtitle='Deseja remover esta loja? Isso não poderá ser desfeito.'
                        onOk={() => this._remove(company)}>
                        <i className='fa fa-trash-o'/>
                      </ConfirmDialog>
                    </Action>
                  )}
                </Actions>
              )
            }
          ]}
        />
      </Content>
    )
  }
}

const CompaniesListRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query companiesList_viewer_Query {
        viewer {
          id
        }
      }
    `}
    render={({ props: rendererProps }) => {
      if (!rendererProps || !rendererProps.viewer) return null
      return (
        <CompaniesList
          {...rendererProps}
          {...props}
        />
      )
    }}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(CompaniesListRenderer)
