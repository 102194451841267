import React from 'react'

import Helper from 'components/templates/dashboard/helper'

const LeadsHelper = () => (
  <Helper>
    <h3>Instruções</h3>
    <p>
      Aqui você pode alterar e exportar para arquivo CSV os <code>Leads</code> que o site capturou. Leads são contatos de pessoas potenciais para se tornarem clientes do supermercado.
      <br />
      <br />
      <h4>Dicas:</h4>
      <ul>
        <li>Lembre-se que é um passo muito grande alguém solicitar para receber novidades e ofertas periodicamente do seu supermercado.</li>
        <li>Monte uma boa estratégia de marketing de relacionamento com essas pessoas para vender mais.</li>
        <li>Caso alguém solicite ser removido faça-o imediatamente, futuramente ele pode mudar de opnião, mas no momento é importante respeitar a decisão da pessoa.</li>
      </ul>
    </p>
  </Helper>
)

export default LeadsHelper
