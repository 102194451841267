/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupermarketEditInput = {|
  image?: ?FileInput,
  favicon?: ?FileInput,
  websiteTitle?: ?string,
  websiteDescription?: ?string,
  keywords?: ?string,
  customerAppToken?: ?string,
  productApiToken?: ?string,
  clientMutationId?: ?string,
|};
export type FileInput = {|
  key?: ?string,
  url?: ?string,
  name?: ?string,
  size?: ?number,
  type?: ?string,
|};
export type SupermarketEditMutationVariables = {|
  input: SupermarketEditInput
|};
export type SupermarketEditMutationResponse = {|
  +SupermarketEdit: ?{|
    +supermarket: ?{|
      +id: string,
      +image: ?{|
        +url: ?string
      |},
      +favicon: ?{|
        +name: ?string,
        +url: ?string,
      |},
      +websiteTitle: ?string,
      +websiteDescription: ?string,
      +keywords: ?string,
      +customerAppToken: ?string,
      +productApiToken: ?string,
    |}
  |}
|};
export type SupermarketEditMutation = {|
  variables: SupermarketEditMutationVariables,
  response: SupermarketEditMutationResponse,
|};
*/


/*
mutation SupermarketEditMutation(
  $input: SupermarketEditInput!
) {
  SupermarketEdit(input: $input) {
    supermarket {
      id
      image {
        url
        id
      }
      favicon {
        name
        url
        id
      }
      websiteTitle
      websiteDescription
      keywords
      customerAppToken
      productApiToken
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "websiteTitle",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "websiteDescription",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "keywords",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customerAppToken",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productApiToken",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SupermarketEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SupermarketEditPayload",
        "kind": "LinkedField",
        "name": "SupermarketEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "favicon",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SupermarketEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SupermarketEditPayload",
        "kind": "LinkedField",
        "name": "SupermarketEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "favicon",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "383a6d8aa77b407524abae3b2fa53a57",
    "id": null,
    "metadata": {},
    "name": "SupermarketEditMutation",
    "operationKind": "mutation",
    "text": "mutation SupermarketEditMutation(\n  $input: SupermarketEditInput!\n) {\n  SupermarketEdit(input: $input) {\n    supermarket {\n      id\n      image {\n        url\n        id\n      }\n      favicon {\n        name\n        url\n        id\n      }\n      websiteTitle\n      websiteDescription\n      keywords\n      customerAppToken\n      productApiToken\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '16614ea5ae99c7e237d0572ee1ee3393';

module.exports = node;
