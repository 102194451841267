import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Bootstrap from 'react-bootstrap'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { createRefetchContainer, QueryRenderer } from 'react-relay'
import { createArray, getPageInfo, hasMore } from 'relay'

import InfiniteScroll from 'utils/infinite-scroll'
import Search from './search'
import Table from './table'

import styles from './datalist.less'

class DataList extends Component {
  state = {
    loading: false,
    variables: this.props.variables,
    count: this.props.variables.first
  }

  _loadMore () {
    const first = this.state.variables.first + this.state.count

    this.setState({
      loading: true,
      variables: {
        ...this.state.variables,
        first: first
      }
    })

    this.props.relay.refetch(
      this.state.variables,
      null,
      error => {
        this.setState({
          loading: false
        })

        if (error != null) {
          this.props.actions.notify('error', 'Erro', 'Não foi possível carregar mais resultados')
        }
      }
    )
  }

  _refresh () {
    this.setState({
      ...this.state,
      loading: true
    })

    this.props.relay.refetch(
      this.state.variables,
      null,
      error => {
        this.setState({
          loading: false
        })

        if (error != null) {
          this.props.actions.notify('error', 'Erro', 'Não foi possível atualizar a listagem')
        }
      }
    )
  }

  _search (search) {
    this.setState({
      loading: true,
      variables: {
        ...this.state.variables,
        search: search
      }
    })

    this.props.relay.refetch(
      this.state.variables,
      null,
      error => {
        this.setState({
          loading: false
        })

        if (error != null) {
          this.props.actions.notify('error', 'Erro', 'Não foi possível realizar a pesquisa')
        }
      }
    )
  }

  _onScroll () {
    if (hasMore(this.props.viewer) && !this.state.loading) {
      this._loadMore()
    }
  }

  render () {
    return (
      <InfiniteScroll onScroll={() => this._onScroll()}>
        <div className={styles.wrapper}>
          {this.props.showSearch && (
            <Search
              callback={(value) => this._search(value)}
              value={this.state.variables.search}
              filters={this.props.filters}
              disabled={this.state.loading}
            />
          )}

          <Table
            columns={this.props.columns}
            data={createArray(this.props.viewer)}
            info={{ pageInfo: getPageInfo(this.props.viewer) }}
            refreshCallback={() => this._refresh()}
          />

          {hasMore(this.props.viewer) && (
            <div className={styles.moreWrapper}>
              <Bootstrap.Button
                onClick={() => this._loadMore()}
                disabled={this.state.loading}
              >
                {this.state.loading ? 'Carregando...' : 'Carregar mais resultados'}
              </Bootstrap.Button>
            </div>
          )}
        </div>
      </InfiniteScroll>
    )
  }
}

const DataListQueryRenderer = (props) => {
  const DataListRefetchContainer = createRefetchContainer(
    DataList,
    props.fragments,
    props.query
  )

  if (!props.variables.first) {
    props.variables.first = 20
  }

  return (
    <QueryRenderer
      viewer={null}
      environment={props.environment}
      query={props.query}
      variables={props.variables}
      render={({ props: rendererProps }) => (
        <DataListRefetchContainer
          name={props.name}
          columns={props.columns}
          variables={props.variables}
          showSearch={props.showSearch}
          filters={props.filters}
          viewer={null}
          {...rendererProps}
        />
      )}
    />
  )
}

DataListQueryRenderer.defaultProps = {
  variables: {},
  showSearch: true,
  fragments: null,
  viewer: null
}

DataListQueryRenderer.propTypes = {
  name: PropTypes.string.isRequired,
  environment: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  fragments: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  variables: PropTypes.shape({
    first: PropTypes.number,
    search: PropTypes.string
  }),
  showSearch: PropTypes.bool,
  filters: PropTypes.array,
  viewer: PropTypes.any
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(DataListQueryRenderer)
