import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation LeadEditMutation($input: LeadEditInput!) {
    LeadEdit(input: $input) {
      lead {
        id
        name
        email
      }
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      lead: viewer.lead.id
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    LeadEdit: {
      lead: {
        name: values.name,
        email: values.email,
        phone: values.phone,
        whatsapp: values.whatsapp,
        company: values.company,
        id: values.id
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: values.id,
          name: values.name,
          email: values.email,
          phone: values.phone,
          whatsapp: values.whatsapp,
          company: values.company.id
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
