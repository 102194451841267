import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import RecipeRemoveMutation from 'relay/mutations/RecipeRemoveMutation'

import Content from 'components/templates/dashboard/content'
import DataList, { Actions, Action } from 'components/datalist'
import ConfirmDialog from 'components/app/confirm-dialog'
import { routeTo } from 'router'
import { fetchPermissions } from 'security/authorization'
import { message } from 'utils/messages'

class RecipesList extends Component {
  state = {
    permissions: {}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions([
      'recipes:add', 'recipes:edit', 'recipes:remove'
    ])

    this.setState({ permissions })
  }

  _remove (recipe) {
    RecipeRemoveMutation.commit(
      Environment,
      recipe,
      this.props.viewer,
      {
        onCompleted: ({ RecipeRemove }) => {
          if (RecipeRemove.error) {
            this.props.actions.notify('error', 'Erro', message(RecipeRemove.error))
          } else {
            this.props.actions.notify('success', 'Sucesso', 'Receita removida com sucesso')
          }
        },
        onError: (transaction) => {
          this.props.actions.notify('error', 'Erro', message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title='Receitas'
        permission='recipes:list'
        menu={{
          link: 'Receitas'
        }}
        breadcrumb={{
          title: 'Receitas',
          items: [
            { 'url': '/dashboard', 'title': 'Dashboard' },
            { 'url': '/dashboard/recipes', 'title': 'Receitas' },
            { 'title': 'Listar' }
          ],
          actions: this.state.permissions['recipes:add'] && (
            <Link to={routeTo('dashboard.recipes.add')} className='btn btn-primary'>
              <i className='fa fa-plus' /> Adicionar
            </Link>
          )
        }}
      >
        <DataList
          name='RecipesList'
          environment={Environment}
          query={graphql`
            query recipesList_datalist_Query($first: Int!, $search: String) {
              viewer {
                ...recipesList_datalist_viewer
              }
            }
          `}
          fragments={{
            viewer: graphql`
              fragment recipesList_datalist_viewer on Viewer {
                id
                recipes(first: $first, search: $search) @connection(key: "list_recipes", filters: []) {
                  edges {
                    node {
                      id
                      title
                    }
                  }
                  pageInfo {
                    hasNextPage
                  }
                }
              }
            `
          }}
          columns={[
            {
              name: 'Título',
              property: 'title'
            },
            {
              name: 'Ação',
              callback: (recipe) => (
                <Actions>
                  {this.state.permissions['recipes:edit'] && (
                    <Action title='Alterar'>
                      <Link to={routeTo('dashboard.recipes.edit', { id: recipe.id })}>
                        <i className='fa fa-edit' />
                      </Link>
                    </Action>
                  )}
                  {this.state.permissions['recipes:remove'] && (
                    <Action title='Excluir'>
                      <ConfirmDialog
                        icon='fa fa-trash-o'
                        title={recipe.name}
                        subtitle='Deseja remover esta receita? Isso não poderá ser desfeito.'
                        onOk={() => this._remove(recipe)}>
                        <i className='fa fa-trash-o' />
                      </ConfirmDialog>
                    </Action>
                  )}
                </Actions>
              )
            }
          ]}
        />
      </Content>
    )
  }
}

const RecipesListRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query recipesList_viewer_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (
      <RecipesList
        {...rendererProps}
        {...props}
      />
    )}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(RecipesListRenderer)
