import React, { Component } from 'react'

import styles from './styles.less'

export default class extends Component {
  constructor (props) {
    super(props)

    this.state = {visible: props.visible || false}
  }

  toggleContent () {
    this.setState({visible: !this.state.visible})
  }

  render () {
    const { title, children } = this.props
    return (
      <div className={`ibox ${styles['section-collapse']}`}>
        <div className='ibox-title'>
          <h3 className={`${styles['toggler-title']} `} onClick={() => this.toggleContent()}>
            <i className={`fa ${this.state.visible ? 'fa-angle-down' : 'fa-angle-up'}`} /> {title}
          </h3>
        </div>

        {this.state.visible && (
          <div className={`${styles['toggler-content']}`}>
            {children}
          </div>
        )}
      </div>
    )
  }
}
