import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import ProductEditMutation from 'relay/mutations/ProductEditMutation'
import Content from 'components/templates/dashboard/content'
import { toForm, toMutation } from 'components/forms'
import { getMessagesFromErrors } from 'utils/messages'
import { routeTo } from 'router'
import ProductsForm from './products-form'
import ProductsHelper from './products-helper'

class ProductsEdit extends Component {
  constructor (props) {
    super(props)

    this.state = {loading: false}
  }

  _loading (loading) {
    this.setState({loading})
  }

  _success () {
    this._loading(false)

    this.props.notify('success', 'Sucesso', 'Produto alterado com sucesso')
    this.props.history.push(routeTo('dashboard.products'))
  }

  _error (errors) {
    this._loading(false)

    getMessagesFromErrors(errors)
      .forEach(m => this.props.notify('error', 'Erro', m))
  }

  _onSubmit (values) {
    this._loading(true)

    ProductEditMutation.commit(toMutation(values), {
      onCompleted: ({ProductEdit}) => this._success(),
      onError: (errors) => this._error(errors)
    })
  }

  render () {
    const { viewer } = this.props
    return (
      <Content
        title='Alterar Produto'
        permission='products:edit'
        menu={{link: 'Produtos'}}
        breadcrumb={{
          title: 'Produtos',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/products', title: 'Produtos'},
            {title: 'Alterar'}
          ]
        }}>
        <Row>
          <Col sm={7}>
            {!viewer ? (
              <ProductsForm loading />
            ) : (
              <ProductsForm
                loading={this.state.loading}
                initialValues={toForm(viewer.product)}
                companies={viewer.companies}
                product={viewer.product}
                editing
                onSubmit={values => this._onSubmit(values)}
              />
            )}
          </Col>
          <Col sm={5}>
            <ProductsHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const ProductsEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query productsEdit_Query($id: ID!) {
        viewer {
          product(id: $id) {
            id
            name
            image {
              url
            }
            gtin
            size
            unitOfMeasure
            saleUnit
            hasVariableWeight
            additionalInfo
            stamps
            active
            prices {
              company {
                id
                name
              }
              price
              promotion
              promotionalPrice
            }
          }
          companies {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `}
    variables={{id: props.match.params.id}}
    render={({props: rendererProps}) => (<ProductsEdit {...rendererProps} {...props} />)}
  />
)

export default connect(null, appActionCreators)(ProductsEditRenderer)
