import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import { fetchPermissions } from 'security/authorization'
import { toForm } from 'components/forms'
import FidelityCardGroupForm from './fidelity-card-group-form'
import FidelityCardEditGroupMutation from 'relay/mutations/FidelityCardEditGroupMutation'

class FidelityCardGroupEdit extends Component {
  constructor (props) {
    super(props)

    this.state = {permissions: {}}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['fidelity-card:edit'])
    this.setState({ permissions })
  }

  _getFormValues () {
    const {id: groupId} = this.props.match.params
    const {fieldGroups} = this.props.viewer.fidelityCardForm
    const group = fieldGroups.filter(g => g.id === groupId)[0]

    return toForm(group)
  }

  _onSubmit (values) {
    const {id, name} = values
    FidelityCardEditGroupMutation.commit(
      {id, name},
      {
        onCompleted: ({FidelityCardEditGroup}) => {},
        onError: (errors) => {}
      }
    )
  }

  render () {
    const { loading, viewer } = this.props
    return (
      <Content
        title='Cartão Fidelidade'
        permission='fidelity-card:edit'
        menu={{link: 'Cartão Fidelidade'}}
        breadcrumb={{
          title: 'Cartão Fidelidade / Grupo de Campos',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/fidelity-card', title: 'Cartão Fidelidade'},
            {title: 'Grupos de Campos'},
            {title: 'Alterar'}
          ]
        }}>

        {!viewer ? (
          <FidelityCardGroupForm
            loading={loading}
           />
        ) : (
          <FidelityCardGroupForm
            {...this.props}
            loading={loading}
            onSubmit={values => this._onSubmit(values)}
            initialValues={this._getFormValues()}
            editing
           />
        )}
      </Content>
    )
  }
}

const FidelityCardGroupEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query fidelityCardGroupEdit_viewer_Query {
        viewer {
          id
          fidelityCardForm {
            id
            title
            text
            bannerText
            buttonText
            fieldGroups {
              id
              name
              fields {
                id
                name
                description
                type
                required
                options
              }
            }
          }
        }
      }
    `}
    render={({props: rendererProps}) => (
      <FidelityCardGroupEdit
        {...rendererProps}
        {...props}
        loading={!rendererProps}
      />
    )}
  />
)

export default connect(null, {...appActionCreators})(FidelityCardGroupEditRenderer)
