import Thumbor from 'thumbor'

export function imageResizer (url, width, height) {
  const thumbor = new Thumbor('pUUC7rhgjGsR5EDG', 'https://d21wiczbqxib04.cloudfront.net')
  return thumbor.setImagePath(url)
    .smartCrop(true)
    .resize(width, height)
    .buildUrl()
}

export function getApiRoot () {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:5000'
  }

  const hostname = window.location.hostname
  const prefix = 'admin.'
  if (hostname.indexOf(prefix) < 0) {
    throw new Error(`Not possible to determine the backend url for ${hostname}`)
  }

  const domain = hostname.substr(prefix.length)

  return `https://admin-api.${domain}`
}

export function getImageUploadUrl () {
  return `${getApiRoot()}/upload-image`
}

export function getGraphQLUrl () {
  return `${getApiRoot()}/graphql`
}
