import { ConnectionHandler } from 'relay-runtime'

import Environment from './environment'

export default Environment

export function removeUpdater (store, viewer, connection, deletedID) {
  const viewerProxy = store.get(viewer.id)

  const conn = ConnectionHandler.getConnection(
    viewerProxy,
    connection
  )

  ConnectionHandler.deleteNode(
    conn,
    deletedID
  )
}

export function getGenericFragment (viewer) {
  return viewer[Object.keys(viewer).slice(-1)]
}

export function hasMore (viewer) {
  return viewer !== null && getGenericFragment(viewer).pageInfo.hasNextPage
}

export function createArray (viewer, usesConnection = true) {
  if (viewer === null) {
    return []
  }

  const list = getGenericFragment(viewer)

  if (usesConnection) {
    return list.edges.map(info => info.node)
  } else {
    // need to recreate the list once the default list is blocked for changes
    return list.map(i => i)
  }
}

export function getPageInfo (viewer) {
  if (viewer === null) {
    return null
  }

  return getGenericFragment(viewer).pageInfo
}
