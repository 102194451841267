/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OrderDirection = "DOWN" | "UP" | "%future added value";
export type OfferCatalogEditOrderInput = {|
  id: string,
  direction: OrderDirection,
  clientMutationId?: ?string,
|};
export type OfferCatalogEditOrderMutationVariables = {|
  input: OfferCatalogEditOrderInput
|};
export type OfferCatalogEditOrderMutationResponse = {|
  +OfferCatalogEditOrder: ?{|
    +offerCatalog: ?{|
      +id: string,
      +order: ?number,
      +isFirst: ?boolean,
      +isLast: ?boolean,
    |}
  |}
|};
export type OfferCatalogEditOrderMutation = {|
  variables: OfferCatalogEditOrderMutationVariables,
  response: OfferCatalogEditOrderMutationResponse,
|};
*/


/*
mutation OfferCatalogEditOrderMutation(
  $input: OfferCatalogEditOrderInput!
) {
  OfferCatalogEditOrder(input: $input) {
    offerCatalog {
      id
      order
      isFirst
      isLast
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OfferCatalogEditOrderPayload",
    "kind": "LinkedField",
    "name": "OfferCatalogEditOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OfferCatalog",
        "kind": "LinkedField",
        "name": "offerCatalog",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "order",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isFirst",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isLast",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OfferCatalogEditOrderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OfferCatalogEditOrderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c4bb6c23d129ebd4d9495a3cc78b269d",
    "id": null,
    "metadata": {},
    "name": "OfferCatalogEditOrderMutation",
    "operationKind": "mutation",
    "text": "mutation OfferCatalogEditOrderMutation(\n  $input: OfferCatalogEditOrderInput!\n) {\n  OfferCatalogEditOrder(input: $input) {\n    offerCatalog {\n      id\n      order\n      isFirst\n      isLast\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '89827450b371f69b404b7b51453d8d36';

module.exports = node;
