/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LeadEditInput = {|
  id: string,
  name?: ?string,
  email: string,
  phone?: ?string,
  whatsapp?: ?string,
  company: string,
  clientMutationId?: ?string,
|};
export type LeadEditMutationVariables = {|
  input: LeadEditInput
|};
export type LeadEditMutationResponse = {|
  +LeadEdit: ?{|
    +lead: ?{|
      +id: string,
      +name: ?string,
      +email: ?string,
    |}
  |}
|};
export type LeadEditMutation = {|
  variables: LeadEditMutationVariables,
  response: LeadEditMutationResponse,
|};
*/


/*
mutation LeadEditMutation(
  $input: LeadEditInput!
) {
  LeadEdit(input: $input) {
    lead {
      id
      name
      email
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LeadEditPayload",
    "kind": "LinkedField",
    "name": "LeadEdit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lead",
        "kind": "LinkedField",
        "name": "lead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LeadEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LeadEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "65720bcf45ddb5e9c94d451daabc89f9",
    "id": null,
    "metadata": {},
    "name": "LeadEditMutation",
    "operationKind": "mutation",
    "text": "mutation LeadEditMutation(\n  $input: LeadEditInput!\n) {\n  LeadEdit(input: $input) {\n    lead {\n      id\n      name\n      email\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '50753210b122f0a6b8b6f412352dc438';

module.exports = node;
