import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import CompanyAddMutation from 'relay/mutations/CompanyAddMutation'

import Content from 'components/templates/dashboard/content'
import { toMutation } from 'components/forms'
import { message } from 'utils/messages'
import { routeTo } from 'router'
import CompaniesForm from './companies-form'
import CompaniesHelper from './companies-helper'

class CompaniesAdd extends Component {
  state = {
    loading: false
  }

  _loading (loading) {
    this.setState({
      loading: loading
    })
  }

  _success () {
    this.props.actions.notify('success', 'Sucesso', 'Loja adicionada com sucesso')
    this.props.history.push(routeTo('dashboard.companies'))
  }

  _error (message) {
    this.props.actions.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    CompanyAddMutation.commit(
      Environment,
      toMutation(values),
      this.props.viewer,
      {
        onCompleted: ({ CompanyAdd }) => {
          this._loading(false)

          if (CompanyAdd.error) {
            this._error(message(CompanyAdd.error))
          } else {
            this._success()
          }
        },
        onError: (transaction) => {
          this._loading(false)

          this._error(message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title='Adicionar loja'
        permission='companies:add'
        menu={{ link: 'Lojas' }}
        breadcrumb={{
          title: 'Lojas',
          items: [
            { 'url': '/dashboard', 'title': 'Dashboard' },
            { 'url': '/dashboard/companies', 'title': 'Lojas' },
            { 'title': 'Adicionar' }
          ]
        }}
      >
        <Row>
          <Col sm={7}>
            <CompaniesForm
              loading={this.state.loading}
              onSubmit={(values) => this._onSubmit(values)}
              initialValues={{active: true}}
            />
          </Col>

          <Col sm={5}>
            <CompaniesHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const CompaniesAddQueryRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query companiesAdd_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (
      <CompaniesAdd
        {...rendererProps}
        {...props}
      />
    )}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(CompaniesAddQueryRenderer)
