import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Panel from 'components/panel'
import Spinner from 'components/spinner'
import createForm, { Form, File, Input, Select, Wysiwyg, Buttons, Datepicker } from 'components/forms'
import Validation from 'utils/validation'
import { activeOptions } from 'utils/constants'

class ArticlesForm extends Component {
  state = {
    firstMount: true
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.state.firstMount && nextProps.initialValues) {
      this.setState({
        firstMount: false
      })
    }
  }

  render () {
    const { handleSubmit, pristine, submitting, valid, reset } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={this.props.loading} />

        <Panel>
          <File name='image' horizontal label='Imagem' />

          <hr className='hr-line-dashed' />
          <Input name='title' horizontal type='text' label='Título' />

          <hr className='hr-line-dashed' />
          <Input name='summary' horizontal type='text' label='Resumo' />

          <hr className='hr-line-dashed' />
          <Wysiwyg name='text' horizontal label='Texto' />

          <hr className='hr-line-dashed' />
          <Input name='font' horizontal type='text' label='Fonte' />

          <hr className='hr-line-dashed' />
          <Input name='fontLink' horizontal type='url' label='Link da fonte' placeholder='http://' />

          <hr className='hr-line-dashed' />
          <Datepicker name='date' horizontal type='date' label='Data' />

          <hr className='hr-line-dashed' />
          <Input name='time' horizontal type='text' label='Hora' />

          <hr className='hr-line-dashed' />
          <Select
            name='active'
            horizontal
            label='Status'
            clearable={false}
            options={activeOptions}
          />
        </Panel>

        <Panel>
          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
          />
        </Panel>
      </Form>
    )
  }
}

ArticlesForm.defaultProps = {
  loading: false
}

ArticlesForm.propTypes = {
  loading: PropTypes.bool
}

const ArticlesValidation = new Validation({
  image: [Validation.required],
  title: [Validation.required],
  status: [Validation.required],
  fontLink: [Validation.url]
})

export default createForm(ArticlesForm, ArticlesValidation)
