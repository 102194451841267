
import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation CompanyToggleSelectableInMenuMutation($input: CompanyToggleSelectableInMenuInput!) {
    CompanyToggleSelectableInMenu(input: $input) {
      company {
        id
        selectableInMenu
      }
    }
  }
`

function getConfigs (companyId) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      company: companyId
    }
  }]
}

function getOptimisticResponse (company) {
  return {
    CompanyToggleSelectableInMenu: {
      company: {
        selectableInMenu: !company.selectableInMenu
      }
    }
  }
}

function commit (company, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: {
          id: company.id
        }
      },
      configs: getConfigs(company.id),
      optimisticResponse: () => getOptimisticResponse(company),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
