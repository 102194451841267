import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation RequestPasswordRecoveryMutation($input: RequestPasswordRecoveryInput!) {
    RequestPasswordRecovery(input: $input) {
      clientMutationId
    }
  }
`

function commit (values, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: {
          ...values
        }
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
