import React from 'react'

import DBlinksInfo from 'components/dblinks/info'

const Footer = () => (
  <div className='footer'>
    <div className='text-center text-uppercase'>
      {DBlinksInfo.razaoSocial}
    </div>
  </div>
)

export default Footer
