import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation RoleAddMutation($input: RoleAddInput!) {
    RoleAdd(input: $input) {
      roleEdge {
        node {
          id
          name
          permissions
        }
      }
      viewer {
        roles {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'RANGE_ADD',
    parentName: 'viewer',
    parentID: viewer.id,
    connectionName: 'roles',
    edgeName: 'roleEdge',
    rangeBehaviors: {
      '': 'prepend'
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    RoleAdd: {
      role: {
        name: values.name,
        permissions: values.permissions
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          name: values.name,
          permissions: values.permissions
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
