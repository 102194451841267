import { commitMutation, graphql } from 'react-relay'
import Environment, { removeUpdater } from 'relay'

const mutation = graphql`
  mutation ContactRemoveMutation($input: ContactRemoveInput!) {
    ContactRemove(input: $input) {
      deletedId
    }
  }
`

function commit (values, viewer, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: values
      },
      updater: (store) => {
        const payload = store.getRootField('ContactRemove')
        const deletedID = payload.getValue('deletedId')

        removeUpdater(store, viewer, 'list_contacts', deletedID)
      },
      optimisticUpdater: (store) => {
        removeUpdater(store, viewer, 'list_contacts', values.id)
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
