/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type configurationSearchEdit_QueryVariables = {||};
export type configurationSearchEdit_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +supermarket: ?{|
      +id: string,
      +search: ?{|
        +googleWebmastersId: ?string,
        +googleAnalyticsId: ?string,
        +bingVerificationToken: ?string,
        +googleTagManagerId: ?string,
      |},
    |},
  |}
|};
export type configurationSearchEdit_Query = {|
  variables: configurationSearchEdit_QueryVariables,
  response: configurationSearchEdit_QueryResponse,
|};
*/


/*
query configurationSearchEdit_Query {
  viewer {
    id
    supermarket {
      id
      search {
        googleWebmastersId
        googleAnalyticsId
        bingVerificationToken
        googleTagManagerId
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "googleWebmastersId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "googleAnalyticsId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bingVerificationToken",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "googleTagManagerId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "configurationSearchEdit_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupermarketSearch",
                "kind": "LinkedField",
                "name": "search",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "configurationSearchEdit_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Supermarket",
            "kind": "LinkedField",
            "name": "supermarket",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupermarketSearch",
                "kind": "LinkedField",
                "name": "search",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "710b0c1975660868bf675aa61b6b5e58",
    "id": null,
    "metadata": {},
    "name": "configurationSearchEdit_Query",
    "operationKind": "query",
    "text": "query configurationSearchEdit_Query {\n  viewer {\n    id\n    supermarket {\n      id\n      search {\n        googleWebmastersId\n        googleAnalyticsId\n        bingVerificationToken\n        googleTagManagerId\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3715c536b8d1ef802c23ab2cd26afc57';

module.exports = node;
