import React from 'react'
import PropTypes from 'prop-types'

import styles from './panel.less'

const Panel = (props) => (
  <div className={`ibox ${styles.panel}`}>
    {props.title && (
      <div className='ibox-title'>
        <h5>
          {props.title}
          {props.subtitle && (
            <small className='margin-left-5'>{props.subtitle}</small>
          )}
        </h5>
        {props.actions && (
          <div className='ibox-tools'>
            {props.actions}
          </div>
        )}
      </div>
    )}

    <div className='ibox-content'>
      {props.children}
    </div>
  </div>
)

Panel.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node.isRequired
}

export default Panel
