import React, { Component } from 'react'
import { Field } from 'redux-form'
import Group from './group'
import Masker from 'vanilla-masker'
import { vanillaMoneyFormatOpts, toMoney, moneyToNumber } from 'utils/numbers'

class MoneyInput extends Component {
  constructor (props) {
    super(props)
    this.state = { value: props.input.value ? toMoney(props.input.value) : '' }
  }

  onChange (event) {
    const { input } = this.props

    const formattedAsMoney = Masker.toMoney(event.target.value, vanillaMoneyFormatOpts)
    this.setState({value: formattedAsMoney})

    input.onChange(moneyToNumber(formattedAsMoney))
  }

  render () {
    const { input } = this.props
    return (
      <Group {...this.props}>
        <input
          value={this.state.value}
          type='text'
          className='form-control'
          placeholder={this.props.placeholder}
          onChange={(e) => this.onChange(e)}
          onBlur={() => input.onBlur()}
          onFocus={() => input.onFocus()}
        />
      </Group>
    )
  }
}

const MoneyInputField = (props) => (
  <Field {...props} component={MoneyInput} />
)

export default MoneyInputField
