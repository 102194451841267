import React, { Component } from 'react'
import TagsInput from 'react-tagsinput'
import Group from './group'
import { Field, touch } from 'redux-form'

import 'react-tagsinput/react-tagsinput.css'
import './tags.less'

class CustomTagsInput extends Component {
  handleChange (tags) {
    const {input, meta} = this.props

    input.onChange(tags)

    meta.dispatch(touch(meta.form, input.name))
  }

  render () {
    const {input} = this.props
    return (
      <Group {...this.props}>
        <TagsInput
          value={input.value ? input.value : []}
          onChange={this.handleChange.bind(this)}
          inputProps={{ placeholder: '' }}
        />
      </Group>
    )
  }
}

const TagsInputField = (props) => (
  <Field {...props} component={CustomTagsInput} />
)

export default TagsInputField
