import React, { Component } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actionCreators from './actions'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'

import MiniMenuListener from './sidebar/mini-menu-listener'
import Sidebar from './sidebar'
import Header from './header'
import Footer from './footer'
import { drawRoutes, routeTo } from 'router'
import Cookie from 'utils/cookie'
import ResizeListener from 'utils/resize-listener'

import 'bootstrap/less/bootstrap.less'
import 'font-awesome/less/font-awesome.less'
import 'assets/inspinia/less/style.less'
import 'assets/less/app.less'
import 'assets/less/dashboard.less'
import 'assets/less/util.less'

let initializedTimeout

class DashboardTemplate extends Component {
  state = {
    initialized: false
  }

  componentDidMount () {
    initializedTimeout = setTimeout(() => {
      this.setState({
        initialized: true
      })
    }, 100)
  }

  componentWillUnmount () {
    clearTimeout(initializedTimeout)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.viewer && !nextProps.viewer.me) {
      this.props.actions.notify('error', 'Área restrita', 'Efetue login para continuar.')
      this.props.history.push(routeTo('login'))
    }
  }

  toggleMiniMenu () {
    this.props.actions.toggleMiniMenu(!this.props.menu.mini)
  }

  logout () {
    Cookie.remove('Authorization')

    this.props.actions.notify('success', 'Logout efetuado com sucesso', `Até mais tarde, ${this.props.viewer.me.firstName}.`)
    this.props.history.push(routeTo('login'))
  }

  render () {
    let wrapperClass = ''
    if (!this.state.initialized) {
      wrapperClass = 'unitialized'
    }

    return (
      <div>
        <ResizeListener
          onResize={(dimensions) => {
            document.body.classList.toggle('body-small', dimensions.width <= 768)
          }}
        />

        <div id='wrapper' className={wrapperClass}>
          <MiniMenuListener />
          <Sidebar />

          <div id='page-wrapper' className='gray-bg'>
            <Header
              toggleMiniMenu={() => this.toggleMiniMenu()}
              logout={() => this.logout()}
            />

            {drawRoutes(this.props.routes)}

            <div className='row'>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const DashboardTemplateQueryRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query dashboardTemplate_Query {
        viewer {
          me {
            id
            firstName
          }
        }
      }
    `}
    render={({props: rendererProps}) => (
      <DashboardTemplate
        {...rendererProps}
        {...props}
      />
    )}
  />
)

const mapStateToProps = (state) => ({
  menu: state.dashboard.menu
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(actionCreators, dispatch),
    ...bindActionCreators(appActionCreators, dispatch)
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTemplateQueryRenderer)
