import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import Panel from 'components/panel'
import { fetchPermissions } from 'security/authorization'
import createForm, { Buttons, ColorPickerGroup, Form, Select, toForm } from 'components/forms'
import { Col, Row } from 'react-bootstrap'
import Spinner from 'components/spinner'
import ConfigurationHelper from './configuration-helper'
import SupermarketThemeEditMutation from 'relay/mutations/SupermarketThemeEditMutation'
import { affirmativeOptions, themeColors, themes } from 'utils/constants'
import Booleans from 'utils/booleans'

class ThemeConfigurationFormComponent extends Component {
  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading, showColorSelectors, colorSet } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={!!loading} />

        <Panel>
          <Select
            name="themeId"
            horizontal
            label="Tema"
            options={themes}
            clearable={false}
            searchable={false}
          />

          <hr className="hr-line-dashed" />
          <Select
            name="overrideColors"
            horizontal
            label="Escolher cores"
            options={affirmativeOptions}
            clearable={false}
            searchable={false}
          />

          {showColorSelectors && (
            <ColorPickerGroup
              name="colors"
              horizontal
              label="Cores"
              colorSet={colorSet}
            />
          )}

          <hr className="hr-line-dashed" />

          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
          />
        </Panel>
      </Form>
    )
  }
}

const valueSelector = formValueSelector(ThemeConfigurationFormComponent.name)

const ThemeConfigurationForm = connect(
  (state, props) => ({
    showColorSelectors: Booleans.valueOf(valueSelector(state, 'overrideColors')),
    colorSet: themeColors[valueSelector(state, 'themeId')]
  })
)(createForm(ThemeConfigurationFormComponent))

class ThemeConfigurationEdit extends Component {
  constructor (props) {
    super(props)

    this.state = { permissions: {} }
  }

  async componentDidMount () {
    const permissions = await fetchPermissions([ 'configuration:theme:edit' ])
    this.setState({ permissions })
  }

  _onSubmit ({ themeId, overrideColors, colors }) {
    SupermarketThemeEditMutation.commit({ themeId, overrideColors: Boolean(overrideColors), colors }, {
      onCompleted: ({ SupermarketThemeEdit }) => this.props.notify('success', 'Sucesso', 'Configurações do Tema foram salvas'),
      onError: (errors) => this.props.notify('error', 'Erro', 'Não foi possível salvar suas alterações')
    })
  }

  render () {
    const { loading, viewer } = this.props
    return (
      <Content
        title="Tema"
        permission="configuration:theme:edit"
        menu={{ group: 'Configurações', link: 'Tema' }}
        breadcrumb={{
          title: 'Tema',
          items: [
            { url: '/dashboard', title: 'Dashboard' },
            { url: '/dashboard/configuration/theme', title: 'Tema' },
            { title: 'Alterar' }
          ]
        }}>
        <Row>
          <Col sm={7}>
            {!viewer ? (
              <ThemeConfigurationForm loading={loading} />
            ) : (
              <ThemeConfigurationForm
                {...this.props}
                loading={loading}
                initialValues={toForm(viewer.supermarket.theme || {})}
                onSubmit={values => this._onSubmit(values)}
              />
            )}
          </Col>
          <Col sm={5}>
            <ConfigurationHelper type="theme" />
          </Col>
        </Row>
      </Content>
    )
  }
}

const ThemeConfigurationEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query configurationThemeEdit_Query {
        viewer {
          id
          supermarket {
            id
            theme {
              themeId
              overrideColors
              colors {
                name
                value
              }
            }
          }
        }
      }
    `}
    render={({ error, props: rendererProps }) => {
      if (error) {
        console.error(error)
      }

      if (rendererProps) {
        return (
          <ThemeConfigurationEdit
            {...rendererProps}
            {...props}
          />
        )
      }

      return null
    }}
  />
)

export default connect(null, { ...appActionCreators })(ThemeConfigurationEditRenderer)
