import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation OfferCatalogAddMutation($input: OfferCatalogAddInput!) {
    OfferCatalogAdd(input: $input) {
      offerCatalogEdge {
        node {
          id
          title
          order
          isuuDocumentId
          category {
            id
            name
          }
          active
          startDate
          endDate
          weekDays
          image {
            key
            url
          }
        }
      }
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'RANGE_ADD',
    parentName: 'viewer',
    parentID: viewer.id,
    connectionName: 'leads',
    edgeName: 'offerCatalogEdge',
    rangeBehaviors: {
      '': 'prepend'
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    OfferCatalogAdd: {
      offerCatalog: {
        title: values.title,
        category: values.category
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          companies: values.companies,
          title: values.title,
          pdfUrl: values.file.url,
          startDate: values.startDate,
          endDate: values.endDate,
          weekDays: values.weekDays,
          category: values.category.name,
          active: values.active,
          image: values.image
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
