import React from 'react'
import { Route } from 'react-router-dom'

import Router from './router'
import routes from './routes'

function verifyChildren (name, children) {
  return children.map((child) => {
    if (child.name === name) {
      return child.path
    }

    if (child.routes) {
      return verifyChildren(name, child.routes)
    }
  }).filter((child) => {
    return child !== undefined
  }).shift()
}

export function routeTo (name, params = {}) {
  let correctRoute = verifyChildren(name, routes)

  if (correctRoute !== null) {
    Object.keys(params).map((key) => {
      correctRoute = correctRoute.replace(':' + key, params[key])
    })
  }

  return correctRoute
}

export function drawRoutes (routes) {
  return routes.map((route, index) => (
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      render={
        (props) => (
          <route.component {...props} routes={route.routes} />
        )
      }
    />
  ))
}

export default Router
