import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Panel from 'components/panel'
import Spinner from 'components/spinner'
import Booleans from 'utils/booleans'
import createForm, { Form, File, Input, CheckboxGroup, Select, Datepicker, Buttons } from 'components/forms'
import Validation from 'utils/validation'
import { activeOptions, linkTargetOptions, affirmativeOptions, daysOfTheWeekOptions } from 'utils/constants'
import RenderIf from 'components/render-if'

class BannersForm extends Component {
  state = {
    initialized: false,
    showTemporaryFields: false
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.state.initialized || !nextProps.initialValues) {
      return
    }

    this.setState({
      initialized: true,
      showTemporaryFields: Booleans.valueOf(nextProps.initialValues.temporary)
    })
  }

  toggleTemporaryFields (show) {
    this.setState({
      showTemporaryFields: show
    })
  }

  render () {
    const { handleSubmit, pristine, submitting, valid, reset, viewer, position } = this.props
    const hasMoreThanOneCompany = viewer.companies.edges.length > 1

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={this.props.loading} />

        <Panel>
          <File name='image' horizontal label='Imagem' />

          <hr className='hr-line-dashed' />
          <Input name='title' horizontal type='text' label='Título' />

          <RenderIf condition={position === 'MINI'}>
            <hr className='hr-line-dashed' />
            <Input name='buttonCaption' horizontal type='text' label='Título do botão' />
          </RenderIf>

          <hr className='hr-line-dashed' />
          <Input name='order' horizontal type='number' label='Ordem' />

          {hasMoreThanOneCompany && (
            <hr className='hr-line-dashed' />
          )}

          {hasMoreThanOneCompany && (
            <CheckboxGroup
              horizontal
              enableSelectAll
              name='companies'
              label='Lojas'
              options={viewer.companies.edges.map(e => e.node)}
            />
          )}

          <hr className='hr-line-dashed' />
          <Select
            name='active'
            horizontal
            label='Status'
            options={activeOptions}
            clearable={false}
          />
        </Panel>

        <Panel>
          <Input name='link' horizontal type='url' label='Link' />

          <hr className='hr-line-dashed' />
          <Select
            name='linkTarget'
            horizontal
            label='Abrir em'
            options={linkTargetOptions}
            clearable={false}
          />
        </Panel>

        <Panel>
          <Select
            name='temporary'
            horizontal
            label='Temporário'
            options={affirmativeOptions}
            clearable={false}
            onChange={(event, value) => {
              this.toggleTemporaryFields(Booleans.valueOf(value))
            }}
          />

          {this.state.showTemporaryFields && (
            <div>
              <hr className='hr-line-dashed' />
              <Datepicker name='start' horizontal type='date' label='Início' />

              <hr className='hr-line-dashed' />
              <Datepicker name='end' horizontal type='date' label='Fim' />
            </div>
          )}

          <hr className='hr-line-dashed' />
          <CheckboxGroup
            name='days'
            horizontal
            enableSelectAll
            label='Dias'
            inline={false}
            options={
              Object.keys(daysOfTheWeekOptions).map(day => ({
                id: day,
                name: daysOfTheWeekOptions[day]
              }))
            }
          />
        </Panel>

        <Panel>
          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
          />
        </Panel>
      </Form>
    )
  }
}

BannersForm.defaultProps = {
  loading: false
}

BannersForm.propTypes = {
  loading: PropTypes.bool
}

const BannersValidation = new Validation({
  image: [Validation.required],
  title: [Validation.required],
  active: [Validation.required]
})

export default createForm(BannersForm, BannersValidation)
