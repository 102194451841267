import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'

import Content from 'components/templates/dashboard/content'
import { toMutation } from 'components/forms'
import { getMessagesFromErrors } from 'utils/messages'
import { routeTo } from 'router'
import ContactsForm from './contacts-form'
import ContactsHelper from './contacts-helper'
import ContactAddMutation from 'relay/mutations/ContactAddMutation'

class ContactsAdd extends Component {
  constructor (props) {
    super(props)

    this.state = {loading: false}
  }

  _loading (loading) {
    this.setState({...this.state, loading})
  }

  _success () {
    this.props.notify('success', 'Sucesso', 'Contato adicionado com sucesso')
    this.props.history.push(routeTo('dashboard.contacts'))
  }

  _error (message) {
    this.props.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)
    ContactAddMutation.commit(
      toMutation(values),
      this.props.viewer,
      {
        onCompleted: ({ContactAdd}) => {
          this._loading(false)

          this._success()
        },
        onError: (errors) => {
          this._loading(false)

          getMessagesFromErrors(errors).forEach(m => this._error(m))
        }
      }
    )
  }

  render () {
    const { viewer } = this.props
    return (
      <Content
        title='Adicionar Contato'
        permission='contacts:add'
        menu={{link: 'Contatos'}}
        breadcrumb={{
          title: 'Contatos',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/contacts', title: 'Contatos'},
            {title: 'Adicionar'}
          ]
        }} >
        <Row>
          <Col sm={7}>
            <ContactsForm
              loading={this.state.loading}
              viewer={viewer}
              initialValues={{
                companies: viewer.companies.edges.map(e => e.node.id),
                type: 'EMAIL'
              }}
              onSubmit={(values) => this._onSubmit(values)}
            />
          </Col>
          <Col sm={5}>
            <ContactsHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const ContactsAddRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query contactsAdd_Query {
        viewer {
          id
          companies {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `}
    render={({props: rendererProps}) => (
      rendererProps ? (
        <ContactsAdd {...rendererProps} {...props} />
      ) : null
    )}
  />
)

export default connect(null, appActionCreators)(ContactsAddRenderer)
