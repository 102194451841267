/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductGroupRemoveInput = {|
  id?: ?string,
  clientMutationId?: ?string,
|};
export type ProductGroupRemoveMutationVariables = {|
  input: ProductGroupRemoveInput
|};
export type ProductGroupRemoveMutationResponse = {|
  +ProductGroupRemove: ?{|
    +deletedId: ?string
  |}
|};
export type ProductGroupRemoveMutation = {|
  variables: ProductGroupRemoveMutationVariables,
  response: ProductGroupRemoveMutationResponse,
|};
*/


/*
mutation ProductGroupRemoveMutation(
  $input: ProductGroupRemoveInput!
) {
  ProductGroupRemove(input: $input) {
    deletedId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProductGroupRemovePayload",
    "kind": "LinkedField",
    "name": "ProductGroupRemove",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductGroupRemoveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductGroupRemoveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "94b22c1dafd7df9b6383a35d780265df",
    "id": null,
    "metadata": {},
    "name": "ProductGroupRemoveMutation",
    "operationKind": "mutation",
    "text": "mutation ProductGroupRemoveMutation(\n  $input: ProductGroupRemoveInput!\n) {\n  ProductGroupRemove(input: $input) {\n    deletedId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '69836d6d09879273d22a9cff067a9c85';

module.exports = node;
