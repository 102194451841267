import React from 'react'

import loginStyles from 'assets/less/login.less'

const Footer = () => (
  <div className={loginStyles.footer}>
    <a href='https://dblinks.atlassian.net/servicedesk/customer/portal/2' target='_blank'>
      Suporte
    </a>
  </div>
)

export default Footer
