import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import RecipeEditMutation from 'relay/mutations/RecipeEditMutation'

import Content from 'components/templates/dashboard/content'
import { toForm, toMutation } from 'components/forms'
import { message } from 'utils/messages'
import { routeTo } from 'router'
import RecipesForm from './recipes-form'
import RecipesHelper from './recipes-helper'

class RecipesEdit extends Component {
  state = {
    loading: false
  }

  _loading (loading) {
    this.setState({
      loading: loading
    })
  }

  _success () {
    this.props.actions.notify('success', 'Sucesso', 'Receita alterada com sucesso')
    this.props.history.push(routeTo('dashboard.recipes'))
  }

  _error (message) {
    this.props.actions.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    RecipeEditMutation.commit(
      Environment,
      toMutation(values),
      this.props.viewer,
      {
        onCompleted: ({ RecipeEdit }) => {
          this._loading(false)

          if (RecipeEdit.error) {
            this._error(message(RecipeEdit.error))
          } else {
            this._success()
          }
        },
        onError: (transaction) => {
          this._loading(false)

          this._error(message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title='Alterar receita'
        permission='recipes:edit'
        menu={{ link: 'Receitas' }}
        breadcrumb={{
          title: 'Receitas',
          items: [
            { 'url': '/dashboard', 'title': 'Dashboard' },
            { 'url': '/dashboard/recipes', 'title': 'Receitas' },
            { 'title': 'Alterar' }
          ]
        }}
      >
        <Row>
          <Col sm={7}>
            {!this.props.viewer ? (
              <RecipesForm loading />
            ) : (
              <RecipesForm
                loading={this.state.loading}
                initialValues={toForm(this.props.viewer.recipe)}
                onSubmit={(values) => this._onSubmit(values)}
              />
            )}
          </Col>

          <Col sm={5}>
            <RecipesHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const RecipesEditQueryRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query recipesEdit_Query($id: ID!) {
        viewer {
          recipe(id: $id) {
            id
            state {
              id
              name
            }
            city {
              id
              name
            }
            title
            image {
              key
              url
            }
            advertisingImage {
              key
              url
            }
            preparationTime
            yield
            ingredients
            instructions
            author
            date
            active
          }
        }
      }
    `}
    variables={{ id: props.match.params.id }}
    render={({props: rendererProps}) => (
      <RecipesEdit
        {...rendererProps}
        {...props}
      />
    )}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(RecipesEditQueryRenderer)
