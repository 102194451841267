import React from 'react'
import Content from 'components/templates/dashboard/content'

const Dashboard = () => (
  <Content menu={{link: 'Dashboard'}}>
    <p>Dashboard view</p>
  </Content>
)

export default Dashboard
