import React from 'react'

export default (props) => (
  <span style={{
    display: 'inline-block',
    verticalAlign: 'middle',
    height: props.height || '18px',
    width: props.width || '18px'
  }}>
    <svg
      width={props.width || '18px'}
      height={props.height || '18px'}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid'
      className='lds-rolling'
      style={{background: 'none'}}>
      <circle
        cx='50'
        cy='50'
        fill='none'
        stroke='#fdfdfd'
        strokeWidth='10'
        r='35'
        strokeDasharray='164.93361431346415 56.97787143782138'
        transform='rotate(66 50 50)'>
        <animateTransform
          attributeName='transform'
          type='rotate'
          calcMode='linear'
          values='0 50 50;360 50 50'
          keyTimes='0;1'
          dur='2s'
          begin='0s'
          repeatCount='indefinite'
        />
      </circle>
    </svg>
  </span>
)
