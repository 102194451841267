/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RecipeAddInput = {|
  state?: ?string,
  city?: ?string,
  title: string,
  image: FileInput,
  advertisingImage?: ?FileInput,
  preparationTime?: ?string,
  yield?: ?string,
  ingredients?: ?string,
  instructions?: ?string,
  author?: ?string,
  date?: ?string,
  active?: ?boolean,
  clientMutationId?: ?string,
|};
export type FileInput = {|
  key?: ?string,
  url?: ?string,
  name?: ?string,
  size?: ?number,
  type?: ?string,
|};
export type RecipeAddMutationVariables = {|
  input: RecipeAddInput
|};
export type RecipeAddMutationResponse = {|
  +RecipeAdd: ?{|
    +recipeEdge: ?{|
      +node: ?{|
        +id: string,
        +state: ?{|
          +id: string,
          +name: ?string,
        |},
        +city: ?{|
          +id: string,
          +name: ?string,
        |},
        +title: ?string,
        +image: ?{|
          +key: ?string,
          +url: ?string,
        |},
        +advertisingImage: ?{|
          +key: ?string,
          +url: ?string,
        |},
        +preparationTime: ?string,
        +yield: ?string,
        +ingredients: ?string,
        +instructions: ?string,
        +author: ?string,
        +date: ?any,
        +active: ?boolean,
      |}
    |},
    +viewer: ?{|
      +recipes: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +title: ?string,
          |}
        |}>
      |}
    |},
    +error: ?string,
  |}
|};
export type RecipeAddMutation = {|
  variables: RecipeAddMutationVariables,
  response: RecipeAddMutationResponse,
|};
*/


/*
mutation RecipeAddMutation(
  $input: RecipeAddInput!
) {
  RecipeAdd(input: $input) {
    recipeEdge {
      node {
        id
        state {
          id
          name
        }
        city {
          id
          name
        }
        title
        image {
          key
          url
          id
        }
        advertisingImage {
          key
          url
          id
        }
        preparationTime
        yield
        ingredients
        instructions
        author
        date
        active
      }
    }
    viewer {
      recipes {
        edges {
          node {
            id
            title
          }
        }
      }
      id
    }
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "State",
  "kind": "LinkedField",
  "name": "state",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "City",
  "kind": "LinkedField",
  "name": "city",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v9 = [
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preparationTime",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yield",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ingredients",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instructions",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "author",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "RecipeConnection",
  "kind": "LinkedField",
  "name": "recipes",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RecipeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Recipe",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v19 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecipeAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RecipeAddPayload",
        "kind": "LinkedField",
        "name": "RecipeAdd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RecipeEdge",
            "kind": "LinkedField",
            "name": "recipeEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Recipe",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "advertisingImage",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecipeAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RecipeAddPayload",
        "kind": "LinkedField",
        "name": "RecipeAdd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RecipeEdge",
            "kind": "LinkedField",
            "name": "recipeEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Recipe",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": (v19/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "advertisingImage",
                    "plural": false,
                    "selections": (v19/*: any*/),
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "92645c5945fa16b3ccffe32f16eb4044",
    "id": null,
    "metadata": {},
    "name": "RecipeAddMutation",
    "operationKind": "mutation",
    "text": "mutation RecipeAddMutation(\n  $input: RecipeAddInput!\n) {\n  RecipeAdd(input: $input) {\n    recipeEdge {\n      node {\n        id\n        state {\n          id\n          name\n        }\n        city {\n          id\n          name\n        }\n        title\n        image {\n          key\n          url\n          id\n        }\n        advertisingImage {\n          key\n          url\n          id\n        }\n        preparationTime\n        yield\n        ingredients\n        instructions\n        author\n        date\n        active\n      }\n    }\n    viewer {\n      recipes {\n        edges {\n          node {\n            id\n            title\n          }\n        }\n      }\n      id\n    }\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '64caf44c580ad005583a6f54cfd3bbbd';

module.exports = node;
