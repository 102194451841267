/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserRemoveInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type UserRemoveMutationVariables = {|
  input: UserRemoveInput
|};
export type UserRemoveMutationResponse = {|
  +UserRemove: ?{|
    +deletedID: ?string,
    +error: ?string,
  |}
|};
export type UserRemoveMutation = {|
  variables: UserRemoveMutationVariables,
  response: UserRemoveMutationResponse,
|};
*/


/*
mutation UserRemoveMutation(
  $input: UserRemoveInput!
) {
  UserRemove(input: $input) {
    deletedID
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserRemovePayload",
    "kind": "LinkedField",
    "name": "UserRemove",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedID",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserRemoveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserRemoveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c2bf61d4c926e32c0de6602b67a979c6",
    "id": null,
    "metadata": {},
    "name": "UserRemoveMutation",
    "operationKind": "mutation",
    "text": "mutation UserRemoveMutation(\n  $input: UserRemoveInput!\n) {\n  UserRemove(input: $input) {\n    deletedID\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '65709eabdc7bb4b5f70bc5f49b94deac';

module.exports = node;
