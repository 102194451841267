import { commitMutation, graphql } from 'react-relay'

import { removeUpdater } from 'relay'

const mutation = graphql`
  mutation ArticleRemoveMutation($input: ArticleRemoveInput!) {
    ArticleRemove(input: $input) {
      deletedID
      error
    }
  }
`

function commit (environment, article, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: article.id
        }
      },
      updater: (store) => {
        const payload = store.getRootField('ArticleRemove')
        const deletedID = payload.getValue('deletedID')

        removeUpdater(store, viewer, 'list_articles', deletedID)
      },
      optimisticUpdater: (store) => {
        removeUpdater(store, viewer, 'list_articles', article.id)
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
