import React, { Component } from 'react'
import { reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { Form, Input, Buttons, Select, toMutation, Tags } from 'components/forms'
import Validation from 'utils/validation'
import { Modal } from 'react-bootstrap'
import { affirmativeOptions, fieldTypes } from 'utils/constants'
import FidelityCardAddFieldMutation from 'relay/mutations/FidelityCardAddFieldMutation'
import FidelityCardEditFieldMutation from 'relay/mutations/FidelityCardEditFieldMutation'

class FidelityCardFieldModal extends Component {
  constructor (props) {
    super(props)
    this.state = {visible: false}
  }

  _close () {
    this.setState({visible: false})
  }

  _onTriggerClick () {
    const {reset} = this.props
    reset()
    this.setState({visible: true})
  }

  _onSubmit (values) {
    const { groupId, editing } = this.props

    if (editing) {
      const {id} = this.props.initialValues
      FidelityCardEditFieldMutation.commit({...toMutation(values), id}, {
        onCompleted: ({FidelityCardEditField}) => { this._close() },
        onError: (errors) => {}
      })
    } else {
      FidelityCardAddFieldMutation.commit({...toMutation(values), groupId}, {
        onCompleted: ({FidelityCardAddField}) => { this._close() },
        onError: (errors) => {}
      })
    }
  }

  render () {
    const { handleSubmit, pristine, submitting, valid, reset, showTriggerAsButton, triggerIcon, triggerLabel, editing } = this.props

    return (
      <div>
        <a className={showTriggerAsButton ? 'btn btn-primary' : ''} onClick={() => this._onTriggerClick()}>
          {triggerIcon && (
            <i className={`fa ${triggerIcon}`} />
          )} {triggerLabel}
        </a>

        <Modal
          show={this.state.visible}
          onHide={() => { this._close() }}
          dialogClassName='inmodal'>
          <Modal.Header closeButton>
            <Modal.Title>{editing ? 'Alterar' : 'Adicionar'} Campo</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form horizontal onSubmit={handleSubmit(this._onSubmit.bind(this))}>
              <Input name='name' horizontal type='text' label='Nome' />
              <Input name='description' horizontal type='text' label='Descrição' />

              <Select
                name='type'
                horizontal
                label='Tipo'
                options={fieldTypes}
              />

              {this.props.showOptions && (
                <Tags
                  name='options'
                  horizontal
                  label='Opções'
                />
              )}

              <Select
                name='required'
                horizontal
                label='Obrigatório'
                options={affirmativeOptions}
              />

              <Buttons
                horizontal
                pristine={pristine}
                submitting={submitting}
                valid={valid}
                reset={reset}
               />
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const validate = new Validation({
  name: [Validation.required],
  type: [Validation.required],
  required: [Validation.required]
})

export default function (formName) {
  const selector = formValueSelector(formName)

  return connect(
    (state, props) => ({showOptions: ['SELECTION', 'MULTIPLE_SELECTION', 'CHOICE'].indexOf(selector(state, 'type')) > -1})
  )(
    reduxForm({
      enableReinitialize: true,
      form: formName,
      validate
    })(FidelityCardFieldModal)
  )
}
