/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductGroupRemoveProductInput = {|
  id?: ?string,
  productId: string,
  clientMutationId?: ?string,
|};
export type ProductGroupRemoveProductMutationVariables = {|
  input: ProductGroupRemoveProductInput
|};
export type ProductGroupRemoveProductMutationResponse = {|
  +ProductGroupRemoveProduct: ?{|
    +productGroup: ?{|
      +id: string,
      +products: ?$ReadOnlyArray<?{|
        +id: string
      |}>,
    |}
  |}
|};
export type ProductGroupRemoveProductMutation = {|
  variables: ProductGroupRemoveProductMutationVariables,
  response: ProductGroupRemoveProductMutationResponse,
|};
*/


/*
mutation ProductGroupRemoveProductMutation(
  $input: ProductGroupRemoveProductInput!
) {
  ProductGroupRemoveProduct(input: $input) {
    productGroup {
      id
      products {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProductGroupRemoveProductPayload",
    "kind": "LinkedField",
    "name": "ProductGroupRemoveProduct",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductGroup",
        "kind": "LinkedField",
        "name": "productGroup",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "products",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductGroupRemoveProductMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductGroupRemoveProductMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6b102b7e2013fad806bc435a02071706",
    "id": null,
    "metadata": {},
    "name": "ProductGroupRemoveProductMutation",
    "operationKind": "mutation",
    "text": "mutation ProductGroupRemoveProductMutation(\n  $input: ProductGroupRemoveProductInput!\n) {\n  ProductGroupRemoveProduct(input: $input) {\n    productGroup {\n      id\n      products {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f37e35cf3368f4dac732b95752bfa065';

module.exports = node;
