import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Col, Checkbox } from 'react-bootstrap'

import { graphql } from 'react-relay'
import Environment from 'relay'

import Panel from 'components/panel'
import Spinner from 'components/spinner'
import createForm, { Form, Input, DynamicSelect, Buttons } from 'components/forms'
import Validation from 'utils/validation'

class UsersForm extends Component {
  state = {
    showPasswordFields: false
  }

  togglePasswordFields (show) {
    this.setState({
      showPasswordFields: show
    })
  }

  render () {
    const { handleSubmit, pristine, submitting, valid, reset } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={this.props.loading} />

        <Panel>
          <Input name='name' horizontal type='text' label='Nome' />

          <hr className='hr-line-dashed' />
          <DynamicSelect
            name='role'
            horizontal
            label='Grupo'
            environment={Environment}
            query={graphql`
              query usersForm_roles_Query($first: Int!, $search: String) {
                viewer {
                  ...usersForm_roles_viewer
                }
              }
            `}
            fragments={{
              viewer: graphql`
                fragment usersForm_roles_viewer on Viewer {
                  id
                  roles(first: $first, search: $search) @connection(key: "list_roles") {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              `
            }}
          />

          <hr className='hr-line-dashed' />
          <Input name='email' horizontal type='email' label='E-mail' />

          {!this.props.initialValues && (
            <div>
              <hr className='hr-line-dashed' />
              <Input name='password' horizontal type='password' label={'Senha'} />
            </div>
          )}
        </Panel>

        {this.props.initialValues && (
          <Panel>
            <FormGroup>
              <Col smOffset={3} sm={9}>
                <Checkbox
                  checked={this.state.showPasswordFields}
                  onChange={(event) => {
                    this.togglePasswordFields(event.target.checked)
                  }}
                >
                  Alterar a senha
                </Checkbox>
              </Col>
            </FormGroup>
            {(this.state.showPasswordFields) && (
              <div>
                <hr className='hr-line-dashed' />
                <Input name='password' horizontal type='password' label='Nova Senha' />
              </div>
            )}
          </Panel>
        )}

        <Panel>
          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
          />
        </Panel>
      </Form>
    )
  }
}

UsersForm.defaultProps = {
  loading: false,
  changePassword: false
}

UsersForm.propTypes = {
  loading: PropTypes.bool,
  changePassword: PropTypes.bool
}

const UsersValidation = new Validation({
  name: [Validation.required],
  role: [Validation.required],
  email: [Validation.required, Validation.email],
  oldPassword: [Validation.required],
  password: [Validation.required]
})

export default createForm(UsersForm, UsersValidation)
