/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FidelityCardInputType = "CHOICE" | "DATE" | "LONG_TEXT" | "MULTIPLE_SELECTION" | "NUMERIC" | "PHONE" | "SELECTION" | "SHORT_TEXT" | "%future added value";
export type OrderDirection = "DOWN" | "UP" | "%future added value";
export type FidelityCardEditGroupOrderInput = {|
  id: string,
  direction: OrderDirection,
  clientMutationId?: ?string,
|};
export type FidelityCardEditGroupOrderMutationVariables = {|
  input: FidelityCardEditGroupOrderInput
|};
export type FidelityCardEditGroupOrderMutationResponse = {|
  +FidelityCardEditGroupOrder: ?{|
    +fidelityCardForm: ?{|
      +id: string,
      +title: ?string,
      +text: ?string,
      +fieldGroups: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +fields: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
          +description: ?string,
          +type: ?FidelityCardInputType,
          +required: ?boolean,
        |}>,
      |}>,
    |}
  |}
|};
export type FidelityCardEditGroupOrderMutation = {|
  variables: FidelityCardEditGroupOrderMutationVariables,
  response: FidelityCardEditGroupOrderMutationResponse,
|};
*/


/*
mutation FidelityCardEditGroupOrderMutation(
  $input: FidelityCardEditGroupOrderInput!
) {
  FidelityCardEditGroupOrder(input: $input) {
    fidelityCardForm {
      id
      title
      text
      fieldGroups {
        id
        name
        fields {
          id
          name
          description
          type
          required
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FidelityCardEditGroupOrderPayload",
    "kind": "LinkedField",
    "name": "FidelityCardEditGroupOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FidelityCardForm",
        "kind": "LinkedField",
        "name": "fidelityCardForm",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "text",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FidelityCardInputGroup",
            "kind": "LinkedField",
            "name": "fieldGroups",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FidelityCardInput",
                "kind": "LinkedField",
                "name": "fields",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "required",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FidelityCardEditGroupOrderMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FidelityCardEditGroupOrderMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "0774d9d2b6362b9eb97760dd384900d3",
    "id": null,
    "metadata": {},
    "name": "FidelityCardEditGroupOrderMutation",
    "operationKind": "mutation",
    "text": "mutation FidelityCardEditGroupOrderMutation(\n  $input: FidelityCardEditGroupOrderInput!\n) {\n  FidelityCardEditGroupOrder(input: $input) {\n    fidelityCardForm {\n      id\n      title\n      text\n      fieldGroups {\n        id\n        name\n        fields {\n          id\n          name\n          description\n          type\n          required\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3e9fda854e9dc029bb981bae8f08d371';

module.exports = node;
