import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import Panel from 'components/panel'
import { fetchPermissions } from 'security/authorization'
import createForm, { Form, Input, Buttons, toForm } from 'components/forms'
import { Row, Col } from 'react-bootstrap'
import Spinner from 'components/spinner'
import Validation from 'utils/validation'
import ConfigurationHelper from './configuration-helper'
import SupermarketEmailEditMutation from 'relay/mutations/SupermarketEmailEditMutation'

class EmailConfigurationFormComponent extends Component {
  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={!!loading} />

        <Panel>
          <Input name='sesEmailAddress' horizontal type='text' label='E-mail de envio' />

          <hr className='hr-line-dashed' />

          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
           />
        </Panel>
      </Form>
    )
  }
}

const validation = new Validation({
  sesEmailAddress: [Validation.required, Validation.email]
})

const EmailConfigurationForm = createForm(EmailConfigurationFormComponent, validation)

class EmailConfigurationEdit extends Component {
  constructor (props) {
    super(props)

    this.state = {permissions: {}}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['configuration:email:edit'])
    this.setState({ permissions })
  }

  _onSubmit ({sesEmailAddress}) {
    SupermarketEmailEditMutation.commit({sesEmailAddress}, {
      onCompleted: ({SupermarketEmailEdit}) => this.props.notify('success', 'Sucesso', 'Configurações de E-mail foram salvas'),
      onError: (errors) => this.props.notify('error', 'Erro', 'Não foi possível salvar suas alterações')
    })
  }

  render () {
    const { loading, viewer } = this.props
    return (
      <Content
        title='E-mail'
        permission='configuration:email:edit'
        menu={{ group: 'Configurações', link: 'E-mail' }}
        breadcrumb={{
          title: 'E-mail',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/configuration/email', title: 'E-mail'},
            {title: 'Alterar'}
          ]
        }}>
        <Row>
          <Col sm={7}>
            {!viewer ? (
              <EmailConfigurationForm loading={loading} />
            ) : (
              <EmailConfigurationForm
                {...this.props}
                loading={loading}
                initialValues={toForm(viewer.supermarket)}
                onSubmit={values => this._onSubmit(values)}
               />
            )}
          </Col>
          <Col sm={5}>
            <ConfigurationHelper type='email' />
          </Col>
        </Row>
      </Content>
    )
  }
}

const EmailConfigurationEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query configurationEmailEdit_Query {
        viewer {
          id
          supermarket {
            id
            sesEmailAddress
          }
        }
      }
    `}
    render={({error, props: rendererProps}) => {
      if (error) {
        console.error(error)
      }

      if (rendererProps) {
        return (
          <EmailConfigurationEdit
            {...rendererProps}
            {...props}
          />
        )
      }

      return null
    }}
  />
)

export default connect(null, {...appActionCreators})(EmailConfigurationEditRenderer)
