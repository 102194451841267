/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductSetPriceInput = {|
  product: string,
  company: string,
  price: number,
  promotionalPrice: number,
  promotion: boolean,
  clientMutationId?: ?string,
|};
export type ProductSetPriceMutationVariables = {|
  input: ProductSetPriceInput
|};
export type ProductSetPriceMutationResponse = {|
  +ProductSetPrice: ?{|
    +product: ?{|
      +id: string,
      +prices: ?$ReadOnlyArray<?{|
        +company: ?{|
          +id: string,
          +name: ?string,
        |},
        +price: ?number,
        +promotion: ?boolean,
        +promotionalPrice: ?number,
      |}>,
    |}
  |}
|};
export type ProductSetPriceMutation = {|
  variables: ProductSetPriceMutationVariables,
  response: ProductSetPriceMutationResponse,
|};
*/


/*
mutation ProductSetPriceMutation(
  $input: ProductSetPriceInput!
) {
  ProductSetPrice(input: $input) {
    product {
      id
      prices {
        company {
          id
          name
        }
        price
        promotion
        promotionalPrice
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "promotion",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "promotionalPrice",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductSetPriceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductSetPricePayload",
        "kind": "LinkedField",
        "name": "ProductSetPrice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductPrice",
                "kind": "LinkedField",
                "name": "prices",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductSetPriceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductSetPricePayload",
        "kind": "LinkedField",
        "name": "ProductSetPrice",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductPrice",
                "kind": "LinkedField",
                "name": "prices",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e45c05987276c9e32889276de9a3b9bf",
    "id": null,
    "metadata": {},
    "name": "ProductSetPriceMutation",
    "operationKind": "mutation",
    "text": "mutation ProductSetPriceMutation(\n  $input: ProductSetPriceInput!\n) {\n  ProductSetPrice(input: $input) {\n    product {\n      id\n      prices {\n        company {\n          id\n          name\n        }\n        price\n        promotion\n        promotionalPrice\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7fb333dca212bd92c9b267f158dce21d';

module.exports = node;
