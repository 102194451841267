import React from 'react'
import PropTypes from 'prop-types'

const Logo = (props) => {
  const { width, color } = props

  return (
    <svg width={width} viewBox='0 0 1775 396'>
      <defs>
        <linearGradient id='id0' gradientUnits='userSpaceOnUse' x1='1477.22' y1='291.917' x2='1696.71' y2='73.6552'>
          <stop offset='0' style={{stopOpacity: 1, stopColor: '#72C162'}} />
          <stop offset='1' style={{stopOpacity: 1, stopColor: '#60B856'}} />
        </linearGradient>
      </defs>
      <g id='Camada_x0020_1'>
        <metadata id='CorelCorpID_0Corel-Layer' />
        <path fill={color} d='M1161 335c1,0 1,0 1,0 16,6 39,9 64,7 60,-6 86,-36 83,-73 -2,-27 -19,-46 -58,-55 -23,-6 -30,-9 -31,-15 0,-8 7,-12 19,-13 13,-1 27,2 37,5 1,0 1,0 2,0 4,1 5,-2 6,-4l0 -1 6 -38 0 0c0,-4 -2,-5 -4,-5 0,-1 -1,-1 -1,-1 -13,-3 -33,-6 -53,-4 -52,4 -83,35 -79,73 1,23 17,45 62,55 21,5 26,7 26,14 1,7 -5,11 -20,12 -15,2 -36,-2 -49,-7 0,0 -1,0 -1,0 -5,-2 -6,3 -6,3l0 1 -7 40 0 1c0,1 1,4 3,5z' />
        <path fill={color} d='M544 338l63 0c3,0 5,-2 5,-4l0 -275c0,-2 -2,-4 -5,-4l-63 9c-2,0 -4,2 -4,4l0 266c0,2 2,4 4,4z' />
        <path fill={color} d='M635 122l63 0c2,0 4,-2 4,-4l0 -59c0,-2 -2,-4 -4,-4l-63 9c-2,0 -4,2 -4,4l0 50c0,2 2,4 4,4z' />
        <path fill={color} d='M635 338l63 0c2,0 4,-2 4,-4l0 -51 0 -87 0 -50c0,-3 -2,-5 -4,-5l-63 0c-2,0 -4,2 -4,5l0 59 0 69 0 60c0,2 2,4 4,4z' />
        <path fill={color} d='M848 338l63 0c3,0 5,-2 5,-4l0 -51 0 -7 0 -10 0 -36c0,-8 0,-15 -1,-23 -5,-44 -29,-70 -70,-70 -25,0 -42,11 -52,21l0 -12c0,-3 -2,-5 -4,-5l-63 0c-3,0 -4,2 -4,5l0 59 0 69 0 60c0,2 1,4 4,4l63 0c2,0 4,-2 4,-4l0 -51 0 -65c0,-2 1,-4 2,-6 3,-7 9,-17 23,-17 17,0 26,13 26,35l0 44 0 0 0 60c0,2 2,4 4,4z' />
        <polygon fill='#FDFDFD' points='1006,221 1007,221 ' />
        <path fill={color} d='M939 338l63 0c3,0 4,-2 4,-4l0 -52 15 -19 36 71c2,3 3,4 7,4l78 0c3,0 5,-2 3,-6l-75 -111 66 -74c3,-3 0,-6 -3,-6l-76 0c-3,0 -5,1 -7,3l-10 19 -1 0 -19 34c-3,4 -5,8 -7,13l-7 11 0 -162c0,-2 -1,-4 -4,-4l-63 9c-2,0 -4,2 -4,4l0 266c0,2 2,4 4,4z' />
        <path fill='#5DB552' d='M1665 36l74 74c48,48 48,128 0,176l-74 74c-48,48 -128,48 -177,0l-73 -74c-48,-48 -48,-128 0,-176l73 -74c49,-48 129,-48 177,0z' />
        <path fill='#489C50' d='M1538 390c-18,-6 -35,-16 -50,-30l-73 -74c-48,-48 -48,-128 0,-176l73 -74c22,-21 49,-33 77,-35 18,5 35,16 49,30l73 73c49,49 49,128 0,177l-73 73c-21,21 -48,33 -76,36z' />
        <path fill='url(#id0)' style={{ fillRule: 'nonzero' }} d='M1615 337l73 0 51 -51c35,-35 45,-88 28,-132 -4,-6 -8,-11 -13,-15l-73 -74c-49,-48 -128,-48 -177,0l-73 74c-16,15 -26,34 -32,54 35,84 119,144 216,144z' />
        <path fill='#59AE59' d='M1615 337l17 0 55 -56c49,-49 49,-128 0,-177l-73 -73c-14,-14 -31,-25 -49,-30 -28,2 -55,14 -77,35l-73 74c-13,13 -22,28 -28,44 23,104 117,183 228,183z' />
        <path fill='#59AE59' d='M1615 337l17 0 55 -56c49,-49 49,-128 0,-177l-73 -73c-14,-14 -31,-25 -49,-30l0 0c-1,0 -2,0 -3,0 0,0 0,0 -1,0 0,0 -1,0 -2,0 0,0 0,0 -1,0 0,0 -1,1 -2,1 0,0 0,0 0,0 -1,0 -2,0 -3,0 0,0 0,0 0,0 -1,1 -2,1 -3,1 0,0 0,0 0,0 -1,0 -2,0 -3,0 0,1 0,1 0,1 -1,0 -2,0 -2,0 -1,0 -1,0 -1,0 -1,1 -2,1 -3,1 0,0 0,0 0,0 -1,0 -2,1 -2,1 -1,0 -1,0 -1,0 -1,0 -1,1 -2,1 0,0 -1,0 -1,0 -1,0 -1,1 -2,1 0,0 -1,0 -1,0 -1,0 -1,1 -2,1 0,0 0,0 -1,0 0,1 -1,1 -2,1 0,0 0,0 0,0 -1,1 -2,1 -2,1 -1,0 -1,1 -1,1 -1,0 -2,0 -2,1 -1,0 -1,0 -1,0 -1,0 -1,1 -2,1 0,0 0,0 -1,0 0,1 -1,1 -2,1 0,1 0,1 0,1 -1,0 -2,1 -2,1 -1,0 -1,0 -1,0 -1,1 -1,1 -2,2 0,0 -1,0 -1,0 0,0 -1,1 -2,1 0,0 0,1 0,1 -1,0 -2,1 -2,1 0,0 -1,0 -1,0 -1,1 -1,1 -2,2 0,0 0,0 0,0 -1,1 -2,1 -2,2 0,0 -1,0 -1,0 0,1 -1,1 -2,2 0,0 0,0 0,0 -1,1 -2,1 -2,2 0,0 -1,0 -1,0 0,1 -1,1 -2,2 0,0 0,0 0,0 -1,1 -1,1 -2,2 0,0 0,0 0,0 -1,1 -2,2 -2,2 0,0 0,0 -1,0l-73 74c-1,1 -1,1 -2,2l0 0c-1,1 -2,2 -2,2l0 1c-1,0 -2,1 -2,2l0 0c-1,1 -2,1 -2,2l0 0c-1,1 -2,2 -2,3l0 0c-1,1 -2,1 -2,2l0 0c-1,1 -1,2 -2,3l0 0c-1,1 -1,1 -2,2l0 0c0,1 -1,2 -1,3l0 0c-1,1 -2,2 -2,2l0 1c-1,1 -1,1 -2,2l0 0c0,1 -1,2 -1,3l0 0c-1,1 -1,2 -1,3l-1 0c0,1 0,2 -1,2l0 1c0,0 -1,1 -1,2l0 0c0,1 -1,2 -1,3l0 0c-1,1 -1,2 -1,3 23,104 117,183 228,183z' />
        <path fill={color} d='M79 114c1,0 1,0 1,0 4,-1 10,-1 18,-1 45,0 78,25 78,80 0,64 -36,91 -85,90 -3,0 -8,0 -11,0 0,0 -1,-1 -1,-1 -2,0 -4,-2 -4,-4l0 -1 0 -158 0 -1c0,-3 3,-4 4,-4zm-75 223l0 0c0,0 0,0 1,0 15,2 37,4 70,4 55,0 101,-10 132,-35 27,-21 48,-58 48,-115 0,-52 -20,-87 -50,-108 -28,-19 -62,-28 -116,-28 -30,0 -60,5 -83,9 -1,0 -1,0 -2,0 -3,1 -4,3 -4,5l0 1 0 261 0 1c0,1 0,4 4,5z' />
        <path fill={color} d='M354 110c0,0 0,0 0,0 5,0 11,-1 20,-1 25,0 38,11 38,27 0,18 -16,29 -48,29l-10 0 -1 0c-2,0 -4,-3 -4,-4l0 -1 0 -45 0 -1c0,-1 2,-3 5,-4zm0 108l0 0 1 0 12 0c29,0 50,9 50,33 0,25 -21,35 -46,35 -7,0 -12,0 -17,0 0,0 0,0 0,0 -4,0 -5,-4 -5,-5l0 -1 0 -57 0 0c0,-2 2,-5 5,-5zm-76 119l0 0c1,0 1,0 1,0 16,2 39,4 70,4 60,0 95,-9 116,-25 18,-14 31,-35 31,-62 0,-33 -21,-58 -55,-68l0 -1c33,-12 46,-35 46,-58 0,-28 -16,-48 -38,-58 -21,-11 -46,-14 -85,-14 -32,0 -66,7 -84,10 0,0 -1,0 -1,0 -3,0 -4,3 -4,4l0 1 0 262 0 0c0,2 1,5 3,5z' />
        <path fill='#5DB552' d='M514 129l0 -62c0,-3 -4,-6 -8,-6l-26 0c13,18 24,38 31,59 1,3 2,6 3,9z' />
        <path fill='#FDFDFD' d='M1630 207l1 0 7 0c16,0 28,5 28,18 0,14 -12,20 -26,20 -4,0 -7,0 -9,-1 0,0 0,0 -1,0 -1,0 -2,-2 -2,-2l0 -1 0 -31 0 -1c0,-1 1,-2 2,-2zm0 -60l0 0c1,0 1,0 1,0 2,0 6,-1 11,-1 14,0 21,6 21,15 0,10 -9,16 -27,16l-5 0 -1 0c-1,0 -2,-1 -2,-2l0 -1 0 -24 0 -1c0,-1 1,-2 2,-2zm-152 2l0 0c0,0 0,0 1,0 2,-1 5,-1 10,-1 25,0 43,15 43,45 0,35 -20,50 -47,50 -2,0 -5,0 -7,0 0,0 0,0 0,0 -1,-1 -2,-2 -2,-3l0 0 0 -88 0 -1c0,-2 2,-2 2,-2zm-42 124l0 0c0,0 1,0 1,0 8,1 21,2 39,2 30,0 56,-6 73,-19 15,-12 27,-32 27,-64 0,-29 -11,-48 -28,-60 -16,-11 -34,-16 -64,-16 -17,0 -34,3 -47,5 0,0 0,0 0,0 -2,1 -3,2 -3,3l0 0 0 146 0 0c0,1 0,2 2,3zm153 0l0 0c0,0 0,0 0,0 9,1 22,2 39,2 33,0 53,-5 64,-14 10,-8 18,-19 18,-34 0,-18 -12,-33 -31,-38l0 0c18,-7 26,-20 26,-33 0,-15 -9,-26 -21,-32 -12,-6 -26,-8 -48,-8 -18,0 -36,4 -47,6 0,0 0,0 0,0 -2,0 -2,1 -2,2l0 0 0 146 0 0c0,1 1,3 2,3z' />
        <path fill='#FDFDFD' d='M1719 157l0 -34c0,-2 -2,-4 -5,-4l-14 0c8,10 13,21 17,33 1,2 1,4 2,5z' />
      </g>
    </svg>
  )
}

Logo.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string
}

Logo.defaultProps = {
  width: 250,
  color: '#413F41'
}

export default Logo
