import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { routeTo } from 'router'
import ConfirmDialog from 'components/app/confirm-dialog'

const Header = (props) => {
  return (
    <div className='row'>
      <div className='header-wrapper border-bottom'>
        <nav className='navbar navbar-static-top' role='navigation'>
          <div className='navbar-header'>
            <a className='navbar-minimalize minimalize-styl-2 btn btn-primary' onClick={props.toggleMiniMenu}>
              <i className='fa fa-bars' />
            </a>

            <form role='search' className='navbar-form-custom' action='search_results.html'>
              <div className='form-group'>
                <input type='text' placeholder='Buscar por algo?' className='form-control' name='top-search' id='top-search' />
              </div>
            </form>
          </div>
          <ul className='nav navbar-top-links navbar-right'>
            <li>
              <Link to={routeTo('dashboard.profile')}>
                <i className='fa fa-user' /> Meu perfil
              </Link>
            </li>
            <li className='divider' />
            <li>
              <ConfirmDialog
                icon='fa fa-sign-out'
                title='Deseja sair do sistema?'
                subtitle='Você será redirecionado para a tela de login.'
                onOk={props.logout}>
                <i className='fa fa-sign-out' /> Sair
              </ConfirmDialog>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

Header.propTypes = {
  toggleMiniMenu: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
}

export default Header
