import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation ArticleAddMutation($input: ArticleAddInput!) {
    ArticleAdd(input: $input) {
      articleEdge {
        node {
          id
          title
          image {
            key
            url
          }
          summary
          text
          font
          fontLink
          date
          time
          active
        }
      }
      viewer {
        articles {
          edges {
            node {
              id
              title
            }
          }
        }
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'RANGE_ADD',
    parentName: 'viewer',
    parentID: viewer.id,
    connectionName: 'articles',
    edgeName: 'articleEdge',
    rangeBehaviors: {
      '': 'prepend'
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    ArticleAdd: {
      article: {
        title: values.title,
        image: values.image,
        summary: values.summary,
        text: values.text,
        font: values.font,
        fontLink: values.fontLink,
        date: values.date,
        time: values.time,
        active: values.active
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          title: values.title,
          image: values.image,
          summary: values.summary,
          text: values.text,
          font: values.font,
          fontLink: values.fontLink,
          date: values.date,
          time: values.time,
          active: values.active
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
