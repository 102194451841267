import React from 'react'
import PropTypes from 'prop-types'

import Icon from './icon'

const Spinner = (props) => {
  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: props.visible ? 9999 : -1,
    opacity: props.visible ? 1 : 0,
    transition: 'all 0.2s',
    background: props.background
  }

  return (
    <div style={style}>
      <div className='table-wrapper'>
        <div className='table-cell'>
          <Icon size={props.size} />
        </div>
      </div>
    </div>
  )
}

Spinner.propTypes = {
  visible: PropTypes.bool.isRequired,
  size: PropTypes.number,
  background: PropTypes.string
}

Spinner.defaultProps = {
  size: 50,
  background: '#FFFFFF'
}

export default Spinner
