class Validation {
  constructor (mapValuesAndPropsToRules) {
    const join = function (rules) {
      return (value) => {
        return rules.map(rule => {
          return rule(value)
        })
        .filter(error => !!error)[0]
      }
    }

    const validate = function (rules, data) {
      const errors = {}

      Object.keys(rules).forEach((key) => {
        let rule = join([].concat(rules[key]))
        let error = rule(data[key])

        if (error) {
          errors[key] = error
        }
      })

      return errors
    }

    return (values, props) => {
      let rules
      if (typeof mapValuesAndPropsToRules === 'function') {
        rules = mapValuesAndPropsToRules(values, props)
      } else {
        rules = mapValuesAndPropsToRules
      }

      return validate(rules, values)
    }
  }

  static email (value, message) {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    if (!!value && !emailRegex.exec(value)) {
      return message || 'Endereço de e-mail inválido.'
    }
  }

  static required (value, message) {
    const isEmpty = value === undefined || value === null || value === ''
    if (isEmpty) {
      return message || 'Campo obrigatório.'
    }
  }

  static phone (value, message) {
    const phoneRegex = /^\([1-9]{2}\)( )?[0-9]{4,5}-[0-9]{4}$/
    if (!!value && !phoneRegex.test(value)) {
      return message || 'Telefone inválido.'
    }
  }

  static url (value, message) {
    if (!!value && (!value.startsWith('http://') && !value.startsWith('https://'))) {
      return message || 'URL inválida. Deve iniciar com http:// ou https://'
    }
  }

  static minLength (minLength) {
    return (value) => {
      if (!!value && value.length < minLength) {
        return `Tamanho mínimo: ${minLength} caracteres.`
      }
    }
  }
}

export default Validation
