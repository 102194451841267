import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation BannerAddMutation($input: BannerAddInput!) {
    BannerAdd(input: $input) {
      bannerEdge {
        node {
          id
          companies {
            edges {
              node {
                id
              }
            }
          }
          title
          image {
            key
            url
          }
          position
          order
          link
          linkTarget
          temporary
          start
          end
          days
          active
        }
      }
      viewer {
        banners {
          edges {
            node {
              id
              title
              order
              active
            }
          }
        }
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'RANGE_ADD',
    parentName: 'viewer',
    parentID: viewer.id,
    connectionName: 'banners',
    edgeName: 'bannerEdge',
    rangeBehaviors: {
      '': 'prepend'
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    BannerAdd: {
      banner: {
        companies: values.companies,
        title: values.title,
        buttonCaption: values.buttonCaption,
        image: values.image,
        position: values.position,
        order: values.order,
        link: values.link,
        linkTarget: values.linkTarget,
        temporary: values.temporary,
        start: values.start,
        end: values.end,
        days: values.days,
        active: values.active
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: values
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
