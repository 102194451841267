import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Col, Checkbox } from 'react-bootstrap'

import Panel from 'components/panel'
import Spinner from 'components/spinner'
import createForm, { Form, Input, Buttons } from 'components/forms'
import Validation from 'utils/validation'

class ProfileForm extends Component {
  state = {
    showPasswordFields: false
  }

  togglePasswordFields (show) {
    this.setState({
      showPasswordFields: show
    })
  }

  render () {
    const { handleSubmit, pristine, submitting, valid, reset } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={this.props.loading} />

        <Panel>
          <Input name='name' horizontal type='text' label='Nome' />
          <hr className='hr-line-dashed' />
          <Input name='email' horizontal type='email' label='E-mail' />
        </Panel>

        <Panel>
          <FormGroup>
            <Col smOffset={3} sm={9}>
              <Checkbox
                checked={this.state.showPasswordFields}
                onChange={(event) => {
                  this.togglePasswordFields(event.target.checked)
                }}
              >
                Desejo alterar minha senha
              </Checkbox>
            </Col>
          </FormGroup>
          {this.state.showPasswordFields && (
            <div>
              <hr className='hr-line-dashed' />
              <Input name='oldPassword' horizontal type='password' label='Senha Atual' />
              <hr className='hr-line-dashed' />
              <Input name='password' horizontal type='password' label='Nova Senha' />
            </div>
          )}
        </Panel>

        <Panel>
          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
          />
        </Panel>
      </Form>
    )
  }
}

ProfileForm.defaultProps = {
  loading: false,
  changePassword: false
}

ProfileForm.propTypes = {
  loading: PropTypes.bool,
  changePassword: PropTypes.bool
}

const ProfileValidation = new Validation({
  name: [Validation.required],
  email: [Validation.required, Validation.email],
  oldPassword: [Validation.required],
  password: [Validation.required]
})

export default createForm(ProfileForm, ProfileValidation)
