// Dashboard
import DashboardTemplate from 'components/templates/dashboard/dashboard-template'
import DashboardView from 'components/views/dashboard/dashboard'
import ProfileView from 'components/views/profile/profile'

// Articles
import ArticlesListView from 'components/views/articles/articles-list'
import ArticlesAddView from 'components/views/articles/articles-add'
import ArticlesEditView from 'components/views/articles/articles-edit'

// Banners
import { bannerPositions } from 'components/views/banners/constants'
import BannersListView from 'components/views/banners/banners-list'
import BannersAddView from 'components/views/banners/banners-add'
import BannersEditView from 'components/views/banners/banners-edit'

// Companies
import CompaniesListView from 'components/views/companies/companies-list'
import CompaniesAddView from 'components/views/companies/companies-add'
import CompaniesEditView from 'components/views/companies/companies-edit'

// Roles
import RolesListView from 'components/views/roles/roles-list'
import RolesAddView from 'components/views/roles/roles-add'
import RolesEditView from 'components/views/roles/roles-edit'

// Recipes
import RecipesListView from 'components/views/recipes/recipes-list'
import RecipesAddView from 'components/views/recipes/recipes-add'
import RecipesEditView from 'components/views/recipes/recipes-edit'

// Users
import UsersListView from 'components/views/users/users-list'
import UsersAddView from 'components/views/users/users-add'
import UsersEditView from 'components/views/users/users-edit'

// Leads
import LeadsListView from 'components/views/leads/leads-list'
import LeadsAddView from 'components/views/leads/leads-add'
import LeadsEditView from 'components/views/leads/leads-edit'

// Login
import LoginTemplate from 'components/templates/login/login-template'
import LoginView from 'components/views/login/login'
import PasswordRecoveryRequestView from 'components/views/login/password-recovery-request'
import PasswordRecoveryResponseView from 'components/views/login/password-recovery-response'

// Contact Messages
import ContactMessagesListView from 'components/views/contact-messages/contact-messages-list'
import ContactMessagesShowView from 'components/views/contact-messages/contact-messages-show'

// Offer Catalogs
import OfferCatalogsListView from 'components/views/offer-catalogs/offer-catalogs-list'
import OfferCatalogsAddView from 'components/views/offer-catalogs/offer-catalogs-add'
import OfferCatalogsEditView from 'components/views/offer-catalogs/offer-catalogs-edit'

// Contacts
import ContactsListView from 'components/views/contacts/contacts-list'
import ContactsAddView from 'components/views/contacts/contacts-add'
import ContactsEditView from 'components/views/contacts/contacts-edit'

// Fidelity Card configuration
import FidelityCardEditView from 'components/views/fidelity-card/fidelity-card-edit'
import FidelityCardGroupAddView from 'components/views/fidelity-card/fidelity-card-group-add'
import FidelityCardGroupEditView from 'components/views/fidelity-card/fidelity-card-group-edit'

// Institutional configuration
import SupermarketInstitutionalEditView from 'components/views/institutional/institutional-edit'

// configuration
import ConfigurationGeneralEditView from 'components/views/configuration/configuration-general-edit'
import ConfigurationSocialEditView from 'components/views/configuration/configuration-social-edit'
import ConfigurationSearchEditView from 'components/views/configuration/configuration-search-edit'
import ConfigurationEmailEditView from 'components/views/configuration/configuration-email-edit'
import ConfigurationThemeEditView from 'components/views/configuration/configuration-theme-edit'
import ConfigurationMaintenanceEditView from 'components/views/configuration/configuration-maintenance-edit'

// Products
import ProductsListView from 'components/views/products/products-list'
import ProductsAddView from 'components/views/products/products-add'
import ProductsEditView from 'components/views/products/products-edit'

// Product Groups
import ProductGroupsListView from 'components/views/product-groups/product-groups-list'
import ProductGroupsAddView from 'components/views/product-groups/product-groups-add'
import ProductGroupsEditView from 'components/views/product-groups/product-groups-edit'
import ProductGroupsAddProductsView from 'components/views/product-groups/product-groups-add-products'

// Privacy Policy
import PrivacyPolicyEditRenderer from '../components/views/privacy-policy/privacy-policy-edit'

export default [
  // Dashboard
  {
    path: '/dashboard',
    component: DashboardTemplate,
    routes: [
      {
        name: 'dashboard',
        path: '/dashboard',
        exact: true,
        component: DashboardView
      },
      {
        name: 'dashboard.profile',
        path: '/dashboard/profile',
        component: ProfileView
      },

      // Articles
      {
        name: 'dashboard.articles',
        path: '/dashboard/articles',
        exact: true,
        component: ArticlesListView
      },
      {
        name: 'dashboard.articles.add',
        path: '/dashboard/articles/add',
        component: ArticlesAddView
      },
      {
        name: 'dashboard.articles.edit',
        path: '/dashboard/articles/edit/:id',
        component: ArticlesEditView
      },

      // Banners
      {
        name: 'dashboard.banners.main',
        path: '/dashboard/banners/main',
        exact: true,
        component: BannersListView(bannerPositions.MAIN)
      },
      {
        name: 'dashboard.banners.main.add',
        path: '/dashboard/banners/main/add',
        component: BannersAddView(bannerPositions.MAIN)
      },
      {
        name: 'dashboard.banners.main.edit',
        path: '/dashboard/banners/main/edit/:id',
        component: BannersEditView(bannerPositions.MAIN)
      },
      {
        name: 'dashboard.banners.mini',
        path: '/dashboard/banners/mini',
        exact: true,
        component: BannersListView(bannerPositions.MINI)
      },
      {
        name: 'dashboard.banners.mini.add',
        path: '/dashboard/banners/mini/add',
        component: BannersAddView(bannerPositions.MINI)
      },
      {
        name: 'dashboard.banners.mini.edit',
        path: '/dashboard/banners/mini/edit/:id',
        component: BannersEditView(bannerPositions.MINI)
      },
      {
        name: 'dashboard.banners.internal',
        path: '/dashboard/banners/internal',
        exact: true,
        component: BannersListView(bannerPositions.INTERNAL)
      },
      {
        name: 'dashboard.banners.internal.add',
        path: '/dashboard/banners/internal/add',
        component: BannersAddView(bannerPositions.INTERNAL)
      },
      {
        name: 'dashboard.banners.internal.edit',
        path: '/dashboard/banners/mini/internal/:id',
        component: BannersEditView(bannerPositions.INTERNAL)
      },

      // Companies
      {
        name: 'dashboard.companies',
        path: '/dashboard/companies',
        exact: true,
        component: CompaniesListView
      },
      {
        name: 'dashboard.companies.add',
        path: '/dashboard/companies/add',
        component: CompaniesAddView
      },
      {
        name: 'dashboard.companies.edit',
        path: '/dashboard/companies/edit/:id',
        component: CompaniesEditView
      },

      // Roles
      {
        name: 'dashboard.roles',
        path: '/dashboard/roles',
        exact: true,
        component: RolesListView
      },
      {
        name: 'dashboard.roles.add',
        path: '/dashboard/roles/add',
        component: RolesAddView
      },
      {
        name: 'dashboard.roles.edit',
        path: '/dashboard/roles/edit/:id',
        component: RolesEditView
      },

      // Recipes
      {
        name: 'dashboard.recipes',
        path: '/dashboard/recipes',
        exact: true,
        component: RecipesListView
      },
      {
        name: 'dashboard.recipes.add',
        path: '/dashboard/recipes/add',
        component: RecipesAddView
      },
      {
        name: 'dashboard.recipes.edit',
        path: '/dashboard/recipes/edit/:id',
        component: RecipesEditView
      },

      // Users
      {
        name: 'dashboard.users',
        path: '/dashboard/users',
        exact: true,
        component: UsersListView
      },
      {
        name: 'dashboard.users.add',
        path: '/dashboard/users/add',
        component: UsersAddView
      },
      {
        name: 'dashboard.users.edit',
        path: '/dashboard/users/edit/:id',
        component: UsersEditView
      },

      // Contact Messages
      {
        name: 'dashboard.contact-messages',
        path: '/dashboard/contact-messages',
        exact: true,
        component: ContactMessagesListView
      },
      {
        name: 'dashboard.contact-messages.show',
        path: '/dashboard/contact-messages/:id',
        component: ContactMessagesShowView
      },

      // Leads
      {
        name: 'dashboard.leads',
        path: '/dashboard/leads',
        exact: true,
        component: LeadsListView
      },
      {
        name: 'dashboard.leads.add',
        path: '/dashboard/leads/add',
        component: LeadsAddView
      },
      {
        name: 'dashboard.leads.edit',
        path: '/dashboard/leads/edit/:id',
        component: LeadsEditView
      },

      // Offer Catalogs
      {
        name: 'dashboard.offer-catalogs',
        path: '/dashboard/offer-catalogs',
        exact: true,
        component: OfferCatalogsListView
      },
      {
        name: 'dashboard.offer-catalogs.add',
        path: '/dashboard/offer-catalogs/add',
        component: OfferCatalogsAddView
      },
      {
        name: 'dashboard.offer-catalogs.edit',
        path: '/dashboard/offer-catalogs/edit/:id',
        component: OfferCatalogsEditView
      },

      // Contacts
      {
        name: 'dashboard.contacts',
        path: '/dashboard/contacts',
        exact: true,
        component: ContactsListView
      },
      {
        name: 'dashboard.contacts.add',
        path: '/dashboard/contacts/add',
        component: ContactsAddView
      },
      {
        name: 'dashboard.contacts.edit',
        path: '/dashboard/contacts/edit/:id',
        component: ContactsEditView
      },

      // Fidelity Card
      {
        name: 'dashboard.fidelity-card.edit',
        path: '/dashboard/fidelity-card',
        exact: true,
        component: FidelityCardEditView
      },
      {
        name: 'dashboard.fidelity-card.group.add',
        path: '/dashboard/fidelity-card/group/add',
        component: FidelityCardGroupAddView
      },
      {
        name: 'dashboard.fidelity-card.group.edit',
        path: '/dashboard/fidelity-card/group/edit/:id',
        component: FidelityCardGroupEditView
      },

      // Institutional Card
      {
        name: 'dashboard.institutional.edit',
        path: '/dashboard/institutional',
        exact: true,
        component: SupermarketInstitutionalEditView
      },

      // Configuration
      {
        name: 'dashboard.configuration.general',
        path: '/dashboard/configuration/general',
        exact: true,
        component: ConfigurationGeneralEditView
      },
      {
        name: 'dashboard.configuration.social',
        path: '/dashboard/configuration/social',
        exact: true,
        component: ConfigurationSocialEditView
      },
      {
        name: 'dashboard.configuration.search',
        path: '/dashboard/configuration/search',
        exact: true,
        component: ConfigurationSearchEditView
      },
      {
        name: 'dashboard.configuration.email',
        path: '/dashboard/configuration/email',
        exact: true,
        component: ConfigurationEmailEditView
      },
      {
        name: 'dashboard.configuration.theme',
        path: '/dashboard/configuration/theme',
        exact: true,
        component: ConfigurationThemeEditView
      },
      {
        name: 'dashboard.configuration.maintenance',
        path: '/dashboard/configuration/maintenance',
        exact: true,
        component: ConfigurationMaintenanceEditView
      },

      // Products
      {
        name: 'dashboard.products',
        path: '/dashboard/products',
        exact: true,
        component: ProductsListView
      },
      {
        name: 'dashboard.products.add',
        path: '/dashboard/products/add',
        component: ProductsAddView
      },
      {
        name: 'dashboard.products.edit',
        path: '/dashboard/products/edit/:id',
        component: ProductsEditView
      },

      // Product Groups
      {
        name: 'dashboard.product-groups',
        path: '/dashboard/product-groups',
        exact: true,
        component: ProductGroupsListView
      },
      {
        name: 'dashboard.product-groups.add',
        path: '/dashboard/product-groups/add',
        component: ProductGroupsAddView
      },
      {
        name: 'dashboard.product-groups.edit',
        path: '/dashboard/product-groups/edit/:id',
        component: ProductGroupsEditView
      },
      {
        name: 'dashboard.product-groups.add-products',
        path: '/dashboard/product-groups/:id/add-products',
        component: ProductGroupsAddProductsView
      },

      // privacy Policy
      {
        name: 'dashboard.privacy.edit',
        path: '/dashboard/privacy-policy',
        exact: true,
        component: PrivacyPolicyEditRenderer
      }
    ]
  },

  // Login
  {
    path: '/login',
    component: LoginTemplate,
    routes: [
      {
        name: 'login',
        path: '/login',
        component: LoginView,
        exact: true
      },
      {
        name: 'password-recovery-request',
        path: '/login/password-recovery',
        component: PasswordRecoveryRequestView,
        exact: true
      },
      {
        name: 'password-recovery-response',
        path: '/login/password-recovery/:token',
        component: PasswordRecoveryResponseView
      }
    ]
  }

]
