import { FETCH_HOST, NOTIFICATION_ADD, NOTIFICATION_REMOVE } from './constants'

const initialState = {
  host: null,
  notifications: []
}

function app (state = initialState, action) {
  switch (action.type) {
    case FETCH_HOST:
      return Object.assign({}, state, {
        host: action.payload.host
      })

    case NOTIFICATION_ADD:
      return Object.assign({}, state, {
        notifications: state.notifications.concat({
          level: action.payload.level,
          title: action.payload.title,
          message: action.payload.message,
          uid: action.payload.uid
        })
      })

    case NOTIFICATION_REMOVE:
      return Object.assign({}, state, {
        notifications: state.notifications.filter(({uid}) => {
          return uid !== action.payload.uid
        })
      })

    default:
      return state
  }
}

export default app
