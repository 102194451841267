import React, { Component } from 'react'
import { Field } from 'redux-form'
import Group from './group'
import Masker from 'vanilla-masker'

const masks = ['(99) 9999-99999', '(99) 99999-9999']

class PhoneInput extends Component {
  onChange (event) {
    const { input } = this.props

    const el = event.target
    var v = el.value.replace(/\D/g, '')
    var m = el.value.length > 14 ? 1 : 0
    Masker(el).unMask()
    Masker(el).maskPattern(masks[m])

    input.onChange(Masker.toPattern(v, masks[m]))
  }

  render () {
    return (
      <Group {...this.props}>
        <input
          {...this.props.input}
          type='text'
          className='form-control'
          placeholder={this.props.placeholder}
          ref='input'
          onChange={this.onChange.bind(this)}
        />
      </Group>
    )
  }
}

const PhoneInputField = (props) => (
  <Field {...props} component={PhoneInput} />
)

export default PhoneInputField
