import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import ReactSelect from 'react-select'

import { createRefetchContainer, QueryRenderer } from 'react-relay'
import { createArray } from 'relay'

import 'react-select/less/default.less'
import './select.less'

import Group from './group'

let searchTimeout

class DynamicSelectCreatable extends Component {
  state = {
    options: createArray(this.props.viewer, this.props.usesConnection)
  }

  onChange (value) {
    const isValidValue = value && Object.keys(value).length > 1
    const callbackValue = isValidValue ? value : null

    this.props.input.onChange(callbackValue)
    if (this.props.onChange) {
      this.props.onChange(callbackValue)
    }

    this.setState({
      value: callbackValue
    })
  }

  refetchOptions (search) {
    clearTimeout(searchTimeout)
    searchTimeout = setTimeout(() => {
      this.props.relay.refetch(
        {
          first: this.props.variables.first,
          search: search
        },
        null,
        () => {
          this.setState({
            options: createArray(this.props.viewer, this.props.usesConnection)
          })
        }
      )
    }, 350)
  }

  render () {
    return (
      <Group {...this.props}>
        <ReactSelect.Creatable
          multi={this.props.multi}
          clearable={this.props.clearable}
          searchable={this.props.searchable}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          valueKey={this.props.valueKey}
          labelKey={this.props.labelKey}
          name={this.props.input.name}
          value={this.props.input.value}
          onChange={(value) => this.onChange(value)}
          onBlur={() => this.props.input.onBlur(this.props.input.value)}
          options={this.state.options}
          onInputChange={(value) => this.refetchOptions(value)}
          allowCreate
          clearAllText='Limpar tudo'
          clearValueText='Limpar'
          noResultsText='Nenhum resultado encontrado'
          searchPromptText='Digite para pesquisar'
          loadingPlaceholder='Carregando...'
          promptTextCreator={label => `${this.props.createLabel || 'Criar'} "${label}"`}
        />
      </Group>
    )
  }
}

const DynamicSelectCreatableQueryRenderer = (props) => {
  const variables = {
    first: 20,
    search: '',
    ...props.variables
  }

  const EmptyDynamicSelectCreatable = () => (
    <DynamicSelectCreatable
      {...props}
      variables={variables}
      viewer={null}
    />
  )

  if (props.disabled) {
    return (
      <EmptyDynamicSelectCreatable />
    )
  }

  const DynamicSelectCreatableRefetchContainer = createRefetchContainer(
    DynamicSelectCreatable,
    props.fragments,
    props.query
  )

  return (
    <QueryRenderer
      environment={props.environment}
      query={props.query}
      variables={variables}
      render={({props: rendererProps}) => {
        if (!rendererProps) {
          return (
            <EmptyDynamicSelectCreatable />
          )
        }

        return (
          <DynamicSelectCreatableRefetchContainer
            {...props}
            {...rendererProps}
            variables={variables}
          />
        )
      }}
    />
  )
}

const DynamicSelectField = (props) => (
  <Field {...props} component={DynamicSelectCreatableQueryRenderer} />
)

DynamicSelectField.propTypes = {
  environment: PropTypes.object.isRequired,
  query: PropTypes.func.isRequired,
  fragments: PropTypes.object.isRequired,
  variables: PropTypes.object,
  onChange: PropTypes.func,
  multi: PropTypes.bool,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  usesConnection: PropTypes.bool
}

DynamicSelectField.defaultProps = {
  multi: false,
  clearable: true,
  searchable: true,
  disabled: false,
  placeholder: 'Selecione',
  valueKey: 'id',
  labelKey: 'name',
  usesConnection: true
}

export default DynamicSelectField
