import { commitMutation, graphql } from 'react-relay'
import { removeUpdater } from 'relay'

const mutation = graphql`
  mutation OfferCatalogRemoveMutation($input: OfferCatalogRemoveInput!) {
    OfferCatalogRemove(input: $input) {
      deletedId
    }
  }
`

function commit (environment, offerCatalog, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: offerCatalog.id
        }
      },
      updater: (store) => {
        const payload = store.getRootField('OfferCatalogRemove')
        const deletedId = payload.getValue('deletedId')

        removeUpdater(store, viewer, 'list_offerCatalogs', deletedId)
      },
      optimisticUpdater: store => {
        removeUpdater(store, viewer, 'list_offerCatalogs', offerCatalog.id)
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
