/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type privacyPolicyEdit_viewer_QueryVariables = {||};
export type privacyPolicyEdit_viewer_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +supermarket: ?{|
      +id: string,
      +privacyPolicy: ?string,
    |},
  |}
|};
export type privacyPolicyEdit_viewer_Query = {|
  variables: privacyPolicyEdit_viewer_QueryVariables,
  response: privacyPolicyEdit_viewer_QueryResponse,
|};
*/


/*
query privacyPolicyEdit_viewer_Query {
  viewer {
    id
    supermarket {
      id
      privacyPolicy
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Supermarket",
        "kind": "LinkedField",
        "name": "supermarket",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "privacyPolicy",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "privacyPolicyEdit_viewer_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "privacyPolicyEdit_viewer_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6d71f2c59bb518b715ccde219bce0e00",
    "id": null,
    "metadata": {},
    "name": "privacyPolicyEdit_viewer_Query",
    "operationKind": "query",
    "text": "query privacyPolicyEdit_viewer_Query {\n  viewer {\n    id\n    supermarket {\n      id\n      privacyPolicy\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7ff4f4440f00d53e2c03244811e6e506';

module.exports = node;
