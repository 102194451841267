/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoleAddInput = {|
  name: string,
  permissions?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type RoleAddMutationVariables = {|
  input: RoleAddInput
|};
export type RoleAddMutationResponse = {|
  +RoleAdd: ?{|
    +roleEdge: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +permissions: ?$ReadOnlyArray<?string>,
      |}
    |},
    +viewer: ?{|
      +roles: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
          |}
        |}>
      |}
    |},
    +error: ?string,
  |}
|};
export type RoleAddMutation = {|
  variables: RoleAddMutationVariables,
  response: RoleAddMutationResponse,
|};
*/


/*
mutation RoleAddMutation(
  $input: RoleAddInput!
) {
  RoleAdd(input: $input) {
    roleEdge {
      node {
        id
        name
        permissions
      }
    }
    viewer {
      roles {
        edges {
          node {
            id
            name
          }
        }
      }
      id
    }
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "RoleEdge",
  "kind": "LinkedField",
  "name": "roleEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "permissions",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "RoleConnection",
  "kind": "LinkedField",
  "name": "roles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Role",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RoleAddPayload",
        "kind": "LinkedField",
        "name": "RoleAdd",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoleAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RoleAddPayload",
        "kind": "LinkedField",
        "name": "RoleAdd",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be1e11a6c5c73dc34388e97f8e893fd3",
    "id": null,
    "metadata": {},
    "name": "RoleAddMutation",
    "operationKind": "mutation",
    "text": "mutation RoleAddMutation(\n  $input: RoleAddInput!\n) {\n  RoleAdd(input: $input) {\n    roleEdge {\n      node {\n        id\n        name\n        permissions\n      }\n    }\n    viewer {\n      roles {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n      id\n    }\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '307a1592433cc4517d343faf7d1ce46c';

module.exports = node;
