import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation ProductEditMutation($input: ProductEditInput!) {
    ProductEdit(input: $input) {
      product {
        name
        image {
          url
        }
        gtin
        size
        unitOfMeasure
        saleUnit
        hasVariableWeight
        additionalInfo
        stamps
        active
      }
    }
  }
`

function commit (values, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: {
          id: values.id,
          name: values.name,
          image: values.image,
          gtin: values.gtin,
          size: values.size,
          unitOfMeasure: values.unitOfMeasure,
          saleUnit: values.saleUnit,
          hasVariableWeight: values.hasVariableWeight,
          additionalInfo: values.additionalInfo,
          stamps: values.stamps,
          active: values.active
        }
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
