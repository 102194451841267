import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

import styles from './toggler.less'

const Toggler = (props) => {
  const isUndefined = typeof props.value === 'undefined'
  const isNull = props.value === null
  if (isUndefined || isNull) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <Button
        bsStyle={props.colors[props.value]}
        onClick={() => props.onChange(!props.value)}
      >
        {props.labels[props.value]}
      </Button>
    </div>
  )
}

Toggler.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  labels: PropTypes.object,
  colors: PropTypes.object
}

Toggler.defaultProps = {
  labels: {
    'false': 'Inativo',
    'true': 'Publicado'
  },
  colors: {
    'false': null,
    'true': 'primary'
  }
}

export default Toggler
