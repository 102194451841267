import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { graphql, QueryRenderer } from 'react-relay'
import Environment from 'relay'
import Content from 'components/templates/dashboard/content'
import Panel from 'components/panel'
import { fetchPermissions } from 'security/authorization'
import createForm, { Form, Wysiwyg, Buttons, toForm } from 'components/forms'
import Spinner from 'components/spinner'
import Validation from 'utils/validation'
import SupermarketPrivacyPolicyEdit from '../../../relay/mutations/SupermarketPrivacyPolicyEdit'

class SupermarketInstitutionalForm extends Component {
  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={loading}/>

        <Panel>
          <Wysiwyg name="privacyPolicy" horizontal label="Texto"/>
          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
          />
        </Panel>
      </Form>
    )
  }
}

const validation = new Validation({
  text: [Validation.required]
})

const PrivacyPolicyEditForm = createForm(SupermarketInstitutionalForm, validation)

class PrivacyPolicyEdit extends Component {
  constructor (props) {
    super(props)

    this.state = { permissions: {} }
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['privacy:edit'])
    console.log(permissions)
    this.setState({ permissions })
  }

  _onSubmit (values) {
    const { privacyPolicy } = values
    SupermarketPrivacyPolicyEdit.commit(
      { privacyPolicy },
      this.props.viewer,
      {
        onCompleted: ({ SupermarketPrivacyPolicyEdit }) => {},
        onError: (errors) => {}
      }
    )
  }

  render () {
    const { loading, viewer } = this.props
    return (
      <Content
        title="Política de Privacidade"
        permission="privacy:edit"
        menu={{ link: 'Política de Privacidade' }}
        breadcrumb={{
          title: 'Política de Privacidade',
          items: [
            { url: '/dashboard', title: 'Dashboard' },
            { url: '/dashboard/privacy-policy', title: 'Política de Privacidade' },
            { title: 'Alterar' }
          ]
        }}>

        {!this.props.viewer ? (
          <PrivacyPolicyEditForm
            loading={loading}
          />
        ) : (
          <PrivacyPolicyEditForm
            {...this.props}
            loading={loading}
            initialValues={toForm(viewer.supermarket)}
            onSubmit={values => this._onSubmit(values)}
          />
        )}

      </Content>
    )
  }
}

const PrivacyPolicyEditRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query privacyPolicyEdit_viewer_Query {
        viewer {
          id
          supermarket {
            id
            privacyPolicy
          }
        }
      }
    `}
    render={({ props: rendererProps }) => (
      <PrivacyPolicyEdit
        {...rendererProps}
        {...props}
        loading={!rendererProps}
      />
    )}
  />
)

export default connect(null, { ...appActionCreators })(PrivacyPolicyEditRenderer)
