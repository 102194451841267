import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation SupermarketPrivacyPolicyEditMutation($input: SupermarketPrivacyPolicyEditInput!) {
    SupermarketPrivacyPolicyEdit(input: $input) {
      supermarket {
        id
        privacyPolicy
      }
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      supermarket: viewer.supermarket.id
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    SupermarketPrivacyPolicyEdit: {
      supermarket: {
        privacyPolicy: values.privacyPolicy
      }
    }
  }
}

function commit (values, viewer, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: {
          privacyPolicy: values.privacyPolicy
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
