import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import AppReducer from 'components/app/reducers'
import DashboardReducer from 'components/templates/dashboard/reducers'

export default combineReducers({
  form: formReducer,
  app: AppReducer,
  dashboard: DashboardReducer
})
