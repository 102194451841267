import React from 'react'

import Helper from 'components/templates/dashboard/helper'

const ArticlesHelper = () => (
  <Helper>
    <h3>Instruções</h3>
    <div>
      Aqui você pode configurar as <code>Notícias</code> do site.
      <br />
      <br />
      <h4>Dicas:</h4>
      <ul>
        <li>Defina um título com as palavras chaves com grande quantidades de buscas no Google é muito importante para que as pessoas encontem o site do supermercado.</li>
        <li>Crie uma estratégia de marketing baseadas em palavras chaves para vender mais.</li>
      </ul>
      <br />
      <h4>Dicas para as images das notícias:</h4>
      <ul>
        <li><b>Tamanho</b>: Não se preocupe, quando você adiciona uma imagem o sistema já otimiza o arquivo da imagem para que o site fique rápido de carregar.</li>
        <li><b>Formato</b>: É recomendado o uso de imagens do tipo .jpg, pois são bem mais leves. Mas se quiser um pouco mais de qualidade utilize o tipo de imagem .png, o sistema
          otimiza ambos os tipos mas o <b>JPG</b> chega a ficar 5x menor que o <b>PNG</b>.
        </li>
        <li><b>Proporção</b>: <b>1004px</b> de largura por <b>444px</b> de altura.</li>
        <li> Você pode inserir mais imagens em qualquer parte da notícia, basta posicionar o cursor onde você quer que insira a imagem e clicar em +. E depois clicar no icone de
          inserção de imagem.
        </li>
      </ul>
    </div>
  </Helper>
)

export default ArticlesHelper
