import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, ControlLabel, Col, HelpBlock } from 'react-bootstrap'

const Group = (props) => {
  const { label, input, meta, horizontal, labelSize, fieldSize } = props

  const handleValidationState = () => {
    if (meta && meta.error && meta.touched) {
      return 'error'
    }
    if (meta && !meta.error && meta.touched) {
      return 'success'
    }

    return null
  }

  const handleHelp = () => {
    if (meta && meta.error && meta.touched) {
      return <HelpBlock>{meta.error}</HelpBlock>
    }

    return ''
  }

  const handleLabel = () => {
    const labelWithMeta = (
      <div>
        {meta && meta.invalid && (
          <span className='margin-right-5 text-danger'>*</span>
        )}
        {label}
      </div>
    )

    if (horizontal) {
      return (
        <Col componentClass={ControlLabel} sm={labelSize}>
          {labelWithMeta}
        </Col>
      )
    }

    return (
      <ControlLabel>
        {labelWithMeta}
      </ControlLabel>
    )
  }

  const handleField = () => {
    if (horizontal) {
      return (
        <Col sm={!label ? labelSize + fieldSize : fieldSize}>
          {props.children}
          {handleHelp()}
        </Col>
      )
    }

    return (
      <div className='relative'>
        {props.children}
        {handleHelp()}
      </div>
    )
  }

  const controlId = input ? input.name : null

  return (
    <FormGroup controlId={controlId} validationState={handleValidationState()}>
      {label && handleLabel()}
      {handleField()}
    </FormGroup>
  )
}

Group.propTypes = {
  horizontal: PropTypes.bool,
  labelSize: PropTypes.number,
  fieldSize: PropTypes.number
}

Group.defaultProps = {
  horizontal: false,
  labelSize: 3,
  fieldSize: 9
}

export default Group
