/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactIdentification = "FIDELITY_CARD" | "GENERAL" | "RH" | "SAC" | "%future added value";
export type ContactType = "EMAIL" | "PHONE" | "%future added value";
export type ContactEditInput = {|
  id: string,
  title: string,
  identification: ContactIdentification,
  type: ContactType,
  value?: ?string,
  companies?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type ContactEditMutationVariables = {|
  input: ContactEditInput
|};
export type ContactEditMutationResponse = {|
  +ContactEdit: ?{|
    +contact: ?{|
      +id: string,
      +title: ?string,
      +type: ?ContactType,
      +value: ?string,
      +companies: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +name: ?string,
          |}
        |}>
      |},
    |}
  |}
|};
export type ContactEditMutation = {|
  variables: ContactEditMutationVariables,
  response: ContactEditMutationResponse,
|};
*/


/*
mutation ContactEditMutation(
  $input: ContactEditInput!
) {
  ContactEdit(input: $input) {
    contact {
      id
      title
      type
      value
      companies {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ContactEditPayload",
    "kind": "LinkedField",
    "name": "ContactEdit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Contact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyConnection",
            "kind": "LinkedField",
            "name": "companies",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactEditMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactEditMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "01e16820ee7c5ba4723463dc24fde463",
    "id": null,
    "metadata": {},
    "name": "ContactEditMutation",
    "operationKind": "mutation",
    "text": "mutation ContactEditMutation(\n  $input: ContactEditInput!\n) {\n  ContactEdit(input: $input) {\n    contact {\n      id\n      title\n      type\n      value\n      companies {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24a731d3a70ca09ca6b2da0519650ab3';

module.exports = node;
