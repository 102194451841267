export default [
  {
    title: 'Dashboard',
    icon: 'fa fa-diamond',
    route: 'dashboard'
  },
  {
    title: 'Institucional',
    icon: 'fa fa-home',
    permission: 'institutional:edit',
    route: 'dashboard.institutional.edit'
  },
  {
    title: 'Banners',
    icon: 'fa fa-clone',
    submenu: [
      { title: 'Banners principais', permission: 'banners:main:list', route: 'dashboard.banners.main' },
      { title: 'Mini banners', permission: 'banners:mini:list', route: 'dashboard.banners.mini' },
      { title: 'Banners internos', permission: 'banners:internal:list', route: 'dashboard.banners.internal' }
    ]
  },
  {
    title: 'Mensagens',
    icon: 'fa fa-envelope',
    permission: 'contact-messages:list',
    route: 'dashboard.contact-messages'
  },
  {
    title: 'Leads',
    icon: 'fa fa-envelope-o',
    permission: 'leads:list',
    route: 'dashboard.leads'
  },
  {
    title: 'Notícias',
    icon: 'fa fa-newspaper-o',
    permission: 'articles:list',
    route: 'dashboard.articles'
  },
  {
    title: 'Receitas',
    icon: 'fa fa-cutlery',
    permission: 'recipes:list',
    route: 'dashboard.recipes'
  },
  {
    title: 'Contatos',
    icon: 'fa fa-address-book-o',
    permission: 'contacts:list',
    route: 'dashboard.contacts'
  },
  {
    title: 'Encartes',
    icon: 'fa fa-book',
    permission: 'offer-catalogs:list',
    route: 'dashboard.offer-catalogs'
  },
  {
    title: 'Produtos',
    icon: 'fa fa-shopping-cart',
    permission: 'products:list',
    route: 'dashboard.products'
  },
  {
    title: 'Grupos de Produtos',
    icon: 'fa fa-clone',
    permission: 'product-groups:list',
    route: 'dashboard.product-groups'
  },
  {
    title: 'Lojas',
    icon: 'fa fa-building-o',
    permission: 'companies:list',
    route: 'dashboard.companies'
  },
  {
    title: 'Cartão Fidelidade',
    icon: 'fa fa-address-card-o',
    permission: 'fidelity-card:edit',
    route: 'dashboard.fidelity-card.edit'
  },
  {
    title: 'Acesso',
    icon: 'fa fa-users',
    submenu: [
      { title: 'Grupos', permission: 'roles:list', route: 'dashboard.roles' },
      { title: 'Usuários', permission: 'users:list', route: 'dashboard.users' }
    ]
  },
  {
    title: 'Política de Privacidade',
    icon: 'fa fa-shield',
    permission: 'privacy:edit',
    route: 'dashboard.privacy.edit'
  },
  {
    title: 'Configurações',
    icon: 'fa fa-cogs',
    submenu: [
      { title: 'Geral', permission: 'configuration:general:edit', route: 'dashboard.configuration.general' },
      { title: 'Mídias sociais', permission: 'configuration:social:edit', route: 'dashboard.configuration.social' },
      { title: 'Buscadores', permission: 'configuration:search:edit', route: 'dashboard.configuration.search' },
      { title: 'E-mail', permission: 'configuration:email:edit', route: 'dashboard.configuration.email' },
      { title: 'Tema', permission: 'configuration:theme:edit', route: 'dashboard.configuration.theme' },
      { title: 'Disponibilidade', permission: 'configuration:maintenance:edit', route: 'dashboard.configuration.maintenance' }
    ]
  }
]
