import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'

import Content from 'components/templates/dashboard/content'
import { toMutation } from 'components/forms'
import { getMessagesFromErrors } from 'utils/messages'
import { routeTo } from 'router'
import ProductsForm from './products-form'
import ProductsHelper from './products-helper'
import ProductAddMutation from 'relay/mutations/ProductAddMutation'

class ProductsAdd extends Component {
  constructor (props) {
    super(props)

    this.state = {loading: false}
  }

  _loading (loading) {
    this.setState({...this.state, loading})
  }

  _success (newProductId) {
    this.props.notify('success', 'Sucesso', 'Produto adicionado com sucesso')
    this.props.history.push(routeTo('dashboard.products.edit', {id: newProductId}))
  }

  _error (message) {
    this.props.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    ProductAddMutation.commit(
      toMutation(values), {
        onCompleted: ({ProductAdd}) => {
          this._loading(false)
          this._success(ProductAdd.productEdge.node.id)
        },
        onError: (errors) => {
          this._loading(false)

          getMessagesFromErrors(errors).forEach(m => this._error(m))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title='Adicionar Produto'
        permission='products:add'
        menu={{link: 'Produtos'}}
        breadcrumb={{
          title: 'Produtos',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/products', title: 'Produtos'},
            {title: 'Adicionar'}
          ]
        }} >
        <Row>
          <Col sm={7}>
            <ProductsForm
              loading={this.state.loading}
              initialValues={{
                active: true,
                additionalInfo: '<p></p>',
                hasVariableWeight: false
              }}
              editing={false}
              onSubmit={(values) => this._onSubmit(values)}
            />
          </Col>
          <Col sm={5}>
            <ProductsHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const ProductsAddRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query productsAdd_Query {
        viewer {
          id
        }
      }
    `}
    render={({props: rendererProps}) => (<ProductsAdd {...rendererProps} {...props} />)}
  />
)

export default connect(null, appActionCreators)(ProductsAddRenderer)
