import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation ProductGroupAddMutation($input: ProductGroupAddInput!) {
    ProductGroupAdd(input: $input) {
      productGroupEdge {
        node {
          id
          title
          description
          sortBy
          active
          start
          end
          weekDays
        }
      }
    }
  }
`

function commit (values, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: values
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
