import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation ProductSetPriceMutation($input: ProductSetPriceInput!) {
    ProductSetPrice(input: $input) {
      product {
        id
        prices {
          company {
            id
            name
          }
          price
          promotion
          promotionalPrice
        }
      }
    }
  }
`

function commit (input, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: { input },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
