/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type offerCatalogsAdd_QueryVariables = {||};
export type offerCatalogsAdd_QueryResponse = {|
  +config: ?{|
    +maxUploadSize: ?string
  |},
  +viewer: ?{|
    +id: string,
    +companies: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
  |},
|};
export type offerCatalogsAdd_Query = {|
  variables: offerCatalogsAdd_QueryVariables,
  response: offerCatalogsAdd_QueryResponse,
|};
*/


/*
query offerCatalogsAdd_Query {
  config {
    maxUploadSize
  }
  viewer {
    id
    companies(selectableInMenu: true) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Config",
    "kind": "LinkedField",
    "name": "config",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maxUploadSize",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "selectableInMenu",
            "value": true
          }
        ],
        "concreteType": "CompanyConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "companies(selectableInMenu:true)"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "offerCatalogsAdd_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "offerCatalogsAdd_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d6bf708035b08d88feadde0025b5ee16",
    "id": null,
    "metadata": {},
    "name": "offerCatalogsAdd_Query",
    "operationKind": "query",
    "text": "query offerCatalogsAdd_Query {\n  config {\n    maxUploadSize\n  }\n  viewer {\n    id\n    companies(selectableInMenu: true) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '114c2439a761f33055f77433f5bd1ffc';

module.exports = node;
