import Masker from 'vanilla-masker'

export const vanillaMoneyFormatOpts = {
  // Decimal precision -> "90"
  precision: 2,
  // Decimal separator -> ",90"
  separator: ',',
  // Number delimiter -> "12.345.678"
  delimiter: '.',
  // Money unit -> "R$ 12.345.678,90"
  unit: 'R$',
  // Money unit -> "12.345.678,90 R$"
  suffixUnit: ''
}

export function toMoney (value) {
  if (!value) {
    return null
  }

  return Masker.toMoney(value.toFixed(vanillaMoneyFormatOpts.precision), vanillaMoneyFormatOpts)
}

export function moneyToNumber (formattedValue) {
  if (!formattedValue) {
    return null
  }

  const moneyNumbers = Masker.toNumber(formattedValue, vanillaMoneyFormatOpts)
  const dotPosition = moneyNumbers.length - vanillaMoneyFormatOpts.precision

  return Number(`${moneyNumbers.substr(0, dotPosition)}.${moneyNumbers.substr(dotPosition)}`)
}
