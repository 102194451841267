import { commitMutation, graphql } from 'react-relay'

import { removeUpdater } from 'relay'

const mutation = graphql`
  mutation RoleRemoveMutation($input: RoleRemoveInput!) {
    RoleRemove(input: $input) {
      deletedID
      error
    }
  }
`

function commit (environment, role, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: role.id
        }
      },
      updater: (store) => {
        const payload = store.getRootField('RoleRemove')
        const deletedID = payload.getValue('deletedID')

        removeUpdater(store, viewer, 'list_roles', deletedID)
      },
      optimisticUpdater: (store) => {
        removeUpdater(store, viewer, 'list_roles', role.id)
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
