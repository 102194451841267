/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoleEditInput = {|
  id: string,
  name: string,
  permissions?: ?$ReadOnlyArray<?string>,
  clientMutationId?: ?string,
|};
export type RoleEditMutationVariables = {|
  input: RoleEditInput
|};
export type RoleEditMutationResponse = {|
  +RoleEdit: ?{|
    +role: ?{|
      +id: string,
      +name: ?string,
    |},
    +error: ?string,
  |}
|};
export type RoleEditMutation = {|
  variables: RoleEditMutationVariables,
  response: RoleEditMutationResponse,
|};
*/


/*
mutation RoleEditMutation(
  $input: RoleEditInput!
) {
  RoleEdit(input: $input) {
    role {
      id
      name
    }
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RoleEditPayload",
    "kind": "LinkedField",
    "name": "RoleEdit",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoleEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "839fcb76370ac6281c6674ec20bb9a3e",
    "id": null,
    "metadata": {},
    "name": "RoleEditMutation",
    "operationKind": "mutation",
    "text": "mutation RoleEditMutation(\n  $input: RoleEditInput!\n) {\n  RoleEdit(input: $input) {\n    role {\n      id\n      name\n    }\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7303d59e751036fd3664c25508404c1f';

module.exports = node;
