import React from 'react'

import Helper from 'components/templates/dashboard/helper'

export default () => (
  <Helper>
    <h3>Instruções</h3>
    <p>
      Aqui você pode configurar todos os contatos de telefone e e-mail para o site. <b>Contato Geral</b>, de <b>Trabalhe Conosco</b>, de <b>SAC - Serviço de Atendimento ao Consumidor</b> e de solicitação de <b>Cartão Fidelidade</b> para cada loja.
      <br />
      <br />
      <h4>Dicas:</h4>
      <ul>
        <li>O campo <b>Valor</b> pode ser tanto para um e-mail quanto para um telefone, conforme o que for selecionado no campo <b>Tipo</b>.</li>
      </ul>
    </p>
  </Helper>
)
