/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type offerCatalogsForm_categories_viewer$ref = any;
export type offerCatalogsForm_categories_QueryVariables = {|
  search?: ?string
|};
export type offerCatalogsForm_categories_QueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: offerCatalogsForm_categories_viewer$ref
  |}
|};
export type offerCatalogsForm_categories_Query = {|
  variables: offerCatalogsForm_categories_QueryVariables,
  response: offerCatalogsForm_categories_QueryResponse,
|};
*/


/*
query offerCatalogsForm_categories_Query(
  $search: String
) {
  viewer {
    ...offerCatalogsForm_categories_viewer
    id
  }
}

fragment offerCatalogsForm_categories_viewer on Viewer {
  id
  offerCatalogCategories(search: $search) {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "offerCatalogsForm_categories_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "offerCatalogsForm_categories_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "offerCatalogsForm_categories_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "search",
                "variableName": "search"
              }
            ],
            "concreteType": "OfferCatalogCategory",
            "kind": "LinkedField",
            "name": "offerCatalogCategories",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "71a93aa9fbb0d9bd966fc08faad14227",
    "id": null,
    "metadata": {},
    "name": "offerCatalogsForm_categories_Query",
    "operationKind": "query",
    "text": "query offerCatalogsForm_categories_Query(\n  $search: String\n) {\n  viewer {\n    ...offerCatalogsForm_categories_viewer\n    id\n  }\n}\n\nfragment offerCatalogsForm_categories_viewer on Viewer {\n  id\n  offerCatalogCategories(search: $search) {\n    id\n    name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '448aa0eb9c3f17501accdbe44de0fc60';

module.exports = node;
