import React, { Component } from 'react'
import { Field } from 'redux-form'
import Group from './group'
import { ChromePicker } from 'react-color'
import _ from 'lodash'

class ColorPickerGroup extends Component {
  UNSAFE_componentWillMount () {
    const { input, colorSet } = this.props
    const { value: selectedColors } = input

    const colors = colorSet.map(({ name, default: defaultColor }) => {
      const existingColor = _.find(selectedColors, { name })
      const value = existingColor ? existingColor.value : defaultColor

      return { name, value }
    })

    input.onChange(colors)
  }

  onColorChange (colorName, color) {
    const { input } = this.props
    const { value: selectedColors } = input

    const changedColorIndex = _.findIndex(selectedColors, { name: colorName })

    const changedColors = _.cloneDeep(selectedColors)
    changedColors[changedColorIndex].value = color.hex

    input.onChange(changedColors)
  }

  getColorValue (colorName, selectedColors) {
    return _.find(selectedColors, { name: colorName }).value
  }

  render () {
    const { input, meta, colorSet } = this.props
    return (
      <div>
        {input.value.length > 0 && colorSet.map(colorDef => (
          <Group key={colorDef.name} label={colorDef.label} input={input} meta={meta} horizontal>
            <ChromePicker
              color={this.getColorValue(colorDef.name, input.value)}
              onChangeComplete={(color, event) => this.onColorChange(colorDef.name, color)}
              disableAlpha
            />
          </Group>
        ))}
      </div>
    )
  }
}

const ColorPickerGroupField = (props) => (
  <Field {...props} component={ColorPickerGroup} />
)

export default ColorPickerGroupField
