import React, { Component } from 'react'
import { Field } from 'redux-form'
import moment from 'moment'
import Group from './group'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'

moment.locale('pt-BR')

class DateTimePicker extends Component {
  parseDate (date) {
    return date ? moment(date).toDate() : ''
  }

  render () {
    return (
      <Group {...this.props}>
        <Datetime
          value={this.parseDate(this.props.input.value)}
          onChange={(moment) => this.props.input.onChange(moment ? moment.toDate() : null)}
          onBlur={(moment) => this.props.input.onBlur(moment ? moment.toDate() : null)}
          onfocus={(moment) => this.props.input.onFocus(moment ? moment.toDate() : null)}
        />
      </Group>
    )
  }
}

const DateTimePickerField = (props) => (
  <Field {...props} component={DateTimePicker} />
)

export default DateTimePickerField
