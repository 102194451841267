import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import DocumentTitle from 'components/app/document-title'
import Breadcrumb from 'components/templates/dashboard/breadcrumb'
import ActiveMenuHandler from './sidebar/active-menu-handler'
import { fetchPermissions } from 'security/authorization'

class Content extends Component {
  state = {
    permissions: {}
  }

  async componentDidMount () {
    if (this.props.permission) {
      const permissions = await fetchPermissions([
        this.props.permission
      ])

      if (!permissions[this.props.permission]) {
        this.props.actions.notify('error', 'Erro', 'Você não possui permissões para acessar este recurso.')
        this.props.history.goBack()
      }
    }
  }

  render () {
    return (
      <div className='content-wrapper'>
        <DocumentTitle>{this.props.title}</DocumentTitle>
        <ActiveMenuHandler group={this.props.menu.group} link={this.props.menu.link} />

        {this.props.breadcrumb && (
          <Breadcrumb {...this.props.breadcrumb} />
        )}

        <div className='wrapper wrapper-content'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Content.propTypes = {
  title: PropTypes.string,
  permission: PropTypes.string,
  menu: PropTypes.shape({
    group: PropTypes.string,
    link: PropTypes.string
  }),
  breadcrumb: PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.array,
    actions: PropTypes.node
  })
}

Content.defaultProps = {
  menu: {
    group: '',
    link: ''
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default withRouter(connect(null, mapDispatchToProps)(Content))
