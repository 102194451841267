/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OfferCatalogEditActiveInput = {|
  id: string,
  active: boolean,
  clientMutationId?: ?string,
|};
export type OfferCatalogEditActiveMutationVariables = {|
  input: OfferCatalogEditActiveInput
|};
export type OfferCatalogEditActiveMutationResponse = {|
  +OfferCatalogEditActive: ?{|
    +offerCatalog: ?{|
      +id: string,
      +active: ?boolean,
    |}
  |}
|};
export type OfferCatalogEditActiveMutation = {|
  variables: OfferCatalogEditActiveMutationVariables,
  response: OfferCatalogEditActiveMutationResponse,
|};
*/


/*
mutation OfferCatalogEditActiveMutation(
  $input: OfferCatalogEditActiveInput!
) {
  OfferCatalogEditActive(input: $input) {
    offerCatalog {
      id
      active
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OfferCatalogEditActivePayload",
    "kind": "LinkedField",
    "name": "OfferCatalogEditActive",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OfferCatalog",
        "kind": "LinkedField",
        "name": "offerCatalog",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OfferCatalogEditActiveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OfferCatalogEditActiveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "69909267bc229fd1a9d50a086c55d536",
    "id": null,
    "metadata": {},
    "name": "OfferCatalogEditActiveMutation",
    "operationKind": "mutation",
    "text": "mutation OfferCatalogEditActiveMutation(\n  $input: OfferCatalogEditActiveInput!\n) {\n  OfferCatalogEditActive(input: $input) {\n    offerCatalog {\n      id\n      active\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1efc89b0c56c3200baa3bf6ee0adde09';

module.exports = node;
