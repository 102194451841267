import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation ProfileEditMutation($input: ProfileEditInput!) {
    ProfileEdit(input: $input) {
      me {
        id
        name
        firstName
        email
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      me: viewer.me.id
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    ProfileEdit: {
      me: {
        name: values.name,
        firstName: values.name.split(' ')[0],
        email: values.email
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          name: values.name,
          email: values.email,
          oldPassword: values.oldPassword,
          password: values.password
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
