import React from 'react'

import Helper from 'components/templates/dashboard/helper'

const BannersHelper = () => (
  <Helper>
    <h3>Instruções</h3>
    <p>
      Aqui você pode configurar os <code>Banners principais</code>, os <code>Banners internos</code> ou os <code>Mini banners</code> do site, de acordo com a opção seleciona do menu lateral a esquerda.
      <br />
      <br />
      <h4>Dicas para imagem do banner:</h4>
      <ul>
        <li><b>Tamanho</b>: Não se preocupe, quando você adiciona uma imagem o sistema já otimiza o arquivo da imagem para que o site fique rápido de carregar.</li>
        <li><b>Formato</b>: É recomendado o uso de imagens do tipo <b>.jpg</b>, pois são bem mais leves. Mas se quiser um pouco mais de qualidade utilize o tipo de imagem .png, o sistema otimiza ambos os tipos mas o JPG chega a ficar 5x menor que o PNG.</li>
        <li><b>Proporção</b> do Banner principal: <b>1920px</b> de largura por <b>922px</b> de altura.</li>
        <ul>
          <li>Para que os textos da imagem fiquem visíveis em aparelhos de diferentes tamanhos de tela, o texto deve ficar a <b>260px do topo</b>, a <b>200px da base</b>, a <b>160px da esquerda</b> e a <b>160px da direita</b>.</li>
        </ul>
        <li><b>Proporção</b> do Banner interno: <b>1920px</b> de largura por <b>564px</b> de altura.</li>
        <li><b>Proporção</b> do Mini banner: <b>380px</b> de largura por <b>380px</b> de altura.</li>
      </ul>
    </p>
  </Helper>
)

export default BannersHelper
