/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type dashboardTemplate_QueryVariables = {||};
export type dashboardTemplate_QueryResponse = {|
  +viewer: ?{|
    +me: ?{|
      +id: string,
      +firstName: ?string,
    |}
  |}
|};
export type dashboardTemplate_Query = {|
  variables: dashboardTemplate_QueryVariables,
  response: dashboardTemplate_QueryResponse,
|};
*/


/*
query dashboardTemplate_Query {
  viewer {
    me {
      id
      firstName
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "me",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dashboardTemplate_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dashboardTemplate_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "261d68057ea5b8f7a19e4fe7a45c606d",
    "id": null,
    "metadata": {},
    "name": "dashboardTemplate_Query",
    "operationKind": "query",
    "text": "query dashboardTemplate_Query {\n  viewer {\n    me {\n      id\n      firstName\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f25d93c34d2d60a93e41735555bb4b08';

module.exports = node;
