import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-relay'
import Environment from 'relay'
import Panel from 'components/panel'
import Spinner from 'components/spinner'
import createForm, { Form, Input, DynamicSelect, Buttons, PhoneInput } from 'components/forms'
import Validation from 'utils/validation'

class LeadsForm extends Component {
  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={loading} />

        <Panel>
          <Input name='email' horizontal type='text' label='E-mail' />

          <hr className='hr-line-dashed' />

          <PhoneInput name='phone' horizontal type='text' label='Telefone' />

          <hr className='hr-line-dashed' />
          <PhoneInput name='whatsapp' horizontal type='text' label='WhatsApp' />

          <hr className='hr-line-dashed' />
          <Input name='name' horizontal type='text' label='Nome' />

          <hr className='hr-line-dashed' />
          <DynamicSelect
            name='company'
            horizontal
            label='Loja'
            environment={Environment}
            query={graphql`
              query leadsForm_companies_Query($first: Int!, $search: String) {
                viewer {
                  ...leadsForm_companies_viewer
                }
              }
            `}
            fragments={{
              viewer: graphql`
                fragment leadsForm_companies_viewer on Viewer {
                  id
                  companies(first: $first, search: $search) @connection(key: "list_companies") {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              `
            }}
          />
        </Panel>

        <Panel>
          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
           />
        </Panel>
      </Form>
    )
  }
}

LeadsForm.defaultProps = {
  loading: false
}

LeadsForm.propTypes = {
  loading: PropTypes.bool
}

const leadsValidation = new Validation({
  email: [Validation.required, Validation.email],
  company: [Validation.required],
  phone: [Validation.phone],
  whatsapp: [Validation.phone]
})

export default createForm(LeadsForm, leadsValidation)
