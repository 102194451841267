import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Panel from 'components/panel'
import Spinner from 'components/spinner'
import createForm, { Form, Input, Select, CheckboxGroup, Buttons, PhoneInput } from 'components/forms'
import Validation from 'utils/validation'
import { contactIdentificationOptions } from 'utils/constants'
import { formValueSelector } from 'redux-form'

class ContactsForm extends Component {
  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading, viewer, typeIsPhone } = this.props

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={loading} />

        <Panel>
          <Select
            name='identification'
            horizontal
            label='Título de Identificação'
            options={contactIdentificationOptions}
            clearable={false}
          />

          <hr className='hr-line-dashed' />
          <Select
            name='type'
            horizontal
            label='Tipo'
            options={{
              'PHONE': 'Telefone',
              'EMAIL': 'E-mail'
            }}
            clearable={false}
          />

          <hr className='hr-line-dashed' />
          {typeIsPhone ? (
            <PhoneInput name='value' horizontal type='text' label='Valor' />
          ) : (
            <Input name='value' horizontal type='text' label='Valor' />
          )}

          <hr className='hr-line-dashed' />
          <CheckboxGroup
            enableSelectAll
            horizontal
            name='companies'
            label='Lojas'
            options={viewer.companies.edges.map(e => e.node)}
          />
        </Panel>

        <Panel>
          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
           />
        </Panel>
      </Form>
    )
  }
}

ContactsForm.defaultProps = {
  loading: false
}

ContactsForm.propTypes = {
  loading: PropTypes.bool
}

const validation = (values) => {
  const errors = {}
  if (Validation.required(values.title)) {
    errors.title = Validation.required(values.title)
  }

  if (Validation.required(values.type)) {
    errors.type = Validation.required(values.type)
  }

  if (Validation.required(values.value)) {
    errors.value = Validation.required(values.value)
  } else if (values.type === 'EMAIL' && Validation.email(values.value)) {
    errors.value = Validation.email(values.value)
  } else if (values.type === 'PHONE' && Validation.phone(values.value)) {
    errors.value = Validation.phone(values.value)
  }

  return errors
}

const selector = formValueSelector(ContactsForm.name)

export default connect(
  (state, props) => ({
    typeIsPhone: selector(state, 'type') === 'PHONE'
  })
)(createForm(ContactsForm, validation))
