import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation SupermarketSocialEditMutation($input: SupermarketSocialEditInput!) {
    SupermarketSocialEdit(input: $input) {
      supermarket {
        id
        social {
          image {
            url
          }
          facebook
          twitter
          googleplus
          youtube
          instagram
        }
      }
    }
  }
`

function commit (values, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: values
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
