/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type configurationEmailEdit_QueryVariables = {||};
export type configurationEmailEdit_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +supermarket: ?{|
      +id: string,
      +sesEmailAddress: ?string,
    |},
  |}
|};
export type configurationEmailEdit_Query = {|
  variables: configurationEmailEdit_QueryVariables,
  response: configurationEmailEdit_QueryResponse,
|};
*/


/*
query configurationEmailEdit_Query {
  viewer {
    id
    supermarket {
      id
      sesEmailAddress
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Supermarket",
        "kind": "LinkedField",
        "name": "supermarket",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sesEmailAddress",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "configurationEmailEdit_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "configurationEmailEdit_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2c705ac510015751a527c7836333ead7",
    "id": null,
    "metadata": {},
    "name": "configurationEmailEdit_Query",
    "operationKind": "query",
    "text": "query configurationEmailEdit_Query {\n  viewer {\n    id\n    supermarket {\n      id\n      sesEmailAddress\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0e2d0d85f60de5bcc1f083e72f806007';

module.exports = node;
