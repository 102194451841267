import React from 'react'
import Panel from 'components/panel'
import ViewedLabel from './viewed-label'
import {formatExtended} from 'utils/date'

export default (props) => (
  <Panel>
    <label>Nome: </label> {props.values.name} <br />
    <label>E-mail: </label> {props.values.email} <br />
    <label>Telefone: </label> {props.values.phone} <br />
    <label>Assunto: </label> {props.values.subject} <br />
    <label>Situação: </label> <ViewedLabel viewed={props.viewedAt} /> <br />
    <hr className='hr-line-dashed' />
    <label>Mensagem: </label> <br />
    {props.values.message}
    <hr className='hr-line-dashed' />
    <label>Data da Visualização: </label> {formatExtended(props.viewedAt)}
  </Panel>
)
