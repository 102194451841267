import React from 'react'

import DBlinksLogo from 'components/dblinks/logo'
import DBlinksIcon from 'components/dblinks/icon'
import DBlinksInfo from 'components/dblinks/info'

const MenuHeader = () => (
  <li className='nav-header'>
    <div className='logo'>
      <div className='table-wrapper'>
        <div className='table-cell'>
          <a alt={DBlinksInfo.nome} href={DBlinksInfo.site} target='_blank'>
            <div className='full'>
              <DBlinksLogo color='#f9f9fa' />
            </div>
            <div className='mini'>
              <DBlinksIcon />
            </div>
          </a>
        </div>
      </div>
    </div>
  </li>
)

export default MenuHeader
