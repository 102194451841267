/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductStamp = "DIET" | "ECONOMIC" | "FROZEN" | "GLUTEN_FREE" | "IMPORTED" | "LOW_FAT" | "ORGANIC" | "OWN_BRAND" | "%future added value";
export type ProductAddInput = {|
  name: string,
  image?: ?FileInput,
  gtin: any,
  size?: ?number,
  unitOfMeasure?: ?string,
  saleUnit?: ?string,
  hasVariableWeight?: ?boolean,
  additionalInfo?: ?string,
  stamps?: ?$ReadOnlyArray<?ProductStamp>,
  active: boolean,
  clientMutationId?: ?string,
|};
export type FileInput = {|
  key?: ?string,
  url?: ?string,
  name?: ?string,
  size?: ?number,
  type?: ?string,
|};
export type ProductAddMutationVariables = {|
  input: ProductAddInput
|};
export type ProductAddMutationResponse = {|
  +ProductAdd: ?{|
    +productEdge: ?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}
  |}
|};
export type ProductAddMutation = {|
  variables: ProductAddMutationVariables,
  response: ProductAddMutationResponse,
|};
*/


/*
mutation ProductAddMutation(
  $input: ProductAddInput!
) {
  ProductAdd(input: $input) {
    productEdge {
      node {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProductAddPayload",
    "kind": "LinkedField",
    "name": "ProductAdd",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductEdge",
        "kind": "LinkedField",
        "name": "productEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductAddMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cf3ff53cc9f87b992bad46ea8cfe107b",
    "id": null,
    "metadata": {},
    "name": "ProductAddMutation",
    "operationKind": "mutation",
    "text": "mutation ProductAddMutation(\n  $input: ProductAddInput!\n) {\n  ProductAdd(input: $input) {\n    productEdge {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '498cd8f788a2762aa9f9ceae1ea66ddd';

module.exports = node;
