import React, { Component } from 'react'
import { Grid } from 'react-bootstrap'

import Header from './header'
import Footer from './footer'
import { drawRoutes } from 'router'

import 'bootstrap/less/bootstrap.less'
import 'assets/less/app.less'
import 'assets/less/util.less'
import loginStyles from 'assets/less/login.less'

class LoginTemplate extends Component {
  state = {
    initialized: false
  }

  componentDidMount () {
    setTimeout(() => {
      this.setState({
        initialized: true
      })
    }, 100)
  }

  render () {
    let wrapperClass = loginStyles.wrapper
    if (!this.state.initialized) {
      wrapperClass += ' ' + loginStyles.unitializedWrapper
    }

    return (
      <div className={wrapperClass}>
        <Grid>
          <div className={loginStyles.cardWrapper}>
            <div className='table-wrapper'>
              <div className='table-cell'>
                <Header initialized={this.state.initialized} />

                <div className={loginStyles.card}>
                  {drawRoutes(this.props.routes)}
                </div>

                <Footer initialized={this.state.initialized} />
              </div>
            </div>
          </div>
        </Grid>

        <div className={loginStyles.background} />
      </div>
    )
  }
}

export default LoginTemplate
