import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Content from 'components/templates/dashboard/content'
import DataList, { Action, Actions } from 'components/datalist'
import { routeTo } from 'router'
import Environment from 'relay'
import { graphql, QueryRenderer } from 'react-relay'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'
import { fetchPermissions } from 'security/authorization'
import ViewedLabel from './viewed-label'
import { contactMessageTypes } from 'utils/constants'

class ContactMessagesList extends Component {
  state = {
    permissions: {}
  }

  async componentDidMount () {
    const permissions = await fetchPermissions(['contact-messages:view'])

    this.setState({ permissions })
  }

  render () {
    return (
      <Content title='Mensagens'
               permission='contact-messages:list'
               menu={{ link: 'Mensagens' }}
               breadcrumb={{
                 title: 'Mensagens',
                 items: [
                   { url: '/dashboard', title: 'Dashboard' },
                   { url: '/dashboard/contact-messages', title: 'Mensagens' },
                   { title: 'Listar' }
                 ]
               }}>
        <DataList
          viewer={null}
          name='ContactMessagesList'
          environment={Environment}
          query={graphql`
            query contactMessagesList_datalist_Query($first: Int!, $search: String) {
              viewer {
                ...contactMessagesList_datalist_viewer
              }
            }
          `}
          fragments={{
            viewer: graphql`
              fragment contactMessagesList_datalist_viewer on Viewer {
                id
                contactMessages(first: $first, search: $search) @connection(key: "list_contactMessages", filters: []) {
                  edges {
                    node {
                      id
                      type
                      subject
                      viewedAt
                    }
                  }
                  pageInfo {
                    hasNextPage
                  }
                }
              }
            `
          }}
          columns={[
            { name: 'Assunto', property: 'subject' },
            { name: 'Tipo', callback: contactMessage => (contactMessageTypes[contactMessage.type]) },
            { name: 'Situação', callback: contactMessage => (<ViewedLabel viewed={contactMessage.viewedAt}/>) },
            {
              name: 'Ação',
              callback: contactMessage => (
                <Actions>
                  {this.state.permissions['contact-messages:view'] && (
                    <Action title='Visualizar'>
                      <Link to={routeTo('dashboard.contact-messages.show', { id: contactMessage.id })}><i className='fa fa-eye'/></Link>
                    </Action>
                  )}
                </Actions>
              )
            }
          ]}/>

      </Content>

    )
  }
}

const ContactMessagesListRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query contactMessagesList_viewer_Query {
        viewer {
          id
        }
      }
    `}
    render={({ props: rendererProps }) => {
      if (!rendererProps) return null
      return (
        <ContactMessagesList
          {...rendererProps}
          {...props}
        />
      )
    }}
  />
)

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(ContactMessagesListRenderer)
