import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation UserEditMutation($input: UserEditInput!) {
    UserEdit(input: $input) {
      user {
        id
        name
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      user: viewer.user.id
    }
  }]
}

function getOptimisticResponse (values) {
  const optimisticResponse = {
    role: values.role,
    name: values.name,
    firstName: values.name.split(' ')[0],
    email: values.email
  }

  return {
    UserEdit: {
      me: optimisticResponse,
      user: optimisticResponse
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: values.id,
          role: values.role.id,
          name: values.name,
          email: values.email,
          password: values.password
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
