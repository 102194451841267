import React from 'react'
import PropTypes from 'prop-types'

const Icon = (props) => {
  const { width } = props

  const st0 = {fillRule: 'evenodd', clipRule: 'evenodd', fill: '#5EB553'}
  const st1 = {fillRule: 'evenodd', clipRule: 'evenodd', fill: '#489C50'}
  const st2 = {clipPath: 'url(#SVGID_2_)', fill: 'url(#SVGID_3_)'}
  const st3 = {fillRule: 'evenodd', clipRule: 'evenodd', fill: '#59AE59'}
  const st4 = {fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FEFEFE'}

  return (
    <svg width={width} viewBox='0 0 8.6 8.4'>
      <path style={st0} d='M6.1,0.9l1.5,1.5c1,1,1,2.6,0,3.6L6.1,7.5c-1,1-2.6,1-3.6,0L1,6C0,5,0,3.4,1,2.4l1.5-1.5
        C3.5-0.1,5.1-0.1,6.1,0.9' />
      <path style={st1} d='M3.5,8.1C3.1,8,2.8,7.8,2.5,7.5L1,6C0,5,0,3.4,1,2.4l1.5-1.5c0.4-0.4,1-0.7,1.5-0.7c0.4,0.1,0.7,0.3,1,0.6
        l1.5,1.5c1,1,1,2.6,0,3.6L5,7.4C4.6,7.8,4,8.1,3.5,8.1' />
      <g>
        <defs>
          <path id='SVGID_1_' d='M5,7h1.5l1-1c0.7-0.7,0.9-1.8,0.6-2.7C8.1,3.2,8,3.1,7.9,3L6.4,1.5c-1-1-2.6-1-3.6,0L1.3,3
            C1,3.3,0.8,3.7,0.7,4.1C1.4,5.8,3.1,7,5,7' />
        </defs>
        <clipPath id='SVGID_2_'>
          <use style={{overflow: 'visible'}} />
        </clipPath>
        <linearGradient id='SVGID_3_' gradientUnits='userSpaceOnUse' x1='1.5906' y1='6.7446' x2='6.9607' y2='1.4046'>
          <stop offset='0' style={{stopColor: '#73C163'}} />
          <stop offset='1' style={{stopColor: '#60B856'}} />
        </linearGradient>
        <rect x='0.7' y='0.5' style={st2} width='7.8' height='6.5' />
      </g>
      <path style={st3} d='M5,7h0.3l1.1-1.1c1-1,1-2.6,0-3.6L5,0.8C4.7,0.5,4.4,0.3,4,0.2C3.5,0.3,2.9,0.5,2.5,0.9L1,2.4
        C0.7,2.7,0.5,3,0.4,3.3C0.9,5.4,2.8,7,5,7' />
      <path style={st3} d='M5,7h0.3l1.1-1.1c1-1,1-2.6,0-3.6L5,0.8C4.7,0.5,4.4,0.3,4,0.2h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
        c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0L1,2.4c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0
        c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1l0,0
        c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1l0,0c0,0,0,0,0,0.1v0c0,0,0,0,0,0.1C0.9,5.4,2.8,7,5,7' />
      <path style={st4} d='M5.4,4.4L5.4,4.4h0.2c0.3,0,0.6,0.1,0.6,0.4c0,0.3-0.2,0.4-0.5,0.4c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0
        c0,0,0,0,0-0.1v0L5.4,4.4L5.4,4.4C5.3,4.4,5.3,4.4,5.4,4.4 M5.4,3.2C5.4,3.2,5.4,3.2,5.4,3.2c0.1,0,0.1,0,0.2,0C5.9,3.2,6,3.3,6,3.5
        c0,0.2-0.2,0.3-0.5,0.3H5.4h0c0,0,0,0,0,0v0L5.4,3.2L5.4,3.2C5.3,3.2,5.3,3.2,5.4,3.2z M2.3,3.2C2.3,3.2,2.3,3.2,2.3,3.2
        c0.1,0,0.1,0,0.2,0c0.5,0,0.9,0.3,0.9,0.9c0,0.7-0.4,1-1,1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0v0L2.3,3.2L2.3,3.2
        C2.2,3.2,2.3,3.2,2.3,3.2z M1.4,5.7C1.4,5.7,1.4,5.7,1.4,5.7c0.2,0,0.4,0,0.8,0c0.6,0,1.1-0.1,1.5-0.4C4,5.2,4.3,4.7,4.3,4.1
        c0-0.6-0.2-1-0.6-1.2C3.4,2.7,3,2.6,2.4,2.6C2,2.6,1.7,2.6,1.4,2.7c0,0,0,0,0,0c0,0,0,0,0,0.1v0L1.4,5.7L1.4,5.7
        C1.4,5.7,1.4,5.7,1.4,5.7z M4.5,5.7C4.5,5.7,4.5,5.7,4.5,5.7c0.2,0,0.4,0,0.8,0c0.7,0,1.1-0.1,1.3-0.3C6.8,5.3,7,5.1,7,4.8
        C7,4.4,6.7,4.1,6.4,4v0c0.4-0.1,0.5-0.4,0.5-0.7c0-0.3-0.2-0.5-0.4-0.7c-0.2-0.1-0.5-0.2-1-0.2c-0.4,0-0.7,0.1-0.9,0.1c0,0,0,0,0,0
        c0,0,0,0,0,0v0L4.5,5.7L4.5,5.7C4.5,5.7,4.5,5.7,4.5,5.7z' />
      <path style={st4} d='M7.2,3.4l0-0.7c0,0,0-0.1-0.1-0.1H6.8C6.9,2.8,7,3,7.1,3.3C7.1,3.3,7.1,3.4,7.2,3.4' />
    </svg>
  )
}

Icon.propTypes = {
  width: PropTypes.number
}

Icon.defaultProps = {
  width: 250
}

export default Icon
