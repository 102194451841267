import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation CompanyEditMutation($input: CompanyEditInput!) {
    CompanyEdit(input: $input) {
      company {
        id
        name
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      company: viewer.company.id
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    CompanyEdit: {
      company: {
        state: values.state,
        city: values.city,
        name: values.name,
        email: values.email,
        phone: values.phone,
        image: values.image,
        address: values.address,
        number: values.number,
        neighborhood: values.neighborhood,
        zipCode: values.zipCode,
        parkingLots: values.parkingLots,
        openingHours: values.openingHours,
        active: values.active,
        location: {
          latitude: values.latitude,
          longitude: values.longitude
        }
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: values.id,
          state: values.state.id,
          city: values.city.id,
          name: values.name,
          email: values.email,
          phone: values.phone,
          image: values.image,
          address: values.address,
          number: values.number,
          neighborhood: values.neighborhood,
          zipCode: values.zipCode,
          parkingLots: values.parkingLots,
          openingHours: values.openingHours,
          active: values.active,
          latitude: values.latitude,
          longitude: values.longitude
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
