import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Panel from 'components/panel'
import Spinner from 'components/spinner'
import createForm, { Form, Input, Select, Wysiwyg, Datepicker, CheckboxGroup, Buttons } from 'components/forms'
import Validation from 'utils/validation'
import { activeOptions, affirmativeOptions, productGroupSortOptions, daysOfTheWeekOptions } from 'utils/constants'
import SectionCollapse from 'components/section-collapse'
import Booleans from 'utils/booleans'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { Link } from 'react-router-dom'
import { routeTo } from 'router'
import SortableGrid from 'components/sortable-grid'
import { Thumbnail } from 'react-bootstrap'
import ProductGroupChangeOrderMutation from 'relay/mutations/ProductGroupChangeOrderMutation'

class ProductGroupsForm extends Component {
  onOrderChanged ({item, order}) {
    const { productGroup } = this.props
    ProductGroupChangeOrderMutation.commit({
      id: productGroup.id,
      productId: item.id,
      order: order + 1
    }, {
      onCompleted: ({ProductGroupChangeOrder}) => {},
      onError: (errors) => console.error(errors)
    })
  }

  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading, editing, productGroup } = this.props

    return (
      <div style={{backgroundColor: '#FFFFFF', marginBottom: '25px'}}>
        <SectionCollapse title='Dados gerais' visible>
          <Panel>
            <Form horizontal onSubmit={handleSubmit}>
              <Spinner visible={loading} />

              <Input name='title' horizontal type='text' label='Título' />

              <Select
                name='sortBy'
                horizontal
                label='Ordenação dos produtos'
                options={productGroupSortOptions}
                clearable={false}
                searchable={false}
              />

              <Wysiwyg name='description' horizontal label='Descrição' />

              <Select
                name='active'
                horizontal
                label='Situação'
                options={activeOptions}
                clearable={false}
                searchable={false}
              />

              <hr className='hr-line-dashed' />

              <Select
                name='temporary'
                horizontal
                label='Temporário'
                options={affirmativeOptions}
                clearable={false}
                searchable={false}
              />

              {this.props.temporary && (
                <div>
                  <Datepicker name='start' horizontal type='date' label='Início' />

                  <Datepicker name='end' horizontal type='date' label='Fim' />
                </div>
              )}

              <hr className='hr-line-dashed' />

              <CheckboxGroup
                name='weekDays'
                enableSelectAll
                horizontal
                label='Dias da semana'
                inline={false}
                options={
                  Object.keys(daysOfTheWeekOptions).map(day => {
                    return { id: day, name: daysOfTheWeekOptions[day] }
                  })
                }
              />

              <Buttons
                horizontal
                pristine={pristine}
                submitting={submitting}
                valid={valid}
                reset={reset}
              />
            </Form>
          </Panel>
        </SectionCollapse>

        <Panel title='Produtos'
          actions={productGroup && (
            <Link to={routeTo('dashboard.product-groups.add-products', { id: productGroup.id })} className='btn btn-primary btn-xs'>
              <i className='fa fa-cog' /> Adicionar/Remover produtos
            </Link>
          )}>
          {!editing && (
            <p>Salve o grupo antes de inserir produtos.</p>
          )}
          {productGroup && (
            <SortableGrid
              width={150}
              height={250}
              keyProperty='id'
              items={productGroup.products}
              renderItem={({item: product}) => (
                <Thumbnail src={product.image.url} alt={product.name} style={{height: '250px'}}>
                  <p style={{textAlign: 'center'}}>{product.name}</p>
                </Thumbnail>
              )}
              onOrderChanged={event => this.onOrderChanged(event)}
            />
          )}
        </Panel>
      </div>
    )
  }
}

ProductGroupsForm.defaultProps = {
  loading: false
}

ProductGroupsForm.propTypes = {
  loading: PropTypes.bool
}

const validation = new Validation({
  title: [Validation.required],
  active: [Validation.required],
  sortBy: [Validation.required]
})

const selector = formValueSelector(ProductGroupsForm.name)

export default connect(
  (state, props) => ({
    temporary: Booleans.valueOf(selector(state, 'temporary'))
  })
)(createForm(ProductGroupsForm, validation))
