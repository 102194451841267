import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation LoginEmailMutation($input: LoginEmailInput!) {
    LoginEmail(input: $input) {
      token
      me {
        firstName
      }
      error
      clientMutationId
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      viewer: viewer.id
    }
  }]
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: values
      },
      configs: getConfigs(viewer),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
