
import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation FidelityCardEditFieldMutation($input: FidelityCardEditFieldInput!) {
    FidelityCardEditField(input: $input) {
      fidelityCardForm {
        id
        title
        text
        fieldGroups {
          id
          name
          fields {
            id
            name
            description
            type
            required
            options
          }
        }
      }
      fidelityCardInput {
        id
        name
        type
        required
      }
    }
  }
`

function commit (values, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: values
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
