import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import UserEditMutation from 'relay/mutations/UserEditMutation'

import Content from 'components/templates/dashboard/content'
import { toForm, toMutation } from 'components/forms'
import { message } from 'utils/messages'
import { routeTo } from 'router'
import UsersForm from './users-form'
import UsersHelper from './users-helper'

class UsersEdit extends Component {
  state = {
    loading: false
  }

  _loading (loading) {
    this.setState({
      loading: loading
    })
  }

  _success () {
    this.props.actions.notify('success', 'Sucesso', 'Usuário alterado com sucesso')
    this.props.history.push(routeTo('dashboard.users'))
  }

  _error (message) {
    this.props.actions.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    UserEditMutation.commit(
      Environment,
      toMutation(values),
      this.props.viewer,
      {
        onCompleted: ({ UserEdit }) => {
          this._loading(false)

          if (UserEdit.error) {
            this._error(message(UserEdit.error))
          } else {
            this._success()
          }
        },
        onError: (transaction) => {
          this._loading(false)

          this._error(message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    return (
      <Content
        title='Alterar usuário'
        permission='users:edit'
        menu={{
          group: 'Acesso',
          link: 'Usuários'
        }}
        breadcrumb={{
          title: 'Usuários',
          items: [
            { 'url': '/dashboard', 'title': 'Dashboard' },
            { 'url': '/dashboard/users', 'title': 'Usuários' },
            { 'title': 'Alterar' }
          ]
        }}
      >
        <Row>
          <Col sm={7}>
            {!this.props.viewer ? (
              <UsersForm loading />
            ) : (
              <UsersForm
                loading={this.state.loading}
                initialValues={toForm(this.props.viewer.user)}
                onSubmit={(values) => this._onSubmit(values)}
              />
            )}
          </Col>

          <Col sm={5}>
            <UsersHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const UsersEditQueryRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query usersEdit_Query($id: ID!) {
        viewer {
          user(id: $id) {
            id
            role {
              id
              name
            }
            name
            email
          }
        }
      }
    `}
    variables={{ id: props.match.params.id }}
    render={({props: rendererProps}) => (
      <UsersEdit
        {...rendererProps}
        {...props}
      />
    )}
  />
)

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(appActionCreators, dispatch)
})

export default connect(null, mapDispatchToProps)(UsersEditQueryRenderer)
