export const bannerPositions = {
  MAIN: 'MAIN',
  MINI: 'MINI',
  INTERNAL: 'INTERNAL'
}

export const bannerPositionsTitles = {
  MAIN: {
    LIST: 'Banners principais',
    ADD: 'Adicionar banner principal',
    EDIT: 'Editar banner principal'
  },
  MINI: {
    LIST: 'Mini banners',
    ADD: 'Adicionar mini banner',
    EDIT: 'Editar mini banner'
  },
  INTERNAL: {
    LIST: 'Banners internos',
    ADD: 'Adicionar banner interno',
    EDIT: 'Editar banner interno'
  }
}

export const bannerPositionsRoutes = {
  MAIN: {
    LIST: 'dashboard.banners.main',
    ADD: 'dashboard.banners.main.add',
    EDIT: 'dashboard.banners.main.edit'
  },
  MINI: {
    LIST: 'dashboard.banners.mini',
    ADD: 'dashboard.banners.mini.add',
    EDIT: 'dashboard.banners.mini.edit'
  },
  INTERNAL: {
    LIST: 'dashboard.banners.internal',
    ADD: 'dashboard.banners.internal.add',
    EDIT: 'dashboard.banners.internal.edit'
  }
}

export const bannerPositionsPermissions = {
  MAIN: {
    LIST: 'banners:main:list',
    ADD: 'banners:main:add',
    EDIT: 'banners:main:edit',
    REMOVE: 'banners:main:remove'
  },
  MINI: {
    LIST: 'banners:mini:list',
    ADD: 'banners:mini:add',
    EDIT: 'banners:mini:edit',
    REMOVE: 'banners:mini:remove'
  },
  INTERNAL: {
    LIST: 'banners:internal:list',
    ADD: 'banners:internal:add',
    EDIT: 'banners:internal:edit',
    REMOVE: 'banners:internal:remove'
  }
}
