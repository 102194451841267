import { CHANGE_ACTIVE_MENU, TOGGLE_MINI_MENU } from './constants'

export function changeActiveMenu (group, link = '') {
  return function (dispatch) {
    dispatch(
      {
        type: CHANGE_ACTIVE_MENU,
        payload: {
          group: group,
          link: link
        }
      }
    )
  }
}

export function toggleMiniMenu (mini) {
  return function (dispatch) {
    dispatch(
      {
        type: TOGGLE_MINI_MENU,
        payload: {
          mini: mini
        }
      }
    )
  }
}
