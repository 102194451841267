import { commitMutation, graphql } from 'react-relay'
import { removeUpdater } from 'relay'

const mutation = graphql`
  mutation LeadRemoveMutation($input: LeadRemoveInput!) {
    LeadRemove(input: $input) {
      deletedId
      error
    }
  }
`

function commit (environment, lead, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: lead.id
        }
      },
      updater: (store) => {
        const payload = store.getRootField('LeadRemove')
        const deletedId = payload.getValue('deletedId')

        removeUpdater(store, viewer, 'list_leads', deletedId)
      },
      optimisticUpdater: store => {
        removeUpdater(store, viewer, 'list_leads', lead.id)
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
