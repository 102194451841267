import { commitMutation, graphql } from 'react-relay'
import Environment from 'relay'

const mutation = graphql`
  mutation SupermarketThemeEditMutation($input: SupermarketThemeEditInput!) {
    SupermarketThemeEdit(input: $input) {
      supermarket {
        id
        theme {
          themeId
          overrideColors
          colors {
            name
            value
          }
        }
      }
    }
  }
`

function commit (values, config) {
  return commitMutation(
    Environment,
    {
      mutation,
      variables: {
        input: values
      },
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default {commit}
