import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { FormControl } from 'react-bootstrap'

import Group from './group'

const Textarea = (props) => (
  <Group {...props}>
    <FormControl componentClass='textarea' placeholder={props.placeholder} {...props.input} />
  </Group>
)

const TextareaField = (props) => (
  <Field {...props} component={Textarea} />
)

TextareaField.propTypes = {
  placeholder: PropTypes.string
}

TextareaField.defaultProps = {
  placeholder: ''
}

export default TextareaField
