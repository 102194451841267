/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LeadRemoveInput = {|
  id?: ?string,
  clientMutationId?: ?string,
|};
export type LeadRemoveMutationVariables = {|
  input: LeadRemoveInput
|};
export type LeadRemoveMutationResponse = {|
  +LeadRemove: ?{|
    +deletedId: ?string,
    +error: ?string,
  |}
|};
export type LeadRemoveMutation = {|
  variables: LeadRemoveMutationVariables,
  response: LeadRemoveMutationResponse,
|};
*/


/*
mutation LeadRemoveMutation(
  $input: LeadRemoveInput!
) {
  LeadRemove(input: $input) {
    deletedId
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "LeadRemovePayload",
    "kind": "LinkedField",
    "name": "LeadRemove",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LeadRemoveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LeadRemoveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "973f32185feb338f840f1b87c7784e6d",
    "id": null,
    "metadata": {},
    "name": "LeadRemoveMutation",
    "operationKind": "mutation",
    "text": "mutation LeadRemoveMutation(\n  $input: LeadRemoveInput!\n) {\n  LeadRemove(input: $input) {\n    deletedId\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b41785bfb4f87696d64d06fc3b4a2f2f';

module.exports = node;
