import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-relay'
import Environment from 'relay'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import Booleans from 'utils/booleans'
import Panel from 'components/panel'
import Spinner from 'components/spinner'
import createForm, { Form, File, Input, CheckboxGroup, Select, Datetimepicker, Buttons, DynamicSelectCreatable } from 'components/forms'
import Validation from 'utils/validation'
import { activeOptions, affirmativeOptions, daysOfTheWeekOptions } from 'utils/constants'

class OfferCatalogsForm extends Component {
  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading, viewer, maxUploadSize } = this.props
    const hasMoreThanOneCompany = viewer.companies.edges.length > 1

    return (
      <Form horizontal onSubmit={handleSubmit}>
        <Spinner visible={loading} />

        <Panel>
          <File
            name='file'
            horizontal
            label='Arquivo PDF'
            accept='application/pdf'
            maxSize={maxUploadSize}
          />

          <File name='image' horizontal label='Imagem de capa' />

          <Input name='title' horizontal type='text' label='Título de identificação' />

          <hr className='hr-line-dashed' />
          <DynamicSelectCreatable
            name='category'
            horizontal
            label='Categoria'
            createLabel='Criar a categoria'
            environment={Environment}
            query={graphql`
              query offerCatalogsForm_categories_Query($search: String) {
                viewer {
                  ...offerCatalogsForm_categories_viewer
                }
              }
            `}
            fragments={{
              viewer: graphql`
                fragment offerCatalogsForm_categories_viewer on Viewer {
                  id
                  offerCatalogCategories(search: $search) {
                    id
                    name
                  }
                }
              `
            }}
            usesConnection={false}
          />

          {hasMoreThanOneCompany && (
            <hr className='hr-line-dashed' />
          )}
          {hasMoreThanOneCompany && (
            <CheckboxGroup
              horizontal
              enableSelectAll
              name='companies'
              label='Lojas'
              options={viewer.companies.edges.map(e => e.node)}
            />
          )}

          <hr className='hr-line-dashed' />
          <Select
            name='active'
            horizontal
            label='Situação'
            options={activeOptions}
            clearable={false}
          />
        </Panel>

        <Panel>
          <Select
            name='temporary'
            horizontal
            label='Temporário'
            options={affirmativeOptions}
            clearable={false}
          />

          {this.props.temporary && (
            <div>
              <Datetimepicker name='startDate' horizontal type='date' label='Início' />

              <Datetimepicker name='endDate' horizontal type='date' label='Fim' />
            </div>
          )}

          <hr className='hr-line-dashed' />
          <CheckboxGroup
            name='weekDays'
            enableSelectAll
            horizontal
            label='Dias'
            inline={false}
            options={
              Object.keys(daysOfTheWeekOptions).map(day => {
                return { id: day, name: daysOfTheWeekOptions[day] }
              })
            }
          />
        </Panel>

        <Panel>
          <Buttons
            horizontal
            pristine={pristine}
            submitting={submitting}
            valid={valid}
            reset={reset}
           />
        </Panel>
      </Form>
    )
  }
}

OfferCatalogsForm.defaultProps = {
  loading: false
}

OfferCatalogsForm.propTypes = {
  loading: PropTypes.bool
}

const selector = formValueSelector(OfferCatalogsForm.name)

export default function (editing = false) {
  const validationRules = {
    title: [Validation.required],
    active: [Validation.required],
    category: [Validation.required]
  }

  if (!editing) {
    validationRules.file = [Validation.required]
  }

  const validation = new Validation(validationRules)

  return connect(
    (state, props) => ({
      temporary: Booleans.valueOf(selector(state, 'temporary'))
    })
  )(createForm(OfferCatalogsForm, validation))
}
