import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation BannerEditOrderMutation($input: BannerEditOrderInput!) {
    BannerEditOrder(input: $input) {
      banner {
        id
        order
      }
      error
    }
  }
`

function getConfigs (values) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      banner: values.id
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    BannerEditOrder: {
      banner: {
        order: values.order
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: values.id,
          order: values.order
        }
      },
      configs: getConfigs(values),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
