import React from 'react'
import PropTypes from 'prop-types'

import Panel from 'components/panel'
import Spinner from 'components/spinner'
import createForm, { Form, Input, Checkboxes, Buttons } from 'components/forms'
import Validation from 'utils/validation'

const RolesForm = (props) => {
  const { handleSubmit, pristine, submitting, valid, reset } = props

  return (
    <Form horizontal onSubmit={handleSubmit}>
      <Spinner visible={props.loading} />

      <Panel>
        <Input name='name' horizontal type='text' label='Nome' />
      </Panel>

      <Panel title='Banners'>
        <Checkboxes
          name='permissions'
          horizontal
          label='Banners principais'
          list={[
            { value: 'banners:main:list', name: 'Listar' },
            { value: 'banners:main:add', name: 'Adicionar' },
            { value: 'banners:main:edit', name: 'Alterar' },
            { value: 'banners:main:remove', name: 'Remover' }
          ]}
        />

        <hr className='hr-line-dashed' />
        <Checkboxes
          name='permissions'
          horizontal
          label='Mini banners'
          list={[
            { value: 'banners:mini:list', name: 'Listar' },
            { value: 'banners:mini:add', name: 'Adicionar' },
            { value: 'banners:mini:edit', name: 'Alterar' },
            { value: 'banners:mini:remove', name: 'Remover' }
          ]}
        />

        <hr className='hr-line-dashed' />
        <Checkboxes
          name='permissions'
          horizontal
          label='Banners internos'
          list={[
            { value: 'banners:internal:list', name: 'Listar' },
            { value: 'banners:internal:add', name: 'Adicionar' },
            { value: 'banners:internal:edit', name: 'Alterar' },
            { value: 'banners:internal:remove', name: 'Remover' }
          ]}
        />
      </Panel>

      <Panel title='Mensagens'>
        <Checkboxes
          name='permissions'
          horizontal
          list={[
            { value: 'contact-messages:list', name: 'Listar' },
            { value: 'contact-messages:view', name: 'Visualizar' }
          ]}
        />
      </Panel>

      <Panel title='Leads'>
        <Checkboxes
          name='permissions'
          horizontal
          list={[
            { value: 'leads:list', name: 'Listar' },
            { value: 'leads:add', name: 'Adicionar' },
            { value: 'leads:edit', name: 'Alterar' },
            { value: 'leads:remove', name: 'Remover' },
            { value: 'leads:export', name: 'Exportar' }
          ]}
        />
      </Panel>

      <Panel title='Notícias'>
        <Checkboxes
          name='permissions'
          horizontal
          list={[
            { value: 'articles:list', name: 'Listar' },
            { value: 'articles:add', name: 'Adicionar' },
            { value: 'articles:edit', name: 'Alterar' },
            { value: 'articles:remove', name: 'Remover' }
          ]}
        />
      </Panel>

      <Panel title='Receitas'>
        <Checkboxes
          name='permissions'
          horizontal
          list={[
            { value: 'recipes:list', name: 'Listar' },
            { value: 'recipes:add', name: 'Adicionar' },
            { value: 'recipes:edit', name: 'Alterar' },
            { value: 'recipes:remove', name: 'Remover' }
          ]}
        />
      </Panel>

      <Panel title='Contatos'>
        <Checkboxes
          name='permissions'
          horizontal
          list={[
            { value: 'contacts:list', name: 'Listar' },
            { value: 'contacts:add', name: 'Adicionar' },
            { value: 'contacts:edit', name: 'Alterar' },
            { value: 'contacts:remove', name: 'Remover' }
          ]}
        />
      </Panel>

      <Panel title='Encartes'>
        <Checkboxes
          name='permissions'
          horizontal
          list={[
            { value: 'offer-catalogs:list', name: 'Listar' },
            { value: 'offer-catalogs:add', name: 'Adicionar' },
            { value: 'offer-catalogs:edit', name: 'Alterar' },
            { value: 'offer-catalogs:remove', name: 'Remover' }
          ]}
        />
      </Panel>

      <Panel title='Produtos'>
        <Checkboxes
          name='permissions'
          horizontal
          list={[
            { value: 'products:list', name: 'Listar' },
            { value: 'products:add', name: 'Adicionar' },
            { value: 'products:edit', name: 'Alterar' },
            { value: 'products:remove', name: 'Remover' }
          ]}
        />
      </Panel>

      <Panel title='Lojas'>
        <Checkboxes
          name='permissions'
          horizontal
          list={[
            { value: 'companies:list', name: 'Listar' },
            { value: 'companies:add', name: 'Adicionar' },
            { value: 'companies:edit', name: 'Alterar' },
            { value: 'companies:remove', name: 'Remover' }
          ]}
        />
      </Panel>

      <Panel title='Cartão Fidelidade'>
        <Checkboxes
          name='permissions'
          horizontal
          list={[
            { value: 'fidelity-card:edit', name: 'Alterar' }
          ]}
        />
      </Panel>

      <Panel title='Acesso'>
        <Checkboxes
          name='permissions'
          horizontal
          label='Grupos'
          list={[
            { value: 'roles:list', name: 'Listar' },
            { value: 'roles:add', name: 'Adicionar' },
            { value: 'roles:edit', name: 'Alterar' },
            { value: 'roles:remove', name: 'Remover' }
          ]}
        />

        <hr className='hr-line-dashed' />
        <Checkboxes
          name='permissions'
          horizontal
          label='Usuários'
          list={[
            { value: 'users:list', name: 'Listar' },
            { value: 'users:add', name: 'Adicionar' },
            { value: 'users:edit', name: 'Alterar' },
            { value: 'users:remove', name: 'Remover' }
          ]}
        />
      </Panel>

      <Panel title='Política de Privacidade'>
        <Checkboxes
          name='permissions'
          horizontal
          list={[
            { value: 'privacy:edit', name: 'Alterar' }
          ]}
        />
      </Panel>

      <Panel title='Configurações'>
        <Checkboxes
          name='permissions'
          horizontal
          list={[
            { value: 'configuration:general:edit', name: 'Geral' },
            { value: 'configuration:social:edit', name: 'Mídias Sociais' },
            { value: 'configuration:search:edit', name: 'Buscadores' },
            { value: 'configuration:email:edit', name: 'E-mail' },
            { value: 'configuration:maintenance:edit', name: 'Disponibilidade' }
          ]}
        />
      </Panel>

      <Panel>
        <Buttons
          horizontal
          pristine={pristine}
          submitting={submitting}
          valid={valid}
          reset={reset}
        />
      </Panel>
    </Form>
  )
}

RolesForm.defaultProps = {
  loading: false
}

RolesForm.propTypes = {
  loading: PropTypes.bool
}

const RolesValidation = new Validation({
  name: [Validation.required]
})

export default createForm(RolesForm, RolesValidation)
