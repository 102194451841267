import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation RoleEditMutation($input: RoleEditInput!) {
    RoleEdit(input: $input) {
      role {
        id
        name
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'FIELDS_CHANGE',
    fieldIDs: {
      role: viewer.role.id
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    RoleEdit: {
      role: {
        name: values.name,
        permissions: values.permissions
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          id: values.id,
          name: values.name,
          permissions: values.permissions
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
