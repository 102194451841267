import React, { Component } from 'react'
import { Table, Actions, Action, Ordenator } from 'components/datalist'
import Panel from 'components/panel'
import ConfirmDialog from 'components/app/confirm-dialog'
import createForm, { Form, Input, Buttons } from 'components/forms'
import Spinner from 'components/spinner'
import Validation from 'utils/validation'
import { fieldTypes } from 'utils/constants'
import FidelityCardFieldModal from './fidelity-card-field-modal'
import FidelityCardRemoveFieldMutation from 'relay/mutations/FidelityCardRemoveFieldMutation'
import FidelityCardEditFieldOrderMutation from 'relay/mutations/FidelityCardEditFieldOrderMutation'

class FidelityCardGroupForm extends Component {
  _removeField (field) {
    const {id} = field
    FidelityCardRemoveFieldMutation.commit(id, {
      onCompleted: ({FidelityCardRemoveField}) => { },
      onError: (errors) => {}
    })
  }

  _renderForm (field) {
    const FieldFormModal = FidelityCardFieldModal(`FidelityCardInputModalForm${field ? field.id : 'Add'}`)

    if (field) {
      return (
        <FieldFormModal
          triggerIcon='fa-edit'
          groupId={this.props.initialValues.id}
          editing
          initialValues={field} />
      )
    } else {
      return (
        <FieldFormModal
          showTriggerAsButton
          triggerLabel='Adicionar'
          triggerIcon='fa-plus'
          groupId={this.props.initialValues.id} />
      )
    }
  }

  _onOrderChange (direction, field) {
    FidelityCardEditFieldOrderMutation.commit(
      {id: field.id, direction},
      {
        onCompleted: ({ FidelityCardEditFieldOrder }) => { },
        onError: (transaction) => {}
      }
    )
  }

  _showUp (field, fieldList) {
    if (fieldList.length === 1) {
      return false
    }

    if (fieldList[0].id === field.id) {
      return false
    }

    return true
  }

  _showDown (field, fieldList) {
    if (fieldList.length === 1) {
      return false
    }

    if (fieldList[fieldList.length - 1].id === field.id) {
      return false
    }

    return true
  }

  render () {
    const { handleSubmit, pristine, submitting, valid, reset, loading, editing } = this.props

    return (
      <div>
        <Form horizontal onSubmit={handleSubmit}>
          <Spinner visible={loading} />

          <Panel>
            <Input name='name' horizontal type='text' label='Nome' />

            <Buttons
              horizontal
              pristine={pristine}
              submitting={submitting}
              valid={valid}
              reset={reset}
            />
          </Panel>

          {editing && (
            <Table
              title='Campos do Formulário'
              actions={this._renderForm()}
              columns={[
                {name: 'Nome', property: 'name'},
                {name: 'Tipo', callback: group => fieldTypes[group.type]},
                {name: 'Obrigatório', callback: (field) => field.required ? 'Sim' : 'Não'},
                {
                  name: 'Ordem',
                  callback: (field, { data }) => (
                    <Ordenator
                      showUp={this._showUp(field, data)}
                      showDown={this._showDown(field, data)}
                      onUp={() => this._onOrderChange('UP', field)}
                      onDown={() => this._onOrderChange('DOWN', field)} />
                  )
                },
                {
                  name: 'Ações',
                  callback: (field) => (
                    <Actions>
                      <Action title='Alterar'>
                        {this._renderForm(field)}
                      </Action>

                      <Action title='Excluir'>
                        <ConfirmDialog
                          icon='fa fa-trash-o'
                          title={field.name}
                          subtitle='Deseja remover este Campo? Esta ação não poderá ser desfeita.'
                          onOk={() => this._removeField(field)}>
                          <i className='fa fa-trash-o' />
                        </ConfirmDialog>
                      </Action>
                    </Actions>
                  )
                }
              ]}
              data={this.props.initialValues.fields}
            />
          )}
        </Form>
      </div>
    )
  }
}

const validation = new Validation({
  name: [Validation.required]
})

export default createForm(FidelityCardGroupForm, validation)
