import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { connect } from 'react-redux'

const MenuLink = (props) => {
  const active = props.menu.group === props.group && props.menu.link === props.title

  return (
    <li className={active ? 'active' : ''}>
      {props.icon ? (
        <Link to={props.url}>
          <i className={props.icon} />
          <span className='nav-label'>
            {props.title}
          </span>
        </Link>
      ) : (
        <Link to={props.url}>
          {props.title}
        </Link>
      )}
    </li>
  )
}

MenuLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string,
  group: PropTypes.string
}

MenuLink.defaultProps = {
  group: ''
}

const mapStateToProps = (state) => ({
  menu: state.dashboard.menu
})

export default connect(mapStateToProps)(MenuLink)
