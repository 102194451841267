import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const Breadcrumb = (props) => (
  <div className='row wrapper border-bottom white-bg page-heading'>
    <div className={props.actions ? 'col-sm-4' : 'col-sm-12'}>
      <h2>{props.title}</h2>

      <ol className='breadcrumb'>
        {props.items.map((item, index) => {
          return (item.url) ? (
            <li key={index}>
              <Link to={item.url}>
                {item.title}
              </Link>
            </li>
          ) : (
            <li className='active' key={index}>
              <strong>{item.title}</strong>
            </li>
          )
        })}
      </ol>
    </div>

    {props.actions && (
      <div className='col-sm-8'>
        <div className='title-action'>
          {props.actions}
        </div>
      </div>
    )}
  </div>
)

Breadcrumb.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  actions: PropTypes.node
}

export default Breadcrumb
