import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import { render } from 'react-dom'
import { AppContainer } from 'react-hot-loader'

import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import reducers from './reducers'

import App from 'components/app'

const store = createStore(
  reducers,
  applyMiddleware(thunk)
)

const renderApp = (NextApp) => {
  render(
    <AppContainer>
      <Provider store={ store }>
        <NextApp />
      </Provider>
    </AppContainer>,
    document.querySelector('[data-js="app"]')
  )
}

renderApp(App)
