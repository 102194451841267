import React from 'react'
import { ButtonGroup, Button } from 'react-bootstrap'

export default (props) => (
  <ButtonGroup>
    {props.showDown && (
      <Button onClick={() => props.onDown()}>
        <i className='fa fa-chevron-down' />
      </Button>
    )}

    {props.showUp && (
      <Button onClick={() => props.onUp()}>
        <i className='fa fa-chevron-up' />
      </Button>
    )}
  </ButtonGroup>
)
