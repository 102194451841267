import { commitMutation, graphql } from 'react-relay'

const mutation = graphql`
  mutation RecipeAddMutation($input: RecipeAddInput!) {
    RecipeAdd(input: $input) {
      recipeEdge {
        node {
          id
          state {
            id
            name
          }
          city {
            id
            name
          }
          title
          image {
            key
            url
          }
          advertisingImage {
            key
            url
          }
          preparationTime
          yield
          ingredients
          instructions
          author
          date
          active
        }
      }
      viewer {
        recipes {
          edges {
            node {
              id
              title
            }
          }
        }
      }
      error
    }
  }
`

function getConfigs (viewer) {
  return [{
    type: 'RANGE_ADD',
    parentName: 'viewer',
    parentID: viewer.id,
    connectionName: 'recipes',
    edgeName: 'recipeEdge',
    rangeBehaviors: {
      '': 'prepend'
    }
  }]
}

function getOptimisticResponse (values) {
  return {
    RecipeAdd: {
      recipe: {
        state: values.state,
        city: values.city,
        title: values.title,
        image: values.image,
        advertisingImage: values.advertisingImage,
        preparationTime: values.preparationTime,
        yield: values.yield,
        ingredients: values.ingredients,
        instructions: values.instructions,
        author: values.author,
        date: values.date,
        active: values.active
      }
    }
  }
}

function commit (environment, values, viewer, config) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: {
          state: values.state ? values.state.id : null,
          city: values.city ? values.city.id : null,
          title: values.title,
          image: values.image,
          advertisingImage: values.advertisingImage,
          preparationTime: values.preparationTime,
          yield: values.yield,
          ingredients: values.ingredients,
          instructions: values.instructions,
          author: values.author,
          date: values.date,
          active: values.active
        }
      },
      configs: getConfigs(viewer),
      optimisticResponse: () => getOptimisticResponse(values),
      onCompleted: config.onCompleted,
      onError: config.onError
    }
  )
}

export default { commit }
