import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { FormControl, InputGroup } from 'react-bootstrap'

import Group from './group'

const Input = (props) => (
  <Group {...props}>
    {props.addon ? (
      <InputGroup>
        <InputGroup.Addon>{props.addon}</InputGroup.Addon>
        <FormControl type={props.type} placeholder={props.placeholder} {...props.input} />
      </InputGroup>
    ) : (
      <FormControl type={props.type} placeholder={props.placeholder} {...props.input} />
    )}
  </Group>
)

const InputField = (props) => (
  <Field {...props} component={Input} normalize={value => value ? Number(value) : null} />
)

InputField.propTypes = {
  placeholder: PropTypes.string
}

InputField.defaultProps = {
  type: 'number',
  placeholder: ''
}

export default InputField
