import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'
import BannerAddMutation from 'relay/mutations/BannerAddMutation'

import Content from 'components/templates/dashboard/content'
import { toMutation } from 'components/forms'
import { message } from 'utils/messages'
import { routeTo } from 'router'
import { permission, title, route } from './utils'
import BannersForm from './banners-form'
import BannersHelper from './banners-helper'
import { daysOfTheWeekOptions } from 'utils/constants'

const extractCompanyIds = viewer => viewer.companies.edges.map(e => e.node.id)

class BannersAdd extends Component {
  state = {
    loading: false
  }

  _loading (loading) {
    this.setState({
      loading: loading
    })
  }

  _success () {
    this.props.actions.notify('success', 'Sucesso', 'Banner adicionado com sucesso')
    this.props.history.push(routeTo(route(this.props.position)))
  }

  _error (message) {
    this.props.actions.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    values.position = this.props.position

    BannerAddMutation.commit(
      Environment,
      toMutation(values),
      this.props.viewer,
      {
        onCompleted: ({ BannerAdd }) => {
          this._loading(false)

          if (BannerAdd.error) {
            this._error(message(BannerAdd.error))
          } else {
            this._success()
          }
        },
        onError: (transaction) => {
          this._loading(false)

          this._error(message('SERVER_OFFLINE'))
        }
      }
    )
  }

  render () {
    const { viewer, position } = this.props
    return (
      <Content
        title={title(this.props.position, 'ADD')}
        permission={permission(this.props.position, 'ADD')}
        menu={{
          group: 'Banners',
          link: title(this.props.position)
        }}
        breadcrumb={{
          title: title(this.props.position),
          items: [
            { 'url': routeTo('dashboard'), 'title': 'Dashboard' },
            { 'url': routeTo(route(this.props.position)), 'title': title(this.props.position) },
            { 'title': 'Adicionar' }
          ]
        }}
      >
        <Row>
          <Col sm={7}>
            <BannersForm
              loading={this.state.loading}
              onSubmit={(values) => this._onSubmit(values)}
              viewer={viewer}
              position={position}
              initialValues={{
                companies: extractCompanyIds(viewer),
                days: Object.keys(daysOfTheWeekOptions),
                active: true,
                temporary: false,
                linkTarget: 'BLANK'
              }}
            />
          </Col>

          <Col sm={5}>
            <BannersHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const BannersAddQueryRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query bannersAdd_Query {
        viewer {
          id
          companies(selectableInMenu: true) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `}
    render={({props: rendererProps}) => {
      if (!rendererProps) {
        return null
      }

      return (
        <BannersAdd
          {...rendererProps}
          {...props}
        />
      )
    }}
  />
)

export default function BannersAddWithPosition (position) {
  const BannersAddWithPosition = (props) => (
    <BannersAddQueryRenderer
      {...props}
      position={position}
    />
  )

  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(appActionCreators, dispatch)
  })

  return connect(null, mapDispatchToProps)(BannersAddWithPosition)
}
