import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'

import { connect } from 'react-redux'
import * as appActionCreators from 'components/app/actions'

import { QueryRenderer, graphql } from 'react-relay'
import Environment from 'relay'

import Content from 'components/templates/dashboard/content'
import { toMutation } from 'components/forms'
import { getMessagesFromErrors } from 'utils/messages'
import { routeTo } from 'router'
import OfferCatalogsForm from './offer-catalogs-form'
import OfferCatalogsHelper from './offer-catalogs-helper'
import OfferCatalogAddMutation from 'relay/mutations/OfferCatalogAddMutation'
import { daysOfTheWeekOptions } from 'utils/constants'
import moment from 'moment'

const AddForm = OfferCatalogsForm()

class OfferCatalogsAdd extends Component {
  constructor (props) {
    super(props)

    this.state = {loading: false}
  }

  _loading (loading) {
    this.setState({...this.state, loading})
  }

  _success () {
    this.props.notify('success', 'Sucesso', 'Encarte adicionado com sucesso')
    this.props.history.push(routeTo('dashboard.offer-catalogs'))
  }

  _error (message) {
    this.props.notify('error', 'Erro', message)
  }

  _onSubmit (values) {
    this._loading(true)

    OfferCatalogAddMutation.commit(Environment, toMutation(values), this.props.viewer, {
      onCompleted: ({OfferCatalogAdd}) => {
        this._loading(false)

        this._success()
      },
      onError: (errors) => {
        this._loading(false)

        getMessagesFromErrors(errors).forEach(m => this._error(m))
      }
    })
  }

  render () {
    const { viewer, config } = this.props
    return (
      <Content
        title='Adicionar Encarte'
        permission='offer-catalogs:add'
        menu={{link: 'Encartes'}}
        breadcrumb={{
          title: 'Encartes',
          items: [
            {url: '/dashboard', title: 'Dashboard'},
            {url: '/dashboard/offer-catalogs', title: 'Encartes'},
            {title: 'Adicionar'}
          ]
        }} >
        <Row>
          <Col sm={7}>
            <AddForm
              loading={this.state.loading}
              onSubmit={values => this._onSubmit(values)}
              viewer={viewer}
              maxUploadSize={config.maxUploadSize}
              initialValues={{
                companies: viewer.companies.edges.map(e => e.node.id),
                active: true,
                weekDays: Object.keys(daysOfTheWeekOptions),
                temporary: true,
                startDate: moment().toDate(),
                endDate: moment().add(1, 'month').hour(23).minutes(59).toDate()
              }}
            />
          </Col>
          <Col sm={5}>
            <OfferCatalogsHelper />
          </Col>
        </Row>
      </Content>
    )
  }
}

const OfferCatalogsAddRenderer = (props) => (
  <QueryRenderer
    environment={Environment}
    query={graphql`
      query offerCatalogsAdd_Query {
        config {
          maxUploadSize
        }
        viewer {
          id
          companies(selectableInMenu: true) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    `}
    render={({props: rendererProps}) => {
      if (!rendererProps) {
        return null
      }

      return (
        <OfferCatalogsAdd {...rendererProps} {...props} />
      )
    }}
  />
)

export default connect(null, appActionCreators)(OfferCatalogsAddRenderer)
