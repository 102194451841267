import { ConnectionHandler } from 'relay-runtime'

function update (store, payload) {
  const record = store.get(payload.dataID)
  if (!record) {
    return
  }

  const serverViewer = record.getLinkedRecord(payload.fieldKey)
  if (!serverViewer) {
    return
  }

  record.setLinkedRecord(serverViewer, payload.handleKey)

  const root = store.getRoot()
  root.setLinkedRecord(serverViewer, payload.handleKey)
}

export default function handlerProvider (handle) {
  switch (handle) {
    // Augment (or remove from) this list:
    case 'connection':
      return ConnectionHandler
    case 'viewer':
      return { update }
    default:
      throw new Error(`handlerProvider: No handler provided for ${handle}`)
  }
}
